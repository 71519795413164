import React, {
  ComponentProps,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import Shadow from './Shadow';

function ScrollViewWithShadow(
  props: PropsWithChildren<ComponentProps<typeof ScrollView>> & {
    linearGradientProps?: Omit<
      ComponentProps<typeof LinearGradient>,
      'children'
    >;
    shadowSize?: number;
    shadowTop?: number;
    shadowBottom?: number;
  },
) {
  const {
    children,
    linearGradientProps,
    shadowTop = 0,
    shadowBottom = 0,
    shadowSize = 20,
    onScroll,
    onContentSizeChange,
    ...rest
  } = props;

  const [layoutHeight, setLayoutHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);
  const [scrollYOffset, setScrollOffset] = useState(0);

  const shadowEnabled = useMemo(() => {
    return contentHeight > layoutHeight;
  }, [contentHeight, layoutHeight]);

  const shadowTopVisible = useMemo(() => {
    return scrollYOffset > shadowSize / 2;
  }, [scrollYOffset, shadowSize]);

  const shadowBottomVisible = useMemo(() => {
    return (
      scrollYOffset + layoutHeight <
      contentHeight + shadowTop + shadowBottom - shadowSize / 2
    );
  }, [
    contentHeight,
    layoutHeight,
    scrollYOffset,
    shadowSize,
    shadowTop,
    shadowBottom,
  ]);

  const shadowTopComponent = useMemo(() => {
    return shadowEnabled ? (
      <Shadow
        visible={shadowTopVisible}
        size={shadowSize}
        linearGradientProps={{
          ...(linearGradientProps ?? {}),
          start: {x: 0, y: 0},
          end: {x: 0, y: 1},
          style: [{top: shadowTop}, linearGradientProps?.style ?? {}],
        }}
      />
    ) : null;
  }, [
    linearGradientProps,
    shadowEnabled,
    shadowSize,
    shadowTop,
    shadowTopVisible,
  ]);

  const shadowBottomComponent = useMemo(() => {
    return shadowEnabled ? (
      <Shadow
        visible={shadowBottomVisible}
        size={shadowSize}
        linearGradientProps={{
          ...(linearGradientProps ?? {}),
          start: {x: 0, y: 1},
          end: {x: 0, y: 0},
          style: [{bottom: shadowBottom}, linearGradientProps?.style ?? {}],
        }}
      />
    ) : null;
  }, [
    linearGradientProps,
    shadowBottom,
    shadowBottomVisible,
    shadowEnabled,
    shadowSize,
  ]);

  const _onScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      setScrollOffset(event.nativeEvent.contentOffset.y);
      onScroll?.call(null, event);
    },
    [onScroll],
  );
  const _onContentSizeChange = useCallback(
    (w: number, h: number) => {
      setContentHeight(h);
      onContentSizeChange?.call(null, w, h);
    },
    [onContentSizeChange],
  );

  return (
    <View
      style={styles.container}
      onLayout={e => setLayoutHeight(e.nativeEvent.layout.height)}
    >
      {shadowTopComponent}
      <ScrollView
        scrollEventThrottle={100}
        {...rest}
        onScroll={_onScroll}
        onContentSizeChange={_onContentSizeChange}
      >
        {children}
      </ScrollView>
      {shadowBottomComponent}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {flexGrow: 1, flex: 1},
});

export default ScrollViewWithShadow;
