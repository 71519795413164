import {Logger} from '@b2cmessenger/doppio-shared';
import {isInitLikeAction, logout, setWorkplaceSigningKey} from '@store/actions';
import {actionChannel, all, put, take} from 'redux-saga/effects';
import SecureStore from '@utils/SecureStore';

const STORAGE_KEY = 'WORKPLACE_SIGNKEY';

function* setWorkplaceSignKey() {
  const channel = yield actionChannel(isInitLikeAction);
  while (true) {
    const action = yield take(channel);
    if (
      'workPlaceSignKey' in action.payload &&
      action.payload.workPlaceSignKey
    ) {
      try {
        yield SecureStore.set(STORAGE_KEY, action.payload.workPlaceSignKey);
        yield put(setWorkplaceSigningKey(STORAGE_KEY));
        Logger.verboseTag('setWorkplaceSignKey', 'succeed');
      } catch (e) {
        Logger.errorTag('setWorkplaceSignKey', e);
      }
    }
  }
}

function* resetWorkplaceSignKey() {
  const channel = yield actionChannel(logout);
  while (true) {
    yield take(channel);
    try {
      yield SecureStore.remove(STORAGE_KEY);
      yield put(setWorkplaceSigningKey(null));
      Logger.verboseTag('resetWorkplaceSignKey', 'succeed');
    } catch (e) {
      Logger.errorTag('resetWorkplaceSignKey', e);
    }
  }
}

export default function* workplaceSigningSaga() {
  yield all([setWorkplaceSignKey(), resetWorkplaceSignKey()]);
}
