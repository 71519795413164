import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {isTablet} from 'react-native-device-info';
import {localization} from '@shared';

const Separator = isTablet() ? ' ' : '\n';
const initialState = {
  stage: 'send',
  header: '',
  description: '',
  primaryButtonText: '',
  inviteLink: null as string | null,
};
export const slice = createSlice({
  name: 'addEmployee',
  initialState: initialState,
  reducers: {
    send: () => ({
      ...initialState,
    }),
    sendFailed: () => ({
      stage: 'sendFailed',
      header: localization.t('Screens.BusinessInvite.slice.invitationFailed'),
      description: localization.t(
        'Screens.BusinessInvite.slice.unableToCreateInvitePleaseTryAgainLaterOrContactSu',
      ),
      primaryButtonText: localization.t(
        'Screens.BusinessInvite.slice.tryAgain',
      ),
      inviteLink: null,
    }),
    done: {
      prepare: (role: 'employee' | 'pos', code: string) => ({
        payload: {role, code},
      }),
      reducer: (
        state,
        action: PayloadAction<{role: 'employee' | 'pos'; code: string}>,
      ) => ({
        stage: 'done',
        ...(action.payload.role === 'employee'
          ? {
              header: localization.t(
                'Screens.BusinessInvite.slice.askTheEmployeeToScanQr',
                {
                  s: Separator,
                },
              ),
              description: localization.t(
                'Screens.BusinessInvite.slice.askTheEmployeeToScanQrWithAPhoneCameraToAddHimToYo',
              ),
            }
          : {
              header: localization.t(
                'Screens.BusinessInvite.slice.scanQrWithYourDevice',
                {s: Separator},
              ),
              description: localization.t(
                'Screens.BusinessInvite.slice.downloadAppOnYourDevicePressJoinToCompanyButtonAnd',
              ),
            }),

        primaryButtonText: localization.t(
          'Screens.BusinessInvite.slice.backToProfile',
        ),
        inviteLink: action.payload.code,
      }),
    },
  },
});
