import {localization, PlaceAppearance} from '@b2cmessenger/doppio-core';
import {useCallback} from 'react';
import {useTranslation} from '../Localization/useTranslation';

export function usePlaceAppearance(placeId: number) {
  const {t} = useTranslation();

  const appearance = PlaceAppearance.usePlaceAppearance(placeId);
  const {IconStamp, IconAward, color} = appearance;
  const stamp = useCallback(
    (qty: number = 1) => {
      return t('common.stamp', {count: qty});
    },
    [t],
  );

  const award = useCallback(
    (qty: number) => {
      if (localization.getLanguage().match('en')) {
        return appearance.award.call(null, qty);
      }

      return appearance.award.call(null, 1);
    },
    [appearance.award],
  );

  return {IconStamp, IconAward, stamp, award, color};
}
