import {
  Button,
  Card,
  Spacer,
  Typography,
  BModal,
} from '@b2cmessenger/doppio-components';
import {Linking, Text} from 'react-native';
import React, {ComponentProps, memo, useMemo} from 'react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useSelector} from 'react-redux';
import {loginedUserSelectors} from '@store/selectors';
import {Analytics} from '@b2cmessenger/doppio-core';

type Props = Omit<
  ComponentProps<typeof BModal>,
  'children' | 'animationType' | 'preventOverlayDismiss'
> & {
  title: string;
  message?: string;
  buttonText?: string;
  buttonURI?: string;
  buttonPressEvent?: string;
  authorizedButtonURI?: string;
};

function EngagementDialogComponent({
  title,
  message,
  buttonText,
  buttonURI,
  buttonPressEvent,
  authorizedButtonURI,
  ...rest
}: Props) {
  const profile = useSelector(loginedUserSelectors.profile);
  const {bottom} = useSafeAreaInsets();
  const onButtonPress = useMemo(
    () =>
      !buttonURI
        ? undefined
        : async () => {
            const url =
              profile && authorizedButtonURI
                ? authorizedButtonURI
                    .replace(
                      '__NAME__',
                      [profile.firstname, profile.lastname]
                        .filter(Boolean)
                        .join(' '),
                    )
                    .replace('__EMAIL__', encodeURIComponent(profile.email))
                : buttonURI;
            if (await Linking.canOpenURL(url)) {
              await Linking.openURL(url);
              if (buttonPressEvent) {
                Analytics.logEvent(buttonPressEvent);
              }
              rest.onRequestClose?.call(null);
            }
          },
    [
      authorizedButtonURI,
      buttonPressEvent,
      buttonURI,
      profile,
      rest.onRequestClose,
    ],
  );

  return (
    <BModal {...rest} animationType="fade">
      <Card shadowEnabled={false}>
        <Text style={Typography.smallHeader}>{title}</Text>
        {message ? (
          <>
            <Spacer />
            <Text style={Typography.mediumBody}>{message}</Text>
          </>
        ) : null}
        {buttonText && buttonURI ? (
          <>
            <Spacer height={24} />
            <Button title={buttonText} onPress={onButtonPress} />
          </>
        ) : null}
      </Card>
      <Spacer height={Math.max(bottom - Card.PADDING_VERTICAL, 0)} />
    </BModal>
  );
}

export const EngagementDialog = memo(EngagementDialogComponent);
