import React, {
  ComponentProps,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Appbar} from 'react-native-paper';
import {IconProfileAvatar} from '@components/common/icons/SvgIcon';

import {
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
  Animated,
  View,
} from 'react-native';
import {colors} from '@b2cmessenger/doppio-shared';
import {Typography, icons, useTheme} from '@b2cmessenger/doppio-components';
import {isTablet} from 'react-native-device-info';
import {OptionsAction} from '@screens/Dashboard/screens/QRCode/components/QRCodeGenerator/QRCodeHeader';
import {useTranslation} from '@shared';

const {white} = colors;
const {
  smallHeader,
  smallHeaderTablet,
  smallBody,
  mediumBodyTablet,
} = Typography;
const {LogOutIcon} = icons;

const _isTablet = isTablet();

export function QRCodeHeaderLandscape(props: {
  title: string;
  subtitle?: string;
  image?: string;
  onLogOut?: () => void;
  onPressOptions?: () => void;
  isImageSmall?: boolean;
  style?: ComponentProps<typeof View>['style'];
  testID?: ComponentProps<typeof Appbar.Header>['testID'];
  titleTestID?: ComponentProps<typeof Animated.Text>['testID'];
  subtitleTestID?: ComponentProps<typeof Animated.Text>['testID'];
  logOutButtonTestID?: ComponentProps<typeof LogOutAction>['testID'];
}) {
  const {colors} = useTheme();
  const {testID, titleTestID, subtitleTestID, logOutButtonTestID} = props;
  const title = 'title' in props ? props.title : undefined;
  const subtitle = 'subtitle' in props ? props.subtitle : undefined;
  const image = 'image' in props ? props.image : undefined;
  const onLogOut = 'onLogOut' in props ? props.onLogOut : undefined;
  const onPressOptions =
    'onPressOptions' in props ? props.onPressOptions : undefined;

  const style = useMemo(() => [styles.container, props.style], [props.style]);

  const imageContainerSize = useMemo(() => (props.isImageSmall ? 48 : 150), [
    props.isImageSmall,
  ]);
  const imageContainerStyles = useMemo(
    () => ({
      width: imageContainerSize,
      height: imageContainerSize,
    }),
    [imageContainerSize],
  );
  const titleContainerStyles = useMemo(
    () => ({
      marginTop: props.isImageSmall ? 16 : 32,
    }),
    [props.isImageSmall],
  );
  const imageStyle = useMemo<ComponentProps<typeof Animated.Image>['style']>(
    () =>
      image
        ? {
            width: imageContainerSize - 2,
            height: imageContainerSize - 2,
            borderRadius: 6,
            opacity: 1,
            marginHorizontal: 1,
            position: 'absolute',
            top: 1,
          }
        : undefined,
    [image, imageContainerSize],
  );

  const [imageLoaded, setImageLoaded] = useState(false);
  const opacity = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    Animated.timing(opacity, {
      toValue: imageLoaded ? 0 : 1,
      duration: 250,
      useNativeDriver: true,
    }).start();
  }, [imageLoaded, opacity]);

  useEffect(() => {
    setImageLoaded(false);
  }, [image]);

  return (
    <View style={style} testID={testID}>
      {onLogOut || onPressOptions ? (
        <View style={styles.actionButtonWrapper}>
          {onLogOut ? (
            <LogOutAction onPress={onLogOut} testID={logOutButtonTestID} />
          ) : null}
          {onPressOptions ? <OptionsAction onPress={onPressOptions} /> : null}
        </View>
      ) : null}
      <View style={styles.content}>
        <View style={imageContainerStyles}>
          {image ? (
            <Animated.Image
              style={imageStyle}
              source={{uri: image}}
              onLoad={() => {
                setImageLoaded(true);
              }}
            />
          ) : undefined}

          <Animated.View style={{opacity, backgroundColor: white, top: 1}}>
            <IconProfileAvatar
              size={imageContainerSize - 2}
              fill={colors.brand}
            />
          </Animated.View>
        </View>
        <View style={titleContainerStyles}>
          <Animated.Text
            style={styles.title}
            numberOfLines={1}
            testID={titleTestID}
          >
            {title}
          </Animated.Text>
          {subtitle ? (
            <Animated.Text
              style={styles.subTitle}
              numberOfLines={1}
              testID={subtitleTestID}
            >
              {subtitle}
            </Animated.Text>
          ) : null}
        </View>
      </View>
    </View>
  );
}

function LogOutAction(props: TouchableOpacityProps) {
  const {t} = useTranslation();
  const {accessibilityLabel = t('common.logout') || '', ...rest} = props;
  return (
    <TouchableOpacity accessibilityLabel={accessibilityLabel} {...rest}>
      <LogOutIcon size={28} />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  actionButtonWrapper: {alignSelf: 'flex-start'},
  content: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  titleContainer: {
    marginTop: 32,
  },
  title: {
    ...StyleSheet.flatten(_isTablet ? smallHeaderTablet : smallHeader),
    textAlign: 'center',
  },
  subTitle: {
    ...StyleSheet.flatten(_isTablet ? mediumBodyTablet : smallBody),
    color: colors.black,
  },
});
