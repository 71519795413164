import {useMemo, useState} from 'react';

function getMaxAvailableSize({
  containerDimension = {w: 0, h: 0},
  occupiedHeight = 0,
  defaultSpaceHeight = 6,
  maxSize = Infinity,
}: {
  containerDimension: {w: number; h: number};
  occupiedHeight: number;
  defaultSpaceHeight?: number;
  maxSize?: number;
}) {
  const size = Math.min(
    Math.max(0, containerDimension.h - occupiedHeight - defaultSpaceHeight * 2),
    maxSize,
  );
  return size < containerDimension.w ? size : containerDimension.w;
}

export function useMaxAvailableSize(props?: {
  defaultSpaceHeight?: number;
  maxSize?: number;
}) {
  const {defaultSpaceHeight = 6, maxSize = 500} = props ? props : {};

  const [occupiedHeight, setOccupiedHeight] = useState(0);
  const [containerDimension, setContainerDimension] = useState({w: 0, h: 0});

  const availableHeight = useMemo(() => {
    return containerDimension.h - occupiedHeight;
  }, [containerDimension.h, occupiedHeight]);
  const availableWidth = useMemo(() => {
    return containerDimension.w;
  }, [containerDimension.w]);

  const size = useMemo<number>(() => {
    return getMaxAvailableSize({
      containerDimension,
      occupiedHeight: occupiedHeight,
      defaultSpaceHeight,
      maxSize,
    });
  }, [containerDimension, occupiedHeight, defaultSpaceHeight, maxSize]);

  return {
    setOccupiedHeight,
    setContainerDimension,
    size,
    availableHeight,
    availableWidth,
  };
}
