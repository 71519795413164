type GetUserNameProps = {
  firstname?: string | null;
  lastname?: string | null;
  email?: string | null;
  phone?: string | null;
};
export function getUserName(props: GetUserNameProps) {
  let name = '';
  if (props.firstname && props.lastname) {
    name = `${props.firstname} ${props.lastname}`;
  } else if (props.firstname || props.lastname) {
    name = props.firstname || props.lastname || '';
  } else if (props.email) {
    name = props.email;
  } else if (props.phone) {
    name = props.phone;
  }
  return name;
}
