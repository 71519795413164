import AsyncStorage from '@react-native-async-storage/async-storage';
import {Logger} from '@b2cmessenger/doppio-shared';
import {EventEmitter} from 'eventemitter3';

const events = new EventEmitter<'trackingInfoSet'>();

export type SetAdsTrackingInfoProps = {
  atid?: string;
  ga4ClientId?: string;
};

export async function setAdsTrackingInfo(props: SetAdsTrackingInfoProps) {
  Logger.verboseTag('setAdsTrackingInfo', props);
  if (props.atid) {
    await AsyncStorage.setItem('atid', props.atid);
  }
  if (props.ga4ClientId) {
    await AsyncStorage.setItem('ga4ClientId', props.ga4ClientId);
  }

  events.emit('trackingInfoSet', props);
}

export async function getAdsTrackingId() {
  return await AsyncStorage.getItem('atid');
}

export async function getGa4ClientId() {
  return await AsyncStorage.getItem('ga4ClientId');
}

export {events};
