import React, {useMemo} from 'react';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryGroup,
} from 'victory-native/lib';
import {Placeholder} from 'rn-placeholder';
import {ActivityIndicator, StyleSheet} from 'react-native';
import {useTheme} from '@b2cmessenger/doppio-components';
import {shadeColor} from '@utils/color';

type Props = {
  scope: 'week' | 'month' | 'year';
  width: number;
  height?: number;
  data: {x: string; y: number}[][];
  categories: string[];
  activeGroupIdx?: number;
  onGroupActive?: (idx: number | undefined) => void;
  xAxisTickCount?: number;
} & Partial<{
  paddingLeft: number;
  paddingRight: number;
  paddingTop: number;
  paddingBottom: number;
  colors: [string, string];
}>;

export function GroupedBarChart({
  scope,
  data,
  categories,
  activeGroupIdx,
  onGroupActive = () => {},
  width,
  xAxisTickCount,
  height = 300,
  paddingTop = 10,
  paddingLeft = 10,
  paddingBottom = 28,
  paddingRight = 28,
  colors: _colors,
}: Props) {
  const {colors} = useTheme();
  const padding = useMemo(
    () => ({
      top: paddingTop,
      left: paddingLeft,
      bottom: paddingBottom,
      right: paddingRight,
    }),
    [paddingBottom, paddingLeft, paddingRight, paddingTop],
  );
  const colorScale = useMemo(
    () => (_colors ? _colors : [colors.black, colors.brand]),
    [_colors, colors.black, colors.brand],
  );
  const dataLength = useMemo(() => data[0].length, [data]);
  const maxValue = useMemo(
    () =>
      data.reduce(
        (maxVal, series) =>
          series.reduce((seriesMax, val) => Math.max(seriesMax, val.y), maxVal),
        0,
      ),
    [data],
  );

  const tickValues = useMemo(() => {
    const step = getYAxisTickValuesStep(maxValue);
    return Array.from({length: Math.ceil(maxValue / step) + 1}).map(
      (_, idx) => step * idx,
    );
  }, [maxValue]);

  const yAxis = useMemo(
    () => (
      <VictoryAxis
        dependentAxis
        orientation="right"
        tickValues={tickValues}
        style={{
          axis: {
            stroke: 'transparent',
          },
          grid: {
            stroke: ({tick}) =>
              tick === 0 ? 'transparent' : colors.middlegray,
            strokeDasharray: () => '3, 5',
            strokeWidth: 2,
          },
          tickLabels: {
            fill: () => colors.darkgray,
            fontFamily: 'System-ui',
            fontWeight: 600,
            fontSize: 12,
            padding: 2,
          },
        }}
      />
    ),
    [colors.darkgray, colors.middlegray, tickValues],
  );

  const xAxis = useMemo(
    () => (
      <VictoryAxis
        crossAxis
        orientation="bottom"
        tickCount={xAxisTickCount}
        style={{
          axis: {
            stroke: colors.middlegray,
          },
          tickLabels: {
            fill: () => colors.darkgray,
            fontFamily: 'System-ui',
            fontWeight: 600,
            fontSize: 12,
            padding: 8,
          },
        }}
      />
    ),
    [colors.darkgray, colors.middlegray, xAxisTickCount],
  );

  const barWidth = scope === 'month' ? 3 : scope === 'year' ? 6 : 12;
  const groupOffset =
    dataLength > 7
      ? dataLength === 12
        ? barWidth * 1.25
        : barWidth + 1
      : barWidth * 1.5;
  const groupContent = useMemo(
    () =>
      data.map((series, idx) => (
        <VictoryBar
          name={`bar-${idx + 1}`}
          key={String(idx)}
          barWidth={barWidth}
          data={series}
          style={{
            data: {
              opacity: ({index}) => {
                return activeGroupIdx === undefined
                  ? 1
                  : index !== activeGroupIdx
                  ? 0.5
                  : 1;
              },
            },
          }}
          cornerRadius={
            scope === 'month' ? {top: 1, bottom: 1} : {top: 2, bottom: 2}
          }
        />
      )),
    [data, barWidth, scope, activeGroupIdx],
  );

  return (
    <VictoryChart
      containerComponent={
        <VictoryContainer
          // @ts-ignore FIXME: `victory-native` type mismatch
          events={useMemo(
            () => ({
              onTouchEnd: () => {
                onGroupActive(undefined);
              },
              // @ts-ignore
              onTouchMove: (evt, props: {width: number}) => {
                if (props === undefined) {
                  return;
                }
                const {locationX} = evt.nativeEvent;
                const sectorWidth = Math.ceil(props.width / dataLength);
                const currentIdx = Math.round(locationX / sectorWidth);
                const sectorIdx = Math.min(
                  Math.max(currentIdx, 0),
                  dataLength - 1,
                );
                onGroupActive(sectorIdx);
                return {};
              },
            }),
            [dataLength, onGroupActive],
          )}
        />
      }
      height={height}
      width={width}
      padding={padding}
      prependDefaultAxes={false}>
      {yAxis}
      {xAxis}
      <VictoryGroup
        events={useMemo(
          () => [
            {
              childName: 'all',
              target: 'data',
              eventHandlers: {
                onPressIn: (e, props) => {
                  onGroupActive(props.index);
                },
              },
            },
          ],
          [onGroupActive],
        )}
        offset={groupOffset}
        categories={useMemo(() => ({x: categories}), [categories])}
        colorScale={colorScale}>
        {groupContent}
      </VictoryGroup>
    </VictoryChart>
  );
}

function ChartPlaceholder({
  width,
  height = 300,
}: Pick<Props, 'width' | 'height'>) {
  const {colors} = useTheme();
  return (
    <Placeholder style={useMemo(() => ({width, height}), [height, width])}>
      <ActivityIndicator
        size="large"
        color={colors.black}
        style={StyleSheet.absoluteFill}
      />
    </Placeholder>
  );
}

function ChartExample({
  width,
  height = 300,
  categories,
  data,
}: Pick<Props, 'width' | 'height' | 'categories' | 'data'>) {
  const {colors} = useTheme();

  const barColors = useMemo<[string, string]>(
    () => ['#dddddd', shadeColor(colors.brand, 60)],
    [colors.brand],
  );

  return (
    <GroupedBarChart
      scope="week"
      width={width}
      height={height}
      data={data}
      categories={categories}
      colors={barColors}
    />
  );
}

GroupedBarChart.Placeholder = ChartPlaceholder;
GroupedBarChart.Example = ChartExample;

function getYAxisTickValuesStep(maxValue: number) {
  return maxValue < 10
    ? 3
    : maxValue <= 20
    ? 5
    : maxValue <= 60
    ? 20
    : maxValue <= 100
    ? 25
    : maxValue <= 200
    ? 50
    : maxValue <= 400
    ? 100
    : maxValue <= 800
    ? 200
    : maxValue <= 1600
    ? 400
    : 400;
}
