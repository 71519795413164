import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Animated,
  Linking,
  Platform,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import {Button, Spacer, Typography} from '@b2cmessenger/doppio-components';
import {OnboardingView} from '@widgets/Onboarding/ui/OnboardingView';

//@ts-ignore
import video from '../../../../../assets/onboarding/videos/scan_stamps_web.mp4';
import {useTranslation} from '@shared';
import DemoVideo from '@features/DemoVideo';
import {ControlsContext} from '@features/Story/lib/ControlsContext';
import {useWorkplaceSigningKey} from '@components/hooks/useWorkplaceSigningKey';
import {useSelector} from 'react-redux';
import {loginedUserSelectors} from '@store/selectors';
import {useScreenPrimaryButtonStyle} from '@components/hooks/useScreenPrimaryButtonStyle';
import {Analytics} from '@b2cmessenger/doppio-core';
import {DynamicLinksConfig} from '@utils/DynamicLinks/shared';
import FirebaseRemoteConfigContext from '@utils/FirebaseRemoteConfigContext';
import {
  createJWTSignature,
  getCurrentIatValue,
} from '@screens/Dashboard/screens/QRCode/components/QRCodeGenerator/createJWTSignature';
import {DynamicLinkActions} from '@screens/Dashboard/screens/QRCode/components/QRCodeGenerator/buildDynamicLink';
import {colors, Logger} from '@b2cmessenger/doppio-shared';

enum State {
  Init = 'init',
  Video = 'video',
  VideoFinished = 'videoFinished',
  Completed = 'completed',
}

function ScanStampsViaWeb() {
  const {t} = useTranslation();
  const [state, setState] = useState<State>(State.Init);
  const {suppressControls, setCustomControls} = useContext(ControlsContext);
  const isVideoFinishedRef = useRef<boolean>(false);
  const {clientWebLinkRoute = '/link/'} = useContext(
    FirebaseRemoteConfigContext,
  );

  const {key: signKey} = useWorkplaceSigningKey();
  const workplace = useSelector(loginedUserSelectors.workplace) as Exclude<
    ReturnType<typeof loginedUserSelectors.workplace>,
    null
  >;
  const loginedUser = useSelector(loginedUserSelectors.profile) as Exclude<
    ReturnType<typeof loginedUserSelectors.profile>,
    null
  >;

  const link = useMemo(() => {
    if (!signKey) {
      return null;
    }

    const issuedAt = getCurrentIatValue();
    const jwt = createJWTSignature(signKey, {
      action: DynamicLinkActions.giveStamps,
      options: {
        placeId: workplace.id,
        issuedBy: loginedUser.id,
        amount: 2,
        issuedAt,
        os: Platform.OS,
      },
    });
    const _link = `${DynamicLinksConfig.Client.DynamicLinkDomain}${clientWebLinkRoute}${jwt}`;
    return _link;
  }, [loginedUser.id, signKey, workplace.id, clientWebLinkRoute]);

  useLayoutEffect(() => {
    suppressControls(true);

    return () => {
      suppressControls(false);
    };
  }, [suppressControls]);

  const navigateToWeb = useCallback(() => {
    const complete = () => {
      setState(State.Completed);
      suppressControls(false);
    };
    setTimeout(() => {
      complete();
    }, 1500);
    Analytics.logEvent('onboarding_open_web', {});
    if (link) {
      Linking.openURL(link).catch(() => {
        complete();
      });
    }
  }, [link, suppressControls]);

  const onVideoFinished = useCallback(() => {
    if (isVideoFinishedRef.current) {
      return;
    }

    isVideoFinishedRef.current = true;
    if (state === State.VideoFinished) {
      return;
    }

    // if (Platform.OS === 'web') {
    setState(State.VideoFinished);
    // } else {
    //   navigateToWeb();
    // }
  }, [state]);

  const [size, setSize] = useState<number>(200);
  const header = (
    <>
      {state === State.Init ? (
        <>
          <Text style={Typography.header}>
            {t(
              'Widgets.Onboarding.ScanWeb.letsGuideYouThroughHowToIssueYourFirstStamps',
            )}
          </Text>
          <Spacer />
          <Spacer />
          <Text style={Typography.smallHeader}>
            {t('Widgets.Onboarding.ScanWeb.tapTheButtonToStartInteractiveDemo')}
          </Text>
          <Spacer />
          <Spacer />
          <Button
            title={t('Widgets.Onboarding.ScanWeb.startDemonstration')}
            onPress={() => {
              setState(State.Video);
            }}
          />
        </>
      ) : null}

      {state === State.Video || state === State.VideoFinished ? (
        <>
          <Text style={Typography.header}>
            {t('Widgets.Onboarding.ScanWeb.howDoesItHappen')}
          </Text>
          <Spacer />
          <Spacer />
          <Text style={Typography.smallHeader}>
            {t('Widgets.Onboarding.ScanWeb.1ChooseStampQuantity')}
          </Text>
          <Spacer />
          <Text style={Typography.smallHeader}>
            {t('Widgets.Onboarding.ScanWeb.2LetGuestScanTheQrCodeAndOpenInWeb')}
          </Text>
        </>
      ) : null}

      {state === State.Completed ? (
        <>
          <Text style={Typography.header}>
            {t('Widgets.Onboarding.ScanWeb.congratulations')}
          </Text>
          <Spacer />
          <Text style={Typography.smallHeader}>
            {t('Widgets.Onboarding.ScanWeb.nowYouAreReady')}
          </Text>
        </>
      ) : null}
    </>
  );

  const openInWebButtonPulsingScaleRef = useRef(new Animated.Value(1));

  const pbs = useScreenPrimaryButtonStyle();
  useEffect(() => {
    if (state === State.VideoFinished) {
      createHeartbeatAnimation(
        openInWebButtonPulsingScaleRef.current,
        0.98,
        1.02,
      ).start();

      setCustomControls(
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Text style={[Typography.smallBody, {color: colors.darkgray}]}>
            {t('Widgets.Onboarding.ScanWeb.proceedAsGuest')}
          </Text>
          <Spacer height={6} />
          <Animated.View
            style={[
              styles.customControlsContainer,
              {transform: [{scale: openInWebButtonPulsingScaleRef.current}]},
            ]}>
            <Button.Primary
              title={t('Widgets.Onboarding.ScanWeb.openInWeb')}
              onPress={navigateToWeb}
              style={[
                pbs,
                styles.customControlButton,
                {minWidth: 200, flex: 1},
              ]}
            />
          </Animated.View>
        </View>,
      );
    } else if (state === State.Video) {
      setCustomControls(
        <View
          style={[styles.customControlsContainer, {height: 48 + 20 + 6 + 17}]}
        />,
      );
    } else {
      setCustomControls(null);
    }
  }, [navigateToWeb, pbs, setCustomControls, state, t]);

  return (
    <OnboardingView header={header} setSize={setSize}>
      {[State.Video, State.VideoFinished].indexOf(state) !== -1 ? (
        <DemoVideo
          size={size}
          source={video}
          containerStyle={[styles.demoVideo, {maxHeight: size}]}
          isLooping={false}
          onPlaybackStatusUpdate={playbackStatus => {
            if (playbackStatus.isLoaded && playbackStatus.didJustFinish) {
              onVideoFinished();
            }
          }}
        />
      ) : null}
    </OnboardingView>
  );
}

export {ScanStampsViaWeb};

const styles = StyleSheet.create({
  container: {flex: 1, flexGrow: 1, justifyContent: 'space-evenly'},
  header: {alignSelf: 'center'},
  demoVideo: {flex: 1},
  imageWrapper: {flex: 1, justifyContent: 'center', alignItems: 'center'},
  customControlsContainer: {
    flexWrap: 'nowrap',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
  },
  customControlButton: {flex: 1},
});

export const createHeartbeatAnimation = (
  value: Animated.Value,
  minValue: number,
  maxValue: number,
) =>
  Animated.loop(
    Animated.sequence([
      Animated.timing(value, {
        useNativeDriver: true,
        toValue: maxValue,
        duration: 100,
      }),
      Animated.timing(value, {
        useNativeDriver: true,
        toValue: minValue,
        duration: 100,
      }),
      Animated.timing(value, {
        useNativeDriver: true,
        toValue: maxValue,
        duration: 100,
      }),
      Animated.timing(value, {
        useNativeDriver: true,
        toValue: minValue,
        duration: 2000,
      }),
    ]),
  );
