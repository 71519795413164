import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {BModal, Navbar, Screen} from '@b2cmessenger/doppio-components';
import {navigationRef} from '@navigation/navigationRef';
import {Platform, View} from 'react-native';
import {ErrorCard, useTranslation} from '@shared';
import {Portal} from 'react-native-paper';
import {useDispatch, useSelector} from 'react-redux';
import {appSelectors} from '@store/selectors';
import {
  dismissLoginError,
  performLogin,
  performLoginViaOtp,
} from '@store/actions';
import {
  emailOtpLoginInit,
  EmailSignInViaOtp,
  EmailSignInViaOtpCode,
} from '@screens/EmailSignIn/EmailSignInViaOtp';
import {EmailWithPasswordCase} from '@screens/EmailSignIn/EmailWithPassword';
import {Alert} from '@components/common/Alert';

enum State {
  email = 'email',
  code = 'code',
  emailWithPassword = 'emailWithPassword',
}

type ContextType = {
  email: string;
  loading: boolean;
  error: Error | null;
  errorStatus: number | null;
  resetError: () => void;
};
export const EmailSignInContext = createContext<ContextType>({
  email: '',
  loading: false,
  error: null,
  errorStatus: null,
  resetError: () => {},
});

export function EmailSignInScreen() {
  const {t} = useTranslation();
  const [verificationId, setVerificationId] = useState<number | null>(null);
  const [error, setError] = useState<null | Error>(null);
  const [errorStatus, setErrorStatus] = useState<null | number>(null);
  const [email, setEmail] = useState('');
  const [state, setState] = useState<State>(State.email);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const isLoggingIn = useSelector(appSelectors.isLoggingIn);
  const loginError = useSelector(appSelectors.loginErrorExtended);

  useEffect(() => {
    setLoading(isLoggingIn);
  }, [isLoggingIn]);
  useEffect(() => {
    if (!loginError) {
      return;
    }
    if (loginError.error) {
      setError(new Error(loginError.error));
    }
    if (loginError.status) {
      setErrorStatus(loginError.status);
    }
    //@ts-ignore
    dispatch(dismissLoginError());
  }, [dispatch, loginError]);

  const resetError = useCallback(() => {
    setError(null);
    setErrorStatus(null);
  }, []);

  const navbar = useMemo(
    () => (
      <EmailSignInNavbar
        state={state}
        setState={setState}
        resetError={resetError}
      />
    ),
    [resetError, state],
  );

  const sendEmailOtpCode = useCallback(
    async (_email: string) => {
      let error = null as null | Error;
      setLoading(true);
      try {
        const result = await emailOtpLoginInit(_email);
        if (!'verification_id' in result) {
          throw new Error(t('Screens.EmailSignIn.anErrorHasOccurred'));
        }
        setVerificationId(result.verification_id);
      } catch (e) {
        setError(e);
        error = e;
      }
      setLoading(false);
      return error;
    },
    [t],
  );

  const emailViaOtpCase = useMemo(() => {
    const onSignInWithAPassword = (_email: string) => {
      setEmail(_email);
      resetError();
      setState(State.emailWithPassword);
    };

    const _onNext = async (_email: string) => {
      setEmail(_email);
      const err = await sendEmailOtpCode(_email);
      if (!err) {
        setState(State.code);
      }
    };

    return (
      <EmailSignInViaOtp
        onNext={_onNext}
        onSignInWithAPassword={onSignInWithAPassword}
        disabled={false}
      />
    );
  }, [resetError, sendEmailOtpCode]);

  const codeCase = useMemo(() => {
    const onResendCode = async () => {
      await sendEmailOtpCode(email);
    };
    const _onNext = async (code: string) => {
      try {
        if (!verificationId) {
          throw new Error(t('Screens.EmailSignIn.anErrorHasOccurred'));
        }
        const _code = parseInt(code);
        if (isNaN(_code)) {
          throw new Error(t('Screens.EmailSignIn.incorrectCode'));
        }
        dispatch(performLoginViaOtp(verificationId, code));
      } catch (e) {
        setError(e);
      }
    };
    return <EmailSignInViaOtpCode onNext={_onNext} resendCode={onResendCode} />;
  }, [dispatch, email, sendEmailOtpCode, t, verificationId]);

  const emailWithPasswordCase = useMemo(() => {
    const onSignInWithOTP = (_email: string) => {
      setEmail(_email);
      resetError();
      setState(State.email);
    };
    const _onNext = (_email: string, password: string) => {
      dispatch(performLogin(_email, password));
    };

    return (
      <EmailWithPasswordCase
        disabled={false}
        onSignInWithOTP={onSignInWithOTP}
        onNext={_onNext}
      />
    );
  }, [resetError, dispatch]);

  const content = useMemo(() => {
    switch (state) {
      case State.email:
        return emailViaOtpCase;
      case State.code:
        return codeCase;
      case State.emailWithPassword:
        return emailWithPasswordCase;
    }
  }, [codeCase, emailViaOtpCase, emailWithPasswordCase, state]);

  const context = useMemo<ContextType>(() => {
    return {
      email,
      loading,
      error,
      errorStatus,
      resetError: resetError,
    };
  }, [email, loading, error, errorStatus, resetError]);

  useEffect(() => {
    if (Platform.OS !== 'web' && error && state === State.email) {
      Alert.alert(
        t('Screens.EmailSignIn.anErrorHasOccurred'),
        typeof error === 'string' ? error : error.message,
      );
      resetError();
    }
  }, [error, state, resetError, t]);

  return (
    <Screen navbar={navbar}>
      <EmailSignInContext.Provider value={context}>
        {content}
      </EmailSignInContext.Provider>
      <Portal>
        {Platform.OS === 'web' && error && state === State.email ? (
          <BModal visible onDismiss={resetError} onRequestClose={resetError}>
            <View style={{margin: 20}}>
              <ErrorCard error={error} />
            </View>
          </BModal>
        ) : null}
      </Portal>
    </Screen>
  );
}

function EmailSignInNavbar({
  state,
  setState,
  resetError,
}: {
  state: State;
  setState: (state: State) => void;
  resetError: () => void;
}) {
  const {t} = useTranslation();
  const title =
    state === State.emailWithPassword
      ? t('Screens.EmailSignIn.signInWithAPassword')
      : t('Screens.EmailSignIn.signInWithEmail');

  const onNavbarBack = useCallback(() => {
    switch (state) {
      case State.email: {
        //@ts-ignore
        navigationRef?.current?.navigate('SignIn');
        break;
      }
      default: {
        setState?.call(null, State.email);
        resetError?.call(null);
      }
    }
  }, [state, setState, resetError]);

  return <Navbar title={title} onBack={onNavbarBack} />;
}
