import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {PlaceAppearance, PlaceFull} from '@typings/Place';
import {localization} from '@shared';

export type PlaceAppearanceConfigExtended = Pick<
  PlaceAppearance,
  'color' | 'stamp_resource_id' | 'award_resource_id'
> &
  Partial<
    Pick<
      PlaceAppearance,
      'stamp_resource_content' | 'award_resource_content' | 'award_name'
    >
  >;

export type BusinessWizardStages =
  | 'enterCompanyName'
  | 'send'
  | 'setupLoyalty'
  | 'readyToUse'
  | 'businessType'
  | 'onboarding';

type State = {
  stage: BusinessWizardStages;
  heading: string;
  description: string;
  place: {
    name: string;
    stamps: number;
  };
  appearance: PlaceAppearanceConfigExtended | null;
  createdPlace: PlaceFull | null;
  businessTypeId: null | number;
};

const initialState: State = {
  stage: 'businessType',
  heading: '',
  description: '',
  place: {
    name: '',
    stamps: 5,
  },
  appearance: null,
  createdPlace: null,
  businessTypeId: null,
};
const slice = createSlice({
  name: 'businessWizardScreen',
  initialState: initialState,
  reducers: {
    init: {
      prepare: (name: string | null) => ({payload: name}),
      reducer: (state, action: PayloadAction<string | null>) => ({
        ...state,
        stage: 'businessType',
        heading: localization.t(
          'Screens.BusinessWizard.slice.helloSelectTypeOfYourBusiness',
          {expr: action.payload ? ', ' + action.payload : ''},
        ),
        description: '',
      }),
    },
    setBusinessType: (state, action: PayloadAction<number | null>) => {
      return {
        ...state,
        stage: 'enterCompanyName',
        heading: localization.t(
          'Screens.BusinessWizard.slice.whatIsTheNameOfYourCompany',
        ),
        description: '',
        businessTypeId: action.payload,
      };
    },
    setPlaceName: {
      prepare: (name: string) => ({payload: name}),
      reducer: (state, action: PayloadAction<string>) => ({
        ...state,
        stage: 'setupLoyalty',
        heading: localization.t(
          'Screens.BusinessWizard.slice.setUpYourStartingLoyaltyProgramNow',
        ),
        description: localization.t(
          'Screens.BusinessWizard.slice.youCanChangeItAndAddMoreOptionsLater',
        ),
        place: {
          ...state.place,
          name: action.payload,
        },
      }),
    },
    setOnboarding: state => {
      return {
        ...state,
        stage: 'onboarding',
        heading: '',
        description: '',
      };
    },
    setPlaceStamps: {
      prepare: (stamps: number) => ({payload: stamps}),
      reducer: (state, action: PayloadAction<number>) => ({
        ...state,
        place: {
          ...state.place,
          stamps: action.payload,
        },
      }),
    },
    setPlaceAppearance: {
      prepare: (config?: PlaceAppearanceConfigExtended) => ({
        payload: config || null,
      }),
      reducer: (
        state,
        action: PayloadAction<PlaceAppearanceConfigExtended | null>,
      ) => ({
        ...state,
        appearance: action.payload,
      }),
    },
    send: state => ({
      ...state,
      stage: 'send',
      heading: localization.t('Screens.BusinessWizard.slice.settingUp'),
      description: localization.t('Screens.BusinessWizard.slice.pleaseWait'),
    }),
    sendFailed: state => ({
      ...state,
      stage: 'setupLoyalty',
      heading: localization.t(
        'Screens.BusinessWizard.slice.setUpYourStartingLoyaltyProgramNow',
      ),
      description: localization.t(
        'Screens.BusinessWizard.slice.youCanChangeItAndAddMoreOptionsLater',
      ),
    }),
    readyToUse: {
      prepare: (place: PlaceFull) => ({payload: place}),
      reducer: (state, action: PayloadAction<PlaceFull>) => ({
        ...state,
        stage: 'readyToUse',
        heading: localization.t(
          'Screens.BusinessWizard.slice.greatYourLoyaltyProgramIsReadyToUse',
        ),
        description: '',
        createdPlace: action.payload,
      }),
    },
  },
});

export {slice};
