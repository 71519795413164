import React, {useCallback, useEffect, useState} from 'react';
import {Portal} from 'react-native-paper';
import {useNotifications} from './hooks/useNotifications';
import {
  isUserEngagementNotification,
  UserEngagementNotification,
} from '@typings/Notification';
import initialNotificationRef from '@utils/initialNotificationRef';
import {EngagementDialog} from '@components/EngagementDialog';
import {useIsMounted} from '@b2cmessenger/doppio-shared';
import {Keyboard} from 'react-native';
import PushNotifications from '@utils/PushNotifications';

interface EngagementEntry {
  title: string;
  message?: string;
  buttonText?: string;
  buttonPressEvent?: string;
  buttonURI?: string;
  authorizedButtonURI?: string;
}

export function UserEngagement() {
  const [engagement, setEngagement] = useState<EngagementEntry | undefined>(
    () => {
      if (initialNotificationRef.initialNotification !== null) {
        if (
          isUserEngagementNotification(
            initialNotificationRef.initialNotification,
          )
        ) {
          return getEngagementEntryFromNotification(
            initialNotificationRef.initialNotification,
          );
        }
      }

      return undefined;
    },
  );
  const [visible, setVisible] = useState(engagement !== undefined);

  useEffect(() => {
    PushNotifications.onNotificationOpenedApp((message) => {
      const notification = PushNotifications.getNotificationFromMessage(
        message,
      );
      if (isUserEngagementNotification(notification)) {
        setEngagement(getEngagementEntryFromNotification(notification));
        setVisible(true);
      }
    });
  }, []);
  useNotifications(
    useCallback((notification) => {
      if (isUserEngagementNotification(notification)) {
        setEngagement(getEngagementEntryFromNotification(notification));
        setVisible(true);
      }
    }, []),
    {disableHandlingBackgroundRestoredMessages: true},
  );

  const isMounted = useIsMounted();
  const onRequestClose = useCallback(() => {
    setVisible(false);
    setTimeout(() => {
      if (isMounted) {
        setEngagement(undefined);
      }
    }, 333);
  }, [isMounted]);
  const onShow = useCallback(() => {
    Keyboard.dismiss();
  }, []);

  return engagement ? (
    <EngagementDialog
      visible={visible}
      onShow={onShow}
      onRequestClose={onRequestClose}
      title={engagement.title}
      message={engagement.message}
      buttonText={engagement.buttonText}
      buttonURI={engagement.buttonURI}
      buttonPressEvent={engagement.buttonPressEvent}
      authorizedButtonURI={engagement.authorizedButtonURI}
    />
  ) : null;
}

function getEngagementEntryFromNotification({
  data: {
    dialog_title: title,
    dialog_button_text: buttonText,
    dialog_button_uri: buttonURI,
    dialog_authorized_button_uri: authorizedButtonURI,
    dialog_button_press_event: buttonPressEvent,
    dialog_description: message,
  },
}: UserEngagementNotification) {
  return {
    title,
    message,
    buttonText,
    buttonURI,
    buttonPressEvent,
    authorizedButtonURI,
  };
}
