import {
  Button,
  Navbar,
  Pusher,
  Screen,
  Spacer,
  Typography,
  useTheme,
} from '@b2cmessenger/doppio-components';
import React, {useCallback, useContext, useMemo, useState} from 'react';
import {navigationRef} from '@navigation/navigationRef';
import {useTranslation} from '@shared';
import {Text, View} from 'react-native';
import WorkplaceContext from '@components/common/WorkplaceContext';
import {Loader} from 'rn-placeholder';
import {Alert} from '@components/common/Alert';
import {
  sendMessageToCustomers,
  useMessageToCustomers,
} from '@screens/Dashboard/screens/Stats/Messages/utils';
import type {TFunction} from 'i18next';

export function CustomerMessages() {
  const {t} = useTranslation();
  const workplace = useContext(WorkplaceContext);
  const {
    isAbleToSend,
    inactiveCustomers,
    loading: getMessagesLoading,
    update,
    sendMessageNextInHours,
  } = useMessageToCustomers(workplace.id);

  const goBack = useCallback(() => {
    if (navigationRef.current?.canGoBack()) {
      navigationRef.current?.goBack();
    } else {
      navigationRef.current?.navigate('Dashboard', {
        screen: 'Stats',
      });
    }
  }, []);
  const navbar = useMemo(() => {
    const title = t('Screens.Stats.CustomerMessages.sendAReminder');
    return <Navbar onBack={goBack} title={title} />;
  }, [goBack, t]);

  const [sending, setSending] = useState(false);
  const onSendMessages = useCallback(async () => {
    try {
      setSending(true);
      const data = await sendMessageToCustomers(workplace.id);
      await update({...data, send_message_allowed: false});
      setSending(false);
    } catch (e) {
      Alert.alert(t('Shared.ErrorCard.anErrorHasOccurred'), e);
      setSending(false);
    }
  }, [update, workplace.id, t]);

  const content = useMemo(() => {
    if (getMessagesLoading || inactiveCustomers === undefined) {
      return <Loader />;
    } else {
      return (
        <MessagesContent
          onSendMessages={onSendMessages}
          t={t}
          isAbleToSend={isAbleToSend}
          inactiveCustomers={inactiveCustomers}
          sending={sending}
          sendMessageNextInHours={sendMessageNextInHours || 0}
        />
      );
    }
  }, [
    getMessagesLoading,
    inactiveCustomers,
    onSendMessages,
    t,
    isAbleToSend,
    sending,
    sendMessageNextInHours,
  ]);

  return (
    <Screen navbar={navbar}>
      <Spacer />
      {content}
      <Spacer />
    </Screen>
  );
}

function MessagesContent({
  onSendMessages,
  t,
  isAbleToSend,
  inactiveCustomers,
  sending,
  sendMessageNextInHours,
}: {
  onSendMessages: () => void;
  t: TFunction<N, TKPrefix>;
  isAbleToSend: boolean;
  inactiveCustomers: number;
  sending: boolean;
  sendMessageNextInHours: number;
}) {
  const {colors} = useTheme();
  const guestText = t('Screens.Stats.CustomerMessages.guests', {
    count: inactiveCustomers,
  });
  const desc1 = t(
    'Screens.Stats.CustomerMessages.haventReceivedStampsOrRewardsForMoreThanWeek',
  );
  const desc2 = t('Screens.Stats.CustomerMessages.letsSendThemAMessage');
  const desc3 = t(
    'Screens.Stats.CustomerMessages.theyWillReceiveMessageShortlyViaPushNotificationOr',
  );

  const buttonText = isAbleToSend
    ? t('Screens.Stats.CustomerMessages.sendAMessage')
    : t('Screens.Stats.CustomerMessages.messageSent');
  const days = Math.ceil(sendMessageNextInHours / 24);

  const buttonDesc = isAbleToSend
    ? t(
        'Screens.Stats.CustomerMessages.youreAbleToSendMessagesToYourGuestsOnlyOncePerWeek',
      )
    : t('Screens.Stats.CustomerMessages.nextTimeYouCanSendAMessageInDays', {
        count: days,
      });

  return (
    <>
      <View style={{flexDirection: 'row'}}>
        <View
          style={{
            backgroundColor: colors.brand,
            borderRadius: 16,
            paddingHorizontal: 12,
            paddingVertical: 2,
          }}>
          <Text style={[Typography.header, {color: colors.white}]}>
            {guestText}
          </Text>
        </View>
      </View>
      <Spacer />
      <Text style={[Typography.body]}>{desc1}</Text>
      <Spacer />
      <Text style={[Typography.body]}>{desc2}</Text>
      <Spacer />
      <Text style={[Typography.body, {color: colors.darkgray}]}>{desc3}</Text>
      <Pusher />
      <Button
        title={buttonText}
        disabled={!isAbleToSend || sending}
        loading={sending}
        onPress={onSendMessages}
      />
      <Spacer />
      <Text
        style={[
          Typography.smallBody,
          {color: colors.darkgray, textAlign: 'center'},
        ]}>
        {buttonDesc}
      </Text>
      <Spacer />
    </>
  );
}
