import {DateTimeUtils} from '@b2cmessenger/doppio-shared';
import {localization} from '@shared';
type SeriesLikeObject = {date: string; [k: string]: any};

export const DAY_IN_MSECS = 24 * 60 * 60 * 1000;

export function fillLBlankAreaInSeries(
  array: SeriesLikeObject[],
  {
    scope,
    from: fromDate,
    gapMs = DAY_IN_MSECS,
    blankData,
  }: {
    scope: 'week' | 'month' | 'year';
    from: string;
    blankData: object;
    /**
     * if date difference between two elements is greater than
     * value of `gapMs` a new elements will be inserted
     */
    gapMs?: number;
  },
) {
  if (scope === 'year') {
    const from = DateTimeUtils.fromDatetimeServer(fromDate);
    return Array.from({length: 12}).map((_, idx) => {
      const date = DateTimeUtils.toDatetimeServer(
        new Date(from.getFullYear(), idx, 1),
        {
          dateOnly: true,
          yearAndMonthOnly: true,
        },
      );
      return (
        array.find(el => el.date === date) || {
          date,
          ...blankData,
        }
      );
    });
  }

  return array.reduce<typeof array>((memo, el, idx, list) => {
    const prevDate =
      idx === 0
        ? DateTimeUtils.fromDatetimeServer(fromDate)
        : DateTimeUtils.fromDatetimeServer(list[idx - 1].date);

    const elDate = DateTimeUtils.fromDatetimeServer(el.date);
    // diff between sibling elements in msecs
    const dx = elDate.getTime() - prevDate.getTime();
    if (dx > gapMs) {
      const missingDays = Math.floor(dx / gapMs) - 1;
      Array.from({length: missingDays}).forEach((_, i) => {
        const patchDate = new Date(
          prevDate.getFullYear(),
          prevDate.getMonth(),
          prevDate.getDate(),
        );
        patchDate.setDate(prevDate.getDate() + i + 1);
        memo.push({
          date: DateTimeUtils.toDatetimeServer(patchDate, {dateOnly: true}),
          ...blankData,
        });
      });
    }

    memo.push(el);
    return memo;
  }, []);
}

export function fillBlankSeriesEdges(
  array: SeriesLikeObject[],
  {
    from,
    to,
    blankData,
  }: {
    from: string;
    to: string;
    blankData: object;
  },
) {
  const hasFirst = array.find(({date}) => date === from);
  const hasLast = array.find(({date}) => date === to);
  const copy = array.slice();
  if (!hasFirst) {
    copy.unshift({
      date: from,
      ...blankData,
    });
  }
  if (!hasLast) {
    copy.push({
      date: to,
      ...blankData,
    });
  }
  return copy;
}

export function getMonths(t: typeof localization.t): string[] {
  const months = {
    jan: t('common.calendar.january') || '',
    feb: t('common.calendar.february') || '',
    mar: t('common.calendar.march') || '',
    apr: t('common.calendar.april') || '',
    may: t('common.calendar.may') || '',
    jun: t('common.calendar.june') || '',
    jul: t('common.calendar.july') || '',
    aug: t('common.calendar.august') || '',
    sep: t('common.calendar.september') || '',
    oct: t('common.calendar.october') || '',
    nov: t('common.calendar.november') || '',
    dec: t('common.calendar.december') || '',
  };

  return Object.values(months);
}
export function getShortMonth(t: typeof localization.t): string[] {
  return getMonths(t).map(m => m.substr(0, 3));
}

export function getDays(t: typeof localization.t): string[] {
  const days = {
    sunday: t('common.calendar.sunday') || '',
    monday: t('common.calendar.monday') || '',
    tuesday: t('common.calendar.tuesday') || '',
    wednesday: t('common.calendar.wednesday') || '',
    thursday: t('common.calendar.thursday') || '',
    friday: t('common.calendar.friday') || '',
    saturday: t('common.calendar.saturday') || '',
  };
  return Object.values(days);
}
export function getShortDays(t: typeof localization.t): string[] {
  return getDays(t).map(m => m.substr(0, 3));
}

export function formatWithLocalization<T>(
  date: Date,
  formatter: (options: {
    month: string;
    shortMonth: string;
    day: string;
    shortDay: string;
    date: number;
  }) => T,
  t: typeof localization.t,
) {
  const months = getMonths(t);
  const days = getDays(t);

  const month = months[date.getMonth()];
  const day = days[date.getDay()];

  return formatter.call(null, {
    month,
    day,
    date: date.getDate(),
    shortMonth: month?.substr(0, 3) || `unknown month ${date.getMonth()}`,
    shortDay: day?.substr(0, 3) || `unknown day ${date.getDay()}`,
  });
}
