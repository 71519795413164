import {take} from 'redux-saga/effects';
import {Logger} from '@b2cmessenger/doppio-shared';
import {localization} from '@shared';
import {changeLanguage} from './actions';

export default function* localizationSaga() {
  // @ts-ignore
  while (true) {
    // @ts-ignore
    const action = yield take(changeLanguage);
    const {payload} = action || {};
    if (hasLocale(payload)) {
      try {
        const {locale} = payload;
        yield localization.changeLanguage(locale);
      } catch (e) {
        Logger.errorTag('localizationSaga', e);
      }
    }
  }
}

function hasLocale(payload: {locale?: string}): payload is {locale: string} {
  return 'locale' in payload && typeof payload.locale === 'string';
}
