import {Logger} from '@b2cmessenger/doppio-shared';
import {isInitLikeAction, logout} from '@store/actions';
import {actionChannel, all, take} from 'redux-saga/effects';
import PushNotifications from '@utils/PushNotifications';
import Config from '@utils/Config';

function* subscribe() {
  const channel = yield actionChannel(isInitLikeAction);
  while (true) {
    const action = yield take(channel);
    if (hasNotificationSubscribeId(action.payload)) {
      try {
        const id = getNotificationSubscribeId(action.payload.workPlaceSignKey);
        PushNotifications.subscribe(id);
      } catch (e) {
        Logger.errorTag('pushNotifications', e);
      }
    }
  }
}

function hasNotificationSubscribeId(payload: any) {
  return (
    'workPlaceSignKey' in payload &&
    typeof payload.workPlaceSignKey === 'string' &&
    payload.workPlaceSignKey.length >= 7
  );
}
function getNotificationSubscribeId(signInKey: string) {
  const id = signInKey.slice(0, 7);
  return `${Config.MODE === 'production' ? 'prod' : 'stage'}/messages/${id}`;
}

function* unsubscribe() {
  const channel = yield actionChannel(logout);
  while (true) {
    yield take(channel);
    try {
      PushNotifications.unsubscribe();
    } catch (e) {
      Logger.errorTag('pushNotifications', e);
    }
  }
}

export default function* pushNotificationWebSaga() {
  yield all([subscribe(), unsubscribe()]);
}
