import {useEffect, useMemo, useRef, useState} from 'react';
import {Platform, useWindowDimensions} from 'react-native';
import {throttle} from 'lodash';
import {useSafeAreaFrame} from 'react-native-safe-area-context';

export default function useWindowInfo(throttleDelay: number = 200) {
  const {width: _widthWindow, height: _heightWindow} = useWindowDimensions();
  const {width: _widthFrame, height: _heightFrame} = useSafeAreaFrame();

  const _width = Platform.select({
    web: _widthWindow,
    default: _widthFrame,
  });
  const _height = Platform.select({
    web: _heightWindow,
    default: _heightFrame,
  });

  const [width, setWidth] = useState(_width);
  const [height, setHeight] = useState(_height);

  const throttledDimensions = useRef(
    throttle((newValue: {width: number; height: number}) => {
      setWidth(newValue.width);
      setHeight(newValue.height);
    }, throttleDelay),
  );

  useEffect(
    () => throttledDimensions.current({width: _width, height: _height}),
    [_width, _height],
  );

  const isLandscape = useMemo(() => width > height, [width, height]);

  return {width, height, isLandscape};
}
