import {all} from 'redux-saga/effects';
import {
  sagas,
  configureFirebaseApp,
  FirebaseRemoteConfig,
  Analytics,
} from '@b2cmessenger/doppio-core';
import {parentSelectors} from '@store/reducer';
import workplaceSigningSaga from './workplaceSigning.saga';
import Config from '@utils/Config';
import {navigationRef} from '@navigation/navigationRef';
import {isEmployeeInviteAcceptNotification} from '@typings/Notification';
import {
  purgeMessageStorage,
  restoreBackgroundMessages,
  triggerMessageRestore,
} from '../BackgroundNotificationsEnvironment';
import {FRCDefaults} from '@utils/FirebaseRemoteConfigContext';
import appTrackingSaga from '@store/appTracking.saga';

import {
  getAdsTrackingId,
  events as AdsTrackingEvents,
  getGa4ClientId,
  SetAdsTrackingInfoProps,
} from '@utils/AdsTracking';
import {Platform} from 'react-native';
import appSubscriptionInfoSaga from '@store/subscription/saga';
import pushNotificationWebSaga from '@store/pushNotificationWeb.saga';
import localizationSaga from '@store/localization.saga';

configureFirebaseApp({
  apiKey: Config.FIREBASE_WEB_API_KEY,
  projectId: Config.FIREBASE_WEB_PROJECT_ID,
  appId: Config.FIREBASE_WEB_APP_ID,
  measurementId: Config.FIREBASE_WEB_MEASUREMENT_ID,
});
FirebaseRemoteConfig.setDefaults(FRCDefaults);

const {
  init: initSagas,
  notifications: notificationsSagas,
  auth: authSagas,
  workplace: workplaceSagas,
  analytics: analyticsSaga,
  network: networkSaga,
} = sagas;

type AppId = Parameters<typeof initSagas>[0]['appId'];

export default function* rootSaga() {
  yield all([
    initSagas({
      parentSelectors: {
        init: parentSelectors.init,
        loginedUser: parentSelectors.loginedUser,
        network: parentSelectors.network,
      },
      appId: Config.APP_ID as AppId,
    })(),
    networkSaga({
      parentSelectors: {
        network: parentSelectors.network,
      },
      appId: Config.APP_ID as AppId,
    })(),

    authSagas({
      parentSelectors: {
        init: parentSelectors.init,
        loginedUser: parentSelectors.loginedUser,
      },
      appId: Config.APP_ID as AppId,
      getGuestAdsTrackingId: getAdsTrackingId,
      getGuestGa4ClientId: getGa4ClientId,
    })(),
    workplaceSagas({
      parentSelectors: {
        init: parentSelectors.init,
        loginedUser: parentSelectors.loginedUser,
      },
      appId: Config.APP_ID as AppId,
    })(),
    createNotificationsSaga()(),
    workplaceSigningSaga(),
    pushNotificationWebSaga(),
    localizationSaga(),
    analyticsSaga({
      parentSelectors: {
        loginedUser: parentSelectors.loginedUser,
      },
      appId: Config.APP_ID as AppId,
      environmentLabelValue: Config.FIREBASE_ANALYTICS_ENV_LABEL,
      setupAnalyticsEnvironment: () => {
        AdsTrackingEvents.addListener(
          'trackingInfoSet',
          (props: SetAdsTrackingInfoProps) => {
            Analytics.setTrackingParameters(props);
          },
        );

        const facebookConfig = Platform.select({
          web: {
            pixelId: Config.FACEBOOK_PIXEL,
          },
          default: {appId: Config.FACEBOOK_APP_ID},
        });

        const appsFlyerConfig = {
          devKey: Config.APPS_FLYER_DEV_KEY,
          isDebug: false,
          onInstallConversionDataListener: true, //Optional
          onDeepLinkListener: true, //Optional
          ...(Platform.OS === 'ios'
            ? {
                appId: Config.IOS_APP_STORE_ID_BUSINESS,
                timeToWaitForATTUserAuthorization: 10, //for iOS 14.5
              }
            : {}),
        };

        return Analytics.initialize({
          facebook: facebookConfig,
          firebase: {},
          appsFlyer: appsFlyerConfig,
          offline: {
            getGA4ClientId: getGa4ClientId,
            getAdsTrackingId,
            userAgent:
              Platform.OS === 'web' ? window.navigator.userAgent : undefined,
          },
        });
      },
      getGuestAdsTrackingId: getAdsTrackingId,
      getGuestGa4ClientId: getGa4ClientId,
    })(),
    appTrackingSaga(),
    appSubscriptionInfoSaga(),
  ]);
}

function createNotificationsSaga() {
  return notificationsSagas({
    parentSelectors: {
      loginedUser: parentSelectors.loginedUser,
    },
    appId: Config.APP_ID as AppId,
    restoreBackgroundMessages,
    triggerMessageRestore,
    purgeMessageStorage,
    /**
     * handle `background` interacted notifications
     *
     * `initial` notifications handled in `useInitialNavigationState` hook
     */
    onInteraction: options => {
      console.debug('onInteraction', options);
      const {notification, workplace, interactionType} = options;
      if (interactionType === 'background') {
        if (
          isEmployeeInviteAcceptNotification(notification) &&
          workplace &&
          workplace.id === notification.data.place_id
        ) {
          navigationRef.current?.navigate('Dashboard', {
            screen: 'Profile',
            params: {
              screen: 'DevicesAndEmployees',
            },
          });
        }
      }
    },
  } as Exclude<Parameters<typeof notificationsSagas>[0], {appId: 'doppio'}>);
}
