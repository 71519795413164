import React from 'react';
import {Text} from 'react-native';
import {Spacer, Typography} from '@b2cmessenger/doppio-components';
import {DEFAULT_SPACE_HEIGHT} from '@widgets/Onboarding/lib';
import {OnboardingView} from '@widgets/Onboarding/ui/OnboardingView';

//@ts-ignore
import video from '../../../../../assets/onboarding/videos/dashboard_demo.mp4';
import {useTranslation} from '@shared';

function Stamps() {
  const {t} = useTranslation();
  const header = (
    <>
      <Text style={Typography.header}>
        {t('Widgets.Onboarding.Stamps.giveStampsToYourGuest')}
      </Text>
      <Spacer height={DEFAULT_SPACE_HEIGHT} />
      <Spacer height={DEFAULT_SPACE_HEIGHT} />
      <Text style={Typography.smallHeader}>
        {t('Widgets.Onboarding.Stamps.1TapTheStampButton')}
      </Text>
      <Spacer height={DEFAULT_SPACE_HEIGHT} />
      <Text style={Typography.smallHeader}>
        {t('Widgets.Onboarding.Stamps.2ChooseTheQuantityOfStamps')}
      </Text>
      <Spacer height={DEFAULT_SPACE_HEIGHT} />
      <Text style={Typography.smallHeader}>
        {t('Widgets.Onboarding.Stamps.3LetYourGuestScanTheQrCode')}
      </Text>
      <Spacer height={DEFAULT_SPACE_HEIGHT} />
    </>
  );

  return <OnboardingView videoSource={video} header={header} />;
}

export {Stamps};
