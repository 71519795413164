import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Animated,
  Easing,
  LayoutChangeEvent,
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
} from 'react-native';
import {Typography, useTheme} from '@b2cmessenger/doppio-components';
import {shadeColor} from '@utils/color';

const ANIMATION_DURATION = 150;
const HEIGHT = 36;
const PADDING = 4;

const ControlContext = React.createContext({
  onPress: () => {},
} as {
  selected?: string;
  onPress?: (value: string) => void;
});

function SegmentedControl<T extends string = string>({
  children,
  value,
  onChange,
}: PropsWithChildren<{
  value: T;
  onChange?: (value: T) => void;
}>) {
  const {colors} = useTheme();
  const childrenCount = useMemo(
    () => React.Children.count(children),
    [children],
  );
  const [width, setWidth] = useState(0);
  const values = useMemo(
    () =>
      React.Children.toArray(children)
        .filter(Boolean)
        // @ts-ignore
        .map((child: React.ReactNode) => child?.props?.value),
    [children],
  );
  const activeIdx = useMemo(() => values.indexOf(value), [value, values]);
  const animatedIdx = useRef(new Animated.Value(activeIdx)).current;

  const cs = useMemo(
    () =>
      StyleSheet.create({
        container: {
          paddingHorizontal: PADDING,
          backgroundColor: colors.lightgray,
          borderRadius: 8,
        },
        wrapper: {
          height: HEIGHT,
          flexDirection: 'row',
          alignItems: 'center',
        },
        bar: {
          ...StyleSheet.absoluteFillObject,
          backgroundColor: colors.brand,
          height: HEIGHT - PADDING * 2,
          top: PADDING,
          borderRadius: 6,
        },
      }),
    [colors.brand, colors.lightgray],
  );

  useEffect(() => {
    Animated.timing(animatedIdx, {
      easing: Easing.elastic(0.5),
      toValue: activeIdx,
      duration: ANIMATION_DURATION,
      useNativeDriver: true,
    }).start();
  }, [activeIdx, animatedIdx]);

  return (
    <ControlContext.Provider
      value={useMemo(
        () => ({selected: value, onPress: onChange as (val: string) => void}),
        [onChange, value],
      )}>
      <View style={cs.container}>
        <Animated.View
          onLayout={useCallback(
            (e: LayoutChangeEvent) => setWidth(e.nativeEvent.layout.width),
            [],
          )}
          style={cs.wrapper}>
          <Animated.View
            style={useMemo(
              () => [
                cs.bar,
                {
                  width: `${100 / childrenCount}%`,
                  transform: [
                    {
                      translateX: animatedIdx.interpolate({
                        inputRange: Array.from({length: values.length}).map(
                          (_, idx) => idx,
                        ),
                        outputRange: Array.from({length: values.length}).map(
                          (_, idx) => idx * (width / childrenCount),
                        ),
                      }),
                    },
                  ],
                },
              ],
              [animatedIdx, childrenCount, cs.bar, values.length, width],
            )}
          />
          {children}
        </Animated.View>
      </View>
    </ControlContext.Provider>
  );
}

function SegmentedControlItem<_T>({
  children,
  value,
  ...rest
}: PropsWithChildren<
  Omit<TouchableOpacityProps, 'onPress'> & {value: string}
>) {
  const {colors} = useTheme();
  const {selected, onPress} = useContext(ControlContext);
  const [color, setColor] = useState(
    selected === value ? colors.white : colors.darkgray,
  );
  useEffect(() => {
    if (selected === value) {
      const timer = setTimeout(() => {
        setColor(colors.white);
      }, ANIMATION_DURATION * 0.75);

      return () => {
        clearTimeout(timer);
      };
    } else {
      setColor(colors.darkgray);
    }
  }, [colors.darkgray, colors.white, selected, value]);
  return (
    <TouchableOpacity
      style={is.wrapper}
      activeOpacity={0.5}
      {...rest}
      disabled={useMemo(() => selected === value, [selected, value])}
      onPress={useMemo(
        () => (onPress ? onPress.bind(null, value) : undefined),
        [onPress, value],
      )}>
      <Animated.Text
        numberOfLines={1}
        style={useMemo(() => [is.text, {color}], [color])}>
        {children}
      </Animated.Text>
    </TouchableOpacity>
  );
}

const is = StyleSheet.create({
  wrapper: {
    height: HEIGHT - PADDING * 2,
    flex: 1,
  },
  text: {
    ...StyleSheet.flatten(Typography.smallBody),
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'center',
  },
});

SegmentedControl.Item = SegmentedControlItem;

export {SegmentedControl};
