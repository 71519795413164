import React from 'react';
import {Text} from 'react-native';
import {Spacer, Typography} from '@b2cmessenger/doppio-components';
import {DEFAULT_SPACE_HEIGHT} from '@widgets/Onboarding/lib';
import {OnboardingView} from '@widgets/Onboarding/ui/OnboardingView';

//@ts-ignore
import video from '../../../../../assets/onboarding/videos/reward.mp4';
import {useTranslation} from '@shared';

function Reward() {
  const {t} = useTranslation();

  const header = (
    <>
      <Text style={Typography.header}>
        {t('Widgets.Onboarding.Reward.rewards')}
      </Text>
      <Spacer height={DEFAULT_SPACE_HEIGHT} />

      <Text style={Typography.smallHeader}>
        {t(
          'Widgets.Onboarding.Reward.whenYourGuestHasEnoughStampsTapTheFreeButtonAndLet',
        )}
      </Text>
    </>
  );

  return <OnboardingView videoSource={video} header={header} />;
}

export {Reward};
