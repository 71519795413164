import {api} from '@b2cmessenger/doppio-core';
import {Place, PlaceFull} from '@typings/Place';
import {UploadedImage} from '@typings/common';
import {useCallback, useState} from 'react';

const PlaceChangeUrl = '/api/place/change';
type PlaceChangeResponse = PlaceFull;
type PlaceChanges = {
  newPhotoId?: UploadedImage['id'];
  name?: Place['name'];
  stampCountForReward?: Place['stamp_count_for_reward'];
};
type PlaceChangeOptions = {
  id: Place['id'];
  changes: PlaceChanges;
};
export async function placeChange(options: PlaceChangeOptions) {
  const {id, changes} = options;

  const data = new FormData();
  data.append('id', String(id));

  if (changes.name) {
    data.append('name', String(changes.name));
  }
  if (changes.newPhotoId) {
    data.append('logo', String(changes.newPhotoId));
  }
  if (changes.stampCountForReward) {
    data.append('stamp_count_for_reward', String(changes.stampCountForReward));
  }

  return api.default
    .post<PlaceChangeResponse>(PlaceChangeUrl, data)
    .catch((e) => {
      throw api.parseErrorToHumanReadableMessage(e);
    })
    .then(api.getResponseData);
}

export function usePlaceEdit(placeId: Place['id']) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const request = useCallback(
    async (changes: PlaceChanges) => {
      try {
        setError(null);
        setLoading(true);

        const place = await placeChange({
          id: placeId,
          changes,
        });

        return place;
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    },
    [placeId],
  );

  return {loading, error, setError, request};
}
