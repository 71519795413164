import React, {ReactNode, useMemo} from 'react';
import {StyleSheet, View} from 'react-native';
import {PlaceAppearance} from '@b2cmessenger/doppio-core';
import {TEST_ID} from '@components/PlaceAppearanceTestEnvelopeTestID';
import {useSelector} from 'react-redux';
import {loginedUserSelectors} from '@store/selectors';

type PlaceAppearanceTestEnvelopeProps = {
  children?: ReactNode;
};

export default function PlaceAppearanceTestEnvelope(
  props: PlaceAppearanceTestEnvelopeProps,
) {
  const workplace = useSelector(loginedUserSelectors.workplace);
  const placeId = useMemo(() => (workplace ? workplace.id : 0), [workplace]);

  const {color} = PlaceAppearance.usePlaceAppearance(placeId);

  const accessibilityValue = useMemo(
    () => ({
      text: color,
    }),
    [color],
  );
  return (
    <View
      testID={TEST_ID}
      accessibilityValue={accessibilityValue}
      style={styles.container}>
      {props.children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
