import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Navbar,
  Screen,
  Spacer,
  useTheme,
} from '@b2cmessenger/doppio-components';
import {Platform, StyleSheet, View} from 'react-native';
import {GroupedBarChart} from './components/GroupedBarChart';
import {GuestStats} from '@screens/Dashboard/screens/Stats/components/GuestStats';
import {
  getWholeMonthRange,
  getWholeWeekRange,
  getWholeYearRange,
  useStats,
} from './hooks/useStats';
import WorkplaceContext from '@components/common/WorkplaceContext';
import {DateTimeUtils} from '@b2cmessenger/doppio-shared';
import {SegmentedControl} from './components/SegmentedControl';
import {CalendarModal} from '@screens/Dashboard/screens/Stats/components/CalendarModal';
import {Portal} from 'react-native-paper';
import {useSelector} from 'react-redux';
import {isAvailableFullAccessToAppSelector} from '@store/selectors';
import OutOfFullAccess from '@screens/Dashboard/screens/Stats/components/OutOfFullAccess';
import {navigationRef} from '@navigation/navigationRef';
import useWindowInfo from '@hooks/useWindowInfo';
import {useTranslation} from '@shared';
import {formatWithLocalization} from '@screens/Dashboard/screens/Stats/hooks/shared';
import StatsExample from '@screens/Dashboard/screens/Stats/components/StatsExample';

import {usePlaceCustomers} from '@screens/Dashboard/screens/Stats/hooks/usePlaceCustomers';
import {CustomersCard} from '@features/Customers/ui/CustomerCard';
import {CustomerMessagesCard} from '@screens/Dashboard/screens/Stats/Messages/CustomerMessagesCard';

const CUSTOMERS_LIMIT = 3;

type StatsScope = 'week' | 'month' | 'year';
export function StatsTabScreen() {
  const {t} = useTranslation();
  const {colors} = useTheme();
  const navbar = useMemo(() => <Navbar />, []);
  const isAvailableFullAccessToApp = useSelector(
    isAvailableFullAccessToAppSelector,
  );
  const workplace = useContext(WorkplaceContext);
  const {data: customers, totalCount} = usePlaceCustomers(
    workplace.id,
    CUSTOMERS_LIMIT,
  );

  const isExample = useMemo(() => customers?.length === 0, [customers]);

  const [scope, setScope] = useState<StatsScope>('week');
  const [activeGroupIdx, setActiveGroupIdx] = useState<number>();
  const onGroupActive = useCallback((idx: number | undefined) => {
    setActiveGroupIdx(idx);
  }, []);

  const {width} = useWindowInfo();
  const chartWidth = useMemo(
    () => width - Screen.PADDING_HORIZONTAL * 2,
    [width],
  );

  const [dates, setDates] = useState(() => {
    return getInitialDateByScope(scope);
  });
  const [from, to] = dates;
  const stats = useStats(
    useMemo(
      () => ({
        placeId: workplace.id,
        from,
        to,
        scope,
        isAvailableFullAccessToApp,
      }),
      [from, scope, to, workplace.id, isAvailableFullAccessToApp],
    ),
  );

  const dateFormatter = useCallback(
    (datetime: string) => {
      if (scope === 'week') {
        return formatWithLocalization(
          DateTimeUtils.fromDatetimeServer(datetime),
          ({month, shortDay, date}) => `${shortDay}, ${month} ${date}`,
          t,
        );
      } else if (scope === 'year') {
        return [
          formatWithLocalization(
            DateTimeUtils.fromDatetimeServer(datetime),
            ({month}) => month,
            t,
          ),
          String(DateTimeUtils.fromDatetimeServer(datetime).getFullYear()),
        ].join(' ');
      } else {
        const month = formatWithLocalization(
          DateTimeUtils.fromDatetimeServer(datetime),
          ({month}) => month,
          t,
        );

        return month;
      }
    },
    [scope, t],
  );

  const formattedScope = useMemo(() => {
    if (scope === 'month') {
      return (
        formatWithLocalization(from, ({month}) => month, t) +
        `${
          new Date().getFullYear() !== from.getFullYear()
            ? String(' ' + from.getFullYear())
            : ''
        }`
      );
    } else if (scope === 'week') {
      const _f = formatWithLocalization(
        from,
        ({month, date}) => [month, String(date)],
        t,
      );
      const _t = formatWithLocalization(
        to,
        ({month, date}) => [month, String(date)],
        t,
      );

      const fromYearAppendix = `${
        new Date().getFullYear() !== from.getFullYear()
          ? String(', ' + from.getFullYear())
          : ''
      }`;
      const toYearAppendix = `${
        new Date().getFullYear() !== to.getFullYear() ||
        from.getFullYear() !== to.getFullYear()
          ? String(', ' + to.getFullYear())
          : ''
      }`;

      // same month
      if (_t[0] === _f[0]) {
        return [_f.join(' '), _t[1]].join(' – ') + toYearAppendix;
      }

      return [
        _f.join(' ') +
          (fromYearAppendix === toYearAppendix ? '' : fromYearAppendix),
        _t.join(' ') + toYearAppendix,
      ].join(' – ');
    } else {
      return `${from.getFullYear()}`;
    }
  }, [from, scope, t, to]);

  const [date, returningGuests, newGuests] = useMemo(
    () => [
      stats.isValidating || !stats.data
        ? '...'
        : activeGroupIdx === undefined
        ? formattedScope
        : dateFormatter(stats.data.series[0][activeGroupIdx].date),
      stats.isValidating || !stats.data
        ? 0
        : activeGroupIdx === undefined
        ? stats.data.series[0].reduce((memo, val) => memo + val.y, 0)
        : stats.data.series[0][activeGroupIdx].y,
      stats.isValidating || !stats.data
        ? 0
        : activeGroupIdx === undefined
        ? stats.data.series[1].reduce((memo, val) => memo + val.y, 0)
        : stats.data.series[1][activeGroupIdx].y,
    ],
    [
      stats.isValidating,
      stats.data,
      activeGroupIdx,
      formattedScope,
      dateFormatter,
    ],
  );

  const [m, setM] = useState(false);

  const onPressGuestStats = useCallback(() => setM(true), []);
  const onChangeSegmentedControl = useCallback((newScope: StatsScope) => {
    setScope(newScope);
    setDates(getInitialDateByScope(newScope));
  }, []);

  const statsContent = useMemo(() => {
    if (isExample) {
      return <StatsExample width={chartWidth} />;
    }

    return (
      <>
        <GuestStats
          loading={stats.isValidating || !stats.data}
          onDatePress={onPressGuestStats}
          date={date}
          total={newGuests + returningGuests}
          returning={returningGuests}
        />
        {stats.data && !stats.isValidating ? (
          <GroupedBarChart
            scope={scope}
            width={chartWidth}
            xAxisTickCount={scope === 'month' ? 3 : undefined}
            data={stats.data.series}
            categories={stats.data.categories}
            activeGroupIdx={scope !== 'month' ? activeGroupIdx : undefined}
            onGroupActive={scope !== 'month' ? onGroupActive : undefined}
          />
        ) : stats.isValidating ? (
          <GroupedBarChart.Placeholder width={chartWidth} />
        ) : null}
        <Spacer />
        <SegmentedControl value={scope} onChange={onChangeSegmentedControl}>
          <SegmentedControl.Item value="week">
            {t('Screens.Stats.week')}
          </SegmentedControl.Item>
          <SegmentedControl.Item value="month">
            {t('Screens.Stats.month')}
          </SegmentedControl.Item>
          <SegmentedControl.Item value="year">
            {t('Screens.Stats.year')}
          </SegmentedControl.Item>
        </SegmentedControl>
      </>
    );
  }, [
    activeGroupIdx,
    chartWidth,
    date,
    isExample,
    newGuests,
    onChangeSegmentedControl,
    onGroupActive,
    onPressGuestStats,
    returningGuests,
    scope,
    stats.data,
    stats.isValidating,
    t,
  ]);
  const onPressOutOfFullAccess = useCallback(() => {
    navigationRef.current?.navigate('Dashboard', {
      screen: 'ManageSubscription',
    });
  }, []);

  return (
    <Screen navbar={navbar} style={styles.screen} scrollable>
      {isAvailableFullAccessToApp ? (
        <>
          {statsContent}
          <Spacer />
          <CustomersCard
            customers={customers}
            colors={colors}
            t={t}
            limit={CUSTOMERS_LIMIT}
            totalCount={totalCount}
            onShowAll={() =>
              navigationRef?.current?.navigate('Dashboard', {
                screen: 'Customers',
              })
            }
          />
          <Spacer />
          <CustomerMessagesCard
            totalCount={totalCount}
            goToMessages={() =>
              navigationRef?.current?.navigate('Dashboard', {
                screen: 'CustomerMessages',
              })
            }
          />
          <Spacer />
        </>
      ) : (
        <View style={styles.outOfFullAccessWrapper}>
          <OutOfFullAccess onPress={onPressOutOfFullAccess} />
        </View>
      )}
      <Portal>
        <CalendarModal
          animationType="fade"
          onDatesChange={useCallback((f, t) => {
            setDates([f, t]);
          }, [])}
          visible={m}
          onRequestClose={() => setM(false)}
          min={useMemo(
            () => DateTimeUtils.fromDatetimeServer(workplace.created_at),
            [workplace.created_at],
          )}
          from={from}
          to={to}
          scope={scope}
        />
      </Portal>
    </Screen>
  );
}

const styles = StyleSheet.create({
  outOfFullAccessWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  screen: Platform.select({
    web: {
      flexGrow: 0,
    },
    default: {},
  }),
});

function getInitialDateByScope(scope: StatsScope, date?: Date) {
  return scope === 'week'
    ? getWholeWeekRange(date)
    : scope === 'month'
    ? getWholeMonthRange(date)
    : getWholeYearRange(date);
}
