import {getDays} from '@screens/Dashboard/screens/Stats/hooks/shared';
import {localization} from '@shared';
import {Customer} from '@typings/common';

export function generateExampleStatsData() {
  const days = getDays(localization.t);

  const stats = {
    totalGuests: 0,
    returnedGuests: 0,
    newGuests: 0,
    series: [
      [
        {
          date: '2023-10-01',
          x: days[0].substring(0, 3),
          y: 10,
        },
        {
          date: '2023-10-02',
          x: days[1].substring(0, 3),
          y: 8,
        },
        {
          date: '2023-10-03',
          x: days[2].substring(0, 3),
          y: 7,
        },
        {
          date: '2023-10-04',
          x: days[3].substring(0, 3),
          y: 7,
        },
        {
          date: '2023-10-05',
          x: days[4].substring(0, 3),
          y: 8,
        },
        {
          date: '2023-10-06',
          x: days[5].substring(0, 3),
          y: 7,
        },
        {
          date: '2023-10-07',
          x: days[6].substring(0, 3),
          y: 11,
        },
      ],
      [
        {
          date: '2023-10-01',
          x: days[0].substring(0, 3),
          y: 12,
        },
        {
          date: '2023-10-02',
          x: days[1].substring(0, 3),
          y: 4,
        },
        {
          date: '2023-10-03',
          x: days[2].substring(0, 3),
          y: 5,
        },
        {
          date: '2023-10-04',
          x: days[3].substring(0, 3),
          y: 6,
        },
        {
          date: '2023-10-05',
          x: days[4].substring(0, 3),
          y: 3,
        },
        {
          date: '2023-10-06',
          x: days[5].substring(0, 3),
          y: 5,
        },
        {
          date: '2023-10-07',
          x: days[6].substring(0, 3),
          y: 11,
        },
      ],
    ],
    categories: days.map(day => day.substring(0, 3)),
  };

  stats.returnedGuests = stats.series[0].reduce<number>(
    (prev, current) => prev + current.y,
    0,
  );
  stats.newGuests = stats.series[1].reduce<number>(
    (prev, current) => prev + current.y,
    0,
  );
  stats.totalGuests = stats.returnedGuests + stats.newGuests;

  return stats;
}

const _mockAvatar = 'https://cdn.say2b.com/storage/av/za/zh/640/nkvcag.jpg';
const _mockNames = [
  {firstName: 'Brian', lastName: 'Hood'},
  {firstName: 'Mike', lastName: 'Wazowski'},
  {firstName: 'Jeffrey', lastName: 'Lebowski'},
  {firstName: '', lastName: ''},
  {firstName: 'Brian', lastName: 'Hood'},
  {firstName: 'Mike', lastName: 'WazowskiWazowskiWazowskiWazowski'},
  {firstName: 'Jeffrey', lastName: 'LebowskiLebowski'},
  {firstName: 'Brian', lastName: 'Hood'},
  {firstName: 'Mike', lastName: 'WazowskiWazowskiWazowskiWazowski'},
  {firstName: 'Jeffrey', lastName: 'LebowskiLebowski'},
  {firstName: 'Brian', lastName: 'Hood'},
  {firstName: 'Mike', lastName: 'WazowskiWazowskiWazowskiWazowski'},
  {firstName: 'Jeffrey', lastName: 'LebowskiLebowski'},
  {firstName: '', lastName: ''},
  {firstName: '', lastName: ''},
  {firstName: '', lastName: ''},
];
const _mockCustomers: Customer[] = [...Array(15).keys()].map(id => ({
  id,
  firstname: _mockNames[id]?.firstName || '',
  avatarThumb: id % 2 === 0 ? _mockAvatar : '',
  lastname: _mockNames[id]?.lastName || '',
  rewardsReceived: id % 2 === 0 ? 10 + id : 0,
  stampBalance: id === 3 ? 0 : id * 2,
}));
