import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Place} from '@typings/Place';
import {isTablet} from 'react-native-device-info';
import {localization} from '@shared';

const Separator = isTablet() ? ' ' : '\n';

export enum Stages {
  initial = 'initial',
  accepting = 'accepting',
  auth = 'auth',
  scanFailed = 'scanFailed',
  scanner = 'scanner',
}

const initialState = {
  stage: Stages.initial,
  heading: '',
  description: '',
  invite: null as null | {
    code: string;
    placeId: number;
  },
  place: null as Place | null,
};
function getInitialState() {
  return {
    ...initialState,
    heading: localization.t(
      'Screens.JoinAsEmployee.slice.scanQRToJoinTheBusiness',
      {
        s: Separator,
      },
    ),
  };
}
export const slice = createSlice({
  name: 'joinAsEmployee',
  initialState,
  reducers: {
    init: () => getInitialState(),
    reset: () => getInitialState(),
    startScanner: () => ({...getInitialState(), stage: Stages.scanner}),
    acceptInvite: {
      prepare: (code: string, placeId: number) => ({payload: {code, placeId}}),
      reducer: (
        state,
        action: PayloadAction<{code: string; placeId: number}>,
      ) => ({
        ...state,
        stage: Stages.accepting,
        heading: localization.t('Screens.JoinAsEmployee.slice.verifyingCode'),
        invite: {...action.payload},
      }),
    },
    setPlaceInvite: {
      prepare: (code: string, place: Place) => ({payload: {code, place}}),
      reducer: (
        state,
        action: PayloadAction<{code: string; place: Place}>,
      ) => ({
        ...state,
        place: action.payload.place,
        invite: {
          code: action.payload.code,
          placeId: action.payload.place.id,
        },
      }),
    },
    requestAuthorization: {
      prepare: (code: string, place: Place) => ({payload: {code, place}}),
      reducer: (
        state,
        action: PayloadAction<{code: string; place: Place}>,
      ) => ({
        ...state,
        stage: Stages.auth,
        heading: localization.t('Screens.JoinAsEmployee.slice.youAreJoining', {
          s: Separator,
          name: action.payload.place.name,
        }),
        description: '',
        place: action.payload.place,
        invite: {
          code: action.payload.code,
          placeId: action.payload.place.id,
        },
      }),
    },
    acceptFailed: {
      prepare: (reason: string) => ({payload: reason}),
      reducer: (state, action: PayloadAction<string>) => ({
        ...state,
        stage: Stages.scanFailed,
        heading: localization.t(
          'Screens.JoinAsEmployee.slice.unableToJoinTheBusiness',
          {
            s: Separator,
          },
        ),
        description: action.payload,
        invite: null,
      }),
    },
  },
});
