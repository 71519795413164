import React, {
  ComponentProps,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import {
  Screen,
  Spacer,
  Typography,
  useTheme,
} from '@b2cmessenger/doppio-components';
import {ProfileNavigatorScreenParams} from '@navigation/config';
import {Alert} from '@components/common/Alert';
import {StyleSheet, Text} from 'react-native';

import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import WorkplaceContext, {
  getWorkplaceRole,
} from '@components/common/WorkplaceContext';
import {DevicesAndEmployees} from '@screens/Dashboard/screens/Profile/DevicesAndEmployees';
import {ProfileSettings} from '@screens/Dashboard/screens/Profile/Settings';
import {usePlaceEdit} from '@screens/Dashboard/screens/Profile/hooks/usePlaceEdit';
import {useDispatch} from 'react-redux';
import {setWorkplace} from '@store/actions';
import {navigationRef} from '@navigation/navigationRef';
import {ProfileHeader} from '@screens/Dashboard/screens/Profile/components/ProfileHeader';
import LoggedInUserNavbar from '@components/common/LoggedInUserNavbar';
import {TEST_IDS} from './testIDS';
import {onImageUploadOptions} from '@b2cmessenger/doppio-image-uploader';
import {useTranslation} from '@shared';
import useWindowInfo from '@hooks/useWindowInfo';

const Tab = createMaterialTopTabNavigator<ProfileNavigatorScreenParams>();
type TabNavigationProps = ComponentProps<typeof Tab.Navigator>;
const MEDIUM_BODY_MIN_WIDTH = 360;

export function ProfileTabScreen() {
  const {t} = useTranslation();
  const {width} = useWindowInfo();

  const {colors} = useTheme();
  const dispatch = useDispatch();
  const navbar = useMemo(() => <LoggedInUserNavbar />, []);
  const workplace = useContext(WorkplaceContext);
  const workplaceRole = getWorkplaceRole(workplace);

  const tabBarOptions = useMemo<TabNavigationProps['tabBarOptions']>(
    () => ({
      inactiveBackgroundColor: colors.white,
      activeBackgroundColor: colors.white,
      activeTintColor: colors.brand,
      allowFontScaling: false,
      inactiveTintColor: colors.darkgray,
      indicatorStyle: {
        backgroundColor: colors.brand,
      },
      tabStyle: {
        paddingHorizontal: 0,
      },
      labelStyle: {
        fontSize: Typography.mediumBody.fontSize,
        fontWeight: Typography.mediumBody.fontWeight,
        textTransform: 'capitalize',
      },
      style: {
        height: workplaceRole === 'owner' ? 48 : 0,
        elevation: 0,
        borderBottomWidth: 1,
        borderBottomColor: colors.lightgray,
      },
    }),
    [
      colors.brand,
      colors.darkgray,
      colors.lightgray,
      colors.white,
      workplaceRole,
    ],
  );
  const tabBarLabelStyles = useMemo(() => {
    return [
      width > MEDIUM_BODY_MIN_WIDTH
        ? Typography.mediumBody
        : Typography.smallBody,
      profileTabScreenStyles.tabBarLabel,
    ];
  }, [width]);

  const {
    error,
    loading: imageLoading,
    request: placeChange,
    setError,
  } = usePlaceEdit(workplace.id);

  useEffect(() => {
    if (error) {
      Alert.alert(t('Screens.Profile.anErrorHasOccurred'), error);
      setError(null);
    }
  }, [setError, error, t]);

  const onUploadImage = useCallback(
    async ({photo, photoThumb, image}: onImageUploadOptions) => {
      try {
        const place = await placeChange({newPhotoId: image.id});
        if (place) {
          dispatch(
            setWorkplace({
              ...workplace,
              logo: photo,
              logoThumb: photoThumb,
            }),
          );
        }
      } catch (e) {}
    },
    [dispatch, placeChange, workplace],
  );

  const onWorkplaceNamePress = useCallback(
    () =>
      navigationRef.current?.navigate('Dashboard', {
        screen: 'ChangeWorkplaceName',
      }),
    [],
  );

  return (
    <Screen
      navbar={navbar}
      style={profileTabScreenStyles.screen}
      testID={TEST_IDS.screen}
    >
      <Spacer height={30} />
      <ProfileHeader
        placeNameTestID={TEST_IDS.elements.PlaceName}
        placeNameAccessibilityValue={useMemo(() => ({text: workplace.name}), [
          workplace.name,
        ])}
        workplaceLogo={workplace.logo}
        workplaceName={workplace.name}
        onWorkplaceNamePress={
          workplaceRole === 'owner' ? onWorkplaceNamePress : undefined
        }
        onUploadWorkplaceLogo={
          workplaceRole === 'owner' ? onUploadImage : undefined
        }
        workplaceImageLoading={imageLoading}
      />
      <Spacer />
      <Tab.Navigator tabBarOptions={tabBarOptions}>
        {workplaceRole === 'owner' ? (
          <Tab.Screen
            name="DevicesAndEmployees"
            options={{
              tabBarTestID: TEST_IDS.elements.DevicesAndEmployeesTab,
              title: t('Screens.Profile.devicesEmployees') || '',
              tabBarLabel: ({color}) => {
                return (
                  <Text style={[...tabBarLabelStyles, {color}]}>
                    {t('Screens.Profile.devicesEmployees')}
                  </Text>
                );
              },
            }}
            component={DevicesAndEmployees}
          />
        ) : null}
        <Tab.Screen
          name="Settings"
          options={{
            tabBarTestID: TEST_IDS.elements.SettingsTab,
            title: t('Screens.Profile.settings') || '',
            tabBarLabel: ({color}) => {
              return (
                <Text style={[...tabBarLabelStyles, {color}]}>
                  {t('Screens.Profile.settings')}
                </Text>
              );
            },
          }}
          component={ProfileSettings}
        />
      </Tab.Navigator>
    </Screen>
  );
}

const profileTabScreenStyles = StyleSheet.create({
  screen: {
    paddingHorizontal: 0,
  },
  tabBarLabel: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
