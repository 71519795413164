import Config from '@utils/Config';
import {FirebaseDynamicLinksTypes} from '@react-native-firebase/dynamic-links';

export const DynamicLinksConfig = {
  Business: {
    DynamicLinkDomain: Config.DYNAMIC_LINK_DOMAIN_BUSINESS,
    DomainURIPrefix: Config.DOMAIN_URI_PREFIX_BUSINESS,
    AndroidPackageName: Config.ANDROID_PACKAGE_NAME_BUSINESS,
    IosAppStoreId: Config.IOS_APP_STORE_ID_BUSINESS,
    IosBundleId: Config.IOS_BUNDLE_ID_BUSINESS,
  },
  Client: {
    DynamicLinkDomain: Config.DYNAMIC_LINK_DOMAIN_CLIENT,
    DomainURIPrefix: Config.DOMAIN_URI_PREFIX_CLIENT,
    AndroidPackageName: Config.ANDROID_PACKAGE_NAME_CLIENT,
    IosAppStoreId: Config.IOS_APP_STORE_ID_CLIENT,
    IosBundleId: Config.IOS_BUNDLE_ID_CLIENT,
  },
};

export class InviteLink {
  static parse(link: string) {
    if (link.startsWith(DynamicLinksConfig.Business.DynamicLinkDomain)) {
      const path = link.substr(
        DynamicLinksConfig.Business.DynamicLinkDomain.length,
      );
      const [routeName, ...args] = path.split('/').filter(Boolean);
      if (routeName === 'employee' || routeName === 'pos') {
        return new InviteLink(routeName, Number(args[0]), String(args[1]));
      }
    }
    return null;
  }

  constructor(
    public type: 'employee' | 'pos',
    public placeId: number,
    public inviteCode: string,
  ) {}

  build() {
    return [
      DynamicLinksConfig.Business.DynamicLinkDomain,
      this.type,
      String(this.placeId),
      this.inviteCode,
    ].join('/');
  }
}

export class ManageSubscriptionLink {
  public type = 'manage-subscription';

  static parse(link: string) {
    if (link.startsWith(DynamicLinksConfig.Business.DynamicLinkDomain)) {
      const path = link.substr(
        DynamicLinksConfig.Business.DynamicLinkDomain.length,
      );
      if (path === 'manage-subscription') {
        return new ManageSubscriptionLink();
      }
    }
    return null;
  }

  build() {
    return [DynamicLinksConfig.Business.DynamicLinkDomain, this.type].join('/');
  }
}

export class StampLink {
  public type = 'stamp';
  constructor(
    public placeId: number,
    public amount: number,
    public signature: string,
  ) {}

  static parse(link: string) {
    if (link.startsWith(DynamicLinksConfig.Client.DynamicLinkDomain)) {
      const path = link.substr(
        DynamicLinksConfig.Client.DynamicLinkDomain.length,
      );
      const [routeName, ...args] = path.split('/');
      if (routeName === 'stamp') {
        return new StampLink(Number(args[0]), Number(args[1]), String(args[2]));
      }
    }
    return null;
  }

  build() {
    return [
      DynamicLinksConfig.Client.DynamicLinkDomain,
      this.type,
      String(this.placeId),
      String(this.amount),
      this.signature,
    ].join('/');
  }
}

type DynamicLinkParameterKey = 'link' | 'apn' | 'afl' | 'ibi' | 'ifl' | 'efr';

export function buildLinkSync(
  options: FirebaseDynamicLinksTypes.DynamicLinkParameters,
) {
  let data: Record<DynamicLinkParameterKey, string | undefined> = {
    link: options.link,
  } as any;

  if (options.android) {
    const {packageName: apn, fallbackUrl: afl} = options.android;
    data = {...data, apn, afl};
  }
  if (options.ios) {
    const {bundleId: ibi, fallbackUrl: ifl} = options.ios;
    data = {...data, ibi, ifl};
  }
  if (options.navigation) {
    const {forcedRedirectEnabled: efr} = options.navigation;
    if (efr) {
      data = {...data, efr: '1'};
    }
  }

  const p = (Object.keys(data) as DynamicLinkParameterKey[])
    .filter((k) => data[k] !== undefined)
    .reduce((params, k) => {
      params.append(k, (data as Record<DynamicLinkParameterKey, string>)[k]);
      return params;
    }, new URLSearchParams());

  return [options.domainUriPrefix, p.toString()].join('?');
}
