import {createScreenTestIDS} from '../../../e2e/createScreenTestIDS';

export const TEST_IDS = createScreenTestIDS('AppearanceCustomizer', {
  buttons: ['ColorTab', 'StampsTab', 'AwardsTab'],
  inputs: [],
  elements: [
    'Picker',
    'ColorPicker',
    'Color',
    'StampIconPicker',
    'StampIcon',
    'AwardIconPicker',
    'AwardIcon',
  ],
});
