import {LinkingOptions, getStateFromPath} from '@react-navigation/native';
import Config from '@utils/Config';
import {Logger} from '@b2cmessenger/doppio-shared';
import {Platform} from 'react-native';

export const linking: LinkingOptions = {
  prefixes: [Config.DOMAIN_URI_PREFIX_BUSINESS, 'https://localhost:19006'],
  config: {
    screens: {
      Link: {
        path: 'link/:link',
        parse: {
          link: String,
        },
      },
      Dashboard: {
        path: '',
        initialRouteName: 'QRCode',
        screens: {
          QRCode: {
            path: 'qr',
          },
          Stats: {
            path: 'stats',
          },
          Customers: {
            path: 'stats/customers',
          },
          Profile: {
            path: 'profile',
            screens: {
              DevicesAndEmployees: {
                path: 'devices',
              },
              Settings: {
                path: 'settings',
              },
            },
          },
        },
      },
      BusinessInvite: {
        path: 'invite',
      },
      CustomizeAppearance: {
        path: 'appearance',
      },
      ChangeWorkplaceName: {
        path: 'profile/name',
      },
      EditLoyaltyProgram: {
        path: 'profile/loyalty',
      },
      AccountRemoval: {
        path: 'account/remove',
      },
      JoinAsEmployee: {
        path: 'join',
      },
      ManageSubscription: {
        path: 'profile/subscription',
      },
      SignIn: {
        path: 'sign-in',
      },

      BusinessWizard: {
        path: 'wizard',
      },
      EmailSignIn: {
        path: 'sign-in/email',
      },
      EmployeeConnected: {
        path: 'join/success',
      },
    },
  },
  getStateFromPath: (path, options) => {
    try {
      if (Platform.OS === 'web') {
        if (path.startsWith('/pos')) {
          const [placeId, code] = path.substr('/pos/'.length).split('/');
          path = `/join?placeId=${placeId}&code=${code}`;
          Logger.verboseTag('PATH: ', path);
        } else if (path.startsWith('/employee')) {
          const [placeId, code] = path.substr('/employee/'.length).split('/');
          path = `/join?placeId=${placeId}&code=${code}`;
        }
      }
    } catch (e) {
      Logger.errorTag('getStateFromPath', e);
    }

    return getStateFromPath(path, options);
  },
};
