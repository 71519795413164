import React, {ComponentProps, useCallback, useMemo, useState} from 'react';
import StampCard from '@components/common/StampCard';
import {TEST_IDS} from '@screens/BusinessWizard/BusinessWizardTestIDS';
import {
  Button,
  Card,
  Spacer,
  Navbar,
  ThemeProvider,
  useTheme,
  BModal,
} from '@b2cmessenger/doppio-components';
import AppearanceCustomizer, {
  getConfigChanges,
  hasChanged,
  useAppearanceCustomizer,
  useFetchPossibleAppearance,
} from '@components/common/AppearanceCustomizer';
import {AppearanceCustomizeInfo} from '@components/common/AppearanceCustomizer/AppearanceCustomizeInfo';
import {
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  View,
  StatusBar,
} from 'react-native';
import {PlaceAppearanceConfigExtended} from '@screens/BusinessWizard/slice';
import {PlaceAppearance} from '@b2cmessenger/doppio-core';
import {useFocusEffect} from '@react-navigation/core';
import {AppearanceStages} from '@components/common/AppearanceCustomizer/slice';
import AppearanceCustomizeControls from '@components/common/AppearanceCustomizer/AppearanceCustomizeControls';
import {colors as defaultColors} from '@b2cmessenger/doppio-shared';
import {DEFAULT_AWARD_NAME} from '@components/common/AppearanceCustomizer/shared';
import {Portal} from 'react-native-paper';
import useWindowInfo from '@hooks/useWindowInfo';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useTranslation} from '@shared';

type SetupLoyaltyProps = {
  businessTypeId: number | null;
  stamps: number;
  onStampsChange: (n: number) => void;
  appearanceConfig: PlaceAppearanceConfigExtended | null;
  onAppearanceChange: (config?: PlaceAppearanceConfigExtended) => void;
  possibleAppearances: ReturnType<
    typeof useFetchPossibleAppearance
  >['possibleAppearances'];
  possibleAppearancesError: ReturnType<
    typeof useFetchPossibleAppearance
  >['possibleAppearancesError'];
};
const BMODAL_TOP_CLOSE_BUTTON = 75;

export function SetupLoyalty(props: SetupLoyaltyProps) {
  const {
    stamps,
    onStampsChange,
    appearanceConfig,
    onAppearanceChange: onAppearanceChangeExternal,
    possibleAppearances,
    possibleAppearancesError,
  } = props;
  const {t} = useTranslation();
  const {height} = useWindowInfo();
  const safeIndent = useSafeIndent();

  const {colors} = useTheme();

  const nameFromAward = useMemo(() => {
    return possibleAppearances?.resources.find(
      v => v.id === appearanceConfig?.award_resource_id,
    )?.name;
  }, [appearanceConfig, possibleAppearances]);

  const awardName = (
    appearanceConfig?.award_name ||
    nameFromAward ||
    DEFAULT_AWARD_NAME
  ).toLowerCase();

  const {
    stage,
    loading,
    error,
    initialConfig,
    currentConfig,
    fetch,
    onChange,
    actions,
  } = useAppearanceCustomizer({
    overrideConfig: appearanceConfig || undefined,
    possibleAppearances,
    possibleAppearancesError,
  });

  const [customizationModalVisible, setCustomizationModalVisible] = useState(
    false,
  );
  const openCustomizationModal = useCallback(() => {
    actions.showPicker();
    setCustomizationModalVisible(true);
  }, [actions]);
  const closeCustomizationModal = useCallback(() => {
    setCustomizationModalVisible(false);
    actions.reset();
  }, [actions]);

  const saveCustomization = useCallback(() => {
    if (hasChanged(currentConfig, initialConfig)) {
      onAppearanceChangeExternal(
        getConfigChanges(currentConfig, possibleAppearances),
      );
    }
    setCustomizationModalVisible(false);
  }, [
    currentConfig,
    initialConfig,
    onAppearanceChangeExternal,
    possibleAppearances,
  ]);

  const currentBrandColor = useMemo(() => {
    if (currentConfig.color) {
      return currentConfig.color;
    } else if (currentConfig.color === null) {
      return defaultColors.brand;
    } else {
      return colors.brand;
    }
  }, [colors.brand, currentConfig.color]);

  const iconComponent = useMemo<
    ComponentProps<typeof StampCard>['IconComponent']
  >(() => {
    return ({size, active}) => {
      return (
        <PlaceAppearance.Icon
          size={size}
          xml={
            appearanceConfig?.stamp_resource_content ||
            PlaceAppearance.Icon.CoffeeBeanSVGXmlContent
          }
          color={active ? currentBrandColor : '#ffffff'}
          contentColor={active ? '#ffffff' : '#cfd4d9'}
          strokeColor={active ? undefined : '#cfd4d9'}
        />
      );
    };
  }, [appearanceConfig, currentBrandColor]);

  useFocusEffect(
    useCallback(() => {
      fetch();
      return () => {
        actions.loading();
      };
    }, [actions, fetch]),
  );

  const navbar = useMemo(() => {
    const _title =
      stage === AppearanceStages.awardNameEdit
        ? t('Screens.BusinessWizard.SetupLoyalty.customizeRewardName')
        : t('Screens.BusinessWizard.SetupLoyalty.customizeColorIcon');
    return (
      <Navbar
        title={_title}
        onBack={
          stage === AppearanceStages.awardNameEdit
            ? actions.onBack
            : closeCustomizationModal
        }
        backButtonTestID={'customize-appearance-back'}
      />
    );
  }, [actions.onBack, closeCustomizationModal, stage, t]);

  return (
    <>
      <StampCard
        awardName={`${awardName}`}
        description={
          t(
            'Screens.BusinessWizard.SetupLoyalty.tapToSetTheNumberOfStampsAClientHasToCollectToGetA',
            {awardName: awardName},
          ) || ''
        }
        stampElementTestID={TEST_IDS.elements.Stamp}
        testID={TEST_IDS.elements.StampCard}
        value={stamps}
        onChange={onStampsChange}
        IconComponent={iconComponent}
        FooterComponent={
          <>
            <Spacer />
            <Button
              title={t(
                'Screens.BusinessWizard.SetupLoyalty.customizeColorAndIcons',
              )}
              testID={TEST_IDS.buttons.CustomizeLoyalty}
              mode="inverted"
              onPress={openCustomizationModal}
            />
          </>
        }
      />
      <Portal>
        <BModal
          style={[styles.modal]}
          contentWrapperStyle={[
            styles.modalContent,
            {maxHeight: height - BMODAL_TOP_CLOSE_BUTTON - safeIndent},
          ]}
          visible={customizationModalVisible}
          onRequestClose={closeCustomizationModal}
        >
          <Spacer height={16} />
          {navbar}
          <KeyboardAvoidingView
            style={[styles.contentContainer]}
            behavior={Platform.OS === 'ios' ? 'padding' : undefined}
            keyboardVerticalOffset={safeIndent + BMODAL_TOP_CLOSE_BUTTON}
          >
            <ThemeProvider colors={{brand: currentBrandColor}}>
              <AppearanceCustomizeInfo
                brandColor={currentBrandColor}
                stage={stage}
              />
              <Spacer />
              <View style={styles.appearanceCustomizerContainer}>
                <AppearanceCustomizer
                  onChange={onChange}
                  error={error}
                  loading={loading}
                  stage={stage}
                  possibleAppearances={possibleAppearances}
                  contentPadding={Card.PADDING_HORIZONTAL}
                  initialConfig={initialConfig}
                  currentConfig={currentConfig}
                  actions={actions}
                />
              </View>
              {!loading ? (
                <AppearanceCustomizeControls
                  stage={stage}
                  hasError={!!error}
                  onSave={saveCustomization}
                  onTryAgain={fetch}
                  actions={actions}
                  doneTestId={TEST_IDS.buttons.CustomizeLoyaltyDone}
                  // @ts-ignore
                  saveButtonTitle={
                    hasChanged(currentConfig, initialConfig)
                      ? t('Screens.BusinessWizard.SetupLoyalty.save')
                      : t('Screens.BusinessWizard.SetupLoyalty.close') || ''
                  }
                />
              ) : null}
            </ThemeProvider>
          </KeyboardAvoidingView>
        </BModal>
      </Portal>
    </>
  );
}

function useSafeIndent() {
  const {top} = useSafeAreaInsets();
  return top ? top : StatusBar?.currentHeight || 0;
}

const styles = StyleSheet.create({
  modal: {flex: 1, flexGrow: 1},
  modalContent: {
    height: '100%',
    maxHeight: '100%',
  },
  contentContainer: {
    flexGrow: 1,
    flex: 1,
    marginLeft: Card.PADDING_HORIZONTAL,
    marginRight: Card.PADDING_HORIZONTAL,
  },
  appearanceCustomizerContainer: {
    flexGrow: 1,
    marginLeft: -Card.PADDING_HORIZONTAL,
    marginRight: -Card.PADDING_HORIZONTAL,
  },
});
