import {
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

type SubscriptionInfo = {
  numberOfTrialDays: number;
  pricePerMonthUsdCents: number;
  updatedAt: number | null;
};
type SubscriptionState = {
  info: SubscriptionInfo;
};

const initialState: SubscriptionState = {
  info: {
    numberOfTrialDays: 14,
    pricePerMonthUsdCents: 3000,
    updatedAt: null,
  },
};

const {reducer, actions} = createSlice({
  name: 'subscription',
  initialState: initialState,
  reducers: {
    updateInfo(
      state,
      action: PayloadAction<Omit<SubscriptionInfo, 'updatedAt'>>,
    ) {
      state.info.numberOfTrialDays = action.payload.numberOfTrialDays;
      state.info.pricePerMonthUsdCents = action.payload.pricePerMonthUsdCents;
      state.info.updatedAt = Date.now();
    },
  },
});

const selectSelf = (state: ReturnType<typeof reducer>) => state;
const numberOfTrialDaysSelector = createDraftSafeSelector(
  selectSelf,
  state => state.info.numberOfTrialDays,
);
const pricePerMonthUsdSelector = createDraftSafeSelector(
  selectSelf,
  state => state.info.pricePerMonthUsdCents / 100,
);
const updatedAt = createDraftSafeSelector(
  selectSelf,
  state => state.info.updatedAt,
);
const selectors = {
  numberOfTrialDaysSelector,
  pricePerMonthUsdSelector,
  updatedAt,
};

export {reducer, actions, selectors};
