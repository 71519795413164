import {combineReducers} from '@reduxjs/toolkit';

import {slices} from '@b2cmessenger/doppio-core';
import {persistReducer} from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {reducer as workplaceSigningReducer} from './workplaceSigning.slice';
import {reducer as tooltipReducer} from './tooltips.slice';
import {reducer as appTrackingReducer} from './appTracking.slice';
import {reducer as subscriptionReducer} from './subscription/slice';

const initReducer = persistReducer(
  {
    key: 'init',
    version: 1,
    storage: AsyncStorage,
    blacklist: ['status'],
  },
  slices.init.reducer,
);

const reducer = combineReducers({
  init: initReducer,
  loginedUser: slices.loginedUser.reducer,
  app: slices.app.reducer,
  workplaceSigningKey: workplaceSigningReducer,
  stampAwards: slices.stampAwards.reducer,
  tooltips: tooltipReducer,
  network: slices.network.reducer,
  appTracking: appTrackingReducer,
  subscription: subscriptionReducer,
});

export type RootState = ReturnType<typeof reducer>;

const parentSelectors = {
  init: (state: RootState) => state.init,
  loginedUser: (state: RootState) => state.loginedUser,
  app: (state: RootState) => state.app,
  workplaceSigningKey: (state: RootState) => state.workplaceSigningKey,
  stampAwards: (state: RootState) => state.stampAwards,
  tooltips: (state: RootState) => state.tooltips,
  network: (state: RootState) => state.network,
  appTracking: (state: RootState) => state.appTracking,
  subscription: (status: RootState) => status.subscription,
};

export {reducer, parentSelectors};
