import React, {
  ComponentProps,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  BModal,
  Button,
  Card,
  icons,
  Pusher,
  Screen,
  Spacer,
  Typography,
  useTheme,
} from '@b2cmessenger/doppio-components';
import {TEST_IDS} from '@screens/Dashboard/screens/QRCode/QRCodeTestIds';
import {useWorkplaceSigningKey} from '@components/hooks/useWorkplaceSigningKey';
import {FlatList, Linking, StyleSheet, Text, View} from 'react-native';
import WorkplaceContext, {
  getWorkplaceRole,
} from '@components/common/WorkplaceContext';
import {useSelector} from 'react-redux';
import {loginedUserSelectors} from '@store/selectors';
import {QRCodeGenerator} from '@screens/Dashboard/screens/QRCode/components/QRCodeGenerator';
import {useBottomTabBarHeight} from '@react-navigation/bottom-tabs';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useLogoutPrompt} from '@components/hooks/useLogoutPrompt';
import useStampAward from '@components/hooks/useStampAwards';
import {QRCodeHeader} from '@screens/Dashboard/screens/QRCode/components/QRCodeGenerator/QRCodeHeader';
import {isTablet} from 'react-native-device-info';
import {useKeepAwake} from 'expo-keep-awake';
import {QRCodeHeaderLandscape} from '@screens/Dashboard/screens/QRCode/components/QRCodeGenerator/QRCodeHeaderLandscape';
import {IconButton, IconButtonProps} from '@components/common/IconButton';
import {Portal} from 'react-native-paper';
import {navigationRef} from '@navigation/navigationRef';
import {
  IconCry,
  IconEmployee,
  IconPosTablet,
} from '@components/common/icons/SvgIcon';
import {colors, Logger} from '@b2cmessenger/doppio-shared';
import useWindowInfo from '@hooks/useWindowInfo';
import {useTranslation, SharedHooks} from '@shared';
import {Place} from '@typings/Place';
import useHowToScanInstructionModal from '@screens/Dashboard/screens/QRCode/components/QRCodeGenerator/hooks/useHowToScanInstructionModal';
const {LogOutIcon, IconOptions} = icons;
const {usePlaceAppearance} = SharedHooks;

const _isTablet = isTablet();

export function QRCodeTabScreen() {
  const workplace = useContext(WorkplaceContext);
  const loginedUser = useSelector(loginedUserSelectors.profile) as Exclude<
    ReturnType<typeof loginedUserSelectors.profile>,
    null
  >;
  const {key: signKey} = useWorkplaceSigningKey();

  const {width, height, isLandscape} = useWindowInfo(200);
  const _tabBarHeight = useBottomTabBarHeight();
  const safeAreaInsets = useSafeAreaInsets();
  const spacerTopHeight = useMemo(
    () => safeAreaInsets.top + 20,
    [safeAreaInsets.top],
  );
  const isEmployee = useMemo(() => {
    const isDevice = Boolean(loginedUser?.is_device);
    const role = getWorkplaceRole(workplace);
    return !isDevice && role === 'employee';
  }, [loginedUser, workplace]);
  const isOwner = useMemo(() => {
    const isDevice = Boolean(loginedUser?.is_device);
    const role = getWorkplaceRole(workplace);
    return !isDevice && role === 'owner';
  }, [loginedUser, workplace]);
  const isTabBarShown = useMemo(() => isOwner, [isOwner]);
  const {headerOptionsModal, onPressNavbarOptions} = useHeaderOptionsModal({
    isEmployee,
  });

  useKeepAwake();

  const size = useMemo(() => {
    const qrCodePaddings = (_isTablet ? 40 : 24) * 2;
    const tabBarHeight = isTabBarShown ? _tabBarHeight : 0;

    const qrCodeHeaderAndSpace = isLandscape ? 0 : 44 + 16;
    const qrCodeCaptionAndSpace = isLandscape ? 0 : 28 + 16;
    const qrCodeControlsAndSpaceAround = 120 + 16 + 16;

    return Math.min(
      width -
        Screen.PADDING_HORIZONTAL * (_isTablet ? 4 * 2 : 2) -
        qrCodePaddings,
      Math.max(
        height -
          spacerTopHeight -
          qrCodePaddings -
          qrCodeHeaderAndSpace -
          qrCodeCaptionAndSpace -
          qrCodeControlsAndSpaceAround -
          tabBarHeight,
        isLandscape ? 177 : 0, // Landscape minimal height of side panel with header and caption
      ),
    );
  }, [
    isTabBarShown,
    _tabBarHeight,
    isLandscape,
    width,
    height,
    spacerTopHeight,
  ]);

  const {stamp, award} = usePlaceAppearance(workplace.id);
  const {getAwardCost} = useStampAward();
  const qrCodeHeader = useMemo(() => {
    const cost = getAwardCost(workplace.id);
    const rules = `${cost} ${stamp(cost as number)} = ${award(1)}`;
    const commonHeaderProps:
      | ComponentProps<typeof QRCodeHeaderLandscape>
      | ComponentProps<typeof QRCodeHeader> = {
      title: workplace.name,
      subtitle: rules,
      image: workplace.logoThumb,
      onPressOptions: isOwner ? undefined : onPressNavbarOptions,
    };

    const landscapeIsImageSmall = height < 530;

    return isLandscape ? (
      <QRCodeHeaderLandscape
        {...commonHeaderProps}
        isImageSmall={landscapeIsImageSmall}
      />
    ) : (
      <QRCodeHeader {...commonHeaderProps} />
    );
  }, [
    getAwardCost,
    workplace.id,
    workplace.name,
    workplace.logoThumb,
    stamp,
    award,
    isOwner,
    onPressNavbarOptions,
    isLandscape,
    height,
  ]);

  const onManageSubscriptions = useCallback(() => {
    navigationRef.current?.navigate('Dashboard', {
      screen: 'ManageSubscription',
    });
  }, []);
  const {modal, showModal, isVisible} = useNoActiveSubscriptionModal({
    onManageSubscriptions,
    placeId: workplace.id,
  });

  const {
    modal: instructionModal,
    showModal: showInstructionModal,
    isVisible: isInstructionModalVisible,
  } = useHowToScanInstructionModal();

  return (
    <Screen
      testID={TEST_IDS.screen}
      style={{
        paddingHorizontal: Screen.PADDING_HORIZONTAL * (_isTablet ? 4 : 1),
      }}>
      {isOwner ? null : <Portal>{headerOptionsModal}</Portal>}
      {isVisible ? <Portal>{modal}</Portal> : null}
      {isInstructionModalVisible ? <Portal>{instructionModal}</Portal> : null}
      <Spacer height={spacerTopHeight} />
      <QRCodeGenerator
        signKey={signKey}
        size={size}
        placeId={workplace.id}
        employeeId={loginedUser.id}
        header={qrCodeHeader}
        onPressNoActiveSubscription={showModal}
        onPressHowToScanInstruction={showInstructionModal}
      />
      <Spacer height={16} />
      <Pusher />
    </Screen>
  );
}

export const SupportEmail = 'hello@getdoppio.com';
function useNoActiveSubscriptionModal({
  onManageSubscriptions,
  placeId,
}: {
  onManageSubscriptions: () => void;
  placeId: Place['id'];
}) {
  const {t} = useTranslation();
  const {colors} = useTheme();
  const [isVisible, setShowNoActiveSubscriptionModal] = useState(false);
  const showModal = useCallback(
    () => setShowNoActiveSubscriptionModal(true),
    [],
  );

  const workplaceSubscription = useSelector(
    loginedUserSelectors.workplaceSubscription,
  );
  const loginedUser = useSelector(loginedUserSelectors.profile);

  const showManageSubscriptionButton = useMemo(
    () => workplaceSubscription?.payer_user_id === loginedUser?.id,
    [loginedUser?.id, workplaceSubscription?.payer_user_id],
  );

  const openEmail = useCallback(async () => {
    const _url = `mailto:${SupportEmail}?subject=${encodeURIComponent(
      `Subscription issue (${placeId})`,
    )}`;
    try {
      if (await Linking.canOpenURL(_url)) {
        await Linking.openURL(_url);
      }
    } catch (e) {
      Logger.errorTag('modal: openEmail', e);
    }
  }, [placeId]);

  const modal = useMemo(() => {
    return (
      <BModal
        visible={isVisible}
        onRequestClose={() => setShowNoActiveSubscriptionModal(false)}>
        <View
          style={{
            paddingHorizontal: Screen.PADDING_HORIZONTAL,
            paddingVertical: Screen.PADDING_HORIZONTAL * 2,
          }}>
          <Card backgroundColor={colors.black} style={styles.container}>
            <IconCry size={44} />
            <Spacer />
            <Text
              style={[
                Typography.fatBody,
                {color: colors.white},
                styles.message,
              ]}>
              {t(
                'Screens.QRCode.NoActiveSubscriptionModal.youHaveLimitedAccess',
              )}
            </Text>
            <Text
              style={[
                Typography.mediumBody,
                {color: colors.white},
                styles.message,
              ]}>
              {t(
                'Screens.QRCode.NoActiveSubscriptionModal.subscribeInOrderToRewardYourCustomers',
              )}
            </Text>
            <Spacer />
            {showManageSubscriptionButton ? (
              <Button
                title={t(
                  'Screens.QRCode.NoActiveSubscriptionModal.manageSubscription',
                )}
                onPress={() => {
                  setShowNoActiveSubscriptionModal(false);
                  onManageSubscriptions.call(null);
                }}
              />
            ) : null}
            <Spacer />
            <Text
              style={[
                Typography.mediumBody,
                {color: colors.white},
                styles.message,
              ]}>
              {t(
                'Screens.QRCode.NoActiveSubscriptionModal.inCaseOfAnyProblemsPleaseContactUs',
              )}
            </Text>
            <Text
              style={[styles.link, {color: colors.brand}]}
              onPress={openEmail}>
              {SupportEmail}
            </Text>
          </Card>
        </View>
      </BModal>
    );
  }, [
    colors.black,
    colors.brand,
    colors.white,
    isVisible,
    onManageSubscriptions,
    openEmail,
    showManageSubscriptionButton,
    t,
  ]);

  return {modal, showModal, isVisible};
}

const styles = StyleSheet.create({
  container: {alignItems: 'center'},
  message: {
    textAlign: 'center',
  },
  link: {
    textDecorationLine: 'underline',
  },
});

function useHeaderOptionsModal({isEmployee}: {isEmployee: boolean}) {
  const {t} = useTranslation();
  const promptLogout = useLogoutPrompt();
  const profile = useSelector(loginedUserSelectors.profile);
  const [isVisibleHeaderOptionsModal, setIsVisibleHeaderOptionsModal] =
    useState(false);
  const onPressNavbarOptions = useCallback(() => {
    setIsVisibleHeaderOptionsModal(true);
  }, []);
  const onPressAccountSettings = useCallback(() => {
    setIsVisibleHeaderOptionsModal(false);
    navigationRef.current?.navigate('Dashboard', {
      screen: 'AccountRemoval',
    });
  }, []);

  const headerOptionsModalListData = useMemo<IconButtonProps[]>(() => {
    const loggedAsArray = [profile?.firstname, profile?.lastname].filter(
      Boolean,
    );
    const loggedAs = loggedAsArray.length
      ? loggedAsArray.join(' ')
      : isEmployee
      ? t('Screens.QRCode.employee')
      : t('Screens.QRCode.pos');
    return [
      {
        label: t('Screens.QRCode.loggedInAs', {loggedAs: loggedAs}),
        IconComponent: isEmployee ? IconEmployee : IconPosTablet,
        accessibilityValue: {text: t('Screens.QRCode.loggedInAsAccess') || ''},
        caption: isEmployee
          ? undefined
          : t('Screens.QRCode.toLogOutContactYourPlaceOwner') || '',
        fill: isEmployee ? colors.darkgray : colors.black,
        disabled: true,
        textStyle: isEmployee
          ? {color: colors.darkgray, borderColor: 'red'}
          : undefined,
      },
      ...(isEmployee
        ? [
            {
              label: t('Screens.QRCode.accountSettings') || '',
              IconComponent: IconOptions,
              accessibilityValue: {
                text: t('Screens.QRCode.accountSettings') || '',
              },
              caption: undefined,
              onPress: onPressAccountSettings,
            },
            {
              label: t('Screens.QRCode.logout') || '',
              IconComponent: LogOutIcon,
              accessibilityValue: {text: t('Screens.QRCode.logout') || ''},
              caption: undefined,
              onPress: promptLogout,
              fill: 'none',
            },
          ]
        : []),
    ];
  }, [isEmployee, onPressAccountSettings, profile, promptLogout, t]);

  const headerOptionsModal = useMemo(() => {
    return (
      <BModal
        visible={isVisibleHeaderOptionsModal}
        onRequestClose={() => setIsVisibleHeaderOptionsModal(false)}>
        <View
          style={{
            paddingHorizontal: Screen.PADDING_HORIZONTAL,
            paddingVertical: Screen.PADDING_HORIZONTAL * 2,
          }}>
          <IconButtonList data={headerOptionsModalListData} />
        </View>
      </BModal>
    );
  }, [headerOptionsModalListData, isVisibleHeaderOptionsModal]);
  return {headerOptionsModal, onPressNavbarOptions};
}

function IconButtonList({
  data,
}: {
  data: Array<ComponentProps<typeof IconButton>>;
}) {
  return (
    <FlatList
      data={data}
      renderItem={({item: props}) => <IconButton {...props} />}
      keyExtractor={item => item.label}
      contentContainerStyle={listStyles.listContainer}
    />
  );
}

const listStyles = StyleSheet.create({
  listContainer: {paddingHorizontal: Screen.PADDING_HORIZONTAL},
});
