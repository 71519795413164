import {slices} from '@b2cmessenger/doppio-core';
import {actions as workplaceSigningActions} from './workplaceSigning.slice';
import {actions as tooltipsActions} from './tooltips.slice';
import {actions as appTrackingActions} from './appTracking.slice';
import {actions as subscriptionActions} from './subscription/slice';

const {actions: loginedUser} = slices.loginedUser;
const {actions: shared} = slices.shared;
const {actions: app} = slices.app;
const {actions: stampAwards} = slices.stampAwards;

const {setWorkplace, setWorkplaceRoles, setWorkplaceSubscription} = loginedUser;
export {setWorkplace, setWorkplaceRoles, setWorkplaceSubscription};

const {
  performInit,
  init,
  reInit,
  performRegister,
  register,
  acceptPlaceInvite,
  login,
  updateUser,
  logout,
  changeLanguage,
} = shared;

export {
  performInit,
  init,
  reInit,
  performRegister,
  register,
  acceptPlaceInvite,
  login,
  updateUser,
  logout,
};

const {
  performLogin,
  performLoginViaOtp,
  performLoginViaApple,
  performLoginViaFacebook,
  performLoginViaGoogle,
  loginFailed,
  dismissLoginError,
} = app;

const {changeAwardCost} = stampAwards;

export {
  performLogin,
  performLoginViaOtp,
  performLoginViaApple,
  performLoginViaFacebook,
  performLoginViaGoogle,
  loginFailed,
  dismissLoginError,
  changeAwardCost,
  changeLanguage,
};

const {set: setWorkplaceSigningKey} = workplaceSigningActions;
export {setWorkplaceSigningKey};

const {matchers} = slices.shared;
const {isInitLikeAction, isUserInfoAwareAction} = matchers;

export {isInitLikeAction, isUserInfoAwareAction};

const {set: setTooltipOptions} = tooltipsActions;
export {setTooltipOptions};

const {setStatus: setAppTrackingStatus} = appTrackingActions;
export {setAppTrackingStatus};

const {updateInfo: updateSubscriptionInfo} = subscriptionActions;
export {updateSubscriptionInfo};
