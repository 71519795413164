import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import {sagas} from '@b2cmessenger/doppio-core';
import rootSaga from './sagas';
import {createMigrate, persistReducer, persistStore} from 'redux-persist';
import storageDriver from './storageDriver';
import {reducer, RootState} from './reducer';
import {MigrationManifest, PersistedState} from 'redux-persist/es/types';
import {Logger} from '@b2cmessenger/doppio-shared';

const {runSaga} = sagas;

const migrations: MigrationManifest = {
  '2': state => {
    const persisted = state as PersistedState & RootState;
    const migrated = {
      ...persisted,
      tooltips: {
        ...persisted.tooltips,
        // Added new field dashboardAdvanced
        dashboardAdvanced: {
          shown: !!persisted.tooltips?.dashboard?.shown,
        },
      },
    };
    Logger.infoTag('Migrated to 2 ver. of root persisted reducer');
    return migrated;
  },
};

const persistedReducer = persistReducer(
  {
    key: 'root',
    version: 2,
    storage: storageDriver,
    blacklist: ['init', 'app', 'network'],
    migrate: createMigrate(migrations, {debug: true}),
  },
  reducer,
);

const sagaMiddleware = createSagaMiddleware();
const middleware = [
  sagaMiddleware,
  ...getDefaultMiddleware({
    thunk: false,
    serializableCheck: false,
    immutableCheck: false,
  }),
];

export const store = configureStore({
  reducer: persistedReducer,
  middleware,
});

export const persistor = persistStore(store);

runSaga(sagaMiddleware, rootSaga);
