import {createScreenTestIDS} from '../../e2e/createScreenTestIDS';

export const TEST_IDS = createScreenTestIDS('DashboardNavigator', {
  buttons: [
    'QRCode',
    'Stats',
    'Profile',
    'ChangeWorkplaceName',
    'EditLoyaltyProgram',
    'CustomizeAppearance',
  ],
});
