import {Place} from '@typings/Place';
import {api} from '@b2cmessenger/doppio-core';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Customer} from '@typings/common';
import useSWRInfinite from 'swr/infinite';
import {useIsFocused} from '@react-navigation/native';
import type {AxiosResponse} from 'axios';
import {Logger} from '@b2cmessenger/doppio-shared';

export function usePlaceCustomers(placeId: Place['id'], limit = 10) {
  const [totalPages, setTotalPages] = useState<null | number>(null);
  const [totalCount, setTotalCount] = useState<null | number>(null);
  const getKey = useCallback(
    (index, previousPageData) => {
      // reached the end?
      if (previousPageData && !previousPageData.length) {
        return null;
      }
      return `/api/v2/place/${placeId}/stamps/stat/customers?page=${
        index + 1
      }&per-page=${limit}`;
    },
    [placeId, limit],
  );
  const updateInfoFromHeaders = useCallback(
    (headers: AxiosResponse['headers']) => {
      if (!headers) {
        return;
      }
      if ('x-pagination-page-count' in headers) {
        setTotalPages(prev => {
          try {
            if (prev !== parseInt(headers['x-pagination-page-count'])) {
              return parseInt(headers['x-pagination-page-count']);
            }
          } catch (e) {
            Logger.errorTag('setTotalPages', e);
          }
          return prev;
        });
      }

      if ('x-pagination-total-count' in headers) {
        setTotalCount(prev => {
          try {
            if (prev !== parseInt(headers['x-pagination-total-count'])) {
              return parseInt(headers['x-pagination-total-count']);
            }
          } catch (e) {
            Logger.errorTag('setTotalCount', e);
          }
          return prev;
        });
      }
    },
    [],
  );

  const fetcher = useCallback(
    async (url: string) => {
      const response = await customersFetcher(url);
      updateInfoFromHeaders(response.headers);
      return api.getResponseData(response);
    },
    [updateInfoFromHeaders],
  );

  const {
    data,
    error,
    isLoading,
    mutate: refresh,
    isValidating,
    size,
    setSize,
  } = useSWRInfinite(getKey, fetcher, {
    revalidateOnMount: false,
    revalidateOnFocus: false,
    revalidateAll: false,
    parallel: true,
    initialSize: 1,
    shouldRetryOnError: true,
  });

  // revalidate onFocus
  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      refresh();
    }
  }, [isFocused, refresh]);

  const customers = useMemo<Customer[] | undefined>(() => {
    return data ? [].concat(...data) : undefined;
  }, [data]);
  const next = useCallback(() => {
    if (totalPages === null || size < totalPages) {
      setSize(size + 1);
    }
  }, [size, setSize, totalPages]);

  return {
    data: customers,
    isEnd: totalPages !== null && size >= totalPages,
    error,
    totalCount,
    refresh,
    next,
    isValidating,
    isLoading,
  };
}

async function customersFetcher(url: string) {
  try {
    return await api.default.get(url);
  } catch (e) {
    throw api.parseErrorToHumanReadableMessage(e);
  }
}
