import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = null as string | null;

export const {reducer, actions} = createSlice({
  name: 'workplaceSigningKey',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<string | null>) => action.payload,
  },
});
