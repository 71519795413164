import {createScreenTestIDS} from '../../e2e/createScreenTestIDS';

export const TEST_IDS = createScreenTestIDS('BusinessWizard', {
  buttons: [
    'Next',
    'CustomizeLoyalty',
    'CustomizeLoyaltyDone',
    'GoToWork',
    'JoinToCompany',
  ],
  inputs: ['CompanyName'],
  elements: ['Description', 'StampCard', 'Stamp'],
});
