import {BarCodeScanner, BarCodeScannerProps} from 'expo-barcode-scanner';
import React, {PropsWithChildren} from 'react';
import {Button} from '@b2cmessenger/doppio-components';
import {StyleSheet, View} from 'react-native';
import Svg, {Path} from 'react-native-svg';
import {Camera} from '@components/common/Camera';
import {isTablet} from 'react-native-device-info';
import {useTranslation} from '@shared';

export function QRCodeScanner({
  onBarCodeScanned,
  scanned,
  scanAgain,
}: Pick<BarCodeScannerProps, 'onBarCodeScanned'> & {
  scanned?: boolean;
  scanAgain?: () => void;
}) {
  const {t} = useTranslation();

  return scanAgain ? (
    <Button.Primary
      title={t('Screens.QRCode.tapToScanAgain')}
      onPress={scanAgain}
    />
  ) : (
    <View style={styles.wrapper}>
      <View style={styles.container}>
        <Bounds>
          <Camera
            barCodeScannerSettings={{
              barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
            }}
            onBarCodeScanned={scanned ? undefined : onBarCodeScanned}
            style={styles.barCode}
            ratio={'1:1'}
          />
        </Bounds>
      </View>
    </View>
  );
}

const _isTablet = isTablet();
const Size = _isTablet ? 275 : 202;
const Margin = _isTablet ? 22 : 16;

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {width: Size + Margin * 2, height: Size + Margin * 2},
  barCode: {
    width: Size,
    height: Size,
    margin: Margin,
    borderRadius: 12,
    overflow: 'hidden',
  },
});

function Bounds({children}: PropsWithChildren<{}>) {
  return (
    <View style={StyleSheet.absoluteFill}>
      <Svg width="48" height="48" viewBox="0 0 48 48" fill="none" style={tr}>
        <Path
          d="M44 44L44 20C44 11.1634 36.8366 4 28 4L4 4"
          stroke="#0057FF"
          strokeWidth="8"
          strokeLinecap="round"
        />
      </Svg>
      <Svg width="48" height="48" viewBox="0 0 48 48" fill="none" style={tl}>
        <Path
          d="M44 4H20C11.1634 4 4 11.1634 4 20V44"
          stroke="#0057FF"
          strokeWidth="8"
          strokeLinecap="round"
        />
      </Svg>
      <Svg width="48" height="48" viewBox="0 0 48 48" fill="none" style={bl}>
        <Path
          d="M4 4L4 28C4 36.8366 11.1634 44 20 44L44 44"
          stroke="#0057FF"
          strokeWidth="8"
          strokeLinecap="round"
        />
      </Svg>
      <Svg width="48" height="48" viewBox="0 0 48 48" fill="none" style={br}>
        <Path
          d="M4 44L28 44C36.8366 44 44 36.8366 44 28L44 4"
          stroke="#0057FF"
          strokeWidth="8"
          strokeLinecap="round"
        />
      </Svg>
      {children}
    </View>
  );
}

const {tr, tl, bl, br} = StyleSheet.create({
  tr: {position: 'absolute', top: 0, right: 0},
  tl: {position: 'absolute', top: 0, left: 0},
  bl: {position: 'absolute', bottom: 0, left: 0},
  br: {position: 'absolute', bottom: 0, right: 0},
});
