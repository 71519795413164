import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {workplaceSigningKeySelector} from '@store/selectors';
import SecureStore from '@utils/SecureStore';
import {Logger} from '@b2cmessenger/doppio-shared';

export function useWorkplaceSigningKey() {
  const [retrieving, setRetrieving] = useState(false);
  const [key, setKey] = useState(null as string | null);
  const storageKey = useSelector(workplaceSigningKeySelector);

  useEffect(() => {
    if (storageKey !== null) {
      const retrieve = async () => {
        setRetrieving(true);
        try {
          const value = await SecureStore.get(storageKey);
          setKey(value);
        } catch (e) {
          Logger.errorTag('useWorkplaceSigningKey', e);
          setKey(null);
        } finally {
          setRetrieving(false);
        }
      };

      // noinspection JSIgnoredPromiseFromCall
      retrieve();
    } else {
      setKey(null);
    }
  }, [storageKey]);

  return {key, retrieving};
}
