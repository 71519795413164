import {PlaceAppearanceConfig} from '@components/common/AppearanceCustomizer';
import {TouchableOpacity, View} from 'react-native';
import {PlaceAppearance} from '@b2cmessenger/doppio-core';
import {colors} from '@b2cmessenger/doppio-shared';
import React, {useMemo} from 'react';
import {
  pickerItemSize,
  pickerStyles,
  PossiblePlaceAppearances,
  SingleAppearanceResource,
} from '@components/common/AppearanceCustomizer/shared';

const ResourcePicker = (props: {
  appearances: PossiblePlaceAppearances;
  value: PlaceAppearanceConfig;
  changeValue: (p: Partial<PlaceAppearanceConfig>) => void;
  config: {
    resourceType: SingleAppearanceResource['type'];
    valueFieldName: keyof PlaceAppearanceConfig;
    additionalResource: Omit<SingleAppearanceResource, 'id'> & {
      id: number | null;
    };
    pickerTestId: string;
    pickerItemTestIdPrefix: string;
  };
}) => {
  const {appearances, value, changeValue, config} = props;
  const {
    resourceType,
    valueFieldName,
    additionalResource,
    pickerTestId,
    pickerItemTestIdPrefix,
  } = config;

  const filteredResources = useMemo(
    () =>
      [additionalResource, ...appearances.resources].filter(
        v => v.type === resourceType,
      ),
    [additionalResource, appearances.resources, resourceType],
  );

  const accessibilityValue = useMemo(() => {
    if (value[valueFieldName]) {
      let i = -1;
      filteredResources.forEach((resource, index) => {
        if (value[valueFieldName] === resource.id) {
          i = index;
        }
      });
      return {text: String(i)};
    } else {
      return {text: 'default'};
    }
  }, [filteredResources, value, valueFieldName]);

  return (
    <View
      style={pickerStyles.pickerContainerInner}
      testID={pickerTestId}
      accessibilityValue={accessibilityValue}
    >
      {filteredResources.map((resource, index) => (
        <TouchableOpacity
          style={pickerStyles.pickerItem}
          activeOpacity={100}
          key={resource.id ? String(resource.id) : 'default'}
          testID={pickerItemTestIdPrefix + (resource.id ? index : 'default')}
          onPress={() => {
            changeValue({[valueFieldName]: resource.id});
          }}
        >
          <PlaceAppearance.Icon
            strokeColor={
              resource.id === value[valueFieldName] ? undefined : 'transparent'
            }
            color={value.color || colors.brand}
            xml={resource.content}
            size={pickerItemSize}
            strokeInside={resource.id === value[valueFieldName]}
          />
        </TouchableOpacity>
      ))}
    </View>
  );
};

export default ResourcePicker;
