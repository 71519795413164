import {Customer} from '@typings/common';
import {
  CustomerList,
  CustomerListPlaceholder,
} from '@features/Customers/ui/CustomerList';
import {
  Button,
  Card,
  Spacer,
  Typography,
} from '@b2cmessenger/doppio-components';
import {navigationRef} from '@navigation/navigationRef';
import {ComponentProps, useMemo} from 'react';
import {Text} from 'react-native';

type CustomersCardProps = {
  customers: Customer[] | undefined;
  colors: Record<string, string>;
  t: (v: string) => string;
  totalCount: number | null;
  limit: number;
  onShowAll: () => void;
} & ComponentProps<typeof Card>;
export function CustomersCard(props: CustomersCardProps) {
  const {
    customers,
    limit = 3,
    colors,
    t = v => v,
    totalCount,
    onShowAll = () => {},
    ...rest
  } = props;

  const customersCardContent = useMemo(() => {
    const _title = t('Screens.Stats.CustomersCard.yourCustomerDatabase');
    const _description = t(
      'Screens.Stats.CustomersCard.sortedByLatestBalanceChange',
    );
    const _showAllText = t('Screens.Stats.CustomersCard.showAll');

    if (!customers) {
      return (
        <ContentPlaceholder
          title={_title}
          colors={colors}
          description={_description}
          length={limit}
          bTitle={_showAllText}
          onPress={() => {}}
        />
      );
    } else if (customers.length === 0) {
      const _createYourOwn = t(
        'Screens.Stats.CustomersCard.createYourOwnDatabaseOfLoyalCustomersByIssuingStam',
      );
      const issueYourFirst = t(
        'Screens.Stats.CustomersCard.issueYourFirstStamp',
      );
      const _onPress = () =>
        navigationRef?.current?.navigate('Dashboard', {
          screen: 'QRCode',
        });

      return (
        <NoCustomersYet
          title={_title}
          colors={colors}
          description={_description}
          length={limit}
          createYourOwn={_createYourOwn}
          bTitle={issueYourFirst}
          onPress={_onPress}
        />
      );
    } else {
      return (
        <CustomersCardContent
          title={_title}
          colors={colors}
          description={_description}
          customers={customers}
          onPress={() => {}}
          totalCount={totalCount}
          limit={limit}
          bTitle={_showAllText}
          onPressButton={onShowAll}
        />
      );
    }
  }, [colors, customers, limit, onShowAll, t, totalCount]);

  return <Card {...rest}>{customersCardContent}</Card>;
}

function ContentPlaceholder(props: {
  title: string;
  colors: Record<string, string>;
  description: string;
  length: number;
  bTitle: string;
  onPress: () => void;
}) {
  return (
    <>
      <Text style={[Typography.mediumBody]}>{props.title}</Text>
      <Text style={[Typography.smallBody, {color: props.colors.darkgray}]}>
        {props.description}
      </Text>
      <Spacer />
      <CustomerListPlaceholder
        length={props.length}
        brandColor={props.colors.brand}
        animationType={'fade'}
      />
      <Spacer />
      <Button title={props.bTitle} disabled loading onPress={props.onPress} />
    </>
  );
}

function NoCustomersYet(props: {
  title: string;
  colors: Record<string, string>;
  description: string;
  length: number;
  createYourOwn: string;
  bTitle: string;
  onPress: () => any;
}) {
  return (
    <>
      <Text style={[Typography.mediumBody]}>{props.title}</Text>
      <Text style={[Typography.smallBody, {color: props.colors.darkgray}]}>
        {props.description}
      </Text>
      <Spacer />
      <CustomerListPlaceholder
        length={props.length}
        brandColor={props.colors.brand}
        Animation={undefined}
      />
      <Spacer />
      <Text
        style={[
          Typography.smallBody,
          {color: props.colors.darkgray, textAlign: 'center'},
        ]}>
        {props.createYourOwn}
      </Text>
      <Spacer />
      <Button title={props.bTitle} onPress={props.onPress} />
    </>
  );
}

function CustomersCardContent(props: {
  title: string;
  colors: Record<string, string>;
  description: string;
  customers: Customer[];
  onPress: () => void;
  totalCount: number | null;
  limit: number;
  bTitle: string;
  onPressButton: () => any;
}) {
  return (
    <>
      <Text style={[Typography.mediumBody]}>{props.title}</Text>
      <Text style={[Typography.smallBody, {color: props.colors.darkgray}]}>
        {props.description}
      </Text>
      <Spacer />
      <CustomerList
        customers={props.customers}
        onPress={props.onPress}
        disabled={false}
        contentContainerStyle={{flex: 1}}
      />
      <Spacer />
      {props.totalCount !== null && props.totalCount > props.limit ? (
        <Button title={props.bTitle} onPress={props.onPressButton} />
      ) : null}
    </>
  );
}
