import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Navbar,
  Screen,
  Spacer,
  TextInput,
  Pusher,
  useTheme,
} from '@b2cmessenger/doppio-components';
import {useIsMounted} from '@b2cmessenger/doppio-shared';

import WorkplaceContext from '@components/common/WorkplaceContext';
import {PlaceAvatar} from '@screens/Dashboard/screens/Profile/components/Place';
import {useDispatch} from 'react-redux';
import {usePlaceEdit} from '@screens/Dashboard/screens/Profile/hooks/usePlaceEdit';
import {Alert} from '@components/common/Alert';
import {View} from 'react-native';
import {setWorkplace} from '@store/actions';
import {navigationRef} from '@navigation/navigationRef';
import {useFocusEffect} from '@react-navigation/core';
import {useScreenPrimaryButtonStyle} from '@components/hooks/useScreenPrimaryButtonStyle';
import type {TextInput as NativeTextInput} from 'react-native';
import {TEST_IDS} from './ChangeWorkplaceNameTestIDS';
import {useTranslation} from '@shared';

function ChangeWorkplaceName() {
  const {t} = useTranslation();

  const {colors} = useTheme();
  const dispatch = useDispatch();
  const workplace = useContext(WorkplaceContext);
  const nameRef = useRef<NativeTextInput>(null);
  const isMounted = useIsMounted();

  const [name, setName] = useState(workplace.name);
  const [disabled, setDisabled] = useState(true);

  const {error, loading, request: placeChange, setError} = usePlaceEdit(
    workplace.id,
  );

  useFocusEffect(
    useCallback(() => {
      if (isMounted) {
        setName(workplace.name);
      }
      return () => {
        if (isMounted) {
          setName(workplace.name);
        }
      };
    }, [workplace.name, isMounted]),
  );

  useEffect(() => {
    if (error) {
      Alert.alert(t('Screens.ChangeWorkplaceName.anErrorHasOccurred'), error);
      setError(null);
    }
  }, [error, setError, t]);

  const focus = useCallback(() => {
    if (isMounted && nameRef.current) {
      nameRef.current?.focus();
    }
  }, [isMounted]);

  useFocusEffect(focus);

  const goBack = useCallback(() => {
    if (navigationRef.current?.canGoBack()) {
      navigationRef.current?.goBack();
    } else {
      navigationRef.current?.navigate('Dashboard', {
        screen: 'Profile',
        params: {
          screen: 'DevicesAndEmployees',
        },
      });
    }
  }, []);

  const pbs = useScreenPrimaryButtonStyle();

  const onSave = useCallback(async () => {
    try {
      const place = await placeChange({name: name});
      if (place) {
        dispatch(
          setWorkplace({
            ...workplace,
            ...place,
          }),
        );
        goBack();
      }
    } catch (e) {}
  }, [placeChange, name, dispatch, workplace, goBack]);

  useEffect(() => {
    setDisabled(
      workplace.name === name ||
        name.length === 0 ||
        name.length > 50 ||
        loading,
    );
  }, [name, workplace.name, loading]);

  const navbar = useMemo(() => {
    return (
      <Navbar
        title={t('Screens.ChangeWorkplaceName.companyName') || ''}
        onBack={goBack}
        backButtonTestID={'change-workplace-name-back'}
      />
    );
  }, [goBack, t]);

  return (
    <Screen navbar={navbar} testID={TEST_IDS.screen}>
      <Spacer height={30} />
      <PlaceAvatar uri={workplace.logo} iconFill={colors.brand} />
      <View>
        <TextInput
          testID={TEST_IDS.inputs.CompanyName}
          style={useMemo(
            () =>
              loading
                ? {
                    borderColor: colors.lightgray,
                    borderBottomWidth: 1,
                  }
                : undefined,
            [colors.lightgray, loading],
          )}
          ref={nameRef}
          value={name}
          disabled={loading}
          onChangeText={n => setName(n)}
          label={t('Screens.ChangeWorkplaceName.yourCompanyName') || ''}
        />
      </View>
      <Pusher />
      <Button.Primary
        testID={TEST_IDS.buttons.Done}
        loading={loading}
        disabled={disabled}
        style={pbs}
        title={t('Screens.ChangeWorkplaceName.done') || ''}
        onPress={onSave}
      />
    </Screen>
  );
}

export default ChangeWorkplaceName;
