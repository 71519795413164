import React, {useMemo} from 'react';
import {AppearanceStages} from '@components/common/AppearanceCustomizer/slice';
import {StyleSheet, View} from 'react-native';
import {Button} from '@b2cmessenger/doppio-components';
import {useAppearanceCustomizer} from '@components/common/AppearanceCustomizer/index';
import {useScreenPrimaryButtonStyle} from '@components/hooks/useScreenPrimaryButtonStyle';
import {useTranslation} from '@shared';

type AppearanceCustomizeControlsProps = Pick<
  ReturnType<typeof useAppearanceCustomizer>,
  'stage' | 'actions'
> & {
  onSave: () => void;
  onTryAgain: () => void;
  doneTestId?: string | undefined;
  saveButtonTitle?: string;
  loading?: boolean;
  hasError: boolean;
};

function AppearanceCustomizeControls({
  stage,
  actions,
  onSave,
  onTryAgain,
  doneTestId,
  loading,
  hasError,
  saveButtonTitle = 'DONE',
}: AppearanceCustomizeControlsProps) {
  const {t} = useTranslation();

  const pbs = useScreenPrimaryButtonStyle();

  const controls = useMemo(() => {
    switch (stage) {
      case AppearanceStages.awardNameEdit:
        return (
          <View style={styles.buttonsWrapper}>
            <Button
              style={[pbs, styles.halfButton]}
              disabled={loading}
              title={t('Components.AppearanceCustomizeControls.back')}
              mode="secondary"
              onPress={actions.onBack}
            />
            <Button
              style={[pbs, styles.halfButton]}
              loading={loading}
              disabled={loading}
              title={saveButtonTitle}
              mode="primary"
              onPress={onSave}
              testID={doneTestId}
            />
          </View>
        );
      case AppearanceStages.picker:
        return (
          <Button
            style={pbs}
            title={t('Components.AppearanceCustomizeControls.next')}
            testID={doneTestId}
            loading={loading}
            disabled={loading}
            mode="primary"
            onPress={actions.onNext}
          />
        );
      default:
        return null;
    }
  }, [
    actions.onBack,
    actions.onNext,
    doneTestId,
    loading,
    onSave,
    pbs,
    saveButtonTitle,
    stage,
    t,
  ]);

  if (hasError) {
    return (
      <Button
        style={pbs}
        loading={loading}
        title={t('Components.AppearanceCustomizeControls.tryAgain')}
        onPress={onTryAgain}
        mode="secondary"
      />
    );
  }

  return controls;
}

const styles = StyleSheet.create({
  buttonsWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  halfButton: {flexGrow: 0.48},
});

export default AppearanceCustomizeControls;
