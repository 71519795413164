import {
  Image,
  StyleSheet,
  Text,
  View,
  ViewStyle,
  Pressable,
} from 'react-native';
import {Spacer, Typography, useTheme} from '@b2cmessenger/doppio-components';
import React, {ComponentProps, useCallback, useMemo} from 'react';
import {Customer} from '@typings/common';
import {getUserName} from '@utils/utils';
import {colors} from '@b2cmessenger/doppio-shared';
import {FlatListWithShadow, useTranslation} from '@shared';
import {Fade, Placeholder, PlaceholderLine} from 'rn-placeholder';
import {
  colors as colorsUniqueName,
  animals,
  uniqueNamesGenerator,
} from 'unique-names-generator';

import {UserAvatarIcon} from '@components/common/icons/SvgIcon';

type CustomerListProps = {
  customers: Customer[];
  onPress: (id: Customer['id']) => void;
  disabled: boolean;
} & ComponentProps<typeof FlatListWithShadow>;
export function CustomerList(props: CustomerListProps) {
  const {customers, onPress = () => {}, disabled = false, ...rest} = props;

  const {colors} = useTheme();
  const {t} = useTranslation();

  const getAwardsTitle = useCallback(
    (count: number) => {
      return t('common.reward', {count});
    },
    [t],
  );
  const getStampsTitle = useCallback(
    (count: number) => {
      return t('common.stamp', {count});
    },
    [t],
  );
  const defaultCustomerName = useMemo(() => {
    return t('common.customer');
  }, [t]);

  return (
    <FlatListWithShadow
      data={customers}
      ItemSeparatorComponent={Spacer}
      renderItem={({item: _props}) => (
        <CustomerListItem
          onPress={onPress}
          disabled={disabled}
          brandColor={colors.brand}
          secondColor={colors.darkgray}
          lightGray={colors.lightgray}
          awardsTitle={getAwardsTitle(_props.rewardsReceived)}
          stampsTitle={getStampsTitle(_props.stampBalance)}
          defaultCustomerName={defaultCustomerName}
          {..._props}
        />
      )}
      keyExtractor={item => item.id}
      {...rest}
    />
  );
}

const PLACEHOLDER_LINE_HEIGHT = 21;
const PLACEHOLDER_LINE_WIDTH = 80;
export function CustomerListPlaceholder(
  props: {
    length: number;
    brandColor: string | undefined;
    animationType: 'fade' | undefined;
  } & ComponentProps<typeof Placeholder>,
) {
  const {length = 10, brandColor, ...rest} = props;
  const rows = [];
  for (let i = 0; i < length; i++) {
    rows.push(
      <View key={i}>
        <CustomerListItemPlaceholder i={i} brandColor={brandColor} />
        <Spacer />
      </View>,
    );
  }

  return (
    <Placeholder
      Animation={Fade}
      {...rest}
      style={[placeholderStyles.placeholderContainer, rest?.style]}>
      {rows}
    </Placeholder>
  );
}

type CustomerListItem = Customer &
  Omit<AvatarProps, 'uri'> & {
    brandColor: string;
    secondColor: string;
    lightGray: string;
    onPress: (c: Customer['id']) => void;
    awardsTitle: string;
    stampsTitle: string;
    defaultCustomerName: string;
  };
function CustomerListItem(props: CustomerListItem) {
  const {
    firstname,
    lastname,
    id,
    brandColor,
    secondColor,
    onPress,
    disabled = false,
    avatarThumb,
    stampBalance,
    rewardsReceived,
    awardsTitle = 'awards',
    stampsTitle = 'stamps',
    lightGray,
    is_generated,
  } = props;

  const _userName =
    getUserName({firstname, lastname}) ||
    `${uniqueNamesGenerator({
      dictionaries: [colorsUniqueName, animals],
      separator: ' ',
      seed: id,
      style: 'capital',
    })}`;

  return (
    <Pressable
      onPress={() => {
        onPress?.call(null, id);
      }}>
      <View style={customerListItemStyles.wrapper}>
        <Avatar
          disabled={disabled}
          iconFill={secondColor}
          uri={avatarThumb}
          style={customerListItemStyles.avatar}
          fill={!is_generated ? brandColor : undefined}
        />
        <View
          style={[
            customerListItemStyles.content,
            {borderBottomColor: lightGray},
          ]}>
          <Text
            numberOfLines={2}
            style={[Typography.mediumBody, customerListItemStyles.userName]}>
            {_userName}
          </Text>
          <View style={customerListItemStyles.countersWrapper}>
            <Counter
              count={stampBalance}
              color={brandColor}
              title={stampsTitle}
            />
            <Counter
              count={rewardsReceived}
              color={secondColor}
              title={awardsTitle}
            />
          </View>
        </View>
      </View>
    </Pressable>
  );
}
const customerListItemStyles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
  },
  avatar: {marginRight: 8},
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    flex: 1,
    paddingVertical: 10,
  },
  userName: {flex: 1, textAlign: 'left', marginRight: 8},
  countersWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: 120,
  },
});

function CustomerListItemPlaceholder({
  i = 0,
  brandColor = colors.brand,
  secondColor = colors.darkgray,
}: {
  i: number;
  brandColor: string | undefined;
  secondColor: string | undefined;
}) {
  const {t} = useTranslation();

  const getAwardsTitle = useCallback(
    (count: number) => {
      return t('common.reward', {count});
    },
    [t],
  );
  const getStampsTitle = useCallback(
    (count: number) => {
      return t('common.stamp', {count});
    },
    [t],
  );

  return (
    <View key={i} style={placeholderStyles.placeholderRow}>
      <View style={customerListItemStyles.wrapper}>
        <Avatar
          disabled={false}
          iconFill={colors.lightgray}
          uri={null}
          style={customerListItemStyles.avatar}
        />
        <View
          style={[
            customerListItemStyles.content,
            {borderBottomColor: colors.lightgray},
          ]}>
          <PlaceholderLine
            noMargin
            width={PLACEHOLDER_LINE_WIDTH * (i % 2 ? 0.6 : 0.5)}
            height={PLACEHOLDER_LINE_HEIGHT}
          />
          <View style={customerListItemStyles.countersWrapper}>
            <View style={counterStyles.counterWrapper}>
              <PlaceholderLine
                noMargin
                width={PLACEHOLDER_LINE_WIDTH * (i % 2 ? 0.6 : 0.5)}
                height={PLACEHOLDER_LINE_HEIGHT}
              />
              <Text style={[Typography.smallBody, {color: brandColor}]}>
                {getStampsTitle(2)}
              </Text>
            </View>
            <View style={counterStyles.counterWrapper}>
              <PlaceholderLine
                noMargin
                width={PLACEHOLDER_LINE_WIDTH * (i % 2 ? 0.6 : 0.5)}
                height={PLACEHOLDER_LINE_HEIGHT}
              />
              <Text style={[Typography.smallBody, {color: secondColor}]}>
                {getAwardsTitle(2)}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

const placeholderStyles = StyleSheet.create({
  placeHolderMedia: {
    marginRight: 12,
    borderRadius: 100,
    marginLeft: 2,
  },
  placeholderContainer: {
    flexDirection: 'column',
  },
  placeholderRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    height: 60,
  },
});

function Counter(props: {count: number; color: string; title: string}) {
  return (
    <View style={counterStyles.counterWrapper}>
      <Text style={[Typography.fatBody, {color: props.color}]}>
        {props.count}
      </Text>
      <Text style={[Typography.smallBody, {color: props.color}]}>
        {props.title}
      </Text>
    </View>
  );
}

const counterStyles = StyleSheet.create({
  counterWrapper: {
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
  },
});

type AvatarProps = {
  uri: string | undefined | null;
  disabled: boolean;
  size?: number;
  style?: ViewStyle;
  fill?: string;
};
const ImagePadding = 4;
function Avatar({uri, disabled, size = 44, style, fill}: AvatarProps) {
  return (
    <View style={style}>
      {uri ? (
        <View style={{padding: ImagePadding, alignSelf: 'flex-start'}}>
          <Image
            style={{
              width: size - ImagePadding * 2,
              height: size - ImagePadding * 2,
              borderRadius: 100,
              opacity: disabled ? 0.75 : 1,
            }}
            source={{uri}}
          />
        </View>
      ) : (
        <UserAvatarIcon
          size={size || 44}
          style={{opacity: disabled ? 0.75 : 1}}
          fill={fill}
        />
      )}
    </View>
  );
}
