import {useCallback, useMemo} from 'react';
import {Place, PlaceEmployeeSearchResultEmployee} from '@typings/Place';
import useSWR from 'swr';
import {api} from '@b2cmessenger/doppio-core';

type EmployeeInvitationStatus = 0 | 1 | -1; // Joined, Pending, Declined

const EMPLOYEE_SEARCH_URL = '/api/place/employee/search';
export function usePlaceEmployees(
  placeId: Place['id'],
  status: EmployeeInvitationStatus = 1,
) {
  const swrKey = useMemo(
    () => [EMPLOYEE_SEARCH_URL, placeId, status].join('/'),
    [placeId, status],
  );
  const fetcher = useCallback(
    async () => employeesFetcher(EMPLOYEE_SEARCH_URL, placeId, status),
    [placeId, status],
  );

  const {data, error, mutate} = useSWR(swrKey, fetcher);
  return {data, error, refresh: mutate};
}

function employeesFetcher(
  url: string,
  placeId: number,
  status: EmployeeInvitationStatus,
) {
  const data = new FormData();
  data.append('place_id', String(placeId));
  data.append('status', String(status));
  return api.default
    .post<{
      employees: PlaceEmployeeSearchResultEmployee[];
    }>(url, data)
    .catch(e => {
      throw api.parseErrorToHumanReadableMessage(e);
    })
    .then(api.getResponseData)
    .then(({employees}) => employees.map(({user}) => user));
}
