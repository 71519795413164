import React, {useEffect, useMemo, useRef} from 'react';
import createStackNavigator from '../navigation/createStackNavigator';
import {RootNavigatorScreenParams} from '@navigation/config';
import {SignInScreen} from './SignIn';
import {DashboardNavigator} from './Dashboard/DashboardNavigator';
import DynamicLinks from '@utils/DynamicLinks';
import {
  getRouteFromHandledDynamicLink,
  handleDynamicLink,
} from '@utils/DynamicLinks/handleDynamicLink';
import {navigate, navigationRef} from '@navigation/navigationRef';
import {BusinessInvite} from '@screens/BusinessInvite/BusinessInvite';
import {BusinessWizard} from '@screens/BusinessWizard/BusinessWizard';
import {JoinAsEmployee} from '@screens/JoinAsEmployee/JoinAsEmployee';
import {useDispatch, useSelector} from 'react-redux';
import {loginedUserSelectors} from '@store/selectors';
import {EmailSignInScreen} from '@screens/EmailSignIn';
import {NativeStackNavigationConfig} from 'react-native-screens/lib/typescript/native-stack/types';
import {isTablet} from 'react-native-device-info';
import {Navbar} from '@b2cmessenger/doppio-components';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {logout} from '@store/actions';
import EmployeeConnected from '@screens/EmployeeConnected';
import {Analytics} from '@b2cmessenger/doppio-core';
import {UserEngagement} from '@components/UserEngagement';
import {LinkScreen} from '@screens/Link';
import {Portal} from 'react-native-paper';
import {useTranslation} from '@shared';

const Stack = createStackNavigator<RootNavigatorScreenParams>();

function getInitialRouteName(props: {
  isLoggedIn: boolean;
  hasWorkplace: boolean;
}): keyof RootNavigatorScreenParams {
  return props.isLoggedIn
    ? props.hasWorkplace
      ? 'Dashboard'
      : 'BusinessWizard'
    : 'SignIn';
}

export function RootNavigator() {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const {top} = useSafeAreaInsets();
  /**
   * 16 – default inset for `web` and devices with no "notch" or "hole"
   */
  const topInset = useMemo(() => Math.max(top, 16), [top]);
  const isLoggedIn = useSelector(loginedUserSelectors.isLoggedIn);
  const workplace = useSelector(loginedUserSelectors.workplace);
  const loginedUser = useSelector(loginedUserSelectors.profile);
  const loginedUserRef = useRef(loginedUser);
  useEffect(() => {
    loginedUserRef.current = loginedUser;
  }, [loginedUser]);
  const hasWorkplace = workplace !== null;

  const initialRouteName = useMemo(
    () => getInitialRouteName({isLoggedIn, hasWorkplace}),
    [hasWorkplace, isLoggedIn],
  );

  useEffect(() => {
    if (isLoggedIn && hasWorkplace) {
      const state = navigationRef.current?.dangerouslyGetState();
      const previousRouteName =
        state?.routes && state?.routes.length > 0
          ? (state.routes[state.routes.length - 1]
              ?.name as keyof RootNavigatorScreenParams)
          : null;

      if (previousRouteName === 'JoinAsEmployee') {
        Analytics.logEvent('join_business', {
          role:
            (
              loginedUserRef.current as Exclude<
                typeof loginedUserRef.current,
                null
              >
            ).is_device === 1
              ? 'pos'
              : 'employee',
        });
        navigationRef.current?.navigate('EmployeeConnected');
      }
    }
  }, [isLoggedIn, hasWorkplace]);

  useEffect(() => {
    const unsubscribe = DynamicLinks.onLink(dl => {
      const handle = async () => {
        const handledDL = handleDynamicLink(dl);
        const route = getRouteFromHandledDynamicLink(handledDL);
        if (route) {
          navigate(route);
        }
      };

      handle();
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (loginedUser?.is_device === 1 && workplace === null) {
      dispatch(logout());
    }
  }, [dispatch, loginedUser, workplace]);

  return (
    <Navbar.TopInsetContext.Provider value={topInset}>
      <Stack.Navigator
        initialRouteName={initialRouteName}
        screenOptions={useMemo(
          () =>
            ({
              headerTopInsetEnabled: false,
              headerShown: false,
              screenOrientation: isTablet() ? undefined : 'portrait',
            }) as NativeStackNavigationConfig,
          [],
        )}>
        {isLoggedIn ? (
          hasWorkplace ? (
            <>
              <Stack.Screen name="Dashboard" component={DashboardNavigator} />
              <Stack.Screen
                name="BusinessInvite"
                component={BusinessInvite}
                options={{
                  title: t('Screens.RootNavigator.businessInvite') || '',
                }}
              />
              <Stack.Screen
                name="EmployeeConnected"
                component={EmployeeConnected}
                options={{
                  title: t('Screens.RootNavigator.employeeConnected') || '',
                }}
              />
              <Stack.Screen
                name="BusinessWizard"
                component={BusinessWizard}
                options={{
                  title: t('Screens.RootNavigator.businessWizard') || '',
                }}
              />
            </>
          ) : (
            <>
              <Stack.Screen
                name="BusinessWizard"
                component={BusinessWizard}
                options={{
                  title: t('Screens.RootNavigator.businessWizard') || '',
                }}
              />
              <Stack.Screen
                name="JoinAsEmployee"
                component={JoinAsEmployee}
                options={{
                  title: t('Screens.RootNavigator.joinAsEmployee') || '',
                }}
              />
            </>
          )
        ) : (
          <>
            <Stack.Screen name="SignIn" component={SignInScreen} />
            <Stack.Screen
              name="JoinAsEmployee"
              component={JoinAsEmployee}
              options={{title: t('Screens.RootNavigator.joinAsEmployee') || ''}}
            />
            <Stack.Screen
              name="EmailSignIn"
              component={EmailSignInScreen}
              options={{title: 'Email SignIn'}}
            />
          </>
        )}
        <Stack.Screen name="Link" component={LinkScreen} />
      </Stack.Navigator>
      <Portal>
        <UserEngagement />
      </Portal>
    </Navbar.TopInsetContext.Provider>
  );
}
