import React, {useEffect} from 'react';
import {RootNavigatorScreenProps} from '@navigation/config';
import {Screen} from '@b2cmessenger/doppio-components';
import {colors, Logger} from '@b2cmessenger/doppio-shared';
import {ActivityIndicator, StyleSheet} from 'react-native';
import {loginedUserSelectors} from '@store/selectors';
import {useStore} from 'react-redux';
import {setAdsTrackingInfo} from '@utils/AdsTracking';
import * as jsrsasign from '@utils/jsrsasign/jsrsasign-jwths-min';

import {decodeUrlSafeBase64, isUrlSafeBase64} from '@utils/safeUrlBase64';

export function LinkScreen({
  route,
  navigation,
}: RootNavigatorScreenProps<'Link'>) {
  const store = useStore();
  useEffect(() => {
    Logger.verboseTag('LinkScreen', route, route.params);

    const navigateToDefaultScreen = () => {
      const isLoggedIn = loginedUserSelectors.isLoggedIn(store.getState());
      navigation.replace(isLoggedIn ? 'Dashboard' : 'SignIn');
    };

    const resolvedLink = resolveLink(route.params.link);
    if (resolvedLink !== null) {
      let adsTrackingInfo = null;
      if ('atid' in resolvedLink || 'gaClientId' in resolvedLink) {
        adsTrackingInfo = {
          ...(resolvedLink.atid ? {atid: String(resolvedLink.atid)} : {}),
          ...(resolvedLink.gaClientId
            ? {ga4ClientId: String(resolvedLink.gaClientId)}
            : {}),
        };
      }

      if (adsTrackingInfo) {
        setAdsTrackingInfo(adsTrackingInfo).then(() => {
          navigateToDefaultScreen();
        });
        return;
      }
    }

    navigateToDefaultScreen();
  }, [navigation, route, route.params.link, store]);

  return (
    <Screen>
      <ActivityIndicator
        size="large"
        color={colors.brand}
        style={StyleSheet.absoluteFill}
      />
    </Screen>
  );
}

// Any possible Payload keys
type LinkPayloadKeys = 'atid' | 'gaClientId';
function resolveLink<Keys extends string = LinkPayloadKeys>(_link: string) {
  if (_link) {
    try {
      const link = isUrlSafeBase64(_link) ? decodeUrlSafeBase64(_link) : _link;
      const parsed = jsrsasign.KJUR.jws.JWS.parse(
        // emulating HS256 JWT format
        link.indexOf('.') === -1
          ? ['eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9', link].join('.')
          : link,
      );
      if (parsed.payloadObj) {
        return parsed.payloadObj as Partial<Record<Keys, unknown>>;
      }

      return null;
    } catch (e) {}
  }

  return null;
}
