import {api} from '@b2cmessenger/doppio-core';
import {DateTimeUtils} from '@b2cmessenger/doppio-shared';
import {useCallback, useEffect, useMemo} from 'react';
import useSWR from 'swr';

import {
  fillLBlankAreaInSeries,
  DAY_IN_MSECS,
  fillBlankSeriesEdges,
  formatWithLocalization,
} from './shared';
import {localization, useGetCurrentLanguage, useTranslation} from '@shared';
import {useIsFocused} from '@react-navigation/native';

type UseStatsOptions = {
  placeId: number;
  from: Date;
  to: Date;
  scope: 'week' | 'month' | 'year';
  isAvailableFullAccessToApp: boolean | null;
};
export function useStats({
  placeId,
  from,
  to,
  scope,
  isAvailableFullAccessToApp,
}: UseStatsOptions) {
  const {t} = useTranslation();
  const locale = useGetCurrentLanguage();

  const swrKey = useMemo(
    () =>
      [
        placeId,
        scope,
        DateTimeUtils.toDatetimeServer(from, {dateOnly: true}),
        DateTimeUtils.toDatetimeServer(to, {dateOnly: true}),
      ].join('/'),
    [from, placeId, scope, to],
  );
  const fetcher = useCallback(
    async () =>
      statsFetcher(
        placeId,
        scope,
        DateTimeUtils.toDatetimeServer(from, {dateOnly: true}),
        DateTimeUtils.toDatetimeServer(to, {dateOnly: true}),
        t,
      ),
    [from, placeId, scope, to, t],
  );

  const {data, error, isValidating, mutate} = useSWR(
    [swrKey, locale],
    isAvailableFullAccessToApp ? fetcher : null,
    {revalidateOnFocus: false, revalidateOnMount: false},
  );
  // revalidate onFocus
  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      mutate();
    }
  }, [isFocused, mutate]);

  return {data, error, isValidating};
}

type DayStat = {
  date: string;
  stamps_added: number;
  stamps_removed: number;
  guests_count: number;
  guests_returned_count: number;
};

export function getWholeMonthRange(date = new Date()) {
  const from = new Date(date.getFullYear(), date.getMonth(), 1);
  const to = new Date(from.getFullYear(), from.getMonth(), 1);
  to.setMonth(to.getMonth() + 1);
  to.setDate(to.getDate() - 1);
  return [from, to];
}

export function getWholeWeekRange(date = new Date()) {
  const to = new Date(date.getFullYear(), date.getMonth(), 1);
  to.setDate(date.getDate() + (6 - date.getDay()));
  const from = new Date(to.getFullYear(), to.getMonth(), to.getDate() - 6);
  return [from, to];
}

export function getWholeYearRange(date = new Date()) {
  const to = new Date(date.getFullYear(), 11, 31);
  const from = new Date(to.getFullYear(), 0, 1);
  return [from, to];
}

const BLANK_DATA = {
  stamps_added: 0,
  stamps_removed: 0,
  guests_count: 0,
  guests_returned_count: 0,
};

function statsFetcher(
  placeId: number,
  scope: 'week' | 'month' | 'year',
  fromDate: string,
  toDate: string,
  t: typeof localization.t,
) {
  const from =
    scope === 'year' ? fromDate.split('-').slice(0, 2).join('-') : fromDate;
  const to =
    scope === 'year' ? toDate.split('-').slice(0, 2).join('-') : toDate;

  return api.default
    .get<DayStat[]>(
      `/api/v2/place/${String(placeId)}/stamps/stat/${
        scope === 'year' ? 'month' : 'day'
      }/${from}/${to}`,
    )
    .catch(e => {
      throw api.parseErrorToHumanReadableMessage(e);
    })
    .then(api.getResponseData)
    .then(data =>
      fillLBlankAreaInSeries(
        fillBlankSeriesEdges(data, {
          from: fromDate,
          to: toDate,
          blankData: BLANK_DATA,
        }),
        {
          scope,
          from: fromDate,
          gapMs: DAY_IN_MSECS,
          blankData: BLANK_DATA,
        },
      ).reduce(
        (memo, {date: _date, guests_count, guests_returned_count}) => {
          const date = scope === 'year' ? `${_date}-01` : _date;
          const newGuests = guests_count - guests_returned_count;
          memo.totalGuests += guests_count;
          memo.returnedGuests += guests_returned_count;
          memo.newGuests += newGuests;

          const category = formatWithLocalization(
            DateTimeUtils.fromDatetimeServer(date),
            res =>
              scope === 'week'
                ? res.shortDay
                : scope === 'year'
                ? res.shortMonth
                : String(res.date),
            t,
          );
          memo.series[0].push({
            date: date,
            x: category,
            y: guests_returned_count,
          });
          memo.series[1].push({
            date: date,
            x: category,
            y: newGuests,
          });

          memo.categories.push(category);
          return memo;
        },
        {
          totalGuests: 0,
          returnedGuests: 0,
          newGuests: 0,
          series: [[], []],
          categories: [],
        } as {
          totalGuests: number;
          returnedGuests: number;
          newGuests: number;
          series: {x: string; y: number; date: string}[][];
          categories: string[];
        },
      ),
    );
}
