import React from 'react';
import {Text} from 'react-native';
import {Spacer, Typography} from '@b2cmessenger/doppio-components';
import {DEFAULT_SPACE_HEIGHT} from '@widgets/Onboarding/lib';
import {OnboardingView} from '@widgets/Onboarding/ui/OnboardingView';

//@ts-ignore
import image from '../../../../../assets/onboarding/images/check_balance.png';
import {useTranslation} from '@shared';

function CheckBalance() {
  const {t} = useTranslation();
  const header = (
    <>
      <Text style={Typography.header}>
        {t('Widgets.Onboarding.CheckBalance.checkCustomerBalance')}
      </Text>
      <Spacer height={DEFAULT_SPACE_HEIGHT} />
      <Text style={Typography.smallHeader}>
        {t(
          'Widgets.Onboarding.CheckBalance.toCheckBalanceLetYourGuestScanTheInitialQrCode',
        )}
      </Text>
    </>
  );

  return <OnboardingView header={header} imageSource={image} />;
}

export {CheckBalance};
