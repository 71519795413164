import React, {ComponentProps, useCallback, useContext, useMemo} from 'react';
import {Screen, Spacer, useTheme} from '@b2cmessenger/doppio-components';
import {FlatList, Platform, StyleSheet} from 'react-native';
import {IconButton} from '@components/common/IconButton';
import {
  IconDollar,
  IconDrop,
  IconEmployee,
  LanguageIcon,
  YouTubeIcon,
} from '@components/common/icons/SvgIcon';
import {navigationRef} from '@navigation/navigationRef';
import WorkplaceContext from '@components/common/WorkplaceContext';
import useStampAward from '@components/hooks/useStampAwards';
import {TEST_IDS} from './SettingsTestIDS';
import {useSelector} from 'react-redux';
import {loginedUserSelectors} from '@store/selectors';
import {getSubscriptionStateExtended} from '@utils/subscriptionState';
import {useActionSheet} from '@expo/react-native-action-sheet';
import {SharedHooks, useChangeLanguage, useTranslation} from '@shared';
import {changeAccountLanguage} from '@screens/Dashboard/screens/Profile/changeAccountLanguage';
const {usePlaceAppearance} = SharedHooks;

const DefaultAwardId = 0;
const DefaultAwardQuantity = 1;

export function ProfileSettings() {
  const {t} = useTranslation();

  const openExtendedDemo = SharedHooks.useExtendedDemo();
  const {colors} = useTheme();
  const workplace = useContext(WorkplaceContext);
  const {IconStamp, stamp, award} = usePlaceAppearance(workplace.id);
  const IconStampActive = useMemo(
    () =>
      function ActiveStampIcon({
        active = true,
        ...rest
      }: ComponentProps<typeof IconStamp>) {
        return <IconStamp active={active} {...rest} />;
      },
    [IconStamp],
  );
  const {getAwardCost} = useStampAward();
  const freeDrinkCost = getAwardCost(workplace.id, DefaultAwardId);

  const onEditLoyaltyProgram = useCallback(() => {
    navigationRef.current?.navigate('Dashboard', {
      screen: 'EditLoyaltyProgram',
    });
  }, []);

  const onCustomizeAppearance = useCallback(() => {
    navigationRef.current?.navigate('Dashboard', {
      screen: 'CustomizeAppearance',
    });
  }, []);

  const {showActionSheetWithOptions} = useActionSheet();
  const openAccountSettings = useCallback(() => {
    const _removeAccount = t('Screens.Profile.Settings.removeAccount');
    const _cancel = t('Screens.Profile.Settings.cancel');

    const options = [_removeAccount, _cancel];
    const destructiveButtonIndex = options.indexOf(_removeAccount);
    const cancelButtonIndex = options.indexOf(_cancel);
    showActionSheetWithOptions(
      {
        title: t('Screens.Profile.Settings.accountSettings') || '',
        options,
        destructiveButtonIndex,
        cancelButtonIndex,
      },
      selectedOptionIdx => {
        if (selectedOptionIdx !== cancelButtonIndex) {
          if (selectedOptionIdx === destructiveButtonIndex) {
            navigationRef.current?.navigate('Dashboard', {
              screen: 'AccountRemoval',
            });
          }
        }
      },
    );
  }, [showActionSheetWithOptions, t]);

  const workplaceSubscription = useSelector(
    loginedUserSelectors.workplaceSubscription,
  );

  const onManageSubscriptions = useCallback(() => {
    navigationRef.current?.navigate('Dashboard', {
      screen: 'ManageSubscription',
    });
  }, []);
  const {changeLanguage} = useChangeLanguage({
    title: t('Screens.Profile.Settings.chooseYourLanguage'),
    onChange: lng => {
      changeAccountLanguage(lng);
    },
  });

  const settingsList = useMemo(() => {
    const settingsListData: Array<ComponentProps<typeof IconButton>> = [
      {
        fill: colors.brand,
        testID: TEST_IDS.buttons.EditLoyaltyProgram,
        accessibilityValue: {text: `${freeDrinkCost}`},
        label: t('Screens.Profile.Settings.editYourLoyaltyProgram'),
        caption: freeDrinkCost
          ? t('Screens.Profile.Settings.loyaltyProgramRules', {
              count: freeDrinkCost,
              stamps: stamp(freeDrinkCost),
              reward: award(DefaultAwardQuantity),
            }) || undefined
          : undefined,
        IconComponent: IconStampActive,
        onPress: onEditLoyaltyProgram,
      },
    ];

    const workplaceSubscriptionExtended = getSubscriptionStateExtended(
      workplaceSubscription,
    );
    const _caption = workplaceSubscriptionExtended.expireAlert
      ? workplaceSubscriptionExtended.expireInDays !== null
        ? workplaceSubscriptionExtended.expireInDays > 0
          ? t('Screens.Profile.Settings.dayFullAccess', {
              count: workplaceSubscriptionExtended.expireInDays,
            })
          : t('Screens.Profile.Settings.limitedAccess')
        : undefined
      : workplaceSubscriptionExtended.status === -2
      ? t('Screens.Profile.Settings.pausedLimitedAccess')
      : undefined;

    settingsListData.push({
      fill: colors.brand,
      label: t('Screens.Profile.Settings.manageSubscriptions'),
      IconComponent: IconDollar,
      onPress: onManageSubscriptions,
      caption: _caption,
      //@ts-ignore
      captionStyle: {color: colors.red},
    });

    settingsListData.push({
      fill: colors.brand,
      label: t('Screens.Profile.Settings.customizeColorIcon'),
      IconComponent: IconDrop,
      accessibilityValue: {
        text: t('Screens.Profile.Settings.customizeAppearance') || '',
      },
      caption: undefined,
      testID: TEST_IDS.buttons.CustomizeAppearance,
      onPress: onCustomizeAppearance,
    });

    settingsListData.push({
      fill: colors.brand,
      label: t('Screens.Profile.Settings.accountSettings'),
      IconComponent: IconEmployee,
      accessibilityValue: {
        text: t('Screens.Profile.Settings.accountSettings') || '',
      },
      caption: undefined,
      onPress: openAccountSettings,
    });
    settingsListData.push({
      fill: colors.brand,
      label: t('Screens.Profile.Settings.language'),
      IconComponent: LanguageIcon,
      accessibilityValue: {
        text: t('Screens.Profile.Settings.accountSettings') || '',
      },
      caption: undefined,
      onPress: changeLanguage,
    });

    settingsListData.push({
      fill: colors.brand,
      label: t('Screens.Profile.Settings.watchProductDemo'),
      IconComponent: YouTubeIcon,
      accessibilityValue: {
        text: t('Screens.Profile.Settings.watchProductDemo') || '',
      },
      caption: t('Screens.Profile.Settings.toSeeAllTheFeaturesAndHowtos') || '',
      onPress: () => {
        openExtendedDemo('settings');
      },
    });

    return (
      <FlatList
        ListHeaderComponent={<Spacer height={20} />}
        data={settingsListData}
        renderItem={({item: props}) => <IconButton {...props} />}
        keyExtractor={item => item.label}
        contentContainerStyle={styles.listContainer}
      />
    );
  }, [
    colors.brand,
    colors.red,
    freeDrinkCost,
    stamp,
    award,
    IconStampActive,
    onEditLoyaltyProgram,
    workplaceSubscription,
    onManageSubscriptions,
    onCustomizeAppearance,
    openAccountSettings,
    changeLanguage,
    t,
    openExtendedDemo,
  ]);

  return (
    <Screen style={styles.screen} testID={TEST_IDS.screen}>
      {settingsList}
    </Screen>
  );
}

const styles = StyleSheet.create({
  screen: {paddingHorizontal: 0},
  listContainer: {
    paddingHorizontal: Screen.PADDING_HORIZONTAL,
    ...(Platform.OS === 'web' ? StyleSheet.absoluteFillObject : {}),
  },
});
