import React, {useCallback, useMemo, useRef} from 'react';
import {
  Button,
  Screen,
  Spacer,
  Pusher,
  Typography,
} from '@b2cmessenger/doppio-components';
import {IconQRCode} from '@components/common/icons/SvgIcon';
import {Animated, Easing, Text} from 'react-native';
import {RootNavigatorScreenProps} from '@navigation/config';
import {navigationRef} from '@navigation/navigationRef';
import {useSelector} from 'react-redux';
import {loginedUserSelectors} from '@store/selectors';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import Confetti from '@components/common/Confetti';
import {TEST_IDS} from './EmployeeConnectedTestIDS';
import useWindowInfo from '@hooks/useWindowInfo';
import {useTranslation} from '@shared';

function EmployeeConnected({}: RootNavigatorScreenProps<'EmployeeConnected'>) {
  const {t} = useTranslation();

  const workplace = useSelector(loginedUserSelectors.workplace);
  const loginedUser = useSelector(loginedUserSelectors.profile) as Exclude<
    ReturnType<typeof loginedUserSelectors.profile>,
    null
  >;
  const isDevice = useMemo(() => loginedUser && loginedUser.is_device, [
    loginedUser,
  ]);
  const heading = useMemo(() => {
    const name = workplace?.name
      ? workplace.name
      : t('Screens.EmployeeConnected.business');

    if (isDevice) {
      return t(
        'Screens.EmployeeConnected.greatThisDeviceHasBeenAddedToTheAccount',
        {s: '\n', name},
      );
    } else {
      return t(
        'Screens.EmployeeConnected.greatYourAccountHasBeenAddedToTheAccount',
        {s: '\n', name},
      );
    }
  }, [isDevice, t, workplace]);

  const onPressGoToWork = useCallback(() => {
    navigationRef.current?.navigate('Dashboard', {
      screen: 'QRCode',
    });
  }, []);

  const {top, bottom} = useSafeAreaInsets();
  const {height: screenHeight} = useWindowInfo();

  const animVal = useRef(new Animated.Value(0)).current;
  const animation = useRef<Animated.CompositeAnimation>();
  const startAnimation = useCallback(() => {
    animation.current = Animated.timing(animVal, {
      toValue: 1,
      duration: 1500,
      easing: Easing.out(Easing.quad),
      useNativeDriver: true,
    });
    animation.current.start(() => {});
  }, [animVal]);

  const animatedContent = useMemo(() => {
    return (
      <Animated.View
        style={{
          transform: [
            {
              translateY: animVal.interpolate({
                inputRange: [0, 1],
                outputRange: [screenHeight + 100, 0],
              }),
            },
          ],
        }}
      >
        <IconQRCode size={28} />
        <Text style={Typography.body}>
          {t('Screens.EmployeeConnected.generateQrCodesToGiveLoyaltyStamps')}
        </Text>
      </Animated.View>
    );
  }, [animVal, screenHeight, t]);

  const animatedButton = useMemo(() => {
    return (
      <Animated.View
        style={{
          transform: [
            {
              translateY: animVal.interpolate({
                inputRange: [0, 1],
                outputRange: [screenHeight + 100, 0],
              }),
            },
          ],
        }}
      >
        <Button.Primary
          testID={TEST_IDS.buttons.GoToWork}
          title={t('Screens.EmployeeConnected.greatLetsGetToWork')}
          onPress={onPressGoToWork}
        />
      </Animated.View>
    );
  }, [animVal, onPressGoToWork, screenHeight, t]);

  return (
    <Screen testID={TEST_IDS.screen}>
      <Spacer height={Math.max(top, 16)} />
      <Confetti
        onFinishAnimation={() => {
          startAnimation();
        }}
      >
        <Screen.Heading>{heading}</Screen.Heading>
      </Confetti>
      <Spacer height={18} />
      {animatedContent}
      <Pusher />
      {animatedButton}
      <Spacer height={Math.max(bottom, 16)} />
    </Screen>
  );
}

export default EmployeeConnected;
