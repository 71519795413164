import {
  PlaceSubscriptionState,
  PlaceSubscriptionStateExtended,
} from '@typings/Place';
import {DateTimeUtils} from '@b2cmessenger/doppio-shared';

type SubscriptionStatusNames =
  | 'notAvailable'
  | 'accessWithPaymentProblems'
  | 'ok'
  | 'paused';
const SubscriptionStatuses: Record<
  SubscriptionStatusNames,
  PlaceSubscriptionState['status']
> = {
  notAvailable: -1,
  accessWithPaymentProblems: 0,
  ok: 1,
  paused: -2,
};

export const isForeverFree = (
  state: Pick<
    PlaceSubscriptionState,
    'status' | 'expire_at' | 'able_to_subscribe'
  >,
): boolean => {
  return (
    state.status === SubscriptionStatuses.ok &&
    state.expire_at === '2100-01-01 00:00:00' &&
    !state.able_to_subscribe
  );
};
export const isTrialNotStartedYet = (
  state: Pick<
    PlaceSubscriptionState,
    'status' | 'expire_at' | 'able_to_subscribe'
  >,
): boolean => {
  return (
    state.status === SubscriptionStatuses.ok &&
    state.expire_at === '2100-01-01 00:00:00' &&
    state.able_to_subscribe
  );
};

export const expireIn = (
  state: PlaceSubscriptionState,
): {days: number | null; alert: boolean} => {
  if (!state.expire_at) {
    return {days: null, alert: false};
  }
  const expireAt = DateTimeUtils.fromDatetimeServer(state.expire_at);
  const inDays = DateTimeUtils.dateInDays(expireAt);

  return {days: inDays > 365 ? null : inDays, alert: inDays <= 5};
};

export const getSubscriptionStateExtended = (
  state: PlaceSubscriptionState | null,
): PlaceSubscriptionStateExtended => {
  if (state === null) {
    return {
      gateway: null,
      status: SubscriptionStatuses.ok,
      payerUserId: null,
      expireAlert: false,
      expireInDays: null,
      isForeverFree: false,
      isAbleToSubscribe: false,
      isTrialNotStartedYet: false,
    };
  } else if (isForeverFree(state)) {
    return {
      gateway: state.gateway,
      status: state.status,
      payerUserId: state.payer_user_id,
      isForeverFree: true,
      expireInDays: null,
      expireAlert: false,
      isAbleToSubscribe: state.able_to_subscribe,
      isTrialNotStartedYet: false,
    };
  } else {
    const {days, alert} = expireIn(state);

    return {
      gateway: state.gateway,
      status: state.status,
      payerUserId: state.payer_user_id,
      isForeverFree: isForeverFree(state),
      expireInDays: days,
      expireAlert: state.status === SubscriptionStatuses.paused ? false : alert,
      isAbleToSubscribe: state.able_to_subscribe,
      isTrialNotStartedYet: isTrialNotStartedYet(state),
    };
  }
};

export {SubscriptionStatuses};
