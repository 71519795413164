import * as jsrsasign from '@utils/jsrsasign/jsrsasign-jwths-min';

import {
  DynamicLinkActions,
  DynamicLinkActionShortTags,
} from '@screens/Dashboard/screens/QRCode/components/QRCodeGenerator/buildDynamicLink';
import {
  StampAwardJwtPayload,
  StampBalanceJwtPayload,
  StampGiveJwtPayload,
} from '@typings/Jwt';

type GiveStampArg = {
  action: DynamicLinkActions.giveStamps;
  options: {
    placeId: number;
    issuedBy: number;
    amount: number;
    issuedAt?: number;
    os?: string;
  };
};

type AwardFreeGiftArg = {
  action: DynamicLinkActions.awardFreeGift;
  options: {
    placeId: number;
    issuedBy: number;
    awardId: number;
    amount: number;
    issuedAt?: number;
  };
};

type StampBalanceArg = {
  action: DynamicLinkActions.stampBalance;
  options: {
    placeId: number;
    issuedBy: number;
    issuedAt?: number;
  };
};

type JWTSignatureFNArg = GiveStampArg | AwardFreeGiftArg | StampBalanceArg;

interface JWTSignatureFN {
  (key: string, arg: JWTSignatureFNArg): string;
}
export const createJWTSignature: JWTSignatureFN = function (key, arg) {
  const {placeId, issuedBy: uid} = arg.options;

  const pid = Number(placeId);

  switch (arg.action) {
    case DynamicLinkActions.awardFreeGift: {
      const {awardId: aid, amount: q} = arg.options;
      return sign(key, {
        t: DynamicLinkActionShortTags[DynamicLinkActions.awardFreeGift],
        pid,
        uid,
        aid,
        q,
      } as StampAwardJwtPayload);
    }
    case DynamicLinkActions.giveStamps: {
      const {amount: q, os} = arg.options;
      return sign(key, {
        t: DynamicLinkActionShortTags[DynamicLinkActions.giveStamps],
        pid,
        uid,
        q,
        ...(os ? {os} : {}),
      } as StampGiveJwtPayload);
    }
    case DynamicLinkActions.stampBalance: {
      return sign(key, {
        t: DynamicLinkActionShortTags[DynamicLinkActions.stampBalance],
        pid,
        uid,
      } as StampBalanceJwtPayload);
    }
  }
};

export function getCurrentIatValue() {
  return jsrsasign.KJUR.jws.IntDate.get('now');
}

function sign(key: string, _payload: object) {
  const iat = jsrsasign.KJUR.jws.IntDate.get('now');
  const header = {alg: 'HS256', typ: 'JWT'};
  const payload = {
    iat,
    ..._payload,
  };
  return jsrsasign.KJUR.jws.JWS.sign(
    'HS256',
    JSON.stringify(header),
    JSON.stringify(payload),
    key,
  );
}
