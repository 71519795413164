import {MigrationManifest} from 'redux-persist/es/types';

export type TooltipOptions = {
  shown: boolean;
};

// --- V1 ---
enum TooltipKeysV1 {
  'dashboard' = 'dashboard',
  'dashboardAdvanced' = 'dashboardAdvanced',
}
type TooltipsSliceStateV1 = Record<TooltipKeysV1, TooltipOptions>;

const migrations: MigrationManifest = {};

const tooltipPersistActualVersion = 1;
export {
  migrations as tooltipsMigrations,
  TooltipKeysV1 as TooltipKeys,
  tooltipPersistActualVersion,
};
export type {
  // eslint-disable-next-line no-undef
  TooltipsSliceStateV1 as TooltipsSliceState,
};
