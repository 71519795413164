import React from 'react';
import {Text} from 'react-native';
import {Typography} from '@b2cmessenger/doppio-components';
import {OnboardingView} from '@widgets/Onboarding/ui/OnboardingView';

//@ts-ignore
import image from '../../../../../assets/onboarding/images/employee_pos.png';
import {useTranslation} from '@shared';

function POS() {
  const {t} = useTranslation();
  const header = (
    <>
      <Text style={Typography.header}>
        {t('Widgets.Onboarding.POS.youCanInviteYourEmployeeOrLinkPosDevice')}
      </Text>
    </>
  );

  return <OnboardingView header={header} imageSource={image} />;
}

export {POS};
