import React, {useCallback, useContext, useMemo, useState} from 'react';
import {
  Navbar,
  Screen,
  Pusher,
  Spacer,
  ThemeProvider,
  useTheme,
} from '@b2cmessenger/doppio-components';
import {PlaceAppearance} from '@b2cmessenger/doppio-core';
import {View, StyleSheet} from 'react-native';
import {navigationRef} from '@navigation/navigationRef';
import {TEST_IDS} from './EditLoyaltyProgramTestIDS';
import AppearanceCustomizer, {
  getConfigChanges,
  hasChanged,
  useAppearanceCustomizer,
  useFetchPossibleAppearance,
} from '@components/common/AppearanceCustomizer';
import WorkplaceContext from '@components/common/WorkplaceContext';
import {updatePlaceAppearance} from '@components/common/AppearanceCustomizer/updatePlaceAppearance';
import {Alert} from '@components/common/Alert';
import {useFocusEffect} from '@react-navigation/core';
import {AppearanceCustomizeInfo} from '@components/common/AppearanceCustomizer/AppearanceCustomizeInfo';
import {AppearanceStages} from '@components/common/AppearanceCustomizer/slice';
import AppearanceCustomizeControls from '@components/common/AppearanceCustomizer/AppearanceCustomizeControls';
import {colors as defaultColors} from '@b2cmessenger/doppio-shared';
import {getPreparedPossibleAppearance} from '@screens/BusinessWizard/shared';
import {useTranslation} from '@shared';

const {storage} = PlaceAppearance;
const SpacerParagraphHeight = 4;

function CustomizeAppearance() {
  const {t} = useTranslation();
  const workplace = useContext(WorkplaceContext);
  const {colors} = useTheme();

  const [loading, setLoading] = useState(false);

  const goBack = useCallback(() => {
    if (navigationRef.current?.canGoBack()) {
      navigationRef.current?.goBack();
    } else {
      navigationRef.current?.navigate('Dashboard', {
        screen: 'Profile',
        params: {
          screen: 'Settings',
        },
      });
    }
  }, []);

  const {
    possibleAppearances,
    possibleAppearancesError,
  } = useFetchPossibleAppearance();
  const {
    stage,
    loading: appearanceLoading,
    error,
    currentConfig,
    fetch,
    onChange,
    actions,
    initialConfig,
  } = useAppearanceCustomizer({
    placeId: workplace.id,
    possibleAppearances,
    possibleAppearancesError,
  });

  const businessTypeId = useMemo(() => {
    if (!initialConfig) {
      return undefined;
    }
    return possibleAppearances?.resources.find(
      t => t.id === initialConfig.stamp_resource_id,
    )?.business_id;
  }, [initialConfig, possibleAppearances]);

  const preparedPossibleAppearances = useMemo(() => {
    if (!businessTypeId) {
      return possibleAppearances;
    }

    return getPreparedPossibleAppearance({
      possibleAppearances,
      businessTypeId: businessTypeId,
    });
  }, [possibleAppearances, businessTypeId]);

  const onSave = useCallback(async () => {
    if (hasChanged(currentConfig, initialConfig)) {
      try {
        setLoading(true);
        const placeAppearance = await updatePlaceAppearance({
          placeId: workplace.id,
          config: getConfigChanges(currentConfig, possibleAppearances),
        });
        await storage.setAppearance(workplace.id, placeAppearance);
      } catch (e) {
        const _title = t('Screens.CustomizeAppearance.anErrorHasOccurred');
        Alert.alert(_title, typeof e === 'string' ? e : '');
      } finally {
        setLoading(false);
      }
    }
    goBack();
  }, [
    currentConfig,
    initialConfig,
    goBack,
    workplace.id,
    possibleAppearances,
    t,
  ]);

  const currentBrandColor = useMemo(() => {
    if (currentConfig.color) {
      return currentConfig.color;
    } else if (currentConfig.color === null) {
      return defaultColors.brand;
    } else {
      return colors.brand;
    }
  }, [colors.brand, currentConfig.color]);

  const _fetch = useCallback(() => {
    fetch(workplace.id);
  }, [fetch, workplace.id]);

  useFocusEffect(
    useCallback(() => {
      actions.showPicker();
      _fetch();

      return () => {
        actions.loading();
      };
    }, [_fetch, actions]),
  );

  const navbar = useMemo(() => {
    const customizeRewardName = t(
      'Screens.CustomizeAppearance.customizeRewardName',
    );
    const customizeColor = t('Screens.CustomizeAppearance.customizeColorIcon');
    return (
      <Navbar
        title={
          stage === AppearanceStages.awardNameEdit
            ? customizeRewardName
            : customizeColor
        }
        onBack={
          stage === AppearanceStages.awardNameEdit ? actions.onBack : goBack
        }
        backButtonTestID={'customize-appearance-back'}
      />
    );
  }, [actions.onBack, goBack, stage, t]);

  return (
    <Screen navbar={navbar} testID={TEST_IDS.screen}>
      <ThemeProvider colors={{brand: currentBrandColor}}>
        <View style={styles.container}>
          <AppearanceCustomizeInfo
            spacerParagraphHeight={SpacerParagraphHeight}
            brandColor={currentBrandColor}
            stage={stage}
          />
          <Spacer height={15} />
          <View style={styles.pickerContainer}>
            <AppearanceCustomizer
              placeId={workplace.id}
              initialConfig={initialConfig}
              onChange={onChange}
              stage={stage}
              possibleAppearances={preparedPossibleAppearances}
              error={error}
              currentConfig={currentConfig}
              contentPadding={Screen.PADDING_HORIZONTAL}
              actions={actions}
              loading={appearanceLoading}
            />
          </View>
        </View>
        <Pusher />
        {!appearanceLoading ? (
          <AppearanceCustomizeControls
            stage={stage}
            hasError={!!error}
            onSave={onSave}
            onTryAgain={_fetch}
            actions={actions}
            doneTestId={TEST_IDS.buttons.Done}
            saveButtonTitle={
              (hasChanged(currentConfig, initialConfig)
                ? t('Screens.CustomizeAppearance.save')
                : t('Screens.CustomizeAppearance.close')) || ''
            }
            loading={loading}
          />
        ) : null}
      </ThemeProvider>
    </Screen>
  );
}

export default CustomizeAppearance;

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  link: {
    textDecorationLine: 'underline',
  },
  pickerContainer: {
    flexGrow: 1,
    marginLeft: -Screen.PADDING_HORIZONTAL,
    marginRight: -Screen.PADDING_HORIZONTAL,
  },
});
