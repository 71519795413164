import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import {Alert} from '@components/common/Alert';
import {StyleSheet, TextInput as NativeTextInput, Animated} from 'react-native';
import {RootNavigatorScreenProps} from '@navigation/config';
import {
  Button,
  Navbar,
  Screen,
  Spacer,
  Pusher,
  TextInput,
  ThemeProvider,
} from '@b2cmessenger/doppio-components';
import {Analytics, PlaceAppearance} from '@b2cmessenger/doppio-core';
import {colors, Logger, useIsMounted} from '@b2cmessenger/doppio-shared';

import {useDispatch, useSelector} from 'react-redux';
import {loginedUserSelectors} from '@store/selectors';
import {LoginedUser} from '@typings/shared';
import {BusinessWizardStages, slice} from './slice';
import {createCompany} from '@screens/BusinessWizard/createCompany';
import {useScreenPrimaryButtonStyle} from '@components/hooks/useScreenPrimaryButtonStyle';
import {reInit} from '@store/actions';
import {TEST_IDS} from './BusinessWizardTestIDS';
import {Fade, Placeholder, PlaceholderLine} from 'rn-placeholder';
import {useLogoutPrompt} from '@components/hooks/useLogoutPrompt';
import {useReinitialize} from '@components/hooks/useReinitialize';
import Confetti from '@components/common/Confetti';
import {isTablet} from 'react-native-device-info';
import {SetupLoyalty} from '@screens/BusinessWizard/components/SetupLoyalty';
import {updatePlaceAppearance} from '@components/common/AppearanceCustomizer/updatePlaceAppearance';
import {useFetchPossibleAppearance} from '@components/common/AppearanceCustomizer';
import SelectBusinessType from '@screens/BusinessWizard/components/SelectBusinessType';
import Onboarding from '@widgets/Onboarding';
import {ErrorCard, useTranslation} from '@shared';
import {
  getBusinessWhenNoneMatch,
  getPlaceAppearanceByBusinessType,
  getPreparedPossibleAppearance,
  PossibleBusinessExtended,
} from '@screens/BusinessWizard/shared';
import FirebaseRemoteConfigContext from '@utils/FirebaseRemoteConfigContext';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

const _isTablet = isTablet();
const {storage} = PlaceAppearance;
const DELAY_BEFORE_ONBOARDING = 500;

export function BusinessWizard({
  navigation,
}: RootNavigatorScreenProps<'BusinessWizard'>) {
  const {
    defaultAwardId = null,
    defaultStampId = null,
    businessIdWithCoffeeIcon,
    scanViaWebOnboardingStory,
  } = useContext(FirebaseRemoteConfigContext);
  const {t} = useTranslation();
  const {top, bottom} = useSafeAreaInsets();
  const realDispatch = useDispatch();
  const {firstname} = useSelector(loginedUserSelectors.profile) as LoginedUser;
  const [state, dispatch] = useReducer(
    slice.reducer,
    slice.reducer(undefined, slice.actions.init(firstname)),
  );

  // Check if user has workplace already since `BusinessWizard` screen added to logged-in user's screen stack and navigate to onboarding.
  const workplace = useSelector(loginedUserSelectors.workplace);
  useLayoutEffect(() => {
    if (workplace !== null) {
      dispatch(slice.actions.setOnboarding());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {possibleAppearances, possibleAppearancesError} =
    useFetchPossibleAppearance();
  const isMounted = useIsMounted();

  const links = useMemo(
    () => possibleAppearances?.links ?? [],
    [possibleAppearances],
  );
  const resources = useMemo(
    () => possibleAppearances?.resources,
    [possibleAppearances],
  );
  const businessTypes = useMemo<PossibleBusinessExtended[]>(() => {
    return possibleAppearances?.businesses
      ? [...possibleAppearances.businesses, getBusinessWhenNoneMatch()]
      : [];
  }, [possibleAppearances]);

  const preparedPossibleAppearances = useMemo(() => {
    return getPreparedPossibleAppearance({
      possibleAppearances,
      businessTypeId: state.businessTypeId,
    });
  }, [possibleAppearances, state.businessTypeId]);

  const promptLogout = useLogoutPrompt();
  const [emptyCompanyName, setEmptyCompanyName] = useState(true);
  const companyInputRef = useRef<NativeTextInput>(null);
  const companyNameRef = useRef<string>(state.place.name);
  const onChangeCompanyName = useCallback((text: string) => {
    companyNameRef.current = text;
    setEmptyCompanyName(text.length === 0);
  }, []);

  const navbarOnBack = useMemo(() => {
    switch (state.stage) {
      case 'businessType':
        return promptLogout;
      case 'enterCompanyName':
        return () => dispatch(slice.actions.init(firstname));
      case 'setupLoyalty':
        return () =>
          dispatch(slice.actions.setBusinessType(state.businessTypeId));
      default:
        return undefined;
    }
  }, [firstname, promptLogout, state.businessTypeId, state.stage]);

  const progress = useMemo(() => getNavbarProgress(state.stage), [state.stage]);
  const navbar = useMemo(
    () =>
      navbarOnBack ? (
        <Navbar onBack={navbarOnBack} progress={progress} />
      ) : null,
    [navbarOnBack, progress],
  );

  const pbs = useScreenPrimaryButtonStyle();

  const reInitializePromiseRef = useRef<Promise<void>>();
  const {execute} = useReinitialize();
  const reInitialize = useCallback(async () => {
    Logger.verboseTag('BusinessWizard', 'Before ReInitialize bootstrap');

    const bootstrapResult = await execute();
    if (bootstrapResult) {
      realDispatch(reInit(bootstrapResult));
    }
  }, [execute, realDispatch]);

  const send = useCallback(async () => {
    try {
      dispatch(slice.actions.send());
      const place = await createCompany(state.place);
      if (place && state.appearance) {
        const placeAppearance = await updatePlaceAppearance({
          placeId: place.id,
          config: state.appearance,
        });
        await storage.setAppearance(place.id, placeAppearance);
      }
      reInitializePromiseRef.current = reInitialize();
      dispatch(slice.actions.readyToUse(place));
      Analytics.logEvent('business_wizard_completed', {
        stamps_for_reward: state.place.stamps,
      });
    } catch (errorMessage) {
      dispatch(slice.actions.sendFailed());
      Analytics.logEvent('business_wizard_failed', {
        reason: errorMessage,
      });
      const _alert = t('Screens.BusinessWizard.companyNotCreated');
      // @ts-ignore
      Alert.alert(_alert, errorMessage);
      Logger.errorTag('BusinessWizard', errorMessage);
    }
  }, [reInitialize, state.appearance, state.place, t]);

  const setBusinessTypeId = useCallback(
    (businessTypeId: number) => {
      if (resources) {
        dispatch(
          slice.actions.setPlaceAppearance(
            getPlaceAppearanceByBusinessType({
              links,
              resources,
              businessTypeId,
              businessIdWithCoffeeIcon,
              appearance: state.appearance,
              defaultStampId,
              defaultAwardId,
            }),
          ),
        );
      }
      dispatch(slice.actions.setBusinessType(businessTypeId));
    },
    [
      resources,
      links,
      businessIdWithCoffeeIcon,
      state.appearance,
      defaultStampId,
      defaultAwardId,
    ],
  );

  const onNextPress = useMemo(() => {
    switch (state.stage) {
      case 'enterCompanyName':
        return () =>
          dispatch(slice.actions.setPlaceName(companyNameRef.current));
      case 'setupLoyalty':
        return send;
      default:
        return undefined;
    }
  }, [state.stage, send]);

  const goToWork = useCallback(() => {
    navigation.navigate('Dashboard', {screen: 'QRCode'});
    Analytics.logEvent('onboarding_on_finish', {scanViaWebOnboardingStory});
  }, [navigation, scanViaWebOnboardingStory]);
  const joinToExistingCompany = useCallback(() => {
    navigation.navigate('JoinAsEmployee');
  }, [navigation]);

  const selectBusinessType = useMemo(() => {
    return (
      <>
        <Spacer height={20} />
        <SelectBusinessType
          onSelectType={id => setBusinessTypeId(id)}
          selectedTypeId={state.businessTypeId}
          businessTypes={businessTypes}
          resources={resources}
          businessIdWithCoffeeIcon={businessIdWithCoffeeIcon}
          defaultStampId={defaultStampId}
        />
        <Spacer height={bottom} />
      </>
    );
  }, [
    bottom,
    state.businessTypeId,
    businessTypes,
    resources,
    businessIdWithCoffeeIcon,
    defaultStampId,
    setBusinessTypeId,
  ]);
  const onboardingFadeAnimRef = useRef(new Animated.Value(0));
  const showPossibleAppearancesError = useMemo(
    () =>
      state.stage === 'businessType' &&
      possibleAppearancesError &&
      !preparedPossibleAppearances,
    [state.stage, possibleAppearancesError, preparedPossibleAppearances],
  );

  const content = useMemo(() => {
    if (showPossibleAppearancesError) {
      return (
        <>
          <Spacer height={20} />
          <ErrorCard error={possibleAppearancesError} />
        </>
      );
    }

    switch (state.stage) {
      case 'businessType':
        return selectBusinessType;
      case 'enterCompanyName':
        return (
          <>
            <Spacer height={30} />
            <TextInput
              testID={TEST_IDS.inputs.CompanyName}
              ref={companyInputRef}
              defaultValue={state.place.name}
              onChangeText={onChangeCompanyName}
              label={t('Screens.BusinessWizard.yourCompanyName') || ''}
              autoCompleteType={undefined}
            />
            <Spacer />
            <Button.Inverted
              testID={TEST_IDS.buttons.JoinToCompany}
              title={t('Screens.BusinessWizard.joinAnExistingCompany')}
              onPress={joinToExistingCompany}
            />
          </>
        );
      case 'setupLoyalty':
        return (
          <>
            <Spacer height={_isTablet ? 60 : 20} />
            <SetupLoyalty
              stamps={state.place.stamps}
              businessTypeId={state.businessTypeId}
              onStampsChange={n => dispatch(slice.actions.setPlaceStamps(n))}
              appearanceConfig={state.appearance}
              possibleAppearances={preparedPossibleAppearances}
              possibleAppearancesError={possibleAppearancesError}
              onAppearanceChange={config =>
                dispatch(slice.actions.setPlaceAppearance(config))
              }
            />
          </>
        );
      case 'onboarding':
        Animated.timing(onboardingFadeAnimRef.current, {
          toValue: 1,
          duration: DELAY_BEFORE_ONBOARDING,
          useNativeDriver: true,
        }).start();
        return (
          <Animated.View
            style={[
              styles.onboardingWrapper,
              {opacity: onboardingFadeAnimRef.current},
            ]}>
            <Spacer height={Math.max(top, 10)} />
            <Onboarding onDone={goToWork} />
          </Animated.View>
        );

      default:
        return null;
    }
  }, [
    showPossibleAppearancesError,
    state.stage,
    state.place.name,
    state.place.stamps,
    state.businessTypeId,
    state.appearance,
    possibleAppearancesError,
    selectBusinessType,
    onChangeCompanyName,
    t,
    joinToExistingCompany,
    preparedPossibleAppearances,
    top,
    goToWork,
  ]);

  useEffect(() => {
    if (state.stage === 'enterCompanyName') {
      companyInputRef.current?.focus();
    }
  }, [state.stage]);

  const readyToUseFadeAnimRefReadyToUse = useRef(new Animated.Value(1));
  const fadeOutToOnboarding = useCallback(() => {
    setTimeout(() => {
      (reInitializePromiseRef.current || Promise.resolve()).finally(() => {
        if (isMounted) {
          Animated.timing(readyToUseFadeAnimRefReadyToUse.current, {
            toValue: 0,
            duration: DELAY_BEFORE_ONBOARDING,
            useNativeDriver: true,
          }).start();

          setTimeout(() => {
            dispatch(slice.actions.setOnboarding());
          }, DELAY_BEFORE_ONBOARDING);
        }
      });
    }, DELAY_BEFORE_ONBOARDING);
  }, [isMounted]);

  const header = useMemo(() => {
    if (showPossibleAppearancesError) {
      return null;
    }

    switch (state.stage) {
      case 'send':
        return (
          <>
            <Spacer height={top} />
            <SendPlaceholderHeader />
          </>
        );
      case 'readyToUse':
        return (
          <Animated.View
            style={{opacity: readyToUseFadeAnimRefReadyToUse.current}}>
            <Spacer height={top} />
            <Confetti onFinishAnimation={fadeOutToOnboarding}>
              <Heading
                heading={state.heading}
                description={state.description}
              />
            </Confetti>
          </Animated.View>
        );
      case 'onboarding':
        return null;
      default:
        return (
          <Heading heading={state.heading} description={state.description} />
        );
    }
  }, [
    showPossibleAppearancesError,
    fadeOutToOnboarding,
    state.description,
    state.heading,
    state.stage,
    top,
  ]);

  const disabled = useMemo(() => {
    switch (state.stage) {
      case 'enterCompanyName':
        return emptyCompanyName;
      default:
        return undefined;
    }
  }, [emptyCompanyName, state.stage]);

  return (
    <Screen navbar={navbar} style={styles.container}>
      <ThemeProvider colors={{brand: state.appearance?.color || colors.brand}}>
        {header}
        {content}
        <Pusher />
        {onNextPress ? (
          <Button.Primary
            testID={TEST_IDS.buttons.Next}
            title={t('Screens.BusinessWizard.next')}
            style={pbs}
            onPress={onNextPress}
            disabled={disabled}
          />
        ) : null}
      </ThemeProvider>
    </Screen>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  onboardingWrapper: {
    flex: 1,
  },
});

function getNavbarProgress(stage: BusinessWizardStages) {
  switch (stage) {
    case 'businessType':
      return 0;
    case 'enterCompanyName':
      return 1;
    case 'setupLoyalty':
      return 2;
    default:
      return undefined;
  }
}

function Heading({
  heading,
  description,
}: {
  heading: string;
  description: string | undefined;
}) {
  return (
    <>
      <Screen.Heading testID={TEST_IDS.screen}>{heading}</Screen.Heading>
      {description ? (
        <Screen.Description testID={TEST_IDS.elements.Description}>
          {description}
        </Screen.Description>
      ) : null}
    </>
  );
}

function SendPlaceholderHeader() {
  return (
    <>
      <Placeholder Animation={Fade} testID={TEST_IDS.screen}>
        <Spacer height={24} />
        <PlaceholderLine width={(150 / 375) * 100} height={24} noMargin />
        <Spacer height={6} />
        <PlaceholderLine width={(231 / 375) * 100} height={24} noMargin />
        <Spacer height={6} />
        <PlaceholderLine
          testID={TEST_IDS.elements.Description}
          width={(218 / 375) * 100}
          height={24}
          noMargin
        />
      </Placeholder>
    </>
  );
}
