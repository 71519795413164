import type {FirebaseMessagingTypes} from '@react-native-firebase/messaging';
import {Notification, NotificationStatus} from '../types/Notification';
import {DateTimeUtils, Logger} from '@b2cmessenger/doppio-shared';
import firebase from 'firebase';
import {EventEmitter} from 'eventemitter3';

const events = new EventEmitter<'notificationWeb'>();

type JSONSerializedNotification = Record<
  keyof Notification | 'notification_id',
  string
>;

//TODO move inside doppio-core after testing
export default class PushNotifications {
  static ref: firebase.database.Reference | null = null;
  static subscribeNotificationId: string | null = null;

  static getNotificationFromMessage(
    message: FirebaseMessagingTypes.RemoteMessage,
  ) {
    const data = message.data as JSONSerializedNotification;
    let additionalData;
    try {
      additionalData =
        (data.data && JSON.parse(data.data)) ||
        (() => {
          const {
            /* eslint-disable @typescript-eslint/no-unused-vars */
            notification_id,
            app_event,
            template_type,
            title,
            message: _message,
            data: _data,
            created_at,
            ...extraData
            /* eslint-enable @typescript-eslint/no-unused-vars */
          } = data;
          return extraData;
        })();
    } catch (e) {
      additionalData = {};
    }

    const {title = null, body = null} = message.notification || {};

    // noinspection UnnecessaryLocalVariableJS
    const notification = {
      id: data.notification_id || additionalData.notification_id,
      status: NotificationStatus.New,
      app_event: data.app_event || additionalData.app_event,
      template_type: data.template_type || additionalData.template_type,
      title: title || data.title,
      message: body || data.message,
      data: additionalData.data || additionalData,
      created_at:
        additionalData.created_at ||
        DateTimeUtils.toUTCDatetimeServer(new Date(), {}),
    } as Notification;

    return notification;
  }

  static subscribe(id: string) {
    if (this.subscribeNotificationId !== id && this.ref) {
      this.unsubscribe();
    }

    if (this.subscribeNotificationId !== id) {
      this.subscribeNotificationId = id;
      this.ref = firebase.database().ref(this.subscribeNotificationId);
      let firstTimeFetched = false;
      this.ref.on('value', snapshot => {
        if (firstTimeFetched) {
          handler(snapshot);
        } else {
          firstTimeFetched = true;
        }
      });
    }
  }

  static unsubscribe() {
    this.ref?.off();
    this.subscribeNotificationId = null;
  }

  static subscribeToNotifications(
    _handler: (data: {notification: Notification}) => any,
  ) {
    events.addListener('notificationWeb', notification => {
      if (notification) {
        _handler({notification});
      }
    });

    return () => events.removeListener('notificationWeb');
  }

  static getInitialNotification() {
    return null;
  }

  static onNotificationOpenedApp() {}
}

function getNotificationFromSnapshot(snapshot: firebase.database.DataSnapshot) {
  const value = snapshot?.val();
  try {
    switch (typeof value) {
      case 'string':
        return JSON.parse(value);
      case 'object':
        if ('message' in value) {
          if (typeof value.message === 'string') {
            return JSON.parse(value.message);
          }

          return value.message;
        }
        return;
      default:
        return undefined;
    }
  } catch (e) {
    Logger.errorTag('getNotificationFromSnapshot', e);
  }
}
function handler(snapshot: firebase.database.DataSnapshot) {
  const notification = getNotificationFromSnapshot(snapshot);
  events.emit('notificationWeb', notification);
}
