import React from 'react';
import type {loginedUserSelectors} from '@store/selectors';

type Workplace = Exclude<
  ReturnType<typeof loginedUserSelectors.workplace>,
  null
>;
const WorkplaceContext = React.createContext<Workplace>({
  adr_city: '',
  adr_country_code: '',
  adr_latitude: '',
  adr_longitude: '',
  adr_postal_code: '',
  adr_state: '',
  adr_street_name: '',
  adr_street_number: '',
  brand_id: 0,
  ext_id: '',
  ext_src: '',
  id: 0,
  logo: '',
  logoThumb: '',
  name: '',
  // @ts-ignore FIXME: resolve type issues
  ratings: {},
});

export function getWorkplaceRole(workplace: Workplace) {
  const {roles = []} = workplace;
  const ROLE_PLACE_MANAGER = 3;
  return roles.indexOf(ROLE_PLACE_MANAGER) === -1 ? 'employee' : 'owner';
}

export default WorkplaceContext;
