import {StyleSheet} from 'react-native';
import {PossiblePlaceAppearances as PossiblePlaceAppearancesWithLinks} from '@typings/Place';

export const DEFAULT_AWARD_NAME = 'Free drink';

const pickerItemSize = 74;
const pickerItemPadding = 7.5;
const pickerItemFullWidth = pickerItemSize + pickerItemPadding * 2;

const pickerStyles = StyleSheet.create({
  pickerContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: pickerItemFullWidth * 4,
    marginTop: 15,
    marginBottom: 15,
  },
  pickerContainerInner: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  pickerItem: {
    padding: pickerItemPadding,
  },
});

export function getAwardNameSuggestions({
  initialName,
  nameFromAward,
  configName,
  isEmpty,
  awardId,
}: {
  initialName: string | null;
  nameFromAward: string | null;
  configName: string | null;
  isEmpty: boolean;
  awardId: number | null;
}): string[] {
  const _suggestions = new Map();

  if (
    (initialName && isEmpty) ||
    (initialName &&
      nameFromAward &&
      configName === null &&
      initialName !== nameFromAward) ||
    (initialName && awardId === null) ||
    (initialName &&
      configName &&
      initialName.trim().toLowerCase() !== configName.trim().toLowerCase())
  ) {
    _suggestions.set(initialName, initialName);
  }
  if (
    (nameFromAward && isEmpty) ||
    (nameFromAward && configName && nameFromAward !== configName)
  ) {
    _suggestions.set(nameFromAward, nameFromAward);
  }

  if (
    awardId === null &&
    ((configName &&
      configName.toLowerCase().trim() !==
        DEFAULT_AWARD_NAME.toLowerCase().trim()) ||
      isEmpty)
  ) {
    _suggestions.set(DEFAULT_AWARD_NAME, DEFAULT_AWARD_NAME);
  }

  return [..._suggestions.keys()];
}

export type PossiblePlaceAppearances = Pick<
  PossiblePlaceAppearancesWithLinks,
  'colors' | 'resources'
>;

export type SingleAppearanceResource = PossiblePlaceAppearances['resources'][0];

export {pickerStyles, pickerItemSize, pickerItemFullWidth};
