/**
 * decode url-safe-base64 string to base64
 * @param {String} safe - url-safe-base64 string
 * @return {String} base64 encoded
 */
export const decodeUrlSafeBase64 = (safe: string): string => {
  const SAFE_URL_BASE64_DECODE_REPS = {
    '-': '+',
    _: '/',
    '.': '=',
  };

  return safe.replace(
    /[-_.]/g,
    (m) =>
      SAFE_URL_BASE64_DECODE_REPS[
        m as keyof typeof SAFE_URL_BASE64_DECODE_REPS
      ],
  );
};

/**
 * checks if `string` is url-safe-base64 encoded
 * @param {String} string
 * @return {Boolean} true if url-safe-base64 encoded
 */
export const isUrlSafeBase64 = (val: string) =>
  /^[A-Za-z0-9_-]*[.]{0,2}$/.test(val);
