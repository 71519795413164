import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native';
import Video from '@components/ExpoAvVideo';
import React, {ComponentProps, useMemo} from 'react';

const DEFAULT_DEMO_SIZE = 200;

type DemoVideoProps = ComponentProps<typeof Video> & {
  size: number;
  containerStyle?: StyleProp<ViewStyle>;
};
function DemoVideo(props: DemoVideoProps) {
  const {size, source, containerStyle, ...rest} = props;
  const _size = useMemo(() => size || DEFAULT_DEMO_SIZE, [size]);

  const videoRef = React.useRef(null);
  return (
    <View style={[styles.demoVideoWrapper, containerStyle]}>
      <Video
        ref={videoRef}
        source={source}
        //@ts-ignore
        resizeMode={'contain'}
        style={{
          alignSelf: 'center',
          width: size || DEFAULT_DEMO_SIZE,
          height: _size,
        }}
        videoStyle={{
          width: _size,
          height: _size,
        }}
        isMuted={true}
        isLooping={true}
        shouldPlay={true}
        {...rest}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  demoVideoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default DemoVideo;
