import {GuestStats} from '@screens/Dashboard/screens/Stats/components/GuestStats';
import React, {useMemo} from 'react';
import {
  Spacer,
  ThemeProvider,
  Typography,
  useTheme,
} from '@b2cmessenger/doppio-components';
import {SegmentedControl} from '@screens/Dashboard/screens/Stats/components/SegmentedControl';
import {useTranslation} from '@shared';
import {GroupedBarChart} from './GroupedBarChart';
import {shadeColor} from '@utils/color';
import {Text} from 'react-native';
import {generateExampleStatsData} from '@screens/Dashboard/screens/Stats/shared';

export default function StatsExample({width}: {width: number}) {
  const {t} = useTranslation();
  const {colors} = useTheme();

  const statsData = useMemo(() => generateExampleStatsData(), []);

  const themeColorsBlinkedBrand = useMemo(() => {
    return {
      brand: shadeColor(colors.brand, 60),
    };
  }, [colors.brand]);

  const themeColorsGrayBrand = useMemo(() => {
    return {
      brand: shadeColor(colors.lightgray, -10),
    };
  }, [colors.lightgray]);

  return (
    <>
      <ThemeProvider colors={themeColorsBlinkedBrand}>
        <Text style={Typography.smallHeader}>
          {t('Screens.Stats.Example.thisIsAnExampleGuestStat')}
        </Text>
        <Text style={Typography.mediumBody}>
          {t(
            'Screens.Stats.Example.startIssuingStampsToTrackYourCustomerVisits',
          )}
        </Text>
        <Spacer height={8} />
        <GuestStats
          total={statsData.totalGuests}
          returning={statsData.returnedGuests}
        />
      </ThemeProvider>
      <GroupedBarChart.Example
        width={width}
        categories={statsData.categories}
        data={statsData.series}
      />
      <Spacer />
      <ThemeProvider colors={themeColorsGrayBrand}>
        <SegmentedControl value="week">
          <SegmentedControl.Item value="week">
            {t('Screens.Stats.week')}
          </SegmentedControl.Item>
          <SegmentedControl.Item value="month">
            {t('Screens.Stats.month')}
          </SegmentedControl.Item>
          <SegmentedControl.Item value="year">
            {t('Screens.Stats.year')}
          </SegmentedControl.Item>
        </SegmentedControl>
      </ThemeProvider>
    </>
  );
}
