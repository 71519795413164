import React, {
  ComponentProps,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Appbar} from 'react-native-paper';
import {IconProfileAvatar} from '@components/common/icons/SvgIcon';

import {
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
  Animated,
  View,
} from 'react-native';
import {Typography, icons, useTheme} from '@b2cmessenger/doppio-components';
import {isTablet} from 'react-native-device-info';
import {useTranslation} from '@shared';

const {
  smallHeader,
  smallHeaderTablet,
  smallBody,
  mediumBodyTablet,
} = Typography;
const {LogOutIcon, IconOptionsDots} = icons;

const _isTablet = isTablet();
const NAVBAR_HEIGHT = _isTablet ? 66 : 44;

export function QRCodeHeader(props: {
  title: string;
  subtitle?: string;
  image?: string;
  onLogOut?: () => void;
  onPressOptions?: () => void;
  style?: ComponentProps<typeof View>['style'];
  testID?: ComponentProps<typeof Appbar.Header>['testID'];
  titleTestID?: ComponentProps<typeof Animated.Text>['testID'];
  subtitleTestID?: ComponentProps<typeof Animated.Text>['testID'];
  logOutButtonTestID?: ComponentProps<typeof LogOutAction>['testID'];
}) {
  const {colors} = useTheme();
  const {testID, titleTestID, subtitleTestID, logOutButtonTestID} = props;
  const title = 'title' in props ? props.title : undefined;
  const subtitle = 'subtitle' in props ? props.subtitle : undefined;
  const image = 'image' in props ? props.image : undefined;
  const onLogOut = 'onLogOut' in props ? props.onLogOut : undefined;
  const onPressOptions =
    'onPressOptions' in props ? props.onPressOptions : undefined;

  const style = useMemo(
    () => [styles.container, {backgroundColor: colors.white}, props.style],
    [colors.white, props.style],
  );
  const imageStyle = useMemo<ComponentProps<typeof Animated.Image>['style']>(
    () =>
      image
        ? {
            width: NAVBAR_HEIGHT - 2,
            height: NAVBAR_HEIGHT - 2,
            borderRadius: 6,
            opacity: 1,
            marginHorizontal: 1,
            position: 'absolute',
            top: 1,
          }
        : undefined,
    [image],
  );

  const [imageLoaded, setImageLoaded] = useState(false);
  const opacity = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    Animated.timing(opacity, {
      toValue: imageLoaded ? 0 : 1,
      duration: 250,
      useNativeDriver: true,
    }).start();
  }, [imageLoaded, opacity]);

  useEffect(() => {
    setImageLoaded(false);
  }, [image]);

  return (
    <View style={style} testID={testID}>
      <View style={[styles.leftStacked]}>
        <View style={styles.imageContainer}>
          {image ? (
            <Animated.Image
              style={imageStyle}
              source={{uri: image}}
              onLoad={() => {
                setImageLoaded(true);
              }}
            />
          ) : undefined}

          <Animated.View
            style={{opacity, backgroundColor: colors.white, top: 1}}
          >
            <IconProfileAvatar size={NAVBAR_HEIGHT - 2} fill={colors.brand} />
          </Animated.View>
        </View>
        <View style={styles.titleContainer}>
          <Animated.Text
            style={styles.title}
            numberOfLines={1}
            testID={titleTestID}
          >
            {title}
          </Animated.Text>
          {useMemo(
            () =>
              subtitle ? (
                <Animated.Text
                  style={[styles.subTitle, {color: colors.black}]}
                  numberOfLines={1}
                  testID={subtitleTestID}
                >
                  {subtitle}
                </Animated.Text>
              ) : null,
            [colors.black, subtitle, subtitleTestID],
          )}
        </View>
      </View>
      {onLogOut ? (
        <LogOutAction onPress={onLogOut} testID={logOutButtonTestID} />
      ) : null}
      {onPressOptions ? <OptionsAction onPress={onPressOptions} /> : null}
    </View>
  );
}

function LogOutAction(props: TouchableOpacityProps) {
  const {t} = useTranslation();

  const {accessibilityLabel = t('common.logout') || '', ...rest} = props;
  return (
    <TouchableOpacity accessibilityLabel={accessibilityLabel} {...rest}>
      <LogOutIcon size={28} />
    </TouchableOpacity>
  );
}

export function OptionsAction(props: TouchableOpacityProps) {
  const {t} = useTranslation();

  const {accessibilityLabel = t('common.options') || '', ...rest} = props;
  return (
    <TouchableOpacity accessibilityLabel={accessibilityLabel} {...rest}>
      <IconOptionsDots size={28} />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    height: NAVBAR_HEIGHT,
    elevation: 0,
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
  },
  leftStacked: {
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    flexShrink: 1,
  },
  imageContainer: {
    width: NAVBAR_HEIGHT,
    height: NAVBAR_HEIGHT,
  },
  titleContainer: {
    paddingLeft: 10,
    flexGrow: 1,
    flexDirection: 'column',
    paddingRight: 44,
    maxWidth: '100%',
  },
  title: {
    ...StyleSheet.flatten(_isTablet ? smallHeaderTablet : smallHeader),
    flexGrow: 1,
    textAlign: 'left',
  },
  subTitle: {
    ...StyleSheet.flatten(_isTablet ? mediumBodyTablet : smallBody),
    flexGrow: 1,
    textAlign: 'left',
  },
});
