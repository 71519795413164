import React, {
  ComponentProps,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import {Portal, Text} from 'react-native-paper';
import {Typography} from '@b2cmessenger/doppio-components';
import {colors, Logger} from '@b2cmessenger/doppio-shared';
import {IconClose} from '@components/common/icons/SvgIcon';
import useWindowInfo from '@hooks/useWindowInfo';

export type TooltipProps = {
  text: string;
  onClose: () => void;
  styles?: ComponentProps<typeof View>['style'];
};

const anchorSize = 14;
function Tooltip(
  props: TooltipProps & {
    children: ReactNode;
    tooltip: {width: number; height: number};
    visible: boolean;
  },
) {
  const {tooltip, visible, text, onClose} = props;
  const ref = useRef<View>(null);
  const gutter = 10;
  const {width: windowWidth, height: windowHeight} = useWindowInfo();
  const [bb, setBb] = React.useState({x: 0, y: 0, w: 0, h: 0});
  const [isReadyToMeasure, setIsReadyToMeasure] = useState(false);

  const tooltipStyle = useMemo(
    () =>
      tooltip
        ? {
            position: 'absolute',
            width: tooltip.width,
            height: tooltip.height,
            top: bb.y - tooltip.height - anchorSize + 2,
            left: Math.min(
              windowWidth - tooltip.width - gutter,
              Math.max(gutter, bb.x - tooltip.width / 2 + bb.w / 2),
            ),
          }
        : undefined,
    [bb.w, bb.x, bb.y, tooltip, windowWidth],
  );

  const anchor = useMemo<ViewStyle | undefined>(
    () =>
      tooltip
        ? {
            position: 'absolute',
            width: anchorSize,
            height: anchorSize,
            backgroundColor: colors.black,
            top: bb.y - anchorSize * 1.5,
            left: bb.x + bb.w / 2 - anchorSize / 2,
            transform: [{rotate: '45deg'}],
            borderRadius: 3,
          }
        : undefined,
    [bb.w, bb.x, bb.y, tooltip],
  );

  const measure = useCallback(() => {
    ref.current?.measureInWindow((x, y, width, height) => {
      setBb({x, y, w: width, h: height});
    });
  }, []);

  useEffect(() => {
    let t: ReturnType<typeof setTimeout> | undefined;
    if (isReadyToMeasure && tooltip && visible) {
      if (Platform.OS === 'web') {
        t = setTimeout(measure, 300);
      } else {
        measure();
      }
    }

    return () => {
      //@ts-ignore
      clearTimeout(t);
    };
  }, [windowWidth, windowHeight, visible, isReadyToMeasure, tooltip, measure]);

  return (
    <View ref={ref} onLayout={() => setIsReadyToMeasure(true)}>
      {tooltip && visible ? (
        <Portal>
          <TouchableWithoutFeedback onPress={onClose}>
            <View style={[styles.wrapper, tooltipStyle as any]}>
              <Text style={[Typography.mediumBody, styles.text]}>{text}</Text>
              <IconClose style={styles.iconClose} size={28} />
            </View>
          </TouchableWithoutFeedback>
          <View style={anchor as any} />
        </Portal>
      ) : null}
      {props.children}
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: colors.black,
    borderRadius: 16,
    borderColor: colors.lightgray,
    borderWidth: 1,
    padding: 16,
  },
  text: {
    color: colors.white,
    marginRight: 16,
  },
  iconClose: {
    position: 'absolute',
    top: 10,
    right: 6,
  },
});

// eslint-disable-next-line no-spaced-func
const GroupTooltipsContext = React.createContext<{
  idx: number | null | undefined;
  tips: {title: string; isShown: boolean}[];
  next: () => void;
  visible: boolean;
}>({
  idx: null,
  tips: [],
  next: () => {},
  visible: false,
});

type TooltipGroupProps = PropsWithChildren<{
  initialTips: {title: string; isShown: boolean}[];
  initialDisplayDelay?: number;
  onDone?: () => void;
  visible: boolean;
  allShown: boolean;
}>;
function Group({
  initialTips,
  children,
  initialDisplayDelay = 3000,
  onDone,
  visible,
  allShown: isAllShownFromProps,
}: TooltipGroupProps) {
  const [tips, setTips] = useState(initialTips);
  const [currentIdx, setCurrentIdx] = useState<number | null>();
  const [_isAllTooltipsShown, _setIsAllTooltipsShown] = useState(false);
  const [delayedVisibility, setDelayedVisibility] = useState(false);

  useEffect(() => {
    const t = setTimeout(() => setDelayedVisibility(true), initialDisplayDelay);
    return () => {
      clearTimeout(t);
    };
  }, [initialDisplayDelay]);

  useLayoutEffect(() => setTips(initialTips), [initialTips]);
  useEffect(() => {
    if (_isAllTooltipsShown) {
      onDone?.call(null);
    }
  }, [_isAllTooltipsShown, onDone]);

  useEffect(() => {
    setCurrentIdx(isAllShownFromProps ? null : tips.findIndex(v => !v.isShown));
    const countOfShownTooltips = tips.reduce(
      (mem, val) => (val.isShown ? mem + 1 : mem),
      0,
    );

    if (countOfShownTooltips === tips.length && !isAllShownFromProps) {
      _setIsAllTooltipsShown(true);
    }
  }, [isAllShownFromProps, tips]);

  const onNext = useCallback(() => {
    if (typeof currentIdx === 'number') {
      setTips(prev => {
        const newTips = [...prev];
        newTips[currentIdx] = {
          ...prev[currentIdx],
          isShown: true,
        };

        return newTips;
      });
    } else if (currentIdx === null || currentIdx === undefined) {
      Logger.errorTag('Tooltip.Group | onNext', 'currentIdx is ', currentIdx);
    }
  }, [currentIdx]);

  const contextValue = useMemo(
    () => ({
      idx: currentIdx,
      tips,
      next: onNext,
      visible: visible && delayedVisibility,
    }),
    [currentIdx, onNext, tips, visible, delayedVisibility],
  );

  return (
    <GroupTooltipsContext.Provider value={contextValue}>
      {children}
    </GroupTooltipsContext.Provider>
  );
}

Tooltip.Group = Group;

function Item({
  idx: _idx,
  ...props
}: Omit<ComponentProps<typeof Tooltip>, 'onClose' | 'text' | 'visible'> & {
  idx: number;
}) {
  const {idx, tips, next, visible} = useContext(GroupTooltipsContext);
  return (
    <Tooltip
      {...props}
      visible={idx === _idx && visible}
      text={tips[_idx].title}
      onClose={next}
    />
  );
}

Tooltip.Item = React.memo(Item);

export default Tooltip;
