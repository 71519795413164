import {useSelector} from 'react-redux';
import {subscriptionSelectors} from '@store/selectors';

export function useSubscriptionInfo() {
  const pricePerMonthUsd = useSelector(
    subscriptionSelectors.pricePerMonthUsdSelector,
  );
  const numberOfTrialDays = useSelector(
    subscriptionSelectors.numberOfTrialDaysSelector,
  );

  return {pricePerMonthUsd, numberOfTrialDays};
}
