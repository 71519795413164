import AsyncStorage from '@react-native-async-storage/async-storage';
import {SecureStore} from '@utils/SecureStore/shared';

class SecureStoreImpl implements SecureStore {
  get(key: string) {
    return AsyncStorage.getItem(key);
  }
  set(key: string, value: string) {
    return AsyncStorage.setItem(key, value);
  }
  remove(key: string) {
    return AsyncStorage.removeItem(key);
  }
}

const store = new SecureStoreImpl();

export default store;
