import AwardNameEdit, {
  useAwardName,
} from '@components/common/AppearanceCustomizer/AwardNameEdit';
import {PlaceAppearanceConfigExtended} from '@screens/BusinessWizard/slice';
import {
  DEFAULT_AWARD_NAME,
  PossiblePlaceAppearances,
} from '@components/common/AppearanceCustomizer/shared';
import {Platform, StyleSheet, Text, View} from 'react-native';
import {colors} from '@b2cmessenger/doppio-shared';
import React, {useMemo} from 'react';
import {Card, Spacer, Typography} from '@b2cmessenger/doppio-components';
import {useAppearanceCustomizer} from '@components/common/AppearanceCustomizer/index';
import {PlaceAppearance} from '@b2cmessenger/doppio-core';
import {useTranslation} from '@shared';

type AppearanceAwardNameEditProps = {
  appearances: PossiblePlaceAppearances;
  initialConfig: PlaceAppearanceConfigExtended | null;
  config: PlaceAppearanceConfigExtended;
  onChange: (config: Partial<PlaceAppearanceConfigExtended>) => void;
  contentPadding?: number;
  actions: ReturnType<typeof useAppearanceCustomizer>['actions'];
};
function AppearanceAwardNameEdit(props: AppearanceAwardNameEditProps) {
  const {t} = useTranslation();

  const {appearances, initialConfig, config, onChange, contentPadding} = props;

  const awardIdx = useMemo(
    () =>
      appearances.resources.findIndex(
        t => t.type === 'award' && t.id === config.award_resource_id,
      ),
    [appearances.resources, config.award_resource_id],
  );

  const {changeAwardName} = useAwardName({
    appearances,
    changeValue: onChange,
  });
  const initialName = useMemo(
    () => (initialConfig?.award_name ? initialConfig.award_name.trim() : null),
    [initialConfig],
  );
  const configName = useMemo(() => config.award_name, [config.award_name]);
  const nameFromAward = useMemo(() => {
    if (awardIdx === -1) {
      return null;
    }
    return appearances.resources[awardIdx].name.trim();
  }, [appearances.resources, awardIdx]);

  const displayedName = useMemo(() => {
    if (config.award_name) {
      return config.award_name.toLowerCase();
    } else if (nameFromAward) {
      return nameFromAward.toLowerCase();
    } else {
      return DEFAULT_AWARD_NAME.toLowerCase();
    }
  }, [config.award_name, nameFromAward]);

  const icon = useMemo(() => {
    return (
      <PlaceAppearance.Icon
        size={50}
        xml={
          awardIdx === -1
            ? PlaceAppearance.Icon.CoffeeBeanSVGXmlContent
            : appearances.resources[awardIdx].content
        }
        color={config.color || colors.brand}
        contentColor={'#ffffff'}
      />
    );
  }, [appearances.resources, awardIdx, config.color]);

  return (
    <View style={[styles.container, {paddingHorizontal: contentPadding}]}>
      <AwardNameEdit
        initialName={initialName}
        configName={configName || null}
        displayedName={displayedName}
        changeAwardName={changeAwardName}
        nameFromAward={nameFromAward}
        awardId={config.award_resource_id}
        autoCompleteType
      />
      <Spacer />
      <View style={[styles.preview]}>
        <Card style={styles.preview} backgroundColor={colors.black}>
          {icon}
          <Spacer height={8} />
          <Text
            style={[
              Typography.fatBody,
              {
                color: colors.white,
              },
            ]}
          >
            {t('Components.AppearanceAwardNameEdit.grabYour', {
              displayedName: displayedName,
            })}
          </Text>
        </Card>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {flexGrow: 1, flex: 1},
  preview: {width: '100%', alignItems: 'center', justifyContent: 'center'},
  codeBlock: {
    opacity: Platform.OS !== 'android' ? 0.7 : 1,
    minHeight: 100,
    maxHeight: 120,
  },
});

export default AppearanceAwardNameEdit;
