import {useTheme} from '@b2cmessenger/doppio-components';
import {useMemo} from 'react';
import {StyleSheet, View} from 'react-native';
import {ProgressBar} from 'react-native-paper';

type NavbarProps = {
  indexes: number[];
  currentIndex: number;
};
function ProgressNavbar({indexes, currentIndex}: NavbarProps) {
  const {colors} = useTheme();
  const navbar = useMemo(() => {
    const length = indexes.length;

    return (
      <View style={styles.header}>
        {indexes.map(val => (
          <View style={{flex: 1 / length, marginHorizontal: 10}} key={val}>
            <ProgressBar
              progress={currentIndex >= val ? 1 : 0}
              color={colors.middlegray}
            />
          </View>
        ))}
      </View>
    );
  }, [indexes, currentIndex, colors.middlegray]);

  return navbar;
}

const styles = StyleSheet.create({
  header: {
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
});

export {ProgressNavbar};
