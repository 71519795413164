import React, {useContext, useMemo} from 'react';
import WorkplaceContext from '@components/common/WorkplaceContext';
import {
  Button,
  Card,
  Spacer,
  Typography,
  useTheme,
} from '@b2cmessenger/doppio-components';
import {Platform, StyleSheet, Text} from 'react-native';
import {Logger} from '@b2cmessenger/doppio-shared';
import {Fade, Placeholder, PlaceholderLine} from 'rn-placeholder';
import {
  CustomerMessagesCardStates,
  getCustomerMessagesState,
  useMessageToCustomers,
} from '@screens/Dashboard/screens/Stats/Messages/utils';
import {useTranslation} from '@b2cmessenger/doppio-core';

const SPACE_HEIGHT = 4;
type CustomerMessagesCardProps = {
  goToMessages: () => void;
  totalCount: number | null;
};
export function CustomerMessagesCard(props: CustomerMessagesCardProps) {
  const {
    goToMessages = () => {
      Logger.verboseTag('CustomerMessagesCard', 'go to messages');
    },
    totalCount,
  } = props;
  const {t} = useTranslation();
  const {colors} = useTheme();
  const workplace = useContext(WorkplaceContext);
  const {inactiveCustomers, isAbleToSend, sendMessageNextInHours} =
    useMessageToCustomers(workplace.id);
  const state = useMemo(() => {
    return getCustomerMessagesState({
      totalCount,
      inactiveCustomers: inactiveCustomers,
      isAbleToSend,
    });
  }, [inactiveCustomers, isAbleToSend, totalCount]);

  const content = useMemo(() => {
    const buttonText = t('Screens.Stats.CustomerMessages.Card.buttonText');

    switch (state) {
      case CustomerMessagesCardStates.loading: {
        return <CustomerMessagesCardsPlaceholder />;
      }
      case CustomerMessagesCardStates.readyToSend: {
        const title = t('Screens.Stats.CustomerMessages.Card.guests', {
          count: inactiveCustomers,
        });
        const description = t(
          'Screens.Stats.CustomerMessages.Card.haventComeToYouForALongTimeLetsSendThemAMessage',
        );

        return (
          <>
            <Text style={[Typography.smallHeader]}>{title}</Text>
            <Spacer height={SPACE_HEIGHT} />
            <Text style={[Typography.mediumBody]}>{description}</Text>
            <Spacer />
            <Button
              title={buttonText}
              onPress={() => goToMessages?.call(null)}
            />
          </>
        );
      }
      case CustomerMessagesCardStates.waiting: {
        const title = t('Screens.Stats.CustomerMessages.Card.guests', {
          count: inactiveCustomers,
        });
        const description = t(
          'Screens.Stats.CustomerMessages.Card.haventComeToYouForALongTimeLetsSendThemAMessage',
        );
        const days = Math.ceil(sendMessageNextInHours / 24);
        const buttonDescription = t(
          'Screens.Stats.CustomerMessages.Card.nextTimeYouCanSendAMessageInDays',
          {count: days},
        );
        return (
          <>
            <Text style={[Typography.smallHeader]}>{title}</Text>
            <Spacer height={SPACE_HEIGHT} />
            <Text style={[Typography.mediumBody]}>{description}</Text>
            <Spacer />
            <Button title={buttonText} disabled />
            <Spacer />
            <Text
              style={[
                Typography.smallBody,
                {color: colors.darkgray, textAlign: 'center'},
              ]}>
              {buttonDescription}
            </Text>
          </>
        );
      }
      case CustomerMessagesCardStates.haveNoGuests: {
        const title = t(
          'Screens.Stats.CustomerMessages.Card.youHaveNoGuestsYet',
        );
        const description = t(
          'Screens.Stats.CustomerMessages.Card.whenYouHaveGuestsYouWillBeAbleToSendThemAMessage',
        );

        return (
          <>
            <Text style={[Typography.smallHeader]}>{title}</Text>
            <Spacer height={SPACE_HEIGHT} />
            <Text style={[Typography.mediumBody]}>{description}</Text>
            <Spacer />
            <Button title={buttonText} disabled />
          </>
        );
      }
      case CustomerMessagesCardStates.allGuestsActive: {
        const title = t('Screens.Stats.CustomerMessages.Card.congratulations');
        const description = t(
          'Screens.Stats.CustomerMessages.Card.allOfYourGuessVisitYouRegularly',
        );
        const description2 = t(
          'Screens.Stats.CustomerMessages.Card.ifSomeoneDoesNotVisitYouForALongTimeYouCanSendThem',
        );

        return (
          <>
            <Text style={[Typography.smallHeader]}>{title}</Text>
            <Spacer height={SPACE_HEIGHT} />
            <Text style={[Typography.mediumBody]}>{description}</Text>
            <Spacer height={SPACE_HEIGHT} />
            <Text style={[Typography.mediumBody]}>{description2}</Text>
            <Spacer />
            <Button title={buttonText} disabled />
          </>
        );
      }
    }
  }, [
    colors.darkgray,
    goToMessages,
    inactiveCustomers,
    sendMessageNextInHours,
    state,
    t,
  ]);

  return <Card>{content}</Card>;
}

const PLACEHOLDER_LINE_HEIGHT = 21;
const PLACEHOLDER_LINE_WIDTH = 80;
function CustomerMessagesCardsPlaceholder() {
  return (
    <Placeholder Animation={Fade}>
      <PlaceholderLine
        noMargin
        width={PLACEHOLDER_LINE_WIDTH * 0.4}
        height={PLACEHOLDER_LINE_HEIGHT}
      />
      <Spacer height={SPACE_HEIGHT} />
      <PlaceholderLine
        noMargin
        width={PLACEHOLDER_LINE_WIDTH * 0.8}
        height={PLACEHOLDER_LINE_HEIGHT}
      />
      <Spacer height={SPACE_HEIGHT} />
      <PlaceholderLine
        noMargin
        width={PLACEHOLDER_LINE_WIDTH}
        height={PLACEHOLDER_LINE_HEIGHT}
      />
      <Spacer />
      <Button title={''} loading disabled />
    </Placeholder>
  );
}
