import React, {
  ComponentProps,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  Platform,
  RefreshControl,
  SectionList,
  SectionListData,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import {DeleteButton, IconButton} from '@components/common/IconButton';
import {
  IconAddEmployee,
  IconAddPosTablet,
  IconEmployee,
  IconPosTablet,
} from '@components/common/icons/SvgIcon';
import {
  Screen,
  Spacer,
  Typography,
  useTheme,
} from '@b2cmessenger/doppio-components';
import {NoDevicesAndEmployeesPlaceholder} from '@screens/Dashboard/screens/Profile/components/NoDevicesAndEmployeesPlaceholder';
import {navigationRef} from '@navigation/navigationRef';
import {PlaceEmployeeSearchResultEmployee} from '@typings/Place';
import WorkplaceContext from '@components/common/WorkplaceContext';
import {usePlaceEmployees} from './hooks/usePlaceEmployees';
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
} from 'rn-placeholder';
import {loginedUserSelectors} from '@store/selectors';
import {useSelector} from 'react-redux';
import {usePlaceEmployeeRemoval} from '@screens/Dashboard/screens/Profile/hooks/usePlaceEmployeeRemoval';
import {useNotifications} from '@components/hooks/useNotifications';
import {isEmployeeInviteAcceptNotification} from '@typings/Notification';
import {TEST_IDS} from './DevicesAndEmployeesTestIDS';
import {localization, useTranslation} from '@shared';

export function DevicesAndEmployees() {
  const {t} = useTranslation();

  const {colors} = useTheme();
  const {id: loginedUserId} = useSelector(
    loginedUserSelectors.profile,
  ) as Exclude<ReturnType<typeof loginedUserSelectors.profile>, null>;
  const {id: workplaceId} = useContext(WorkplaceContext);
  const {data: rawEntities, error, refresh} = usePlaceEmployees(workplaceId);

  const entities = useMemo(
    () => rawEntities?.filter(user => user.id !== loginedUserId),
    [loginedUserId, rawEntities],
  );

  const onAddEmployeeDevice = useCallback(
    () => navigationRef.current?.navigate('BusinessInvite', {role: 'employee'}),
    [],
  );
  const onAddPOSDevice = useCallback(() => {
    navigationRef.current?.navigate('BusinessInvite', {role: 'pos'});
  }, []);

  const onEmployeeRemoved = useCallback(
    (id: number) => {
      if (entities) {
        // noinspection JSIgnoredPromiseFromCall
        /** manually remove employee from fetched data,
         * then trigger data revalidation (data fetch)
         */
        refresh(entities.filter(({id: eId}) => eId !== id));
      }
    },
    [entities, refresh],
  );
  const {onDelete, removingEmployeeId} = usePlaceEmployeeRemoval({
    placeId: workplaceId,
    onEmployeeRemoved,
  });

  useNotifications(
    useCallback(
      notification => {
        if (isEmployeeInviteAcceptNotification(notification)) {
          refresh();
        }
      },
      [refresh],
    ),
  );

  const buttons = useMemo<
    SectionListData<ComponentProps<typeof IconButton> | {}>
  >(
    () => ({
      key: 'buttons',
      keyExtractor: item => ('label' in item ? item.label : 'placeholder'),
      data: (entities?.length === 0 ? [{}] : []).concat([
        {
          fill: colors.brand,
          testID: TEST_IDS.buttons.AddPosTablet,
          label: t('Screens.Profile.DevicesAndEmployees.addPosTablet'),
          IconComponent: IconAddPosTablet,
          onPress: onAddPOSDevice,
        },
        {
          fill: colors.brand,
          testID: TEST_IDS.buttons.AddEmployeeDevice,
          label: t('Screens.Profile.DevicesAndEmployees.addEmployeeDevice'),
          IconComponent: IconAddEmployee,
          onPress: onAddEmployeeDevice,
        },
      ]),
      renderItem: ({item: props}) =>
        !('label' in props) ? (
          <>
            <Spacer height={40} />
            <NoDevicesAndEmployeesPlaceholder />
            <Spacer height={16} />
          </>
        ) : (
          <IconButton {...props} />
        ),
    }),
    [colors.brand, entities, onAddEmployeeDevice, onAddPOSDevice, t],
  );
  const added = useMemo<SectionListData<any> | null>(
    () =>
      error
        ? {
            key: 'error',
            data: [error],
            renderItem: ({item}) => (
              <>
                <Text style={Typography.smallHeader}>
                  {t(
                    'Screens.Profile.DevicesAndEmployees.unableToLoadDevicesAndEmployees',
                  )}
                </Text>
                <Spacer />
                <Text style={Typography.mediumBody}>{item}</Text>
              </>
            ),
          }
        : !entities
        ? {
            key: 'added-loading',
            keyExtractor: ({id}: {id: number}) => String(id),
            data: [{id: 0}, {id: 1}, {id: 2}, {id: 3}],
            renderItem: ({index}) => (
              <Placeholder Animation={Fade}>
                <View style={placeholderStyles.row}>
                  <PlaceholderMedia size={28} />
                  <PlaceholderLine
                    width={65 + index * 5 - (index % 2) * 5}
                    height={24}
                    style={placeholderStyles.label}
                    noMargin
                  />
                </View>
              </Placeholder>
            ),
          }
        : entities.length === 0
        ? null
        : {
            key: 'added',
            keyExtractor: ({id}: {id: number}) => String(id),
            data: entities,
            renderItem: ({item}) => (
              <IconButton
                disabled
                fill={colors.brand}
                label={getEntityName(item)}
                IconComponent={isDevice(item) ? IconPosTablet : IconEmployee}
                children={
                  <DeleteButton
                    loading={removingEmployeeId === item.id}
                    disabled={removingEmployeeId !== undefined}
                    onPress={() => {
                      onDelete(item);
                    }}
                  />
                }
              />
            ),
          },
    [colors.brand, entities, error, onDelete, removingEmployeeId, t],
  );

  const sections = useMemo<ReadonlyArray<SectionListData<any, any>>>(
    () => (added !== null ? [buttons, added] : [buttons]),
    [buttons, added],
  );

  const renderSectionHeader = useCallback(
    ({section}: {section: SectionListData<any>}) =>
      section.key === 'added' ? (
        <View style={styles.sectionHeader}>
          <Text style={Typography.smallHeader}>
            {t('Screens.Profile.DevicesAndEmployees.added')}
          </Text>
        </View>
      ) : (
        <Spacer height={20} />
      ),
    [t],
  );

  const [refreshing, setRefreshing] = useState(false);
  const onRefresh = useCallback(async () => {
    try {
      setRefreshing(true);
      await refresh();
    } finally {
      setRefreshing(false);
    }
  }, [refresh]);

  const refreshControl = useMemo(
    () => (
      <RefreshControl
        colors={[colors.brand]}
        tintColor={colors.brand}
        refreshing={refreshing}
        onRefresh={onRefresh}
      />
    ),
    [colors.brand, onRefresh, refreshing],
  );

  return (
    <Screen style={styles.screen} testID={TEST_IDS.screen}>
      <SectionList
        contentContainerStyle={styles.listContainer}
        refreshControl={refreshControl}
        stickySectionHeadersEnabled={false}
        renderSectionHeader={renderSectionHeader}
        sections={sections}
      />
    </Screen>
  );
}

const styles = StyleSheet.create({
  screen: {paddingHorizontal: 0},
  listContainer: {
    paddingHorizontal: Screen.PADDING_HORIZONTAL,
    ...(Platform.OS === 'web' ? StyleSheet.absoluteFillObject : {}),
  },
  sectionHeader: {
    height: 64,
    justifyContent: 'flex-end',
  },
});

const placeholderStyles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
  },
  label: {
    marginLeft: 8,
  },
});

function isDevice(
  user: Pick<PlaceEmployeeSearchResultEmployee['user'], 'is_device'>,
) {
  return user.is_device === 1;
}

function getEntityName(item: PlaceEmployeeSearchResultEmployee['user']) {
  const unknownDevice = localization.t(
    'Screens.Profile.DevicesAndEmployees.unknownDevice',
  );
  const unknownPerson = localization.t(
    'Screens.Profile.DevicesAndEmployees.unknownPerson',
  );
  const _unknown = isDevice(item) ? unknownDevice : unknownPerson;

  return [item.firstname, item.lastname].filter(Boolean).join(' ') || _unknown;
}
