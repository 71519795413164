import {Screen, Spacer} from '@b2cmessenger/doppio-components';
import {ColorValue, Linking, StyleSheet, Text} from 'react-native';
import React, {useContext, useMemo} from 'react';
import {AppearanceStages} from '@components/common/AppearanceCustomizer/slice';
import {Paragraph} from 'react-native-paper';
import {useTranslation} from '@shared';
import WorkplaceContext from '@components/common/WorkplaceContext';

const SpacerParagraphHeight = 4;
const SupportEmail = 'hello@getdoppio.com';

type CustomizeOptionsContentProps = {
  spacerParagraphHeight?: number;
  brandColor: ColorValue;
  stage?: AppearanceStages;
};

export function AppearanceCustomizeInfo(props: CustomizeOptionsContentProps) {
  const {
    spacerParagraphHeight = SpacerParagraphHeight,
    brandColor,
    stage = AppearanceStages.picker,
  } = props;
  const {t} = useTranslation();
  const {id: placeId} = useContext(WorkplaceContext);

  const content = useMemo(() => {
    switch (stage) {
      case AppearanceStages.picker:
        return (
          <>
            <Screen.Heading>
              {t('Components.AppearanceCustomizeInfo.customizeOptions')}
            </Screen.Heading>
            <Spacer height={spacerParagraphHeight} />
            <Paragraph>
              {t(
                'Components.AppearanceCustomizeInfo.customizeTheMainColorOfTheApplicationAndIconsOfSta',
              )}
            </Paragraph>
            <Spacer height={spacerParagraphHeight} />
            <Paragraph>
              {t(
                'Components.AppearanceCustomizeInfo.didntFindASuitableIconDropUsALineAt',
                {expr: '\n'},
              )}{' '}
              <Text
                style={[styles.link, {color: brandColor}]}
                onPress={() =>
                  Linking.openURL(
                    `mailto:${SupportEmail}?subject=${encodeURIComponent(
                      `Custom icons (${placeId})`,
                    )}`,
                  )
                }>
                {SupportEmail}
              </Text>
            </Paragraph>
          </>
        );
      default:
        return null;
    }
  }, [brandColor, placeId, spacerParagraphHeight, stage, t]);

  return content;
}

const styles = StyleSheet.create({
  link: {
    textDecorationLine: 'underline',
  },
});
