import {Notification} from '@typings/Notification';

class InitialNotificationRef {
  initialNotification: Notification | null = null;

  set(value: Notification | null) {
    this.initialNotification = value;
  }
}

export default new InitialNotificationRef();
