import {PossiblePlaceAppearances} from '@typings/Place';
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
} from 'rn-placeholder';
import React, {useCallback, useMemo} from 'react';
import {Spacer, useTheme, Screen} from '@b2cmessenger/doppio-components';
import {PlaceAppearance} from '@b2cmessenger/doppio-core';
import {IconButton} from '@components/common/IconButton';
import {Platform, StyleSheet, View} from 'react-native';
import {PossibleBusinessExtended} from '@screens/BusinessWizard/shared';
import {FlatListWithShadow, ScrollViewWithShadow} from '@shared';

const ICON_SIZE = 36;
const SHADOW_SIZE = 15;
const SHADOW_BOTTOM = -1;

type SelectBusinessTypeProps = {
  onSelectType: (id: number) => void;
  selectedTypeId: number | null;
  businessIdWithCoffeeIcon?: number;
  businessTypes: PossibleBusinessExtended[];
  resources: PossiblePlaceAppearances['resources'] | undefined;
  defaultStampId: number | null;
};
function SelectBusinessType(props: SelectBusinessTypeProps) {
  if (!props.resources) {
    return (
      <Placeholder Animation={Fade}>
        <BusinessTypesListPlaceholder />
      </Placeholder>
    );
  }

  return <BusinessTypesList {...props} />;
}

function BusinessTypesList(props: SelectBusinessTypeProps) {
  const {
    onSelectType,
    businessTypes,
    resources,
    selectedTypeId,
    businessIdWithCoffeeIcon,
    defaultStampId,
  } = props;

  const {colors} = useTheme();
  const defaultStampContent = useMemo(() => {
    return resources?.find(t => t.id === defaultStampId && t.type === 'stamp')
      ?.content;
  }, [defaultStampId, resources]);

  const getIconByBusinessTypeId = useCallback(
    (businessTypeId: number) => {
      if (
        businessIdWithCoffeeIcon &&
        businessTypeId === businessIdWithCoffeeIcon
      ) {
        return PlaceAppearance.Icon.CoffeeBeanSVGXmlContent;
      }

      const contentByBusinessId = resources?.find(
        t =>
          t.business_id === businessTypeId &&
          t.is_main_for_business &&
          t.type === 'stamp',
      )?.content;
      return (
        contentByBusinessId ??
        defaultStampContent ??
        PlaceAppearance.Icon.CoffeeBeanSVGXmlContent
      );
    },
    [businessIdWithCoffeeIcon, resources, defaultStampContent],
  );

  const settingsListData = useMemo(() => {
    return businessTypes.map(type => {
      const icon = () => {
        const xml = getIconByBusinessTypeId(type.id);
        const active = selectedTypeId === null || type.id === selectedTypeId;

        return (
          <PlaceAppearance.Icon
            size={ICON_SIZE}
            xml={xml}
            color={active ? colors.brand : '#ffffff'}
            contentColor={active ? '#ffffff' : '#cfd4d9'}
            strokeColor={active ? undefined : '#cfd4d9'}
          />
        );
      };

      return {
        id: type.id,
        fill: colors.brand,
        label: type.name,
        IconComponent: icon,
        caption: type.description,
        onPress: () => onSelectType.call(null, type.id),
      };
    });
  }, [
    businessTypes,
    colors.brand,
    getIconByBusinessTypeId,
    onSelectType,
    selectedTypeId,
  ]);

  if (Platform.OS === 'web') {
    return (
      <View style={styles.webContainer}>
        <ScrollViewWithShadow
          style={{paddingHorizontal: Screen.PADDING_HORIZONTAL}}
          shadowSize={SHADOW_SIZE}
          shadowBottom={SHADOW_BOTTOM}
        >
          {settingsListData.map(item => {
            return <IconButton {...item} key={item.id} />;
          })}
        </ScrollViewWithShadow>
      </View>
    );
  }

  return (
    <FlatListWithShadow
      containerStyles={{
        marginHorizontal: -Screen.PADDING_HORIZONTAL,
      }}
      ListFooterComponent={<Spacer height={8} />}
      data={settingsListData}
      // @ts-ignore
      renderItem={({item: _props}) => <IconButton {..._props} />}
      // @ts-ignore
      keyExtractor={item => item.label}
      contentContainerStyle={styles.listContainer}
      scrollEnabled
    />
  );
}

const PLACEHOLDER_LINE_HEIGHT = 21;
const PLACEHOLDER_LINE_WIDTH = 100;
const PLACEHOLDER_MEDIA_SIZE = 28;
const PLACEHOLDER_SPACER = 20;
function BusinessTypesListPlaceholder() {
  const rows = [];
  for (let i = 0; i < 11; i++) {
    rows.push(
      <View key={i} style={styles.placeholderRow}>
        <PlaceholderMedia
          size={PLACEHOLDER_MEDIA_SIZE}
          style={styles.placeHolderMedia}
        />
        <Spacer height={PLACEHOLDER_SPACER} />
        <PlaceholderLine
          noMargin
          width={PLACEHOLDER_LINE_WIDTH * (i % 2 ? 0.6 : 0.5)}
          height={PLACEHOLDER_LINE_HEIGHT}
        />
      </View>,
    );
  }

  return (
    <Placeholder style={styles.placeholderContainer} Animation={Fade}>
      {rows}
    </Placeholder>
  );
}

const styles = StyleSheet.create({
  webContainer: {
    flex: 1,
    marginHorizontal: -Screen.PADDING_HORIZONTAL,
  },
  placeHolderMedia: {
    marginRight: 12,
    borderRadius: 100,
    marginLeft: 2,
  },
  placeholderContainer: {
    flexDirection: 'column',
  },
  placeholderRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    height: 60,
  },
  listContainer: {
    ...(Platform.OS === 'web' ? StyleSheet.absoluteFillObject : {}),
    paddingHorizontal: Screen.PADDING_HORIZONTAL,
  },
});

export default SelectBusinessType;
