import {
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
} from 'react-native';
import {Typography} from '@b2cmessenger/doppio-components';
import {colors} from '@b2cmessenger/doppio-shared';
import {
  IconPen,
  IconPhoto,
  IconProfileAvatar,
} from '@components/common/icons/SvgIcon';
import React from 'react';
import {ActivityIndicator} from 'react-native';

export function PlaceAvatar({
  onPress,
  uri,
  disabled,
  loading,
  iconFill,
}: TouchableOpacityProps & {
  uri?: string;
  loading?: boolean;
  iconFill?: string;
}) {
  return (
    <View style={placeAvatarStyles.container}>
      <TouchableOpacity
        disabled={disabled || !onPress}
        onPress={onPress}
        style={placeAvatarStyles.opacityContainer}>
        {uri ? (
          <Image
            style={{
              width: 112,
              height: 112,
              borderRadius: 16,
              opacity: disabled ? 0.75 : 1,
            }}
            source={{uri}}
          />
        ) : (
          <IconProfileAvatar
            fill={iconFill}
            size={112}
            style={{opacity: disabled ? 0.75 : 1}}
          />
        )}
        {onPress ? (
          <IconPhoto
            fill={iconFill}
            size={28}
            style={placeAvatarStyles.avatarCameraIcon}
            onPress={onPress}
            disabled={Boolean(disabled)}
          />
        ) : null}
        {loading ? (
          <ActivityIndicator
            style={StyleSheet.absoluteFillObject}
            color={colors.white}
            size={'large'}
          />
        ) : null}
      </TouchableOpacity>
    </View>
  );
}

const placeAvatarStyles = StyleSheet.create({
  container: {alignItems: 'center'},
  opacityContainer: {
    justifyContent: 'center',
    position: 'relative',
    width: 112,
    borderRadius: 16,
  },
  avatarCameraIcon: {position: 'absolute', top: -4, right: -4},
});

export function PlaceName({
  onPress,
  name,
  iconFill,
  ...rest
}: TouchableOpacityProps & {
  name: string;
  iconFill?: string;
}) {
  return (
    <View style={placeNameStyles.container}>
      <TouchableOpacity
        onPress={onPress}
        style={placeNameStyles.opacityContainer}
        {...rest}>
        <Text style={Typography.header}>{name}</Text>
        {rest.disabled ? null : (
          <IconPen size={28} style={placeNameStyles.icon} fill={iconFill} />
        )}
      </TouchableOpacity>
    </View>
  );
}

const placeNameStyles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  opacityContainer: {
    position: 'relative',
    paddingHorizontal: 30,
  },
  icon: {position: 'absolute', top: 2, right: 0},
});
