import React, {useCallback, useEffect, useMemo, useReducer} from 'react';
import {StyleSheet, Platform} from 'react-native';
import {
  Button,
  Navbar,
  TextDivider,
  Spacer,
  Screen,
  Pusher,
} from '@b2cmessenger/doppio-components';
import {api, Analytics} from '@b2cmessenger/doppio-core';
import {Logger} from '@b2cmessenger/doppio-shared';

import {RootNavigatorScreenProps} from '@navigation/config';
import {
  PrettyQRCode,
  PrettyQRCodePlaceholder,
} from '@components/common/PrettyQRCode';
import {buildInviteLink} from '@utils/DynamicLinks/buildInviteLink';
import {shareURL} from '@utils/shareURL';
import {Fade, Placeholder, PlaceholderLine} from 'rn-placeholder';
import {useSelector} from 'react-redux';
import {loginedUserSelectors} from '@store/selectors';
import {slice} from './slice';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useNotifications} from '@components/hooks/useNotifications';
import {isEmployeeInviteAcceptNotification} from '@typings/Notification';
import {useIsFocused} from '@react-navigation/native';
import {isTablet} from 'react-native-device-info';
const _isTablet = isTablet();
import {TEST_IDS} from './BusinessInviteTestIDS';
import {Alert} from '@components/common/Alert';
import {useTranslation} from '@shared';

const isShareSupported = Platform.OS !== 'web' || !!navigator.share;

async function invite(role: 'employee' | 'pos', placeId: number) {
  return api.default
    .post<{invite_code: string}>(`/api/v2/place/${placeId}/employee/invite`, {
      roles: [7],
      is_device: role === 'pos',
    })
    .then(api.getResponseData);
}

export function BusinessInvite({
  navigation,
  route,
}: RootNavigatorScreenProps<'BusinessInvite'>) {
  const {t} = useTranslation();
  const isFocused = useIsFocused();
  const {bottom} = useSafeAreaInsets();
  const {role} = route.params;
  const workplace = useSelector(loginedUserSelectors.workplace) as Exclude<
    ReturnType<typeof loginedUserSelectors.workplace>,
    null
  >;

  const onBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('Dashboard', {
        screen: 'Profile',
        params: {
          screen: 'DevicesAndEmployees',
        },
      });
    }
  }, [navigation]);

  const navbar = useMemo(() => {
    const _title =
      role === 'employee'
        ? t('Screens.BusinessInvite.addEmployee')
        : t('Screens.BusinessInvite.addDevice');
    return <Navbar title={_title} onBack={onBack} />;
  }, [onBack, role, t]);

  const [state, dispatch] = useReducer(
    slice.reducer,
    slice.reducer(undefined, {type: '@@DUMMY'}),
  );

  const send = useCallback(async () => {
    try {
      dispatch(slice.actions.send());
      const {invite_code} = await invite(role, workplace.id);
      const link = await buildInviteLink({
        type: role,
        placeId: workplace.id,
        inviteCode: invite_code,
      });
      Analytics.logEvent('create_business_invite_link', {role});
      dispatch(slice.actions.done(role, link));
    } catch (e) {
      dispatch(slice.actions.sendFailed());
      Logger.errorTag('BusinessInvite', e);
    }
  }, [role, workplace.id]);

  useNotifications(
    useCallback(
      notification => {
        if (isEmployeeInviteAcceptNotification(notification) && isFocused) {
          onBack();
        }
      },
      [onBack, isFocused],
    ),
  );

  const shareInviteLink = useCallback(() => {
    if (state.inviteLink) {
      Analytics.logEvent('share_business_invite_link');

      if (isShareSupported) {
        shareURL(state.inviteLink, {});
      } else {
        if (navigator.clipboard) {
          navigator.clipboard.writeText(state.inviteLink).then(() => {
            Alert.alert(
              t('Screens.BusinessInvite.inviteLinkSuccessfullyCopied'),
            );
          });
        } else {
          Alert.alert(
            t('Screens.BusinessInvite.copyingALinkIsNotAvailableScanQrInstead'),
          );
        }
      }
    }
  }, [state.inviteLink, t]);

  useEffect(() => {
    send();
  }, [send]);

  return (
    <Screen navbar={navbar} testID={TEST_IDS.screen}>
      {state.stage === 'send' ? (
        <Placeholder Animation={Fade} testID={TEST_IDS.elements.Placeholder}>
          <Spacer height={24} />
          <PlaceholderLine width={(231 / 375) * 100} height={24} noMargin />
          <Spacer height={6} />
          <PlaceholderLine width={(150 / 375) * 100} height={24} noMargin />
          <Spacer />
          <PlaceholderLine width={(218 / 375) * 100} height={16} noMargin />
        </Placeholder>
      ) : (
        <>
          <Screen.Heading>{state.header}</Screen.Heading>
          <Screen.Description>{state.description}</Screen.Description>
        </>
      )}
      {state.stage === 'done' && state.inviteLink !== null ? (
        <>
          {_isTablet ? <Spacer height={40} /> : null}
          <PrettyQRCode
            testID={TEST_IDS.elements.QRCode}
            accessibilityValue={{text: state.inviteLink}}
            style={styles.qrCode}
            value={state.inviteLink}
            size={_isTablet ? 300 : 227}
          />
          <Pusher />
          <TextDivider text="or" style={styles.textDivider} />
          <Button.Secondary
            testID={TEST_IDS.buttons.Share}
            onPress={shareInviteLink}
            title={
              isShareSupported
                ? t('Screens.BusinessInvite.sendInviteLinkToEmployee')
                : t('Screens.BusinessInvite.copyInviteLink')
            }
          />
        </>
      ) : state.stage === 'sendFailed' ? (
        <>
          <Pusher />
          <Button.Primary
            testID={TEST_IDS.buttons.Retry}
            title={state.primaryButtonText}
            onPress={send}
          />
        </>
      ) : (
        <>
          <Placeholder Animation={Fade}>
            <PrettyQRCodePlaceholder style={styles.qrCode} />
          </Placeholder>
          <Pusher />
          <Placeholder Animation={Fade}>
            <PlaceholderLine height={48} noMargin />
          </Placeholder>
        </>
      )}
      <Spacer height={Math.max(bottom, 16)} />
    </Screen>
  );
}

const styles = StyleSheet.create({
  qrCode: {
    marginTop: 16,
    alignSelf: 'center',
  },
  textDivider: {
    marginVertical: 24,
  },
});
