import DynamicLinks from './index';
import {DynamicLinksConfig, InviteLink} from './shared';
import type {FirebaseDynamicLinksTypes} from '@react-native-firebase/dynamic-links';

export function buildInviteLink(options: {
  type: 'employee' | 'pos';
  placeId: number;
  inviteCode: string;
}) {
  const inviteLink = new InviteLink(
    options.type,
    options.placeId,
    options.inviteCode,
  );
  const dynamicLinkParameters: FirebaseDynamicLinksTypes.DynamicLinkParameters = {
    domainUriPrefix: DynamicLinksConfig.Business.DomainURIPrefix,
    link: inviteLink.build(),
    android: {
      packageName: DynamicLinksConfig.Business.AndroidPackageName,
    },
    ios: {
      appStoreId: DynamicLinksConfig.Business.IosAppStoreId,
      bundleId: DynamicLinksConfig.Business.IosBundleId,
    },
    navigation: {
      forcedRedirectEnabled: true,
    },
  };

  return DynamicLinks.buildShortLink(dynamicLinkParameters);
}
