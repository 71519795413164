import React from 'react';
import {Text} from 'react-native';
import {Spacer, Typography} from '@b2cmessenger/doppio-components';
import {DEFAULT_SPACE_HEIGHT} from '@widgets/Onboarding/lib';
import {OnboardingView} from '@widgets/Onboarding/ui/OnboardingView';

import {useTranslation} from '@shared';
import {colors} from '@b2cmessenger/doppio-shared';
//@ts-ignore
import image from '../../../../../assets/onboarding/images/stat_customer_list.png';

export function CustomersStory() {
  const {t} = useTranslation();
  const header = (
    <>
      <Text style={Typography.header}>
        {t('Widgets.Onboarding.Stats.theStatistics')}
      </Text>
      <Spacer height={DEFAULT_SPACE_HEIGHT} />
      <Text style={Typography.smallHeader}>
        {t('Widgets.Onboarding.Customer.customerListWithStampAndRewardCount')}
      </Text>
      <Spacer height={DEFAULT_SPACE_HEIGHT} />
      <Text style={[Typography.mediumBody, {color: colors.darkgray}]}>
        {t('Widgets.Onboarding.Customer.guestReminder')}
      </Text>
      <Spacer height={DEFAULT_SPACE_HEIGHT} />
    </>
  );

  return <OnboardingView header={header} imageSource={image} />;
}
