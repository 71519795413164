import React from 'react';
import {useCallback, useMemo, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {Button} from '@b2cmessenger/doppio-components';
import {ProgressNavbar} from '@features/Story/ui/ProgressNavbar';
import {useScreenPrimaryButtonStyle} from '@components/hooks/useScreenPrimaryButtonStyle';
import {useTranslation} from '@shared';

type UseStoryProps = {
  indexes: number[];
};
function useStory({indexes}: UseStoryProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const length = indexes.length;

  const onNext = useCallback(() => {
    setCurrentIndex(prev => (prev < length - 1 ? prev + 1 : length));
  }, [length]);
  const onPrev = useCallback(() => {
    setCurrentIndex(prev => (prev > 0 ? prev - 1 : 0));
  }, []);

  const progressNavbar = useMemo(() => {
    return <ProgressNavbar indexes={indexes} currentIndex={currentIndex} />;
  }, [currentIndex, indexes]);

  return {index: currentIndex, progressNavbar, onNext, onPrev};
}

type UseControlsProps = {
  onPrev?: () => void;
  onNext: () => void;
  onDone?: () => void;
  index: number;
  length: number;
  doneText?: string;
};

function useControls(props: UseControlsProps) {
  const {t} = useTranslation();
  const {
    onPrev,
    onNext,
    onDone,
    index,
    length,
    doneText = t('Features.useControls.finish'),
  } = props;
  const pbs = useScreenPrimaryButtonStyle();

  const controls = useMemo(() => {
    return (
      <View style={controlsStyles.container}>
        {onPrev ? (
          <Button.Secondary
            title={t('Features.useControls.prev')}
            onPress={() => onPrev?.call(null)}
            disabled={index === 0}
            style={[pbs, controlsStyles.button]}
          />
        ) : null}
        <Button.Primary
          title={
            (index === length - 1
              ? doneText
              : t('Features.useControls.next')) || 'next'
          }
          onPress={() =>
            index === length - 1 ? onDone?.call(null) : onNext?.call(null)
          }
          disabled={index === length - 1 && !onDone}
          style={[pbs, controlsStyles.button, {flex: onPrev ? 0.45 : 1}]}
        />
      </View>
    );
  }, [onPrev, t, index, pbs, length, doneText, onDone, onNext]);

  return controls;
}
const controlsStyles = StyleSheet.create({
  container: {
    flexWrap: 'nowrap',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  button: {flex: 0.45},
});

export {useStory, useControls};
