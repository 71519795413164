import React, {useCallback, useContext, useMemo} from 'react';
import {ColorValue, Linking, StyleSheet, Text, View} from 'react-native';
import {
  Screen,
  Spacer,
  Typography,
  useTheme,
} from '@b2cmessenger/doppio-components';
import {PlaceSubscriptionStateExtended} from '@typings/Place';
import {IconQRCode, IconStats} from '@components/common/icons/SvgIcon';
import {colors, Logger} from '@b2cmessenger/doppio-shared';
import {useSelector} from 'react-redux';
import {loginedUserSelectors} from '@store/selectors';
import type {LoginedUser} from '@b2cmessenger/doppio-core';
import {
  OverviewDisplayState,
  getOverviewDisplayState,
  hasPaymentProblems,
} from '@screens/Dashboard/screens/ManageSubscription/components/Overview/utils';
import {useSubscriptionInfo} from '@hooks/useSubscription';
import WorkplaceContext from '@components/common/WorkplaceContext';
import {useTranslation, SharedHooks} from '@shared';
import {SupportEmail} from '@screens/Dashboard/screens/QRCode';
const {usePlaceAppearance} = SharedHooks;

type OverviewProps = {
  subscriptionStateExtended: PlaceSubscriptionStateExtended;
};

function Overview({subscriptionStateExtended}: OverviewProps) {
  const profile = useSelector(loginedUserSelectors.profile) as Exclude<
    ReturnType<typeof loginedUserSelectors.profile>,
    null
  >;
  const {numberOfTrialDays} = useSubscriptionInfo();
  const {colors: themeColors} = useTheme();

  return (
    <OverviewContent
      subscriptionStateExtended={subscriptionStateExtended}
      loginedUserId={profile.id}
      numberOfTrialDays={numberOfTrialDays}
      brandColor={themeColors.brand}
    />
  );
}

type OverviewContentProps = {
  subscriptionStateExtended: PlaceSubscriptionStateExtended;
  loginedUserId: LoginedUser['id'];
  numberOfTrialDays: number;
  brandColor: ColorValue;
};
function OverviewContent({
  subscriptionStateExtended,
  loginedUserId,
  numberOfTrialDays,
  brandColor,
}: OverviewContentProps) {
  const {t} = useTranslation();
  const content = useMemo(() => {
    const isCurrentUserCanPay =
      subscriptionStateExtended.payerUserId === loginedUserId;

    const displayState = getOverviewDisplayState(subscriptionStateExtended);
    switch (displayState) {
      case OverviewDisplayState.fullAccess:
        return (
          <>
            <Screen.Heading>
              {t(
                'Screens.ManageSubscription.OverviewContent.youHaveFullAccess',
              )}
            </Screen.Heading>
            {hasPaymentProblems(subscriptionStateExtended.status) ? (
              <FixProblemWithPayment
                isCurrentUserCanPay={isCurrentUserCanPay}
              />
            ) : null}
          </>
        );
      case OverviewDisplayState.fullAccessWithExpirationDate:
        return (
          <>
            <FullAccessWithExpirationDate
              expireAlert={subscriptionStateExtended.expireAlert}
              expireInDays={subscriptionStateExtended.expireInDays || 0}
              brandColor={brandColor}
            />
            {hasPaymentProblems(subscriptionStateExtended.status) ? (
              <FixProblemWithPayment
                isCurrentUserCanPay={isCurrentUserCanPay}
              />
            ) : null}
          </>
        );
      case OverviewDisplayState.trialNotStartedYet:
        return (
          <TrialNotStartedYet
            numberOfTrialDays={numberOfTrialDays}
            brandColor={brandColor}
          />
        );

      case OverviewDisplayState.subscriptionPaused:
        return (
          <SubscriptionPaused
            expireAlert={subscriptionStateExtended.expireAlert}
            expireInDays={subscriptionStateExtended.expireInDays || 0}
            brandColor={brandColor}
          />
        );
      case OverviewDisplayState.limitedAccess:
        return (
          <>
            <Screen.Heading>
              {t(
                'Screens.ManageSubscription.OverviewContent.youHaveLimitedAccess',
              )}
            </Screen.Heading>
            {subscriptionStateExtended.isAbleToSubscribe ? (
              <Screen.Description>
                {t(
                  'Screens.ManageSubscription.OverviewContent.subscribeInOrderToGetFollowingFeatures',
                )}
              </Screen.Description>
            ) : (
              <FixProblemWithPayment
                isCurrentUserCanPay={isCurrentUserCanPay}
              />
            )}
          </>
        );
    }
  }, [
    subscriptionStateExtended,
    loginedUserId,
    t,
    brandColor,
    numberOfTrialDays,
  ]);

  return (
    <View>
      {content}
      <Spacer height={16} />
      <WhatGivesFullAccess />
    </View>
  );
}

type ExpireInfo = {
  expireAlert: boolean;
  expireInDays: number;
  brandColor: ColorValue;
};

function WhatGivesFullAccess() {
  const {t} = useTranslation();
  const workplace = useContext(WorkplaceContext);
  const {award} = usePlaceAppearance(workplace.id);

  return (
    <>
      <IconQRCode size={28} />
      <Text style={Typography.body}>
        {t('Screens.ManageSubscription.WhatGivesFull.qrCodesToRewardClients', {
          award: award(1),
        })}
      </Text>
      <Spacer height={18} />
      <IconStats size={28} />

      <Text style={Typography.body}>
        {t('Screens.ManageSubscription.WhatGivesFull.accessToStats')}
      </Text>
      <Spacer />
      <InCaseOfAnyProblem />
    </>
  );
}
function InCaseOfAnyProblem() {
  const {colors} = useTheme();
  const {t} = useTranslation();
  const {id: placeId} = useContext(WorkplaceContext);
  const openEmail = useCallback(async () => {
    const _url = `mailto:${SupportEmail}?subject=${encodeURIComponent(
      `Manage Subscription issue (${placeId})`,
    )}`;
    try {
      if (await Linking.canOpenURL(_url)) {
        await Linking.openURL(_url);
      }
    } catch (e) {
      Logger.errorTag('openEmail', e);
    }
  }, [placeId]);

  return (
    <>
      <Text style={[Typography.body]}>
        {t(
          'Screens.QRCode.NoActiveSubscriptionModal.inCaseOfAnyProblemsPleaseContactUs',
        )}
      </Text>
      <Text
        style={[{color: colors.brand, textDecorationLine: 'underline'}]}
        onPress={openEmail}>
        {SupportEmail}
      </Text>
    </>
  );
}
function FullAccessWithExpirationDate({
  expireAlert,
  expireInDays,
  brandColor,
}: ExpireInfo) {
  const {t} = useTranslation();
  const _days = t('Screens.ManageSubscription.FullAccessWith.day', {
    count: expireInDays,
  });

  return (
    <>
      <View style={styles.headerContainer}>
        <Text style={Typography.header}>
          {t('Screens.ManageSubscription.FullAccessWith.youHave')}{' '}
        </Text>
        <View
          style={
            expireAlert
              ? styles.roundedLabelAlert
              : [styles.roundedLabel, {backgroundColor: brandColor}]
          }>
          <Text style={styles.roundedLabelText}>{_days}</Text>
        </View>
      </View>
      <Text style={Typography.header}>
        {t('Screens.ManageSubscription.FullAccessWith.ofFullAccess')}
      </Text>
    </>
  );
}

function TrialNotStartedYet({
  numberOfTrialDays,
  brandColor,
}: {
  numberOfTrialDays: number;
  brandColor: ColorValue;
}) {
  const {t} = useTranslation();
  const _days = t('Screens.ManageSubscription.TrialNotStartedYet.day', {
    count: numberOfTrialDays,
  });

  return (
    <>
      <View style={styles.headerContainer}>
        <Text style={Typography.header}>
          {t('Screens.ManageSubscription.TrialNotStartedYet.youWillHave')}{' '}
        </Text>
        <View style={[styles.roundedLabel, {backgroundColor: brandColor}]}>
          <Text style={styles.roundedLabelText}>{_days}</Text>
        </View>
      </View>
      <Text style={Typography.header}>
        {t(
          'Screens.ManageSubscription.TrialNotStartedYet.ofFullAccessAfterYouGiveYourFirstStamp',
        )}
      </Text>
    </>
  );
}

function SubscriptionPaused({
  expireAlert,
  expireInDays,
  brandColor,
}: ExpireInfo) {
  const {t} = useTranslation();
  const _days = t('Screens.ManageSubscription.SubscriptionPaused.day', {
    count: expireInDays,
  });
  return (
    <>
      <View style={styles.headerContainer}>
        <Text style={Typography.header}>
          {t(
            'Screens.ManageSubscription.SubscriptionPaused.yourSubscriptionPaused',
          )}
        </Text>
        <View
          style={
            expireAlert
              ? styles.roundedLabelAlert
              : [styles.roundedLabel, {backgroundColor: brandColor}]
          }>
          <Text style={styles.roundedLabelText}>{_days}</Text>
        </View>
        <Text style={Typography.header}>
          {t('Screens.ManageSubscription.SubscriptionPaused.untilResume')}
        </Text>
      </View>
      <Screen.Description style={styles.subscriptionIssues}>
        {t(
          'Screens.ManageSubscription.SubscriptionPaused.clickOnTheManageSubscriptionToGoToTheSubscriptionS',
        )}
      </Screen.Description>
      <Screen.Description>
        {t(
          'Screens.ManageSubscription.SubscriptionPaused.resumeASubscriptionNowInOrderToGetFollowingFeature',
        )}
      </Screen.Description>
    </>
  );
}

function FixProblemWithPayment({
  isCurrentUserCanPay,
}: {
  isCurrentUserCanPay: boolean;
}) {
  const {t} = useTranslation();
  const problem = t(
    'Screens.ManageSubscription.FixProblemWithPayment.thereIsAProblemWithYourSubscription',
  );
  const clickToFix = t(
    'Screens.ManageSubscription.FixProblemWithPayment.clickOnTheManageSubscriptionToGoToTheSubscriptionS',
  );
  const contactToOwner = t(
    'Screens.ManageSubscription.FixProblemWithPayment.contactThePlaceOwnerToFixPaymentMethod',
  );
  const issue = `${problem} ${
    isCurrentUserCanPay ? clickToFix : contactToOwner
  }`;

  return (
    <>
      <Screen.Description style={styles.subscriptionIssues}>
        {issue}
      </Screen.Description>
      <Screen.Description>
        {t(
          'Screens.ManageSubscription.FixProblemWithPayment.fixPaymentMethodInOrderToContinueUsingFollowingFea',
        )}
      </Screen.Description>
    </>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: 16,
  },
  roundedLabelText: {
    ...StyleSheet.flatten(Typography.header),
    color: colors.white,
    marginBottom: 2,
  },
  roundedLabel: {
    flexDirection: 'row',
    borderRadius: 32 / 2,
    backgroundColor: colors.brand,
    paddingHorizontal: 12,
    paddingVertical:
      (32 - StyleSheet.flatten(Typography.header).lineHeight) / 2,
  },
  roundedLabelAlert: {
    flexDirection: 'row',
    borderRadius: 32 / 2,
    backgroundColor: colors.red,
    paddingHorizontal: 12,
    paddingVertical:
      (32 - StyleSheet.flatten(Typography.header).lineHeight) / 2,
  },
  subscriptionIssues: {
    color: colors.red,
  },
});

export default Overview;
