import {StyleSheet, View, ViewStyle} from 'react-native';
import React, {ReactNode, useMemo, useState} from 'react';
import {useControls, useStory} from '@features/Story/lib/hooks';
import {Pusher} from '@b2cmessenger/doppio-components';
import {
  ControlsContext,
  ControlsContextType,
} from '@features/Story/lib/ControlsContext';

type StoryViewProps = {
  onDone?: () => void;
  doneText?: string;
  stories: ReactNode[];
  displayProgressBar?: boolean;
  displayPrevButton?: boolean;
  contentContainerStyle?: ViewStyle;
};
function StoryView({
  onDone,
  doneText,
  stories = [],
  displayProgressBar = false,
  displayPrevButton = false,
  contentContainerStyle,
}: StoryViewProps) {
  const indexes = useMemo(() => stories.map((_, idx) => idx), [stories]);
  const length = useMemo(() => indexes.length, [indexes]);
  const {progressNavbar, index, onNext, onPrev} = useStory({
    indexes,
  });
  const _onPrev = useMemo(
    () => (displayPrevButton && index < length - 1 ? onPrev : undefined),
    [displayPrevButton, onPrev, length, index],
  );
  const _displayProgressBar = useMemo(
    () =>
      displayProgressBar && index < length - 1 ? displayProgressBar : false,
    [displayProgressBar, index, length],
  );
  const controls = useControls({
    onNext,
    onPrev: _onPrev,
    index,
    length,
    onDone,
    doneText,
  });
  const content = useMemo(() => stories[index], [index, stories]);
  const _contentContainerStyle = useMemo(
    () => contentContainerStyle,
    [contentContainerStyle],
  );

  const [controlsSuppressed, setControlsSuppressed] = useState<boolean>(false);
  const [customControls, setCustomControls] = useState<React.ReactNode>(null);
  const controlsSuppressesContextValue = useMemo<ControlsContextType>(() => {
    return {
      controlsSuppressed,
      suppressControls: setControlsSuppressed,
      customControls,
      setCustomControls,
    };
  }, [controlsSuppressed, customControls]);

  return (
    <ControlsContext.Provider value={controlsSuppressesContextValue}>
      <View style={styles.container}>
        {_displayProgressBar ? progressNavbar : null}
        <View style={_contentContainerStyle}>{content}</View>
        <Pusher />
        {controlsSuppressed ? null : controls}
        {customControls ? customControls : null}
      </View>
    </ControlsContext.Provider>
  );
}

const styles = StyleSheet.create({
  container: {flex: 1, flexGrow: 1},
});

export {StoryView};
