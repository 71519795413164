import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewProps,
} from 'react-native';
import {Spacer, Typography, useTheme} from '@b2cmessenger/doppio-components';
import React, {useMemo} from 'react';
import {IconChoice} from '@components/common/icons/SvgIcon';
import {useTranslation} from '@shared';

type Props = {
  date?: string;
  total: number;
  returning: number;
  onDatePress?: () => void;
  loading?: boolean;
} & ViewProps;

export function GuestStats({
  date,
  returning: returningGuests,
  total: totalGuests,
  onDatePress,
  loading,
  style,
  ...rest
}: Props) {
  const {t} = useTranslation();

  const {colors} = useTheme();
  const newGuests = totalGuests - returningGuests;
  const styles = useMemo(
    () =>
      StyleSheet.create({
        wrapper: {
          paddingLeft: 12,
        },
        loader: {marginRight: 'auto'},
        centeredRow: {
          flexDirection: 'row',
          alignItems: 'center',
        },
        brandMediumBody: {
          ...StyleSheet.flatten(Typography.mediumBody),
          color: colors.brand,
        },
        smallWhiteHeader: {
          ...StyleSheet.flatten(Typography.smallHeader),
          color: colors.white,
        },
        brandSmallHeader: {
          ...StyleSheet.flatten(Typography.smallHeader),
          color: colors.brand,
        },
        whiteExtraSmallButtonText: {
          ...StyleSheet.flatten(Typography.extraSmallButton),
          color: colors.white,
          textTransform: 'uppercase',
          marginBottom: 2,
        },
        blackExtraSmallButtonText: {
          ...StyleSheet.flatten(Typography.extraSmallButton),
          color: colors.black,
          textTransform: 'uppercase',
        },
        brandExtraSmallButtonText: {
          ...StyleSheet.flatten(Typography.extraSmallButton),
          color: colors.brand,
          textTransform: 'uppercase',
        },
        roundedLabel: {
          flexDirection: 'row',
          alignItems: 'flex-end',
          borderRadius: 32 / 2,
          backgroundColor: colors.brand,
          marginRight: 'auto',
          paddingHorizontal: 12,
          paddingVertical:
            (32 - StyleSheet.flatten(Typography.smallHeader).lineHeight) / 2,
          marginLeft: -12,
        },
      }),
    [colors.black, colors.brand, colors.white],
  );

  return (
    <View
      style={useMemo(() => [styles.wrapper, style], [style, styles.wrapper])}
      {...rest}>
      <TouchableOpacity
        onPress={onDatePress}
        disabled={!onDatePress || loading}>
        {loading ? (
          <ActivityIndicator
            color={colors.darkgray}
            size={28}
            style={styles.loader}
          />
        ) : (
          <View style={styles.centeredRow}>
            {date ? (
              <>
                <Text style={styles.brandMediumBody}>{date}</Text>
                <IconChoice fill={colors.brand} />
              </>
            ) : null}
          </View>
        )}
      </TouchableOpacity>
      <Spacer height={6} />
      <View style={styles.roundedLabel}>
        <Text style={styles.smallWhiteHeader}>{totalGuests}</Text>
        <Text style={styles.whiteExtraSmallButtonText}>
          {' '}
          {t('Screens.Stats.GuestStats.totalGuest', {
            count: totalGuests,
          })}
        </Text>
      </View>
      <Spacer height={6} />
      <Text>
        <Text style={Typography.smallHeader}>{returningGuests}</Text>{' '}
        <Text style={styles.blackExtraSmallButtonText}>
          {t('Screens.Stats.GuestStats.returningGuest', {
            count: returningGuests,
          })}
        </Text>
      </Text>
      <Spacer height={6} />
      <Text>
        <Text style={styles.brandSmallHeader}>{newGuests}</Text>{' '}
        <Text style={styles.brandExtraSmallButtonText}>
          {t('Screens.Stats.GuestStats.newGuest', {
            count: newGuests,
          })}
        </Text>
      </Text>
    </View>
  );
}
