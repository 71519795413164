import {
  CustomerList,
  CustomerListPlaceholder,
} from '@features/Customers/ui/CustomerList';
import {
  Button,
  Navbar,
  Pusher,
  Screen,
  Spacer,
  Typography,
  useTheme,
} from '@b2cmessenger/doppio-components';
import React, {useCallback, useContext, useMemo, useState} from 'react';
import {Platform, RefreshControl, StyleSheet, Text, View} from 'react-native';
import {navigationRef} from '@navigation/navigationRef';
import WorkplaceContext from '@components/common/WorkplaceContext';
import {usePlaceCustomers} from '@screens/Dashboard/screens/Stats/hooks/usePlaceCustomers';
import {useTranslation} from '@shared';

const LIMIT = 10;
export function Customers() {
  const {t} = useTranslation();
  const {colors} = useTheme();
  const goBack = useCallback(() => {
    if (navigationRef.current?.canGoBack()) {
      navigationRef.current?.goBack();
    } else {
      navigationRef.current?.navigate('Dashboard', {
        screen: 'Stats',
      });
    }
  }, []);
  const navbar = useMemo(
    () => <Navbar onBack={goBack} title={'Customers'} />,
    [goBack],
  );
  const disabled = false;
  const workplace = useContext(WorkplaceContext);
  const {data, next, isEnd, isLoading, isValidating, refresh} =
    usePlaceCustomers(workplace.id, LIMIT);

  const loadMore = useMemo(() => {
    return isEnd || data?.length === 0 ? null : (
      <>
        <Spacer height={20} />
        <Button
          title={'Load more'}
          disabled={isLoading || isValidating}
          onPress={() => next()}
        />
        <Spacer height={20} />
      </>
    );
  }, [data?.length, isEnd, isLoading, isValidating, next]);

  const [refreshing, setRefreshing] = useState(false);
  const onRefresh = useCallback(async () => {
    try {
      setRefreshing(true);
      await refresh();
    } finally {
      setRefreshing(false);
    }
  }, [refresh]);
  const refreshControl = useMemo(
    () => (
      <RefreshControl
        colors={[colors.brand]}
        tintColor={colors.brand}
        refreshing={refreshing}
        onRefresh={onRefresh}
      />
    ),
    [colors.brand, onRefresh, refreshing],
  );

  return (
    <Screen navbar={navbar} style={styles.screen}>
      <View style={{paddingHorizontal: Screen.PADDING_HORIZONTAL}}>
        <Spacer height={4} />
        <Text style={[Typography.mediumBody, {color: colors.darkgray}]}>
          {t('Screens.Stats.CustomersCard.sortedByLatestBalanceChange')}
        </Text>
        <Spacer height={4} />
        <Text style={[Typography.mediumBody, {color: colors.darkgray}]}>
          {t(
            'Screens.Stats.CustomersCard.greyAvatarsIndicateUnregisteredUsersOnceRegistered',
          )}
        </Text>
      </View>

      <Spacer />
      {data === undefined ? (
        <CustomerListPlaceholder
          length={LIMIT}
          brandColor={colors.brand}
          style={{paddingHorizontal: Screen.PADDING_HORIZONTAL}}
        />
      ) : (
        <CustomerList
          customers={data}
          onPress={() => {}}
          disabled={disabled}
          ListFooterComponent={() => loadMore || <Spacer />}
          contentContainerStyle={{paddingHorizontal: Screen.PADDING_HORIZONTAL}}
          refreshControl={refreshControl}
        />
      )}
      <Pusher />
    </Screen>
  );
}

const styles = StyleSheet.create({
  screen: Platform.select({
    web: {flex: 1, paddingHorizontal: 0},
    default: {
      flex: 1,
      paddingHorizontal: 0,
    },
  }),
});
