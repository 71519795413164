import {PossiblePlaceAppearances} from '@typings/Place';
import {PlaceAppearanceConfigExtended} from '@components/common/AppearanceCustomizer';
import {localization} from '@shared';

type GetPlaceAppearanceByBusinessTypeProps = {
  links: PossiblePlaceAppearances['links'];
  resources: PossiblePlaceAppearances['resources'];
  appearance: PlaceAppearanceConfigExtended | null;
  businessTypeId: number | null;
  defaultStampId: null | number;
  defaultAwardId: null | number;
  businessIdWithCoffeeIcon: number;
};
export const INITIAL_EXTENDED_CONFIG = {
  color: null,
  stamp_resource_id: null,
  stamp_resource_content: null,
  award_name: null,
  award_resource_id: null,
  award_resource_content: null,
};
export type PossibleBusinessExtended = PossiblePlaceAppearances['businesses'][0] & {
  description?: string;
};
export const BUSINESS_ID_WHEN_NONE_MATCH = 9999;
export function getBusinessWhenNoneMatch(): PossibleBusinessExtended {
  return {
    id: BUSINESS_ID_WHEN_NONE_MATCH,
    name: localization.t('Screens.BusinessWizard.noneOfTheListed'),
    description:
      localization.t('Screens.BusinessWizard.youllBeAbleToSetItManually') || '',
  };
}

export function getPlaceAppearanceByBusinessType(
  props: GetPlaceAppearanceByBusinessTypeProps,
) {
  const stampPartialConfig = getStampPartialConfig(props);
  const awardPartialConfig = getAwardPartialConfig(
    props,
    stampPartialConfig?.stamp_resource_id,
  );
  const config: PlaceAppearanceConfigExtended = {
    ...INITIAL_EXTENDED_CONFIG,
    ...(props.appearance ? props.appearance : {}),
    ...(awardPartialConfig ? awardPartialConfig : {}),
    ...(stampPartialConfig ? stampPartialConfig : {}),
  };

  return config;
}

function getStampPartialConfig(
  props: Omit<GetPlaceAppearanceByBusinessTypeProps, 'appearance' | 'links'>,
) {
  const {
    resources,
    businessTypeId,
    defaultStampId,
    businessIdWithCoffeeIcon,
  } = props;

  if (businessTypeId === businessIdWithCoffeeIcon) {
    return {
      stamp_resource_content: null,
      stamp_resource_id: null,
    };
  }

  const stampIdxByBusinessType = resources.findIndex(
    v =>
      v.business_id === businessTypeId &&
      v.type === 'stamp' &&
      v.is_main_for_business,
  );

  const idx =
    stampIdxByBusinessType !== -1
      ? stampIdxByBusinessType
      : defaultStampId
      ? resources.findIndex(v => v.id === defaultStampId && v.type === 'stamp')
      : null;

  if (typeof idx === 'number' && idx !== -1) {
    return {
      stamp_resource_content: resources[idx].content,
      stamp_resource_id: resources[idx].id,
    };
  }
  return null;
}

function getAwardPartialConfig(
  props: Omit<GetPlaceAppearanceByBusinessTypeProps, 'appearance'>,
  stampId: number | undefined | null,
) {
  const {
    resources,
    links,
    defaultAwardId,
    businessTypeId,
    businessIdWithCoffeeIcon,
  } = props;
  if (businessTypeId === businessIdWithCoffeeIcon) {
    return {
      award_resource_id: null,
    };
  }

  const awardIdxByStampId = getAwardIdxByStampId({resources, links}, stampId);
  const idx =
    typeof awardIdxByStampId === 'number' && awardIdxByStampId !== -1
      ? awardIdxByStampId
      : defaultAwardId
      ? resources.findIndex(v => v.id === defaultAwardId && v.type === 'award')
      : undefined;

  if (typeof idx === 'number' && idx !== -1) {
    return {
      award_resource_id: resources[idx].id,
    };
  }
  return null;
}

function getAwardIdxByStampId(
  {links, resources}: Pick<PossiblePlaceAppearances, 'links' | 'resources'>,
  stampId?: number | null,
) {
  if (!stampId) {
    return;
  }
  const linkedIdx = links.findIndex(v => v && v[0] === stampId);
  if (linkedIdx !== -1) {
    const linkedAwardId = links[linkedIdx][1];
    return resources.findIndex(
      v => v.type === 'award' && v.id === linkedAwardId,
    );
  }
  return;
}

export function getPreparedPossibleAppearance({
  possibleAppearances,
  businessTypeId,
}: {
  possibleAppearances?: PossiblePlaceAppearances;
  businessTypeId: number | null;
}) {
  if (possibleAppearances && businessTypeId) {
    const _resources = [...possibleAppearances.resources].sort(a =>
      a.business_id === businessTypeId ? -1 : 1,
    );
    return {
      ...possibleAppearances,
      resources: _resources,
    };
  }

  return possibleAppearances;
}
