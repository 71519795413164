import React, {ComponentProps, useCallback, useMemo, useState} from 'react';
import {LayoutChangeEvent, Platform, StyleSheet, View} from 'react-native';
import {TabBar, TabView} from 'react-native-tab-view';
import {colors as defaultColors} from '@b2cmessenger/doppio-shared';
import {PlaceAppearance} from '@b2cmessenger/doppio-core';
import ColorPicker from '@components/common/AppearanceCustomizer/pickers/ColorPicker';
import ResourcePicker from '@components/common/AppearanceCustomizer/pickers/ResourcePicker';
import {
  pickerItemFullWidth,
  PossiblePlaceAppearances,
} from '@components/common/AppearanceCustomizer/shared';
import {TEST_IDS} from '@components/common/AppearanceCustomizer/AppearancePickerTestIDS';
import {PlaceAppearanceConfigExtended} from '@screens/BusinessWizard/slice';
import {ScrollViewWithShadow, localization} from '@shared';
import {useTheme} from '@b2cmessenger/doppio-components';

function getRoutes() {
  return [
    {
      key: 'color',
      title: localization.t('Components.AppearancePicker.mainColor'),
    },
    {
      key: 'stamps',
      title: localization.t('Components.AppearancePicker.stamps'),
    },
    {
      key: 'rewards',
      title: localization.t('Components.AppearancePicker.rewards'),
    },
  ];
}

type AppearancePickerProps = {
  appearances: PossiblePlaceAppearances;
  config: PlaceAppearanceConfigExtended;
  onChange: (config: Partial<PlaceAppearanceConfigExtended>) => void;
  visible?: boolean;
};
const SHADOW_SIZE = 15;
const SCROLL_MARGIN_VERTICAL = 15;
function AppearancePicker(props: AppearancePickerProps) {
  const {appearances, config, onChange, visible = true} = props;
  const {colors} = useTheme();

  const [index, setIndex] = React.useState(0);
  const navigationState = useMemo(
    () => ({
      index,
      routes: getRoutes(),
    }),
    [index],
  );

  const [pickerColumnCount, setPickerColumnCount] = useState(4);

  const onContainerLayout = useCallback((event: LayoutChangeEvent) => {
    const {width} = event.nativeEvent.layout;
    const columns = Math.floor(width / pickerItemFullWidth);
    setPickerColumnCount(columns);
  }, []);

  const colorPicker = useMemo(() => {
    return (
      <ColorPicker
        appearances={appearances}
        value={config}
        changeValue={onChange}
      />
    );
  }, [appearances, config, onChange]);

  const stampsPicker = useMemo(() => {
    return (
      <ResourcePicker
        appearances={appearances}
        value={config}
        changeValue={onChange}
        config={{
          resourceType: 'stamp',
          valueFieldName: 'stamp_resource_id',
          additionalResource: {
            id: null,
            type: 'stamp',
            name: 'Bean',
            content: PlaceAppearance.Icon.CoffeeBeanSVGXmlContent,
            business_id: null,
            is_main_for_business: 0,
          },
          pickerTestId: TEST_IDS.elements.StampIconPicker,
          pickerItemTestIdPrefix: TEST_IDS.elements.StampIcon,
        }}
      />
    );
  }, [appearances, config, onChange]);

  const rewardsPicker = useMemo(() => {
    return (
      <RewardsPicker
        appearances={appearances}
        value={config}
        changeValue={onChange}
      />
    );
  }, [appearances, config, onChange]);

  const renderScene: ComponentProps<
    typeof TabView
  >['renderScene'] = useCallback(
    ({route}) => {
      let pickerComponent;

      switch (route.key) {
        case 'color':
          pickerComponent = colorPicker;
          break;
        case 'stamps':
          pickerComponent = stampsPicker;
          break;
        case 'rewards':
          pickerComponent = rewardsPicker;
          break;
        default:
          pickerComponent = null;
      }

      return (
        <ScrollViewWithShadow
          style={[
            basePickerStyles.scrollView,
            {
              width: pickerColumnCount * pickerItemFullWidth,
            },
          ]}
          contentContainerStyle={basePickerStyles.scrollViewContentContainer}
          shadowSize={SHADOW_SIZE}
          shadowTop={0}
          shadowBottom={SCROLL_MARGIN_VERTICAL}
        >
          {pickerComponent}
        </ScrollViewWithShadow>
      );
    },
    [pickerColumnCount, colorPicker, stampsPicker, rewardsPicker],
  );

  const renderTabBar = useCallback(
    renderTabBarProps => {
      return (
        <TabBar
          {...renderTabBarProps}
          style={tabBarStyles.style}
          indicatorStyle={{backgroundColor: colors.brand}}
          indicatorContainerStyle={tabBarStyles.indicatorContainerStyle}
          tabStyle={tabBarStyles.tabStyles}
          activeColor={colors.brand}
          inactiveColor={colors.darkgray}
          getLabelText={scene => scene.route.title}
          getTestID={scene => {
            switch (scene.route.key) {
              case 'color':
                return TEST_IDS.buttons.ColorTab;
              case 'stamps':
                return TEST_IDS.buttons.StampsTab;
              case 'rewards':
                return TEST_IDS.buttons.AwardsTab;
            }
          }}
        />
      );
    },
    [colors],
  );

  return (
    <View
      style={[
        basePickerStyles.container,
        {
          opacity: visible ? 1 : 0,
          flex: visible ? 1 : 0,
          display: visible ? 'flex' : 'none',
        },
      ]}
      onLayout={onContainerLayout}
      testID={TEST_IDS.elements.Picker}
    >
      <TabView
        navigationState={navigationState}
        renderScene={renderScene}
        renderTabBar={renderTabBar}
        onIndexChange={setIndex}
      />
    </View>
  );
}

type PickerProps = {
  appearances: PossiblePlaceAppearances;
  value: PlaceAppearanceConfigExtended;
  changeValue: (p: Partial<PlaceAppearanceConfigExtended>) => void;
};
function RewardsPicker(props: PickerProps) {
  const {value: config, changeValue, appearances} = props;

  return (
    <ResourcePicker
      appearances={appearances}
      value={config}
      changeValue={changeValue}
      config={{
        resourceType: 'award',
        valueFieldName: 'award_resource_id',
        additionalResource: {
          id: null,
          type: 'award',
          name: 'Drink',
          content: PlaceAppearance.Icon.CoffeeCupSVGXmlContent,
          business_id: null,
          is_main_for_business: 0,
        },
        pickerTestId: TEST_IDS.elements.AwardIconPicker,
        pickerItemTestIdPrefix: TEST_IDS.elements.AwardIcon,
      }}
    />
  );
}

const basePickerStyles = StyleSheet.create({
  container: {
    ...(Platform.OS === 'web'
      ? StyleSheet.absoluteFillObject
      : {
          flexGrow: 1,
        }),
    flexGrow: 1,
    flex: 1,
  },
  scrollView: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 0,
    marginBottom: SCROLL_MARGIN_VERTICAL,
  },
  scrollViewContentContainer: {flexGrow: 1},
});

const tabBarStyles = StyleSheet.create({
  style: {backgroundColor: 'transparent'},
  indicatorContainerStyle: {
    borderBottomWidth: 1,
    borderBottomColor: defaultColors.lightgray,
  },
  tabStyles: {
    padding: 0,
  },
});
export default AppearancePicker;
