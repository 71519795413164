import React, {useCallback, useContext, useEffect, useState} from 'react';
import {
  Button,
  Pusher,
  Screen,
  Spacer,
  TextInput,
  Typography,
  useTheme,
} from '@b2cmessenger/doppio-components';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {StyleProp, StyleSheet, Text, TextStyle} from 'react-native';
import {useTranslation} from '@shared';
import {AxiosRequestConfig} from 'axios';
import {api} from '@b2cmessenger/doppio-core';
import {Logger} from '@b2cmessenger/doppio-shared';
import {EmailSignInContext} from '@screens/EmailSignIn/index';
import {EmailSignInError, isValidEmail} from '@screens/EmailSignIn/shared';

type EmailSignInViaOtpProps = {
  disabled: boolean;
  onSignInWithAPassword: (email: string) => void;
  onNext: (email: string) => void;
};
export function EmailSignInViaOtp({
  onNext,
  disabled,
  onSignInWithAPassword,
}: EmailSignInViaOtpProps) {
  const {t} = useTranslation();
  const {email, loading} = useContext(EmailSignInContext);
  const {colors} = useTheme();
  const {bottom} = useSafeAreaInsets();
  const [_email, _setEmail] = useState(email);
  useEffect(() => {
    _setEmail(email);
  }, [email]);

  const onPress = useCallback(() => {
    if (!isValidEmail(_email)) {
      return;
    }
    onNext?.call(null, _email);
  }, [_email, onNext]);

  return (
    <>
      <Screen.Heading>
        {t('Screens.EmailSignIn.ViaOtp.helloEnterYourEmail')}
      </Screen.Heading>
      <TextInput
        autoFocus
        label={t('Screens.EmailSignIn.ViaOtp.email')}
        disabled={disabled}
        textContentType="emailAddress"
        keyboardType="email-address"
        value={_email}
        onChangeText={_setEmail}
      />
      <Spacer />
      <Text style={[Typography.smallBody]}>
        {t(
          'Screens.EmailSignIn.ViaOtp.wellEmailYouACodeForAPasswordfreeSignIn',
        )}
      </Text>
      <Spacer />
      <Text style={[Typography.smallBody]}>
        {t('Screens.EmailSignIn.ViaOtp.orYouCan')}{' '}
        <Text
          onPress={() => {
            if (loading) {
              return;
            }
            onSignInWithAPassword(_email);
          }}
          style={[
            Typography.smallBody,
            styles.link,
            {color: loading ? colors.darkgray : colors.brand},
          ]}>
          {t('Screens.EmailSignIn.ViaOtp.signInWithAPasswordInstead')}
        </Text>
      </Text>
      <Pusher />
      <Button
        title={t('Screens.EmailSignIn.ViaOtp.next')}
        loading={loading}
        disabled={disabled || loading || !isValidEmail(_email)}
        onPress={onPress}
      />
      <Spacer height={Math.max(bottom, 16)} />
    </>
  );
}

type EmailSignInViaOtpCodeProps = {
  disabled: boolean;
  onNext: (code: string) => void;
  resendCode: () => void;
};
const CODE_LENGTH = 6;
const NUMBER_OF_ATTEMPTS_EXCEEDED_STATUS = 429;
export function EmailSignInViaOtpCode({
  onNext,
  disabled,
  resendCode,
}: EmailSignInViaOtpCodeProps) {
  const {t} = useTranslation();
  const {email, loading, error, errorStatus, resetError} =
    useContext(EmailSignInContext);
  const [showResendButton, setShowResendButton] = useState(false);
  useEffect(() => {
    if (errorStatus && errorStatus === NUMBER_OF_ATTEMPTS_EXCEEDED_STATUS) {
      setShowResendButton(true);
    }
  }, [errorStatus]);

  const {colors} = useTheme();
  const {bottom} = useSafeAreaInsets();
  const [code, setCode] = useState('');

  const onChange = useCallback(
    (e: string) => {
      setCode(e.replace(/[^0-9]/g, ''));
      if (error) {
        resetError?.call(null);
      }
    },
    [error, resetError],
  );

  const onPress = useCallback(() => {
    if (!code || code.length !== CODE_LENGTH) {
      return;
    }
    resetError?.call(null);
    onNext?.call(null, code);
  }, [code, onNext, resetError]);

  const onResendCode = useCallback(() => {
    resendCode?.call(null);
    resetError?.call(null);
    setShowResendButton(false);
  }, [resendCode, resetError]);

  return (
    <>
      <Screen.Heading>
        {t('Screens.EmailSignIn.ViaOtp.enterCodeFromYourEmail')}
      </Screen.Heading>
      <Spacer />
      <Text style={[Typography.smallBody]}>
        {t('Screens.EmailSignIn.ViaOtp.weveSentA6digitalCodeTo')}
        {'\n'}
        <Text
          style={[
            Typography.smallBody,
            {fontWeight: 800, color: colors.darkgray} as StyleProp<TextStyle>,
          ]}>
          {email}
        </Text>
      </Text>
      <Spacer />
      <TextInput
        autoFocus
        error={errorStatus && [400, 429].includes(errorStatus)}
        keyboardType="number-pad"
        label={t('Screens.EmailSignIn.ViaOtp.code')}
        disabled={disabled}
        value={code}
        onChangeText={onChange}
      />
      {error ? (
        <>
          <Spacer height={6} />
          <EmailSignInError error={error} />
        </>
      ) : null}

      <Pusher />
      <Text style={[Typography.smallBody, {textAlign: 'center'}]}>
        {t('Screens.EmailSignIn.ViaOtp.cantFindYourCodeCheckYourSpamFolder')}
      </Text>
      {showResendButton ? (
        <>
          <Spacer />
          <Button.Secondary
            title={t('Screens.EmailSignIn.ViaOtp.resendCode')}
            loading={loading}
            disabled={disabled || loading}
            onPress={onResendCode}
          />
        </>
      ) : null}
      <Spacer />
      <Button.Primary
        title={t('Screens.EmailSignIn.ViaOtp.next')}
        loading={loading}
        disabled={
          disabled ||
          loading ||
          code.length !== CODE_LENGTH ||
          errorStatus === NUMBER_OF_ATTEMPTS_EXCEEDED_STATUS
        }
        onPress={onPress}
      />
      <Spacer height={Math.max(bottom, 16)} />
    </>
  );
}

type EmailOtpLoginInitReturnValue = {verification_id: number};
export function emailOtpLoginInit(
  email: string,
  requestConfig?: AxiosRequestConfig,
): Promise<EmailOtpLoginInitReturnValue> {
  return api.default
    .post<EmailOtpLoginInitReturnValue>(
      '/api/v2/user/otp/email/login/init',
      {email},
      requestConfig,
    )
    .then(api.getResponseData)
    .catch(e => {
      Logger.errorTag('updatePlaceAppearance', e);
      throw api.parseErrorToHumanReadableMessage(e);
    });
}

const styles = StyleSheet.create({
  link: {
    textDecorationLine: 'underline',
  },
});
