import React from 'react';
import {Platform} from 'react-native';
import * as RNIap from 'react-native-iap';
import {api} from '@b2cmessenger/doppio-core';
import {PlaceSubscriptionState} from '@typings/Place';

export enum ConnectionState {
  init,
  loading,
  success,
  fail,
  unsupported,
}
export type useInAppPurchaseReturnProps = {
  connectionState: ConnectionState;
  purchasing: boolean;
  requestPurchase?: (productId: string, offerToken?: string) => Promise<void>;
};
const IAPContextDefaults: useInAppPurchaseReturnProps = {
  connectionState: Platform.select({
    web: ConnectionState.unsupported,
    default: ConnectionState.init,
  }),
  purchasing: false,
};
export const IAPContext =
  React.createContext<useInAppPurchaseReturnProps>(IAPContextDefaults);

export async function validatePurchaseOnBackend(
  placeId: number,
  purchase: RNIap.ProductPurchase | RNIap.SubscriptionPurchase,
  platform: typeof Platform.OS,
) {
  if (platform !== 'ios' && platform !== 'android') {
    throw new Error(
      `You are validating purchase on wrong platform: '${platform}`,
    );
  }
  return api.default
    .post<PlaceSubscriptionState>(
      `/api/v2/place/${placeId}/subscription/${
        platform === 'ios' ? 'apple' : 'google'
      }/receipt`,
      {
        receipt: purchase.transactionReceipt,
      },
    )
    .then(api.getResponseData);
}
