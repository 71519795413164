import {
  actionChannel,
  call,
  cancelled,
  put,
  take,
  select,
} from 'redux-saga/effects';
import {getSubscriptionInfo} from '@store/subscription/api';
import {
  updateSubscriptionInfo as updateSubscriptionInfoAction,
  isInitLikeAction,
} from '@store/actions';
import {Logger} from '@b2cmessenger/doppio-shared';
import {loginedUserSelectors, subscriptionSelectors} from '@store/selectors';
import {Await} from '@typings/shared';
import {getWorkplaceRole} from '@components/common/WorkplaceContext';

const CACHE_TIME = 1 * 1000 * 60 * 60;

export default function* appSubscriptionInfoSaga() {
  // @ts-ignore
  const channel = yield actionChannel(isInitLikeAction);
  try {
    while (true) {
      // @ts-ignore
      yield take(channel);

      const isLoggedIn: ReturnType<
        typeof loginedUserSelectors.isLoggedIn
      > = yield select(loginedUserSelectors.isLoggedIn);
      let workplace: ReturnType<typeof loginedUserSelectors.workplace> = null;
      if (isLoggedIn) {
        workplace = yield select(loginedUserSelectors.workplace);
      }

      const updatedAt: ReturnType<
        typeof subscriptionSelectors.updatedAt
      > = yield select(subscriptionSelectors.updatedAt);

      // in the case when the user does not yet have a workplace (example: first time login via socials)
      const firstTimeLoggedIn = isLoggedIn && !workplace;
      const isOwner = workplace && getWorkplaceRole(workplace) === 'owner';
      const cacheIsRotten = !updatedAt || Date.now() - updatedAt > CACHE_TIME;
      const shouldUpdateSubscriptionInfo =
        (firstTimeLoggedIn || isOwner) && cacheIsRotten;

      if (shouldUpdateSubscriptionInfo) {
        yield updateSubscriptionInfo();
      }
    }
  } catch (e) {
    Logger.error('appSubscriptionInfoSaga', e);
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      Logger.verboseTag('appSubscriptionInfoSaga', 'cancelled');
      channel.close();
    } else {
      Logger.verboseTag('appSubscriptionInfoSaga', 'finished');
    }
  }
}

function* updateSubscriptionInfo() {
  const subscriptionInfo: Await<
    ReturnType<typeof getSubscriptionInfo>
  > = yield call(getSubscriptionInfo);

  if (subscriptionInfo) {
    yield put(
      updateSubscriptionInfoAction({
        numberOfTrialDays: subscriptionInfo.numberOfTrialDays,
        pricePerMonthUsdCents: subscriptionInfo.pricePerMonthUsdCents,
      }),
    );
  } else {
    Logger.error(
      'updateSubscriptionInfo',
      'getSubscriptionInfo returned undefined',
    );
  }
}
