import {useActionSheet} from '@expo/react-native-action-sheet';
import {useCallback} from 'react';
import localization from './index';
import {useTranslation} from './useTranslation';
import {LANGUAGES} from './index';

function useChangeLanguage(
  props:
    | {
        title: string;
        onChange?: (lng: string) => void;
      }
    | undefined,
) {
  const {t} = useTranslation();
  const {
    title = t('Shared.Localization.useChangeLanguage.chooseYourLanguage') || '',
    onChange,
  } = props || {};
  const {showActionSheetWithOptions} = useActionSheet();

  const changeLanguage = useCallback(() => {
    const options = LANGUAGES.map(value => value.name);
    showActionSheetWithOptions(
      {
        title,
        options,
      },
      selectedIdx => {
        if (selectedIdx !== undefined) {
          localization.changeLanguage(LANGUAGES[selectedIdx].lng);
          onChange?.call(null, LANGUAGES[selectedIdx].lng);
        }
      },
    );
  }, [onChange, showActionSheetWithOptions, title]);

  return {changeLanguage};
}

export default useChangeLanguage;
