import React, {useMemo} from 'react';
import {IconCry} from '@components/common/icons/SvgIcon';
import {StyleSheet, Text} from 'react-native';
import {
  Button,
  Card,
  Spacer,
  Typography,
  useTheme,
} from '@b2cmessenger/doppio-components';
import {useTranslation} from '@shared';

function OutOfFullAccess({onPress}: {onPress: () => void}) {
  const {t} = useTranslation();

  const {colors} = useTheme();
  const styles = useMemo(
    () =>
      StyleSheet.create({
        wrapper: {
          alignItems: 'center',
          margin: 10,
        },
        description: {color: colors.white, textAlign: 'center'},
        title: {color: colors.white, textAlign: 'center'},
      }),
    [colors.white],
  );

  return (
    <Card
      backgroundColor={colors.black}
      paddingHorizontal={30}
      style={styles.wrapper}
    >
      <IconCry />
      <Spacer />
      <Text style={[Typography.fatBody, styles.title]}>
        {t('Screens.Stats.OutOfFullAccess.youHaveRunOutOfFullAccess')}
      </Text>
      <Spacer />
      <Text style={[Typography.body, styles.description]}>
        {t(
          'Screens.Stats.OutOfFullAccess.payForASubscriptionToRestoreAccessToStatistics',
        )}
      </Text>
      <Spacer />
      <Button
        title={t('Screens.Stats.OutOfFullAccess.manageSubscription')}
        onPress={onPress}
      />
    </Card>
  );
}

export default OutOfFullAccess;
