import {api} from '@b2cmessenger/doppio-core';
import {Logger} from '@b2cmessenger/doppio-shared';
import {PlaceFull} from '@typings/Place';
import {AxiosRequestConfig} from 'axios';
import {getTimeZone} from 'react-native-localize';

export async function createCompany(
  {
    name,
    stamps,
  }: {
    name: string;
    stamps: number;
  },
  requestConfig?: AxiosRequestConfig,
) {
  const data = new FormData();

  data.append('name', name);
  data.append('status', '1');
  data.append('stamp_count_for_reward', String(stamps));

  const timezone = getTimeZone();
  data.append('timezone', timezone);

  return api.default
    .post<PlaceFull>('/api/place/create', data, requestConfig)
    .then(api.getResponseData)
    .catch((e) => {
      Logger.errorTag('createCompany', e);
      throw api.parseErrorToHumanReadableMessage(e);
    });
}
