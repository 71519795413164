import {localization} from '@shared';
import {
  BModal,
  Button,
  Screen,
  Spacer,
  Typography,
  useTheme,
} from '@b2cmessenger/doppio-components';
import React, {useCallback, useMemo, useState} from 'react';
import {ScrollView, StyleSheet, Text, TouchableOpacity} from 'react-native';
import {colors} from '@b2cmessenger/doppio-shared';
import {IconChoice} from '@components/common/icons/SvgIcon';

function useCollapsibleBlock(defaultOpen: boolean) {
  const {colors} = useTheme();
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const toggle = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  const icon = useMemo(() => {
    return (
      <IconChoice
        fill={colors.brand}
        style={isOpen ? {transform: [{rotate: '90deg'}]} : undefined}
      />
    );
  }, [colors.brand, isOpen]);

  return {isOpen, toggle, icon};
}

export default function useHowToScanInstructionModal() {
  const {colors} = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  const showModal = useCallback(() => setIsVisible(true), []);

  const {
    isOpen: iosIsOpen,
    toggle: iosToggle,
    icon: iosIcon,
  } = useCollapsibleBlock(false);
  const {
    isOpen: androidIsOpen,
    toggle: androidToggle,
    icon: androidIcon,
  } = useCollapsibleBlock(false);

  const textEn = useMemo(() => {
    return (
      <>
        <Text
          style={[Typography.header, {color: colors.brand}, styles.message]}>
          How to Scan QR Code?
        </Text>
        <Spacer />

        <Text style={styles.header}>Using the Web Version on Any Device</Text>
        <Spacer />
        <Text style={styles.listItem}>
          1. <Text style={styles.strong}>Open a web browser</Text> and go to
        </Text>
        <Spacer />
        <Text style={[styles.link, styles.message]}>
          <Text
            style={{
              color: colors.middlegray,
              textDecorationLine: 'underline',
            }}>
            https://
          </Text>
          <Text
            style={{
              color: colors.brand,
              textDecorationLine: 'underline',
            }}>
            doppio.cards
          </Text>
        </Text>
        <Spacer />
        <Text style={styles.listItem}>
          2. <Text style={styles.strong}>Tap the "Scan QR" button </Text> at the
          bottom of the page and{' '}
          <Text style={styles.strong}>point your device's camera</Text> at the
          QR code.
        </Text>
        <Spacer />

        <Text style={styles.header}>Using Your Device's Camera App</Text>
        <Spacer />

        <TouchableOpacity
          style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}
          onPress={iosToggle}>
          <Text style={styles.subheader}>For iOS Devices</Text>
          {iosIcon}
        </TouchableOpacity>

        <Spacer />
        {iosIsOpen ? (
          <>
            <Text style={styles.listItem}>
              1. <Text style={styles.strong}>Unlock your iPhone</Text> and open
              the <Text style={styles.strong}>Camera app</Text>.
            </Text>
            <Text style={styles.listItem}>
              2. <Text style={styles.strong}>Point your iPhone's camera</Text>{' '}
              at the QR code.
            </Text>
            <Text style={styles.listItem}>
              3. <Text style={styles.strong}>Hold your iPhone steady</Text>{' '}
              until it recognizes the QR code.
            </Text>
            <Text style={styles.listItem}>
              4. A notification banner will appear at the bottom of the screen.{' '}
              <Text style={styles.strong}>Tap the banner</Text> to open the link
              associated with the QR code.
            </Text>
            <Spacer />
          </>
        ) : null}

        <TouchableOpacity
          style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
          }}
          onPress={androidToggle}>
          <Text style={styles.subheader}>For Android Devices</Text>
          {androidIcon}
        </TouchableOpacity>

        <Spacer />
        {androidIsOpen ? (
          <>
            <Text style={styles.listItem}>
              1. <Text style={styles.strong}>Unlock your device</Text> and open{' '}
              <Text style={styles.strong}>your Camera app</Text>.
            </Text>
            <Text style={styles.listItem}>
              2. <Text style={styles.strong}>Point your device's camera</Text>{' '}
              at the QR code steadily for 2-3 seconds.
            </Text>
            <Text style={styles.listItem}>
              3. A notification banner will appear.{' '}
              <Text style={styles.strong}>Tap the banner</Text> to open the link
              associated with the QR code.
            </Text>
            <Text style={styles.listItem}>
              4. If nothing happens, you may need to go to your Camera app
              settings to{' '}
              <Text style={styles.strong}>enable QR Code scanning</Text> and try
              again.
            </Text>
            <Text style={styles.listItem}>
              5. If QR Code scanning isn't available in your settings, your
              device might not support it natively.
            </Text>
            <Spacer />
            <Text style={Typography.mediumBody}>
              No worries, you can still scan QR codes using our web version
              (refer to the instructions above).
            </Text>
            <Spacer />
          </>
        ) : null}

        <Spacer />
        <Button
          title="I understand, let's scan!"
          onPress={() => {
            setIsVisible(false);
          }}
        />
      </>
    );
  }, [
    androidIcon,
    androidIsOpen,
    androidToggle,
    colors.brand,
    colors.middlegray,
    iosIcon,
    iosIsOpen,
    iosToggle,
  ]);

  const textEs = useMemo(() => {
    return (
      <>
        <Text
          style={[Typography.header, {color: colors.brand}, styles.message]}>
          ¿Cómo escanear un código QR?
        </Text>
        <Spacer />

        <Text style={styles.header}>
          Usando la Versión Web en Cualquier Dispositivo
        </Text>
        <Spacer />
        <Text style={styles.listItem}>
          1. <Text style={styles.strong}>Abre un navegador web</Text> y ve a
        </Text>
        <Spacer />
        <Text style={[styles.link, styles.message]}>
          <Text
            style={{
              color: colors.middlegray,
              textDecorationLine: 'underline',
            }}>
            https://
          </Text>
          <Text
            style={{
              color: colors.brand,
              textDecorationLine: 'underline',
            }}>
            doppio.cards
          </Text>
        </Text>
        <Spacer />
        <Text style={styles.listItem}>
          2. <Text style={styles.strong}>Toca el botón "Escanear QR"</Text> en
          la parte inferior de la página y{' '}
          <Text style={styles.strong}>apunta la cámara de tu dispositivo</Text>{' '}
          al código QR.
        </Text>
        <Spacer />

        <Text style={styles.header}>
          Usando la Aplicación de Cámara de Tu Dispositivo
        </Text>
        <Spacer />
        <TouchableOpacity
          style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}
          onPress={iosToggle}>
          <Text style={styles.subheader}>Para Dispositivos iOS</Text>
          {iosIcon}
        </TouchableOpacity>

        <Spacer />
        {iosIsOpen ? (
          <>
            <Text style={styles.listItem}>
              1. <Text style={styles.strong}>Desbloquea tu iPhone</Text> y abre
              la <Text style={styles.strong}>aplicación de Cámara</Text>.
            </Text>
            <Text style={styles.listItem}>
              2.{' '}
              <Text style={styles.strong}>Apunta la cámara de tu iPhone</Text>{' '}
              al código QR.
            </Text>
            <Text style={styles.listItem}>
              3. <Text style={styles.strong}>Mantén tu iPhone estable</Text>{' '}
              hasta que reconozca el código QR.
            </Text>
            <Text style={styles.listItem}>
              4. Aparecerá una notificación en la parte inferior de la pantalla.{' '}
              <Text style={styles.strong}>Toca la notificación</Text> para abrir
              el enlace asociado con el código QR.
            </Text>
            <Spacer />
          </>
        ) : null}

        <TouchableOpacity
          style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}
          onPress={androidToggle}>
          <Text style={styles.subheader}>Para Dispositivos Android</Text>
          {androidIcon}
        </TouchableOpacity>

        <Spacer />
        {androidIsOpen ? (
          <>
            <Spacer />
            <Text style={styles.listItem}>
              1. <Text style={styles.strong}>Desbloquea tu dispositivo</Text> y
              abre <Text style={styles.strong}>la aplicación de Cámara</Text>.
            </Text>
            <Text style={styles.listItem}>
              2.{' '}
              <Text style={styles.strong}>
                Apunta la cámara de tu dispositivo
              </Text>{' '}
              al código QR de forma estable durante 2-3 segundos.
            </Text>
            <Text style={styles.listItem}>
              3. Aparecerá una notificación.{' '}
              <Text style={styles.strong}>Toca la notificación</Text> para abrir
              el enlace asociado con el código QR.
            </Text>
            <Text style={styles.listItem}>
              4. Si no sucede nada, es posible que necesites ir a la
              configuración de tu aplicación de Cámara para{' '}
              <Text style={styles.strong}>
                habilitar el escaneo de códigos QR
              </Text>{' '}
              y volver a intentarlo.
            </Text>
            <Text style={styles.listItem}>
              5. Si el escaneo de códigos QR no está disponible en tus ajustes,
              es posible que tu dispositivo no lo admita de forma nativa.
            </Text>
            <Spacer />
            <Text style={Typography.mediumBody}>
              No te preocupes, aún puedes escanear códigos QR usando nuestra
              versión web (consulta las instrucciones anteriores).
            </Text>
            <Spacer />
          </>
        ) : null}

        <Spacer />
        <Button
          title="¡Entendido, vamos a escanear!"
          onPress={() => {
            setIsVisible(false);
          }}
        />
      </>
    );
  }, [
    androidIcon,
    androidIsOpen,
    androidToggle,
    colors.brand,
    colors.middlegray,
    iosIcon,
    iosIsOpen,
    iosToggle,
  ]);

  const modal = useMemo(() => {
    return (
      <BModal
        visible={isVisible}
        onRequestClose={() => setIsVisible(false)}
        contentWrapperStyle={{
          paddingHorizontal: Screen.PADDING_HORIZONTAL,
          paddingVertical: Screen.PADDING_HORIZONTAL * 2,
        }}>
        <ScrollView>
          {localization.getLanguage() === 'es' ? textEs : textEn}
        </ScrollView>
      </BModal>
    );
  }, [isVisible, textEn, textEs]);

  return {modal, showModal, isVisible};
}

const styles = StyleSheet.create({
  container: {alignItems: 'center'},
  message: {
    textAlign: 'center',
  },
  link: {
    ...StyleSheet.flatten(Typography.smallHeader),
  },
  listItem: {...StyleSheet.flatten(Typography.body), marginBottom: 3},
  header: {
    ...StyleSheet.flatten(Typography.smallHeader),
    color: colors.black,
  },
  subheader: {
    ...StyleSheet.flatten(Typography.smallHeader),
    fontSize: 18,
    color: colors.darkgray,
  },
  strong: {fontWeight: 'bold'},
});
