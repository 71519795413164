import {FirebaseDynamicLinksTypes} from '@react-native-firebase/dynamic-links';
import {Logger} from '@b2cmessenger/doppio-shared';
import {DynamicLinksConfig} from '@utils/DynamicLinks/shared';
import {Place} from '@typings/Place';
type PlaceId = Place['id'];

export type DynamicLink = FirebaseDynamicLinksTypes.DynamicLink;
export enum DynamicLinkResult {
  Link = 'link',
  PosInvitation = 'pos_invite',
  EmployeeInvitation = 'employee_invite',
  ManageSubscription = 'manage_subscription',
}

type HandledDynamicLinkPos = {
  type: DynamicLinkResult.PosInvitation;
  placeId: PlaceId;
  code: string;
};
type HandledDynamicLinkEmployee = {
  type: DynamicLinkResult.EmployeeInvitation;
  placeId: PlaceId;
  code: string;
};
type HandledDynamicLinkManageSubscription = {
  type: DynamicLinkResult.ManageSubscription;
};

type HandledDynamicLinkDefault = {
  type: null;
};

type HandledDynamicLinkWithLink = {
  type: DynamicLinkResult.Link;
  link: string;
};

export function handleDynamicLink(dl: DynamicLink) {
  const {path} = parseUrlFromDynamicLink(dl.url);

  if (path) {
    if (path.startsWith('/pos')) {
      const [placeId, code] = path.substr('/pos/'.length).split('/');
      return {
        type: DynamicLinkResult.PosInvitation,
        placeId: Number(placeId),
        code,
      } as HandledDynamicLinkPos;
    } else if (path.startsWith('/employee')) {
      const [placeId, code] = path.substr('/employee/'.length).split('/');
      return {
        type: DynamicLinkResult.EmployeeInvitation,
        placeId: Number(placeId),
        code,
      } as HandledDynamicLinkEmployee;
    } else if (path.startsWith('/manage-subscription')) {
      return {
        type: DynamicLinkResult.ManageSubscription,
      } as HandledDynamicLinkManageSubscription;
    } else if (path.startsWith('/link')) {
      const [link] = path.substr('/link'.length).split('/').filter(Boolean);

      return {
        type: DynamicLinkResult.Link,
        link,
      } as HandledDynamicLinkWithLink;
    }
  }
  return {
    type: null,
  } as HandledDynamicLinkDefault;
}

export type HandledDynamicLink = ReturnType<typeof handleDynamicLink>;

export function getRouteFromHandledDynamicLink(handledDL: HandledDynamicLink) {
  if (handledDL.type !== null) {
    Logger.verboseTag(
      'getRouteFromHandledDynamicLink',
      `found ${handledDL.type} link`,
    );
    if (
      handledDL.type === DynamicLinkResult.PosInvitation ||
      handledDL.type === DynamicLinkResult.EmployeeInvitation
    ) {
      return {
        name: 'JoinAsEmployee',
        params: {
          placeId: handledDL.placeId,
          code: handledDL.code,
        },
      } as const;
    } else if (handledDL.type === DynamicLinkResult.ManageSubscription) {
      return {
        name: 'Dashboard',
        params: {
          screen: 'ManageSubscription',
        },
      } as const;
    } else if (handledDL.type === DynamicLinkResult.Link) {
      return {
        name: 'Link',
        params: {
          link: handledDL.link,
        },
      } as const;
    }
  }

  return null;
}

const DynamicLinkDomain = DynamicLinksConfig.Business.DynamicLinkDomain;

function parseUrlFromDynamicLink(url: string) {
  if (url.startsWith(DynamicLinkDomain)) {
    const [path, _params] = url.substr(DynamicLinkDomain.length).split('?');
    const params = new URLSearchParams(_params);
    return {
      url,
      path,
      params,
    };
  }

  return {
    url,
    path: undefined,
  };
}
