import {PlaceAppearanceConfig} from '@components/common/AppearanceCustomizer';
import {colors} from '@b2cmessenger/doppio-shared';
import {TouchableOpacity, View} from 'react-native';
import {PlaceAppearance} from '@b2cmessenger/doppio-core';
import React, {useMemo} from 'react';
import {
  pickerItemSize,
  pickerStyles,
  PossiblePlaceAppearances,
} from '@components/common/AppearanceCustomizer/shared';
import {TEST_IDS} from '@components/common/AppearanceCustomizer/AppearancePickerTestIDS';

const ColorPicker = (props: {
  appearances: PossiblePlaceAppearances;
  value: PlaceAppearanceConfig;
  changeValue: (p: Partial<PlaceAppearanceConfig>) => void;
}) => {
  const {appearances, value, changeValue} = props;
  const defaultColor = colors.brand;
  const accessibilityValue = useMemo(
    () => ({text: String(value.color || 'default')}),
    [value],
  );

  return (
    <View
      style={pickerStyles.pickerContainerInner}
      testID={TEST_IDS.elements.ColorPicker}
      accessibilityValue={accessibilityValue}>
      {appearances.colors.map((_color) => {
        const color = _color === defaultColor ? null : _color;
        return (
          <TouchableOpacity
            style={pickerStyles.pickerItem}
            activeOpacity={100}
            key={color || 'default'}
            testID={TEST_IDS.elements.Color + (color || 'default')}
            onPress={() => {
              changeValue({color});
            }}>
            <PlaceAppearance.Icon
              strokeColor={color === value.color ? undefined : 'transparent'}
              color={color || defaultColor}
              size={pickerItemSize}
              strokeInside={color === value.color}
            />
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

export default ColorPicker;
