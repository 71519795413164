import React, {ComponentProps} from 'react';
import {StyleSheet} from 'react-native';
import {SocialSignInButton} from '@b2cmessenger/doppio-components';
import {colors} from '@b2cmessenger/doppio-shared';

export function InvertedSocialSignInButton({
  iconFill = colors.white,
  ...rest
}: ComponentProps<typeof SocialSignInButton>) {
  return (
    <SocialSignInButton
      style={rest.type === 'apple' ? styles.appleButton : styles.button}
      titleStyle={styles.buttonTitle}
      iconFill={iconFill}
      {...rest}
    />
  );
}

const commonButtonStyle = {
  paddingLeft: 26,
};
const styles = StyleSheet.create({
  button: {
    ...commonButtonStyle,
    backgroundColor: colors.brand,
  },
  appleButton: {
    ...commonButtonStyle,
    backgroundColor: colors.black,
  },
  buttonTitle: {
    color: colors.white,
  },
});
