import React, {useMemo} from 'react';
import {StyleProp, StyleSheet, View, ViewProps, ViewStyle} from 'react-native';
import QRCode from './QRCode';
import {colors} from '@b2cmessenger/doppio-shared';
import {PlaceholderMedia} from 'rn-placeholder';

const PADDING = 20;

export function PrettyQRCode({
  value,
  size: _size = 227,
  style: _style,
  ...rest
}: ViewProps & {
  loading?: boolean;
  value: string;
  size?: number;
}) {
  const size = useMemo(() => _size - PADDING * 2, [_size]);
  const style = useMemo(
    () => [styles.wrapper, {width: _size, height: _size}, _style],
    [_size, _style],
  );
  return (
    <View style={style} {...rest}>
      <QRCode size={size} value={value} ecl="H" color={colors.black} />
    </View>
  );
}

export function PrettyQRCodePlaceholder({
  size: _size = 227,
  style: _style,
}: {
  size?: number;
  style?: StyleProp<ViewStyle>;
}) {
  const size = useMemo(() => _size - PADDING * 2, [_size]);
  const style = useMemo(
    () => [styles.wrapper, {width: _size, height: _size}, _style],
    [_size, _style],
  );
  return (
    <View style={style}>
      <PlaceholderMedia size={size} />
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    padding: PADDING,
    borderRadius: PADDING,
    backgroundColor: colors.white,
    shadowColor: colors.black,
    shadowOpacity: 0.4,
    shadowRadius: 4,
    shadowOffset: {
      width: 0,
      height: 4,
    },
  },
});
