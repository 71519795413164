import {useCallback, useState} from 'react';
import {Alert} from '@components/common/Alert';
import {PlaceEmployeeSearchResultEmployee} from '@typings/Place';
import {Logger} from '@b2cmessenger/doppio-shared';
import {api} from '@b2cmessenger/doppio-core';
import {localization, useTranslation} from '@shared';

export function usePlaceEmployeeRemoval({
  placeId,
  onEmployeeRemoved,
}: {
  placeId: number;
  onEmployeeRemoved?: (
    e: PlaceEmployeeSearchResultEmployee['user']['id'],
  ) => void;
}) {
  const [id, setId] = useState<number>();
  const {t} = useTranslation();

  const remove = useCallback(
    async (employeeId: number) => {
      setId(employeeId);
      try {
        await removeEmployee(placeId, employeeId);
        if (onEmployeeRemoved) {
          onEmployeeRemoved(employeeId);
        }
      } catch (e) {
        //TODO fix it (add delete code)
        if (e === 'Employee not found') {
          if (onEmployeeRemoved) {
            onEmployeeRemoved(employeeId);
          }
        } else {
          Logger.errorTag('usePlaceEmployeeRemoval', e);
        }
      } finally {
        setId(undefined);
      }
    },
    [onEmployeeRemoved, placeId],
  );

  const onDelete = useCallback(
    (item: PlaceEmployeeSearchResultEmployee['user']) => {
      const _title = t('Screens.Profile.usePlaceEmployeeRemoval.areYouSure');
      const device = t(
        'Screens.Profile.usePlaceEmployeeRemoval.deviceWillBeDeleted',
        {getEntityName: getEntityName(item)},
      );
      const employee = t(
        'Screens.Profile.usePlaceEmployeeRemoval.employeeWillBeDeleted',
        {getEntityName: getEntityName(item)},
      );
      const _message = isDevice(item) ? device : employee;

      Alert.alert(_title, _message, [
        {
          text: t('Screens.Profile.usePlaceEmployeeRemoval.delete') || '',
          style: 'destructive',
          onPress: () => {
            remove(item.id);
          },
        },
        {
          text: t('Screens.Profile.usePlaceEmployeeRemoval.cancel') || '',
          style: 'cancel',
        },
      ]);
    },
    [remove, t],
  );

  return {
    onDelete,
    removingEmployeeId: id,
  };
}

function removeEmployee(placeId: number, employeeId: number) {
  const data = new FormData();
  data.append('place_id', String(placeId));
  data.append('user_id', String(employeeId));
  return api.default
    .post<{success: boolean}>('/api/place/employee/delete', data)
    .catch(e => {
      throw api.parseErrorToHumanReadableMessage(e);
    })
    .then(api.getResponseData)
    .then(({success}) => success);
}

function isDevice(
  user: Pick<PlaceEmployeeSearchResultEmployee['user'], 'is_device'>,
) {
  return user.is_device === 1;
}

function getEntityName(item: PlaceEmployeeSearchResultEmployee['user']) {
  const unknownDevice = localization.t(
    'Screens.Profile.DevicesAndEmployees.unknownDevice',
  );
  const unknownPerson = localization.t(
    'Screens.Profile.DevicesAndEmployees.unknownPerson',
  );
  const _unknown = isDevice(item) ? unknownDevice : unknownPerson;

  return [item.firstname, item.lastname].filter(Boolean).join(' ') || _unknown;
}
