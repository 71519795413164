import React, {useMemo} from 'react';
import {TouchableOpacity, View, ViewStyle} from 'react-native';
import {Stamp} from '@b2cmessenger/doppio-components';

export function StampList({
  maxStamps = 15,
  stampPerRow = 5,
  stampSize = 48,
  value,
  onChange,
  stampElementTestID,
  IconComponent,
}: {
  maxStamps?: number;
  stampPerRow?: number;
  stampSize?: number;
  value: number;
  onChange?: (value: number) => void;
  stampElementTestID?: string;
  IconComponent?: (props: {size: number; active: boolean}) => JSX.Element;
}) {
  const Icon = useMemo(() => IconComponent || Stamp, [IconComponent]);

  const data = useMemo(
    () =>
      Array.from({
        length: Math.ceil(maxStamps / stampPerRow),
      }).map((_, rowIdx) =>
        Array.from({
          length:
            stampPerRow * rowIdx + 1 <= maxStamps
              ? stampPerRow
              : maxStamps - stampPerRow * rowIdx,
        }).map((__, colIdx) => rowIdx * stampPerRow + colIdx + 1),
      ),
    [maxStamps, stampPerRow],
  );
  const rowStyle = useMemo<ViewStyle>(() => ({flexDirection: 'row'}), []);
  return useMemo(
    () => (
      <>
        {data.map((row, idx) => (
          <View key={String(idx)} style={rowStyle}>
            {row.map((el) => (
              <TouchableOpacity
                testID={
                  stampElementTestID ? `${stampElementTestID}${el}` : undefined
                }
                key={String(el)}
                onPress={onChange ? onChange.bind(null, el) : undefined}
                activeOpacity={onChange ? 0.8 : undefined}>
                <Icon size={stampSize} active={value >= el} />
              </TouchableOpacity>
            ))}
          </View>
        ))}
      </>
    ),
    [data, onChange, rowStyle, stampElementTestID, stampSize, value],
  );
}
