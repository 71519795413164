import {Place} from '@typings/Place';
import React, {useCallback, useMemo} from 'react';
import {Spacer, useTheme} from '@b2cmessenger/doppio-components';
import {imageUploadMethods} from '@b2cmessenger/doppio-core';
import {
  ImageUploader,
  onImageUploadOptions,
} from '@b2cmessenger/doppio-image-uploader';
import {
  PlaceAvatar,
  PlaceName,
} from '@screens/Dashboard/screens/Profile/components/Place';
import {ViewProps} from 'react-native';
import {useTranslation} from '@shared';

type ProfileHeaderProps = {
  onWorkplaceNamePress?: () => void;
  onUploadWorkplaceLogo?: (opt: onImageUploadOptions) => void;
  workplaceImageLoading: boolean;
  workplaceLogo: Place['logo'];
  workplaceName: Place['name'];
} & Partial<{
  placeNameTestID: ViewProps['testID'];
  placeNameAccessibilityValue: ViewProps['accessibilityValue'];
}>;

export function ProfileHeader(props: ProfileHeaderProps) {
  const {t} = useTranslation();
  const {colors} = useTheme();
  const {
    workplaceName,
    onWorkplaceNamePress,
    workplaceLogo,
    onUploadWorkplaceLogo,
    workplaceImageLoading,
    placeNameTestID,
    placeNameAccessibilityValue,
  } = props;

  const renderPlaceAvatar = useCallback(
    ({disabled, display}: {disabled: boolean; display: () => void}) => (
      <PlaceAvatar
        iconFill={colors.brand}
        uri={workplaceLogo}
        onPress={onUploadWorkplaceLogo ? display : undefined}
        disabled={disabled || workplaceImageLoading}
        loading={disabled || workplaceImageLoading}
      />
    ),
    [colors.brand, onUploadWorkplaceLogo, workplaceImageLoading, workplaceLogo],
  );

  const onUpload = useMemo(() => onUploadWorkplaceLogo || (() => {}), [
    onUploadWorkplaceLogo,
  ]);

  return (
    <>
      <ImageUploader
        pick={{
          source: 'all',
          cameraOptionText: t('Screens.Profile.Header.openCamera') || '',
          cameraRollOptionText: t('Screens.Profile.Header.pickImage') || '',
          cancelOptionText: t('Screens.Profile.Header.cancel') || '',
          actionSheet: {
            title: t('Screens.Profile.Header.chooseAnAction') || '',
          },
        }}
        upload={{
          type: 'bl',
          options: {imageUploadFunc: imageUploadMethods.uploadImage},
        }}
        onUpload={onUpload}
        render={renderPlaceAvatar}
      />
      <Spacer height={20} />
      <PlaceName
        testID={placeNameTestID}
        accessibilityValue={placeNameAccessibilityValue}
        name={workplaceName}
        iconFill={colors.brand}
        onPress={onWorkplaceNamePress}
        disabled={onWorkplaceNamePress === undefined}
      />
    </>
  );
}
