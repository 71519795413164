import {api} from '@b2cmessenger/doppio-core';
import {Place} from '@typings/Place';
import {AxiosRequestConfig} from 'axios';
import {Logger} from '@b2cmessenger/doppio-shared';

export async function checkInvite(
  {
    code,
    placeId,
  }: {
    code: string;
    placeId: number;
  },
  requestConfig?: AxiosRequestConfig,
) {
  return api.default
    .get<{place: Place}>(
      `/api/v2/place/${String(placeId)}/employee/invite/${code}`,
      requestConfig,
    )
    .then(api.getResponseData)
    .catch((e) => {
      Logger.errorTag('checkInvite', e);
      throw api.parseErrorToHumanReadableMessage(e);
    });
}
