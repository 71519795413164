import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import {
  ActivityIndicator,
  Platform,
  StyleSheet,
  Text,
  View,
  ImageBackground,
} from 'react-native';
import {
  Screen,
  SocialSignInButton,
  Spacer,
  Typography,
  Pusher,
  TextDivider,
  Button,
} from '@b2cmessenger/doppio-components';

import {
  dismissLoginError,
  performLoginViaApple,
  performLoginViaFacebook,
  performLoginViaGoogle,
} from '@store/actions';
import {useDispatch, useSelector} from 'react-redux';
import Config from '@utils/Config';
import useGoogleSignIn from '@components/hooks/auth/useGoogleSignIn';
import useFacebookSignIn from '@components/hooks/auth/useFacebookSignIn';
import useAppleSignIn from '@components/hooks/auth/useAppleSignIn';
import {TEST_IDS} from '@screens/SignInTestIDS';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {appSelectors, shouldPreloadAppearance} from '@store/selectors';
import {RootNavigatorScreenProps} from '@navigation/config';
import {IconDoppio, YouTubeIcon} from '@components/common/icons/SvgIcon';
import {navigationRef} from '@navigation/navigationRef';
import {Alert} from '@components/common/Alert';
import {useIsFocused} from '@react-navigation/native';
import {Analytics} from '@b2cmessenger/doppio-core';
import {colors, Logger} from '@b2cmessenger/doppio-shared';
import FirebaseRemoteConfigContext from '@utils/FirebaseRemoteConfigContext';
import {openPrivacyPolicy, openTos} from '@utils/policiesAndEtc';
import {
  getLanguageName,
  localization,
  SharedHooks,
  useChangeLanguage,
  useGetCurrentLanguage,
  useTranslation,
} from '@shared';
import {
  POSSIBLE_PLACE_APPEARANCES_SWR_KEY,
  possiblePlaceAppearancesFetcher,
} from '@components/common/AppearanceCustomizer';
import {preload} from 'swr';
import {isTablet} from 'react-native-device-info';
// @ts-ignore
import BackgroundPatternWatermarkImage from '../../assets/background_pattern_watermark.png';

useGoogleSignIn.configure(
  Config.GOOGLE_WEB_CLIENT_ID,
  Config.GOOGLE_IOS_CLIENT_ID,
);

export function SignInScreen({navigation}: RootNavigatorScreenProps<'SignIn'>) {
  const {top, bottom} = useSafeAreaInsets();
  const isLoggingIn = useSelector(appSelectors.isLoggingIn);
  const openExtendedDemo = SharedHooks.useExtendedDemo();
  const shouldFetchAppearance = useSelector(shouldPreloadAppearance);
  const {t} = useTranslation();

  useEffect(() => {
    function _preload(locale: string) {
      if (shouldFetchAppearance) {
        preload([POSSIBLE_PLACE_APPEARANCES_SWR_KEY, locale], ([url]) =>
          possiblePlaceAppearancesFetcher(url),
        );
      }
    }

    _preload(localization.getLanguage());

    localization.events.addListener('languageChanged', _preload);
    return () => {
      localization.events.removeListener('languageChanged', _preload);
    };
  }, [shouldFetchAppearance]);

  const joinToCompany = useCallback(() => {
    navigation.navigate('JoinAsEmployee');
  }, [navigation]);

  const headerText = useMemo(() => {
    return t('Screens.SignIn.loyaltyProgramForYourBusiness');
  }, [t]);
  const currentLanguage = useGetCurrentLanguage();
  const currentLanguageName = useMemo(
    () => getLanguageName(currentLanguage),
    [currentLanguage],
  );

  const {changeLanguage} = useChangeLanguage({
    title: t('Screens.SignIn.chooseYourLanguage'),
  });

  return (
    <Screen style={s.screen} testID={TEST_IDS.screen}>
      <ImageBackground
        style={s.backgroundContainer}
        source={BackgroundPatternWatermarkImage}
        resizeMode="cover">
        <Spacer height={top} />
        <Pusher />
        <View>
          <View style={s.logoWrapper}>
            <IconDoppio />
          </View>
          <Spacer height={32} />
          <Text style={s.header}>{headerText}</Text>
          <Spacer height={20} />
          <SignUpContainer loading={isLoggingIn} />
          {Platform.OS === 'ios' ? (
            <>
              <Spacer />
              <View style={s.policiesContainer}>
                <Text style={[s.policiesText, s.policyLink]} onPress={openTos}>
                  {t('Screens.SignIn.termsOfUse')}
                </Text>
                <Text style={s.policiesText}> • </Text>
                <Text
                  style={[s.policiesText, s.policyLink]}
                  onPress={openPrivacyPolicy}>
                  {t('Screens.SignIn.privacyPolicy')}
                </Text>
              </View>
            </>
          ) : null}
          <Spacer />
          <View style={s.demoContainer}>
            <YouTubeIcon color={colors.lightgray} size={28} />
            <Text
              style={[s.demoLink]}
              onPress={() => openExtendedDemo('sign-in')}>
              {t('Screens.SignIn.watchGetdoppioProductDemo')}
            </Text>
          </View>
          <Spacer />
          <View style={s.languageContainer}>
            <Text style={[s.language]} onPress={changeLanguage}>
              {currentLanguageName}
            </Text>
          </View>
        </View>
        <Pusher />
        <Spacer />
        <TextDivider
          text={t('Screens.SignIn.or') || 'or'}
          color={colors.white}
        />
        <Spacer />
        <Button.Inverted
          onPress={joinToCompany}
          testID={TEST_IDS.buttons.JoinToCompany}
          title={t('Screens.SignIn.joinExistingAccount')}
          subtitle={t('Screens.SignIn.forPosDevicesAndEmployees') || ''}
        />
        <Spacer height={Math.max(bottom, 16)} />
      </ImageBackground>
    </Screen>
  );
}
function SignUpContainer({loading = false}: {loading?: boolean}) {
  const remoteConfigValues = useContext(FirebaseRemoteConfigContext);
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const loginError = useSelector(appSelectors.loginError);
  const isFocused = useIsFocused();
  useEffect(() => {
    if (loginError && isFocused) {
      Logger.verboseTag('SignUpContainer [loginError]', loginError);
      Alert.alert('Error', loginError, [
        {
          text: t('Screens.SignIn.ok') || 'ok',
          style: 'cancel',
          onPress: dispatch.bind(null, dismissLoginError()),
        },
      ]);
    }
  }, [dispatch, isFocused, loginError, t]);

  const onAppleSignInSucceed = useCallback(
    (token, data) => {
      dispatch(performLoginViaApple(token, data));
    },
    [dispatch],
  );
  const onAppleSignInFailed = useCallback((error, raw) => {
    Logger.errorTag('SignIn with Apple', error, raw);
  }, []);
  const {
    isAvailable: isAppleSignInAvailable,
    loading: signingInApple,
    authorize: _signInApple,
  } = useAppleSignIn({
    onSuccess: onAppleSignInSucceed,
    onError: onAppleSignInFailed,
  });
  const signInApple = useCallback(() => {
    logLoginAttempt('apple');
    _signInApple();
  }, [_signInApple]);

  const onFacebookSignInSucceed = useCallback(
    (token, data) => {
      dispatch(performLoginViaFacebook(token, data));
    },
    [dispatch],
  );
  const onFacebookSignInFailed = useCallback(error => {
    Logger.errorTag('SignIn with Facebook', error);
  }, []);
  const {loading: signingInFacebook, authorize: _signInFacebook} =
    useFacebookSignIn({
      webClientId: Config.FACEBOOK_APP_ID,
      onSuccess: onFacebookSignInSucceed,
      onError: onFacebookSignInFailed,
    });
  const signInFacebook = useCallback(() => {
    logLoginAttempt('facebook');
    _signInFacebook();
  }, [_signInFacebook]);

  const onGoogleSignInSucceed = useCallback(
    (serverAuthCode: string) => {
      dispatch(performLoginViaGoogle(serverAuthCode));
    },
    [dispatch],
  );
  const onGoogleSignInFailed = useCallback((error, more) => {
    Logger.errorTag('SignIn with Google', error, more);
  }, []);
  const {loading: signingInGoogle, authorize: _signInGoogle} = useGoogleSignIn({
    clientId: Config.GOOGLE_WEB_CLIENT_ID,
    onSuccess: onGoogleSignInSucceed,
    onError: onGoogleSignInFailed,
  });
  const signInGoogle = useCallback(() => {
    logLoginAttempt('google');
    _signInGoogle();
  }, [_signInGoogle]);

  const buttonStyle = useMemo(
    () => (loading ? {opacity: 0} : undefined),
    [loading],
  );

  const onPressSignInByEmail = useCallback(() => {
    logLoginAttempt('email');
    navigationRef.current?.navigate('EmailSignIn');
  }, []);

  const displaySignInByEmail =
    Config.MODE !== 'production' || remoteConfigValues?.displaySignInByEmail;

  const signInWithApple = t('Screens.SignIn.signInWithApple');
  const signInWithFacebook = t('Screens.SignIn.signInWithFacebook');
  const signInWithGoogle = t('Screens.SignIn.signInWithGoogle');
  const currentLanguage = useGetCurrentLanguage();
  const _isTablet = isTablet();
  const socialButtonStyles = useMemo(() => {
    if (currentLanguage.match('es')) {
      return _isTablet ? {width: 315} : {width: 275};
    }

    return undefined;
  }, [currentLanguage, _isTablet]);

  return (
    <View style={s.signUp} pointerEvents={loading ? 'none' : undefined}>
      <View style={buttonStyle}>
        {isAppleSignInAvailable ? (
          <>
            <SocialSignInButton.Apple
              testID={TEST_IDS.buttons.Apple}
              disabled={signingInApple}
              onPress={signInApple}
              title={signInWithApple}
              style={socialButtonStyles}
            />
            <Spacer />
          </>
        ) : null}
        <SocialSignInButton.Facebook
          testID={TEST_IDS.buttons.Facebook}
          disabled={signingInFacebook}
          onPress={signInFacebook}
          title={signInWithFacebook}
          style={socialButtonStyles}
        />
        <Spacer />
        <SocialSignInButton.Google
          testID={TEST_IDS.buttons.Google}
          disabled={signingInGoogle}
          onPress={signInGoogle}
          title={signInWithGoogle}
          style={socialButtonStyles}
        />
        <Spacer />
        {displaySignInByEmail ? (
          <SocialSignInButton.Email
            iconFill={colors.black}
            title={t('Screens.SignIn.signInWithEmail') || 'Sign in with email'}
            onPress={onPressSignInByEmail}
            style={socialButtonStyles}
          />
        ) : null}
      </View>
      {loading ? (
        <ActivityIndicator
          size="large"
          color={colors.white}
          style={StyleSheet.absoluteFill}
        />
      ) : null}
    </View>
  );
}

function logLoginAttempt(channel: 'email' | 'apple' | 'google' | 'facebook') {
  Analytics.logEvent('login_attempt', {channel});
}

const s = StyleSheet.create({
  screen: {
    backgroundColor: colors.brand,
    paddingHorizontal: 0,
  },
  backgroundContainer: {
    display: 'flex',
    flexGrow: 1,
    paddingHorizontal: Screen.PADDING_HORIZONTAL,
  },
  logoWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  header: {
    ...StyleSheet.flatten(Typography.header),
    color: colors.white,
    textAlign: 'center',
  },
  signUp: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  policiesContainer: {
    flexDirection: 'row',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  policiesText: {
    color: colors.lightgray,
  },
  policyLink: {
    textDecorationLine: 'underline',
  },
  demoContainer: {
    paddingTop: 20,
    flexDirection: 'row',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
  },
  demoLink: {
    color: colors.lightgray,
    textDecorationLine: 'underline',
    marginLeft: 10,
  },
  languageContainer: {
    flexDirection: 'row',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
  },
  language: {
    color: colors.lightgray,
    textDecorationLine: 'underline',
    marginLeft: 10,
  },
});
