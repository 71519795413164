import React from 'react';
import {Spacer} from '@b2cmessenger/doppio-components';
import {Text} from 'react-native';
import {Divider} from 'react-native-paper';
import {PlaceSubscriptionStateExtended} from '@typings/Place';
import {useTranslation} from '@shared';

type ManageProps = {
  subscriptionStateExtended: PlaceSubscriptionStateExtended;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Manage(props: ManageProps) {
  const {t} = useTranslation();
  return (
    <>
      <Divider />
      <Spacer />
      <Text>
        {t('Screens.Manage.managingSubscriptionIsNotAvailableInWebVersion')}
      </Text>
    </>
  );
}

export default Manage;
