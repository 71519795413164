import {Text} from 'react-native';
import React, {useMemo} from 'react';
import {
  Card,
  Screen,
  Spacer,
  Typography,
} from '@b2cmessenger/doppio-components';
import {StampList} from '@components/common/StampList';
import {isTablet} from 'react-native-device-info';
import useWindowInfo from '@hooks/useWindowInfo';
import {useTranslation} from '@shared';
const _isTablet = isTablet();
function StampCard({
  value,
  onChange,
  testID,
  stampElementTestID,
  awardName,
  description,
  IconComponent,
  FooterComponent,
}: {
  value: number;
  onChange?: (value: number) => void;
  testID?: string;
  stampElementTestID?: string;
  awardName: string;
  description?: string;
  IconComponent?: (props: {size: number; active: boolean}) => JSX.Element;
  FooterComponent?: React.ComponentType<any> | React.ReactElement | null;
}) {
  const {t} = useTranslation();
  const {width} = useWindowInfo();
  const stampPerRow = 5;
  const stampSize = useMemo(
    () =>
      Math.min(
        70,
        (width -
          (Screen.PADDING_HORIZONTAL * 2 + Card.PADDING_HORIZONTAL * 2)) /
          stampPerRow,
      ),
    [stampPerRow, width],
  );
  const accessibilityValue = useMemo(() => ({text: String(value)}), [value]);
  const _desc = t('Components.StampCard.tapToSetHowManyStamps', {
    awardName: awardName,
  });
  return (
    <Card
      testID={testID}
      accessibilityValue={accessibilityValue}
      style={
        _isTablet
          ? {
              maxWidth: stampSize * stampPerRow + Card.PADDING_HORIZONTAL * 2,
              alignItems: 'center',
            }
          : {}
      }
    >
      <Text style={Typography.smallHeader}>
        {description ? description : _desc}
      </Text>
      <Spacer />
      <StampList
        stampElementTestID={stampElementTestID}
        value={value}
        onChange={onChange}
        stampSize={stampSize}
        stampPerRow={5}
        IconComponent={IconComponent}
      />
      {FooterComponent}
    </Card>
  );
}

export default StampCard;
