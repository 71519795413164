import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {loginedUserSelectors} from '@store/selectors';
import {useLogoutPrompt} from '@components/hooks/useLogoutPrompt';
import {Navbar} from '@b2cmessenger/doppio-components';
import {getUserName} from '@utils/utils';

const LoggedInUserNavbar = () => {
  const profile = useSelector(loginedUserSelectors.profile);
  const promptLogout = useLogoutPrompt();
  const name = useMemo(() => {
    if (!profile) {
      return '';
    }
    return getUserName(profile);
  }, [profile]);

  return profile !== null ? (
    <Navbar.LoggedInUser
      name={name}
      avatar={profile.avatarThumb || undefined}
      onLogOut={promptLogout}
    />
  ) : null;
};

export default LoggedInUserNavbar;
