import type {Say2B} from './ApiSpec';

export type Notification = Omit<Say2B.Schemas.Notification, 'data'> & {
  data: object;
};
export enum NotificationStatus {
  New = 0,
  Read = 3,
}

type HeadlessNotification = Omit<Notification, 'app_event' | 'data'>;

type StampScanNotificationDataCommon = {
  place_id: number;
  employee_user_id: number;
};

type StampScanNotificationDataUserAware = {
  user_id: number;
  user_fullname: string;
  balance: number;
  iat: number;
};

export type StampScanNotification = HeadlessNotification & {
  app_event: 'stampScan';
  data: StampScanNotificationDataCommon &
    (
      | ({
          type: 'give';
          current: number;
        } & StampScanNotificationDataUserAware)
      | ({
          type: 'award';
          current: number;
          award_id: number;
          award_quantity: number;
        } & StampScanNotificationDataUserAware)
      | ({
          type: 'award_failed';
          award_remains: number;
        } & StampScanNotificationDataUserAware)
      | ({
          type: 'give_register';
          current: number;
        } & Pick<StampScanNotificationDataUserAware, 'iat'>)
    );
};

type EmployeeInviteAcceptNotification = HeadlessNotification & {
  app_event: 'employeeInviteAccept';
  data: {
    user_id: number;
    user_name: string;
    is_device: boolean;
    suggested_by: number;
    place_id: number;
  };
};

export type UserEngagementNotification = HeadlessNotification & {
  app_event: 'userEngagement';
  data: {
    dialog_title: string;
    dialog_description?: string;
    dialog_button_text?: string;
    dialog_button_uri?: string;
    dialog_button_press_event?: string;
    dialog_authorized_button_uri?: string;
  };
};

export function isStampScanNotification(
  n: Notification,
): n is StampScanNotification {
  return n.app_event === 'stampScan';
}

export function isEmployeeInviteAcceptNotification(
  n: Notification,
): n is EmployeeInviteAcceptNotification {
  return n.app_event === 'employeeInviteAccept';
}

export function isUserEngagementNotification(
  n: Notification,
): n is UserEngagementNotification {
  return n.app_event === 'userEngagement';
}
