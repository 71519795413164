import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {localization} from '@shared';

type State = 'loyalty' | 'stamp' | 'award' | 'result';
const initialState = {
  state: 'loyalty' as State,
  counter: 1,
  caption: '',
  isWithInstruction: false,
};
function getInitialState() {
  return {
    state: 'loyalty' as State,
    counter: 1,
    caption: localization.t(
      'Screens.QRCode.slice.scanQrToCheckCustomerBalance',
    ),
    isWithInstruction: false,
  };
}

const {reducer, actions} = createSlice({
  name: 'QRCodeGenerator',
  initialState: initialState,
  reducers: {
    init: () => getInitialState(),
    setLoyaltyState: () => getInitialState(),
    setStampState: (state, action: PayloadAction<number>) => ({
      state: 'stamp',
      counter: action.payload,
      caption: getCaption('stamp'),
      isWithInstruction: true,
    }),
    setAwardState: (state, action: PayloadAction<number>) => ({
      state: 'award',
      counter: action.payload,
      caption: getCaption('award'),
      isWithInstruction: true,
    }),
    setResultState: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<'award' | 'stamp' | 'error'>,
    ) => ({
      state: 'result',
      caption: getCaption('result'),
      counter: 1,
      isWithInstruction: false,
    }),
    updateCaption: state => {
      state.caption = getCaption(state.state);
    },
  },
});

function getCaption(state: State) {
  switch (state) {
    case 'loyalty':
      return localization.t(
        'Screens.QRCode.slice.scanQrToCheckCustomerBalance',
      );
    case 'stamp':
      return localization.t('Screens.QRCode.slice.howToScan');
    case 'award':
      return localization.t('Screens.QRCode.slice.howToScan');
    default:
      return '';
  }
}

export {reducer, actions, initialState};
