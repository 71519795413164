import {useEffect, useState} from 'react';
import localization, {LANGUAGES} from './index';

export function useGetCurrentLanguage() {
  const [currentLanguage, setCurrentLanguage] = useState(
    localization.getLanguage(),
  );
  useEffect(() => {
    const updateCurrentLanguage = (lng: string) => {
      setCurrentLanguage(lng);
    };
    localization.events.addListener('languageChanged', updateCurrentLanguage);
    return () => {
      localization.events.removeListener(
        'languageChanged',
        updateCurrentLanguage,
      );
    };
  }, []);

  return currentLanguage;
}

export function getLanguageName(lng: string) {
  return LANGUAGES.find(l => lng.match(l.lng))?.name || LANGUAGES[0].name;
}
