import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import {Navbar, Screen, Spacer} from '@b2cmessenger/doppio-components';
import {RootNavigatorScreenProps} from '@navigation/config';
import {BarCodeScanner, BarCodeScannerProps} from 'expo-barcode-scanner';
import {parseDynamicLink} from '@utils/DynamicLinks/parseDynamicLink';
import {checkInvite} from '@screens/JoinAsEmployee/checkInvite';
import {useDispatch, useSelector} from 'react-redux';
import {appSelectors, loginedUserSelectors} from '@store/selectors';
import {
  acceptPlaceInvite,
  dismissLoginError,
  performLoginViaApple,
  performLoginViaFacebook,
  performLoginViaGoogle,
} from '@store/actions';
import {QRCodeScanner} from '@screens/JoinAsEmployee/components/QRCodeScanner';
import {slice, Stages} from '@screens/JoinAsEmployee/slice';
import {ActivityIndicator, Platform, StyleSheet} from 'react-native';
import {Auth} from '@screens/JoinAsEmployee/components/Auth';
import {isTablet} from 'react-native-device-info';
import {TEST_IDS} from './JoinAsEmployeeTestIDS';
import {Analytics} from '@b2cmessenger/doppio-core';
import {colors} from '@b2cmessenger/doppio-shared';
import {DynamicLinksConfig} from '@utils/DynamicLinks/shared';
import {useTranslation} from '@shared';

const _isTablet = isTablet();
export function JoinAsEmployee({
  route,
  navigation,
}: RootNavigatorScreenProps<'JoinAsEmployee'>) {
  const {t} = useTranslation();

  const isLoggedIn = useSelector(loginedUserSelectors.isLoggedIn);
  const isLoggingIn = useSelector(appSelectors.isLoggingIn);
  const workplace = useSelector(loginedUserSelectors.workplace);
  const hasWorkplace = workplace !== null;

  const loginError = useSelector(appSelectors.loginError);
  const _dispatch = useDispatch();

  const [hasPermission, setHasPermission] = useState<null | boolean>(null);
  const reqCamPermission = useCallback(async () => {
    const {status} = await BarCodeScanner.requestPermissionsAsync();
    setHasPermission(status === 'granted');
  }, []);

  const onBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else if (isLoggedIn && hasWorkplace) {
      navigation.navigate('Dashboard', {screen: 'QRCode'});
    } else if (isLoggedIn) {
      dispatch(slice.actions.reset());
      navigation.navigate('BusinessWizard');
    } else {
      navigation.navigate('SignIn');
    }
  }, [hasWorkplace, isLoggedIn, navigation]);

  const [state, dispatch] = useReducer(
    slice.reducer,
    slice.reducer(undefined, slice.actions.init()),
  );

  useEffect(() => {
    if (
      !(route.params && route.params.placeId && route.params.code) &&
      state.stage === Stages.initial
    ) {
      dispatch(slice.actions.startScanner());
    }
  }, [route.params, state.stage]);

  useEffect(() => {
    if (!hasPermission && state.stage === Stages.scanner) {
      reqCamPermission();
    }
  }, [reqCamPermission, hasPermission, state.stage]);

  useEffect(() => {
    if (loginError !== null) {
      if (
        !isLoggedIn &&
        state.place !== null &&
        state.invite !== null &&
        loginError.match(/should be authenticated/g) !== null
      ) {
        dispatch(
          slice.actions.requestAuthorization(state.invite.code, state.place),
        );
      } else {
        dispatch(slice.actions.acceptFailed(loginError));
      }
      _dispatch(dismissLoginError());
    }
  }, [_dispatch, isLoggedIn, loginError, state.invite, state.place]);

  const [scanned, setScanned] = useState(false);
  const scanAgain = useCallback(() => {
    setScanned(false);
    dispatch(slice.actions.startScanner());
  }, []);
  const acceptCode = useCallback(
    async (invite: {code: string; placeId: number}) => {
      try {
        dispatch(slice.actions.acceptInvite(invite.code, invite.placeId));
        const {place} = await checkInvite(invite);
        dispatch(slice.actions.setPlaceInvite(invite.code, place));
        _dispatch(acceptPlaceInvite(invite));
      } catch (e) {
        //@ts-ignore
        dispatch(slice.actions.acceptFailed(e));
      }
    },
    [_dispatch],
  );
  const onBarCodeScanned = useCallback<
    Extract<BarCodeScannerProps['onBarCodeScanned'], Function>
  >(
    async ({data}) => {
      setScanned(true);

      try {
        if (Platform.OS === 'web') {
          if (isBusinessDynamicLink(data)) {
            document.location.replace(data);
            return;
          } else {
            const unrecognizable = t(
              'Screens.JoinAsEmployee.unrecognizableLink',
            );
            throw new Error(unrecognizable);
          }
        }

        const link = await parseDynamicLink(data);
        if (link != null && link.invite) {
          Analytics.logEvent('join_business_attempt', {
            channel: 'qrcode',
          });
          await acceptCode(link.invite);
        } else {
          const unrecognizable = t('Screens.JoinAsEmployee.unrecognizableLink');
          throw new Error(unrecognizable);
        }
      } catch (e) {
        const description = t(
          'Screens.JoinAsEmployee.invalidCodePleaseScanAgain',
        );
        dispatch(slice.actions.acceptFailed(description));
      }
    },
    [acceptCode, t],
  );

  useEffect(() => {
    if (route.params && route.params.placeId && route.params.code) {
      const invite = {
        placeId: route.params.placeId,
        code: route.params.code,
      };
      const task = async () => {
        Analytics.logEvent('join_business_attempt', {
          channel: 'external',
        });
        await acceptCode(invite);
      };
      task();
      navigation.setParams({placeId: undefined, code: undefined});
    }
  }, [acceptCode, navigation, route.params]);

  const navbar = useMemo(
    () => (
      <Navbar onBack={state.stage === Stages.accepting ? undefined : onBack} />
    ),
    [onBack, state.stage],
  );
  const description = useMemo(() => {
    let desc = '';
    if (state.stage === Stages.scanner && hasPermission === null) {
      desc = t('Screens.JoinAsEmployee.requestingCameraPermissions');
    } else if (state.stage === Stages.scanner && !hasPermission) {
      desc = t('Screens.JoinAsEmployee.noCameraAccess');
    } else {
      desc = state.description;
    }
    return <Screen.Description>{desc}</Screen.Description>;
  }, [hasPermission, state.description, state.stage, t]);
  const scanner = useMemo(
    () =>
      hasPermission ? (
        <>
          <Spacer height={_isTablet ? 54 : 24} />
          <QRCodeScanner
            onBarCodeScanned={onBarCodeScanned}
            scanned={scanned}
            scanAgain={
              state.stage === Stages.scanFailed ? scanAgain : undefined
            }
          />
        </>
      ) : null,
    [hasPermission, onBarCodeScanned, scanAgain, scanned, state.stage],
  );

  const onAppleSignInSucceed = useCallback(
    (token, data) => {
      _dispatch(performLoginViaApple(token, data));
    },
    [_dispatch],
  );
  const onFacebookSignInSucceed = useCallback(
    (token, data) => {
      _dispatch(performLoginViaFacebook(token, data));
    },
    [_dispatch],
  );
  const onGoogleSignInSucceed = useCallback(
    (serverAuthCode: string) => {
      _dispatch(performLoginViaGoogle(serverAuthCode));
    },
    [_dispatch],
  );

  useEffect(() => {
    if (isLoggedIn) {
      if (state.stage === Stages.auth && state.place && state.invite) {
        acceptCode(state.invite);
      }
    }
  }, [acceptCode, isLoggedIn, state.invite, state.place, state.stage]);

  return (
    <Screen navbar={navbar} testID={TEST_IDS.screen}>
      <Screen.Heading>{state.heading}</Screen.Heading>
      {description}
      {state.stage === Stages.auth ? (
        isLoggingIn ? (
          <ActivityIndicator
            size="large"
            style={StyleSheet.absoluteFill}
            color={colors.black}
          />
        ) : isLoggedIn ? null : (
          <Auth
            onAppleSignInSucceed={onAppleSignInSucceed}
            onFacebookSignInSucceed={onFacebookSignInSucceed}
            onGoogleSignInSucceed={onGoogleSignInSucceed}
          />
        )
      ) : state.stage === Stages.scanner ||
        state.stage === Stages.scanFailed ? (
        scanner
      ) : null}
      {state.stage === Stages.accepting ? (
        <ActivityIndicator
          style={StyleSheet.absoluteFillObject}
          color={colors.black}
          size={'large'}
        />
      ) : null}
    </Screen>
  );
}

function isBusinessDynamicLink(data: string | undefined) {
  return data?.startsWith(DynamicLinksConfig.Business.DomainURIPrefix);
}
