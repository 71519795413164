import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import AppearancePicker from '@components/common/AppearanceCustomizer/AppearancePicker';
import {Place, PlaceAppearance, PossiblePlaceAppearances} from '@typings/Place';
import {api} from '@b2cmessenger/doppio-core';
import {colors} from '@b2cmessenger/doppio-shared';
import {ActivityIndicator, StyleSheet, Text, View} from 'react-native';
import {Spacer, Typography} from '@b2cmessenger/doppio-components';
import slice, {
  AppearanceStages,
} from '@components/common/AppearanceCustomizer/slice';
import useSWR from 'swr';
import AppearanceAwardNameEdit from '@components/common/AppearanceCustomizer/AppearanceAwardNameEdit';
import {localization, useTranslation} from '@shared';

const INITIAL_CONFIG = {
  color: null,
  stamp_resource_id: null,
  award_resource_id: null,
  award_name: null,
};

function AppearanceCustomizer(
  props: Omit<ReturnType<typeof useAppearanceCustomizer>, 'fetch'> & {
    placeId?: Place['id'];
    contentPadding?: number;
  },
) {
  const {
    initialConfig,
    currentConfig,
    possibleAppearances,
    placeId,
    contentPadding,
    onChange,
    stage,
    error,
    actions,
    loading,
  } = props;

  const content = useMemo(() => {
    if (loading || !currentConfig || !possibleAppearances) {
      return <ActivityIndicator color={colors.darkgray} size={28} />;
    }

    return (
      <View style={styles.container}>
        <AppearancePicker
          key={placeId}
          appearances={possibleAppearances as PossiblePlaceAppearances}
          config={currentConfig}
          onChange={onChange}
          visible={stage === AppearanceStages.picker}
        />
        {stage === AppearanceStages.awardNameEdit ? (
          <AppearanceAwardNameEdit
            appearances={possibleAppearances as PossiblePlaceAppearances}
            config={currentConfig}
            initialConfig={initialConfig}
            onChange={onChange}
            contentPadding={contentPadding}
            actions={actions}
          />
        ) : null}
      </View>
    );
  }, [
    actions,
    contentPadding,
    currentConfig,
    initialConfig,
    loading,
    onChange,
    placeId,
    possibleAppearances,
    stage,
  ]);

  if (error) {
    return (
      <AppearanceError
        title={error?.title || 'An error has occurred'}
        description={error?.description || 'Please try again later'}
        contentPadding={contentPadding}
      />
    );
  } else {
    return content;
  }
}

export type UseAppearanceCustomizerProps = {
  /**
   * Place ID used to load current customization values, if no ID provided use default values
   */
  placeId?: number;
  /**
   * Current config value, used for local config management in cases when no place available yet
   */
  overrideConfig?: PlaceAppearanceConfigExtended;
  possibleAppearances: ReturnType<
    typeof useFetchPossibleAppearance
  >['possibleAppearances'];
  possibleAppearancesError: ReturnType<
    typeof useFetchPossibleAppearance
  >['possibleAppearancesError'];
};
export function useAppearanceCustomizer(props: UseAppearanceCustomizerProps) {
  const {t} = useTranslation();

  const {
    placeId,
    overrideConfig,
    possibleAppearances,
    possibleAppearancesError,
  } = props;
  if (placeId && overrideConfig) {
    const error = t(
      'Components.useAppearanceCustomizer.placeidAndOverrideconfigCanNotBeUsedAtTheSameTime',
    );
    throw new Error(error);
  }
  const [state, dispatch] = useReducer(
    slice.reducer,
    slice.reducer(undefined, {type: '@@DUMMY'}),
  );

  const {
    data: _initialConfig,
    loading: isPlaceAppearanceLoading,
    error: fetchPlaceAppearanceError,
    fetch,
  } = useFetchPlaceAppearance();

  const initialConfig = useMemo(
    () => (placeId ? _initialConfig : overrideConfig || INITIAL_CONFIG),
    [_initialConfig, placeId, overrideConfig],
  );

  const actions = useRef({
    showAwardNameEdit: () => dispatch(slice.actions.awardNameEdit()),
    showPicker: () => dispatch(slice.actions.picker()),
    onBack: () => dispatch(slice.actions.picker()),
    onNext: () => dispatch(slice.actions.awardNameEdit()),
    loading: () => dispatch(slice.actions.loading()),
    reset: () => dispatch(slice.actions.reset()),
  }).current;

  useEffect(() => {
    if (possibleAppearances && initialConfig) {
      dispatch(
        slice.actions.success({
          placeAppearanceConfig: initialConfig,
        }),
      );
    } else if (possibleAppearancesError) {
      dispatch(
        slice.actions.error({
          title: t(
            'Components.useAppearanceCustomizer.unableToLoadPossibleAppearanceCustomizationSetting',
          ),
          description: possibleAppearancesError as string,
        }),
      );
    } else if (fetchPlaceAppearanceError) {
      dispatch(
        slice.actions.error({
          title: t(
            'Components.useAppearanceCustomizer.unableToLoadAppearanceSettingsForPlace',
          ),
          description: fetchPlaceAppearanceError,
        }),
      );
    } else if (!possibleAppearances || isPlaceAppearanceLoading) {
      dispatch(slice.actions.loading());
    }
  }, [
    possibleAppearances,
    initialConfig,
    possibleAppearancesError,
    fetchPlaceAppearanceError,
    isPlaceAppearanceLoading,
    t,
  ]);

  const _onChange = useCallback(
    (config: Partial<PlaceAppearanceConfigExtended>) => {
      dispatch(slice.actions.change(config));
    },
    [],
  );

  const error = useMemo(() => state.error, [state.error]);
  const stage = useMemo(() => state.stage, [state.stage]);
  const loading = useMemo(() => state.loading, [state.loading]);

  return {
    stage,
    loading,
    error,
    currentConfig: state.placeAppearanceConfig,
    possibleAppearances,
    actions,
    fetch,
    onChange: _onChange,
    initialConfig,
  };
}

export const POSSIBLE_PLACE_APPEARANCES_SWR_KEY = '/api/v2/place/appearance';
export function useFetchPossibleAppearance() {
  const [locale, setLocale] = useState(localization.getLanguage());

  const {data: possibleAppearances, error: possibleAppearancesError} = useSWR(
    [POSSIBLE_PLACE_APPEARANCES_SWR_KEY, locale],
    ([url]) => possiblePlaceAppearancesFetcher(url),
    {refreshInterval: 60 * 5 * 1000},
  );

  useEffect(() => {
    localization.events.addListener('languageChanged', setLocale);
    return () => {
      localization.events.removeListener('languageChanged', setLocale);
    };
  }, []);

  return {possibleAppearances, possibleAppearancesError};
}

function useFetchPlaceAppearance() {
  const [data, setData] = useState<PlaceAppearanceConfigExtended | null>(null);
  const [error, setError] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(async (placeId?: Place['id']) => {
    if (placeId) {
      setLoading(true);
      setError(null);
      setData(null);
      try {
        const config = await fetchPlaceAppearance(placeId);
        setData(config);
      } catch (e) {
        setError(e as string);
      } finally {
        setLoading(false);
      }
    }
  }, []);
  return {data, loading, error, fetch};
}

export function getConfigChanges(
  config: PlaceAppearanceConfigExtended,
  possibleAppearances?: PossiblePlaceAppearances | null,
) {
  return {
    ...config,
    award_name: config.award_name ?? null,
    stamp_resource_content: possibleAppearances?.resources.find(
      resource => resource.id === config.stamp_resource_id,
    )?.content,
    award_resource_content: possibleAppearances?.resources.find(
      resource => resource.id === config.award_resource_id,
    )?.content,
  };
}

export function hasChanged(
  currentValue: PlaceAppearanceConfigExtended,
  initialValue: PlaceAppearanceConfigExtended | null,
) {
  if (initialValue === null) {
    return true;
  }

  return !(
    currentValue.color === initialValue.color &&
    currentValue.stamp_resource_id === initialValue.stamp_resource_id &&
    currentValue.award_resource_id === initialValue.award_resource_id &&
    currentValue.award_name === initialValue.award_name
  );
}

export function AppearanceError({
  title,
  description,
  contentPadding = 8,
}: {
  title: string;
  description: string;
  contentPadding?: number;
}) {
  return (
    <View style={{paddingHorizontal: contentPadding}}>
      <Text style={Typography.smallHeader}>{title}</Text>
      <Spacer />
      <Text style={Typography.mediumBody}>{description}</Text>
    </View>
  );
}

export function possiblePlaceAppearancesFetcher(url: string) {
  return api.default
    .get<PossiblePlaceAppearances>(url)
    .catch(e => {
      throw api.parseErrorToHumanReadableMessage(e);
    })
    .then(api.getResponseData);
}

function fetchPlaceAppearance(placeId: Place['id']) {
  return api.default
    .get<PlaceAppearance>(`/api/v2/place/${placeId}/appearance`)
    .catch(e => {
      throw api.parseErrorToHumanReadableMessage(e);
    })
    .then(api.getResponseData);
}

export type PlaceAppearanceConfig = Pick<
  PlaceAppearance,
  'color' | 'stamp_resource_id' | 'award_resource_id'
>;

export type PlaceAppearanceConfigExtended = PlaceAppearanceConfig &
  Partial<
    Pick<
      PlaceAppearance,
      'stamp_resource_content' | 'award_resource_content' | 'award_name'
    >
  >;

const styles = StyleSheet.create({
  container: {flexGrow: 1, flex: 1},
  pickerWrapper: {flex: 1},
});
export default AppearanceCustomizer;
