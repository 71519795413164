import {createContext} from 'react';

export const FRCDefaults = {
  displaySignInByEmail: false,
  stripePublishableKey: '',
  defaultStampId: 5,
  defaultAwardId: 8,
  businessIdWithCoffeeIcon: 1,
  extendedDemoUrl: 'https://www.youtube.com/watch?v=OXOZkkMk_3g',
  scanViaWebOnboardingStory: false,
  clientWebLinkRoute: '/link/',
};
const FirebaseRemoteConfigContext = createContext(FRCDefaults);
export default FirebaseRemoteConfigContext;
