import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Navbar,
  Screen,
  Pusher,
  Spacer,
} from '@b2cmessenger/doppio-components';
import {Analytics} from '@b2cmessenger/doppio-core';
import WorkplaceContext from '@components/common/WorkplaceContext';
import {useDispatch} from 'react-redux';
import {usePlaceEdit} from '@screens/Dashboard/screens/Profile/hooks/usePlaceEdit';
import {Alert} from '@components/common/Alert';
import {View} from 'react-native';
import {changeAwardCost} from '@store/actions';
import {navigationRef} from '@navigation/navigationRef';
import {useFocusEffect} from '@react-navigation/core';
import {useScreenPrimaryButtonStyle} from '@components/hooks/useScreenPrimaryButtonStyle';
import {TEST_IDS} from './EditLoyaltyProgramTestIDS';
import StampCard from '@components/common/StampCard';
import useStampAward from '@components/hooks/useStampAwards';
import {useIsMounted} from '@b2cmessenger/doppio-shared';
import {useTranslation, SharedHooks} from '@shared';
const {usePlaceAppearance} = SharedHooks;

const DefaultAwardId = 0;
const DefaultAwardQuantity = 1;

function EditLoyaltyProgram() {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const workplace = useContext(WorkplaceContext);

  const {getAwardCost} = useStampAward();
  const {award, IconStamp} = usePlaceAppearance(workplace.id);
  const freeDrinkCost = getAwardCost(workplace.id, DefaultAwardId) || 0;

  const isMounted = useIsMounted();
  const [cost, setCost] = useState(freeDrinkCost);
  const [disabled, setDisabled] = useState(true);

  const {error, loading, request: placeChange, setError} = usePlaceEdit(
    workplace.id,
  );

  useFocusEffect(
    useCallback(() => {
      if (isMounted && freeDrinkCost) {
        setCost(freeDrinkCost);
      }
      return () => {
        if (isMounted && freeDrinkCost) {
          setCost(freeDrinkCost);
        }
      };
    }, [freeDrinkCost, isMounted]),
  );

  useEffect(() => {
    if (error) {
      const _title = t('Screens.EditLoyaltyProgram.anErrorHasOccurred');
      Alert.alert(_title, error);
      setError(null);
    }
  }, [error, setError, t]);

  const goBack = useCallback(() => {
    if (navigationRef.current?.canGoBack()) {
      navigationRef.current?.goBack();
    } else {
      navigationRef.current?.navigate('Dashboard', {
        screen: 'Profile',
        params: {
          screen: 'Settings',
        },
      });
    }
  }, []);

  const pbs = useScreenPrimaryButtonStyle();

  const onSave = useCallback(async () => {
    try {
      const place = await placeChange({stampCountForReward: cost});
      if (place) {
        Analytics.logEvent('change_loyalty_program', {stamps: cost});
        dispatch(changeAwardCost(place.id, 0, place.stamp_count_for_reward));
        goBack();
      }
    } catch (e) {}
  }, [placeChange, cost, dispatch, goBack]);

  useEffect(() => {
    setDisabled(freeDrinkCost === cost || loading);
  }, [cost, freeDrinkCost, loading]);

  const navbar = useMemo(() => {
    const _title = t('Screens.EditLoyaltyProgram.loyaltyProgram');
    return (
      <Navbar
        title={_title}
        onBack={goBack}
        backButtonTestID={'loyalty-program-back'}
      />
    );
  }, [goBack, t]);

  return (
    <Screen navbar={navbar} testID={TEST_IDS.screen}>
      <View>
        <Screen.Heading>
          {t('Screens.EditLoyaltyProgram.editYourLoyaltyProgram')}
        </Screen.Heading>
        <Spacer height={30} />
        <StampCard
          stampElementTestID={TEST_IDS.elements.Stamp}
          testID={TEST_IDS.elements.StampCard}
          value={cost}
          onChange={n => setCost(n)}
          awardName={useMemo(() => award(DefaultAwardQuantity), [award])}
          IconComponent={IconStamp}
        />
      </View>
      <Pusher />
      <Button.Primary
        testID={TEST_IDS.buttons.Done}
        loading={loading}
        disabled={disabled}
        style={pbs}
        title={t('Screens.EditLoyaltyProgram.done')}
        onPress={onSave}
      />
    </Screen>
  );
}

export default EditLoyaltyProgram;
