import React, {
  ComponentProps,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {Calendar, LocaleConfig} from 'react-native-calendars';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {
  Button,
  Card,
  Spacer,
  Screen,
  Typography,
  useTheme,
  BModal,
} from '@b2cmessenger/doppio-components';
import {
  fillLBlankAreaInSeries,
  formatWithLocalization,
  getDays,
  getMonths,
  getShortDays,
  getShortMonth,
} from '@screens/Dashboard/screens/Stats/hooks/shared';
import {
  getWholeMonthRange,
  getWholeWeekRange,
  getWholeYearRange,
} from '@screens/Dashboard/screens/Stats/hooks/useStats';
import {FlatList, SectionList, StyleSheet, Text, View} from 'react-native';
import {DateTimeUtils} from '@b2cmessenger/doppio-shared';
import {localization, useTranslation} from '@shared';

type Scope = 'week' | 'month' | 'year';

type Props = ComponentProps<typeof BModal> & {
  scope: Scope;
  min: Date;
  from: Date;
  to: Date;
  onDatesChange: (from: Date, to: Date) => void;
};

function getScopeNames(t: typeof localization.t): Record<Scope, string> {
  return {
    week: t('Screens.Stats.CalendarModal.week'),
    month: t('Screens.Stats.CalendarModal.month'),
    year: t('Screens.Stats.CalendarModal.year'),
  };
}

export function CalendarModal({
  scope,
  min,
  from,
  to,
  onDatesChange,
  ...rest
}: Props) {
  const {colors} = useTheme();
  const {bottom} = useSafeAreaInsets();
  const [dates, setDates] = useState(() => ({from, to}));
  const {t} = useTranslation();

  const scopeNames = useMemo(() => getScopeNames(t), [t]);

  useEffect(() => {
    setDates(() => ({from, to}));
  }, [from, to, rest.visible]);

  const [fromDate, toDate] = useMemo(() => {
    const f = DateTimeUtils.toDatetimeServer(dates.from, {dateOnly: true});
    const t = DateTimeUtils.toDatetimeServer(dates.to, {dateOnly: true});
    return [f, t];
  }, [dates.from, dates.to]);

  const getMarkedDates = useCallback(
    (dateFrom: string, dateTo: string) => {
      const color = colors.brand;
      const textColor = colors.white;
      const commonData = {color, textColor};
      return fillLBlankAreaInSeries(
        [
          {date: dateFrom, startingDay: true, ...commonData},
          {date: dateTo, endingDay: true, ...commonData},
        ],
        {
          blankData: commonData,
          scope: scope,
          from: dateFrom,
        },
      ).reduce(
        (memo, {date, ...entry}) => ({
          ...memo,
          [date]: {...entry},
        }),
        {},
      );
    },
    [colors.brand, colors.white, scope],
  );

  const datesAreUnchanged = useMemo(() => {
    const arr = [from, dates.from, to, dates.to].map(d =>
      DateTimeUtils.toDatetimeServer(d, {dateOnly: true}),
    );

    return arr[0] === arr[1] && arr[2] === arr[3];
  }, [dates.from, dates.to, from, to]);

  const markedDates = useMemo(() => getMarkedDates(fromDate, toDate), [
    fromDate,
    getMarkedDates,
    toDate,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const maxDate = useMemo(() => new Date(), [rest.visible]);
  useEffect(() => {
    LocaleConfig.locales.default = {
      dayNames: getDays(t),
      dayNamesShort: getShortDays(t),
      monthNames: getMonths(t),
      monthNamesShort: getShortMonth(t),
      today: t('common.calendar.today'),
    };
    LocaleConfig.defaultLocale = 'default';
  }, [t]);

  const calendar = useMemo(
    () => (
      <Calendar
        enableSwipeMonths
        minDate={min}
        maxDate={maxDate}
        style={styles.calendar}
        theme={{
          arrowColor: colors.brand,
          textMonthFontWeight: '600',
          textDayFontWeight: '500',
        }}
        current={fromDate}
        firstDay={0}
        markedDates={markedDates}
        markingType="period"
        hideExtraDays={scope === 'month'}
        onDayPress={date => {
          if (scope === 'week') {
            const weekStartDate = new Date(date.year, date.month - 1, date.day);
            weekStartDate.setDate(
              weekStartDate.getDate() - weekStartDate.getDay(),
            );
            const newDates = getWholeWeekRange(weekStartDate);
            setDates({from: newDates[0], to: newDates[1]});
          } else if (scope === 'month') {
            const monthStartDate = new Date(date.year, date.month - 1, 1);
            const newDates = getWholeMonthRange(monthStartDate);
            setDates({from: newDates[0], to: newDates[1]});
          }
        }}
      />
    ),
    [colors.brand, fromDate, markedDates, maxDate, min, scope],
  );
  const yearCalendar = useMemo(() => {
    return (
      <YearCalendar
        maxYear={maxDate.getFullYear()}
        totalYears={Math.max(1, maxDate.getFullYear() - min.getFullYear() + 1)}
        value={dates.from.getFullYear()}
        onChange={year => {
          const [f, t] = getWholeYearRange(new Date(year, 11, 21));
          setDates({from: f, to: t});
        }}
      />
    );
  }, [dates.from, maxDate, min]);

  const onMonthCalendarChange = useCallback((year, month) => {
    const [f, t] = getWholeMonthRange(new Date(year, month, 1));
    setDates({from: f, to: t});
  }, []);
  const monthCalendar = useMemo(() => {
    return (
      <MonthCalendar
        // totalYears={Math.max(1, maxDate.getFullYear() - min.getFullYear() + 1)}
        minDate={min}
        maxYear={maxDate.getFullYear()}
        year={dates.from.getFullYear()}
        month={dates.from.getMonth()}
        onChange={onMonthCalendarChange}
      />
    );
  }, [dates.from, maxDate, min, onMonthCalendarChange]);
  const heading = useMemo(
    () => t('Screens.Stats.CalendarModal.select', {scope: scopeNames[scope]}),
    [scope, scopeNames, t],
  );

  return (
    <BModal {...rest}>
      <Card shadowEnabled={false}>
        <Screen.Heading>{heading}</Screen.Heading>
        {scope === 'year'
          ? yearCalendar
          : scope === 'month'
          ? monthCalendar
          : calendar}
        <Spacer />
        <Button
          title={t('Screens.Stats.CalendarModal.displayStats')}
          disabled={datesAreUnchanged}
          onPress={useCallback(() => {
            if (!datesAreUnchanged) {
              onDatesChange(dates.from, dates.to);
            }
            rest.onRequestClose?.call(null);
          }, [
            dates.from,
            dates.to,
            datesAreUnchanged,
            onDatesChange,
            rest.onRequestClose,
          ])}
        />
      </Card>
      <Spacer height={Math.max(bottom - Card.PADDING_VERTICAL, 0)} />
    </BModal>
  );
}

const styles = StyleSheet.create({
  calendar: {
    height: 380,
    paddingLeft: 0,
    paddingRight: 0,
  },
});

function YearCalendar({
  totalYears = 10,
  maxYear,
  value,
  onChange,
}: {
  totalYears?: number;
  maxYear: number;
  value: number;
  onChange: (year: number) => void;
}) {
  const buttonWidth = 80;
  return (
    <FlatList
      initialScrollIndex={totalYears - (maxYear - value) - 1}
      getItemLayout={useCallback(
        (data, idx) => ({
          length: buttonWidth,
          offset: buttonWidth * idx,
          index: idx,
        }),
        [],
      )}
      data={useMemo(
        () =>
          Array.from({length: totalYears})
            .map((_, idx) => new Date(maxYear - idx, 11, 21))
            .reverse(),
        [maxYear, totalYears],
      )}
      contentContainerStyle={{
        paddingVertical: Card.PADDING_HORIZONTAL,
      }}
      horizontal
      keyExtractor={useCallback(date => String(date.getFullYear()), [])}
      ItemSeparatorComponent={Spacer}
      renderItem={useCallback(
        ({item}) => (
          <Button
            style={{width: buttonWidth}}
            shadow={false}
            mode={value === item.getFullYear() ? 'primary' : 'inverted'}
            title={String(item.getFullYear())}
            onPress={() => {
              onChange(item.getFullYear());
            }}
          />
        ),
        [onChange, value],
      )}
    />
  );
}
const MonthCalendarContext = React.createContext<{year: number; month: number}>(
  {year: 2012, month: 11},
);
function MonthCalendar({
  minDate,
  maxYear,
  year,
  month,
  onChange,
}: {
  minDate: Date;
  maxYear: number;
  year: number;
  month: number;
  onChange: (year: number, month: number) => void;
}) {
  const {colors} = useTheme();
  const totalYears = useMemo(() => maxYear - minDate.getFullYear() + 1, [
    maxYear,
    minDate,
  ]);

  /* eslint-disable react-hooks/exhaustive-deps */
  const initialScrollIndex = useMemo(
    () => (totalYears - (maxYear - year) - 1) * 3 - 2,
    [],
  );
  /* eslint-enable react-hooks/exhaustive-deps */
  return (
    <MonthCalendarContext.Provider
      value={useMemo(() => ({year, month}), [month, year])}
    >
      <SectionList<
        Array<Date>,
        {
          year: number;
        }
      >
        style={useMemo(
          () => ({
            height: 300,
            marginTop: 8,
          }),
          [],
        )}
        stickySectionHeadersEnabled={false}
        getItemLayout={useCallback(
          (data, index) => ({
            length: 40,
            offset: 40 * index + Math.floor(index / 3) * 40,
            index: index,
          }),
          [],
        )}
        initialScrollIndex={initialScrollIndex}
        keyExtractor={useCallback(dates => String(dates[0].getTime()), [])}
        renderItem={useCallback(
          data => {
            return (
              <View>
                <MonthRow
                  dates={data.item}
                  onChange={onChange}
                  minDate={minDate}
                />
              </View>
            );
          },
          [minDate, onChange],
        )}
        renderSectionHeader={useCallback(
          info => {
            return (
              <View
                /* eslint-disable-next-line react-native/no-inline-styles */
                style={{
                  backgroundColor: colors.white,
                  flexGrow: 1,
                  alignItems: 'center',
                  height: 40,
                }}
              >
                <Spacer height={8} />
                <Text style={Typography.smallHeader}>
                  {String(info.section.year)}
                </Text>
              </View>
            );
          },
          [colors.white],
        )}
        sections={useMemo(
          () =>
            Array.from({length: totalYears})
              .map((_, idx) => ({
                year: maxYear - idx,
                data: Array.from({length: 3}).map((__, row) =>
                  Array.from({length: 4}).map(
                    (___, col) => new Date(maxYear - idx, row * 4 + col, 1),
                  ),
                ),
              }))
              .reverse(),
          [maxYear, totalYears],
        )}
      />
    </MonthCalendarContext.Provider>
  );
}

const MemoButton = React.memo(Button);

function MonthRow({
  minDate,
  dates,
  onChange,
}: {
  minDate?: Date;
  dates: Date[];
  onChange: (year: number, month: number) => void;
}) {
  const {t} = useTranslation();
  const {colors} = useTheme();
  const {year, month} = useContext(MonthCalendarContext);
  const datesInfo = useMemo(
    () =>
      dates.map(value => ({
        value,
        change: onChange.bind(null, value.getFullYear(), value.getMonth()),
      })),
    [dates, onChange],
  );
  const min = useMemo(
    () =>
      minDate ? new Date(minDate.getFullYear(), minDate.getMonth(), 1) : null,
    [minDate],
  );
  const mrStyles = useMemo(
    () =>
      StyleSheet.create({
        month: {
          width: 60,
          height: 40,
          marginLeft: 10,
        },
        firstMonth: {
          width: 60,
          height: 40,
        },
        fadedTitle: {
          color: colors.middlegray,
        },
        activeMonth: {},
      }),
    [colors.middlegray],
  );

  return (
    <View
      style={useMemo(
        () => ({
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }),
        [],
      )}
    >
      {useMemo(
        () =>
          datesInfo.map(({value: date, change}, idx) => (
            <MemoButton
              key={String(idx)}
              shadow={false}
              style={idx === 0 ? mrStyles.firstMonth : mrStyles.month}
              mode={
                year === date.getFullYear() && month === date.getMonth()
                  ? 'primary'
                  : 'inverted'
              }
              titleStyle={min && min > date ? mrStyles.fadedTitle : undefined}
              onPress={min && min > date ? undefined : change}
              title={formatWithLocalization(date, f => f.shortMonth, t)}
            />
          )),
        [
          t,
          datesInfo,
          min,
          month,
          mrStyles.fadedTitle,
          mrStyles.firstMonth,
          mrStyles.month,
          year,
        ],
      )}
    </View>
  );
}
