import {Linking} from 'react-native';

export function openTos() {
  Linking.openURL(
    'https://www.apple.com/legal/internet-services/itunes/dev/stdeula/',
  );
}

export function openPrivacyPolicy() {
  Linking.openURL('https://say2b.com/privacy.html');
}
