import {useSelector} from 'react-redux';
import {hooks} from '@b2cmessenger/doppio-core';
import {parentSelectors} from '@store/reducer';
import Config from '@utils/Config';
const {useReinitialize: useReinitializeHook} = hooks;

export function useReinitialize() {
  return useReinitializeHook({
    appId: Config.APP_ID as 'doppio4b',
    useSelector: useSelector,
    parentSelectors: {
      init: parentSelectors.init,
      loginedUser: parentSelectors.loginedUser,
    },
  });
}
