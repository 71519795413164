import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {Spacer} from '@b2cmessenger/doppio-components';
import {QRCodeWithCaption, styles} from './QRCodeWithCaption';
import {
  OnPressNoActiveSubscription,
  QRCodeGeneratorControls,
  QRCodeGeneratorControlsRef,
} from './QRCodeGeneratorControls';
import {
  createJWTSignature,
  getCurrentIatValue,
} from '@screens/Dashboard/screens/QRCode/components/QRCodeGenerator/createJWTSignature';
import {
  buildDynamicLink,
  DynamicLinkActions,
} from '@screens/Dashboard/screens/QRCode/components/QRCodeGenerator/buildDynamicLink';
import * as slice from './slice';
import {useDebounce} from 'use-debounce';
import {useNotifications} from '@components/hooks/useNotifications';
import {isStampScanNotification} from '@typings/Notification';
import {
  constructFromNotification,
  OperationResult,
} from '@screens/Dashboard/screens/QRCode/components/QRCodeGenerator/operationResultFactory';
import useStampAward from '@components/hooks/useStampAwards';
import {useSelector} from 'react-redux';
import {isAvailableFullAccessToAppSelector} from '@store/selectors';
import {useGetCurrentLanguage} from '@shared';
import {IconQuestion} from '@components/common/icons/SvgIcon';
import {isTablet} from 'react-native-device-info';
import useWindowInfo from '@hooks/useWindowInfo';
import {colors} from '@b2cmessenger/doppio-shared';

export function QRCodeGenerator({
  signKey,
  size,
  placeId,
  employeeId,
  header,
  onPressNoActiveSubscription,
  onPressHowToScanInstruction,
}: {
  signKey: string | null;
  size: number;
  placeId: number;
  employeeId: number;
  onPressNoActiveSubscription: OnPressNoActiveSubscription;
  onPressHowToScanInstruction: () => void;
  header?: React.ReactNode;
}) {
  const _isTablet = isTablet();
  const {isLandscape} = useWindowInfo();

  const {getAwardCost} = useStampAward();
  const isFullAccessToAppFromSelector = useSelector(
    isAvailableFullAccessToAppSelector,
  );
  const [isAvailableFullAccessToApp, setIsAvailableFullAccessToApp] = useState(
    isFullAccessToAppFromSelector,
  );
  const [s, d] = useReducer(
    slice.reducer,
    slice.reducer(undefined, slice.actions.init()),
  );

  const iatStorage = useRef<IatStorage>();
  useEffect(() => {
    iatStorage.current = new IatStorage();
    return () => {
      iatStorage.current?.destroy();
    };
  }, []);
  const {state, counter: _counter, caption: _caption, isWithInstruction} = s;

  const currentLanguage = useGetCurrentLanguage();
  useEffect(() => {
    d(slice.actions.updateCaption());
  }, [currentLanguage]);

  useEffect(() => {
    setIsAvailableFullAccessToApp(prev => {
      if (prev === isFullAccessToAppFromSelector) {
        return prev;
      }
      if (prev && !isFullAccessToAppFromSelector && state === 'award') {
        d(slice.actions.setLoyaltyState());
      }
      return isFullAccessToAppFromSelector;
    });
  }, [isFullAccessToAppFromSelector, state]);

  const [counter] = useDebounce(_counter, 333, {leading: true});
  const [linkIssuedAt, link] = useMemo(
    () =>
      signKey === null
        ? [0, null]
        : dynamicLinkFetcher.call(
            null,
            'qrCodeGenerator',
            state,
            counter,
            placeId,
            employeeId,
            signKey,
          ),
    [counter, employeeId, placeId, signKey, state],
  );
  const linkIssuedAtRef = useRef(linkIssuedAt);
  useEffect(() => {
    linkIssuedAtRef.current = linkIssuedAt;
    if (linkIssuedAt !== 0) {
      iatStorage.current?.set(linkIssuedAt, 'issued');
    }
  }, [linkIssuedAt]);

  const onInitialState = useCallback(
    () => d(slice.actions.setLoyaltyState()),
    [],
  );
  const onOperationDone = useCallback(
    (type: 'stamp' | 'award' | 'error') =>
      d(slice.actions.setResultState(type)),
    [],
  );
  const controlsRef = useRef<QRCodeGeneratorControlsRef>(null);
  useNotifications(
    useCallback(
      notification => {
        if (isStampScanNotification(notification)) {
          const {type, iat} = notification.data;

          if (type === 'give_register') {
            iatStorage.current?.set(iat, 'registered');
          } else {
            iatStorage.current?.set(iat, 'activated');
          }

          if (
            // incoming `iat` still displaying
            linkIssuedAtRef.current === iat ||
            // incoming `iat` is registered and state is `loyalty`
            (state === 'loyalty' &&
              typeof iatStorage.current?.get(iat) === 'number')
          ) {
            const result = constructFromNotification(notification, {
              placeId,
              awardCost: getAwardCost(placeId) || 5,
            });
            if (result) {
              onInitialState();
              if (result.state !== 'reset') {
                controlsRef.current?.setOperationResult(
                  result as OperationResult,
                );
              }
            }
          }
        }
      },
      [getAwardCost, onInitialState, placeId, state],
    ),
  );

  const caption = useMemo(() => {
    if (!isWithInstruction) {
      return _caption;
    }

    return (
      <TouchableOpacity
        style={isLandscape ? styles.captionLandscape : styles.caption}
        onPress={onPressHowToScanInstruction}>
        <IconQuestion
          size={_isTablet ? 36 : 28}
          style={styles.captionIcon}
          fill={colors.darkgray}
        />
        <View style={styles.captionTextContainer}>
          <Text
            style={[
              styles.captionText,
              {
                textDecorationLine: 'underline',
                color: colors.darkgray,
                textDecorationColor: colors.middlegray,
              },
            ]}
            numberOfLines={isLandscape ? 2 : 1}>
            {_caption}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }, [
    _caption,
    _isTablet,
    isLandscape,
    isWithInstruction,
    onPressHowToScanInstruction,
  ]);

  return (
    <View>
      <QRCodeWithCaption
        hideContent={s.state === 'result'}
        value={counter !== _counter ? null : link}
        caption={caption}
        size={size}
        header={header}
      />
      <Spacer />
      <QRCodeGeneratorControls
        ref={controlsRef}
        state={
          s.state === 'loyalty' || s.state === 'result' ? undefined : s.state
        }
        onOperationDone={onOperationDone}
        onStamp={useCallback(q => d(slice.actions.setStampState(q)), [d])}
        onAward={useCallback(q => d(slice.actions.setAwardState(q)), [d])}
        onPressNoActiveSubscription={onPressNoActiveSubscription}
        onInitialState={onInitialState}
        placeId={placeId}
        isAvailableFullAccessToApp={Boolean(isAvailableFullAccessToApp)}
      />
    </View>
  );
}

export function dynamicLinkFetcher(
  swrCacheKey: string,
  type: typeof slice.initialState.state,
  amount: number,
  placeId: number,
  employeeId: number,
  signKey: string,
) {
  const issuedAt = getCurrentIatValue();
  const jwt = createJWTSignature(
    signKey,
    type === 'loyalty'
      ? {
          action: DynamicLinkActions.stampBalance,
          options: {placeId, issuedBy: employeeId},
        }
      : type === 'stamp'
      ? {
          action: DynamicLinkActions.giveStamps,
          options: {placeId, issuedBy: employeeId, amount, issuedAt},
        }
      : {
          action: DynamicLinkActions.awardFreeGift,
          options: {
            placeId,
            issuedBy: employeeId,
            amount,
            awardId: 0,
            issuedAt,
          },
        },
  );
  return [
    issuedAt,
    buildDynamicLink({
      action:
        type === 'loyalty'
          ? DynamicLinkActions.stampBalance
          : type === 'stamp'
          ? DynamicLinkActions.giveStamps
          : DynamicLinkActions.awardFreeGift,
      jwt,
    }),
  ] as const;
}

class IatStorage {
  private lifecycleTimer: number;

  constructor(
    private store: Record<number, 'issued' | number> = {},
    private storageItemTTL = 20,
  ) {
    this.store = {};
    // @ts-ignore
    this.lifecycleTimer = setInterval(() => {
      const now = (Date.now() / 1000) ^ 0;
      const iats = Object.keys(this.store).map(Number);
      iats
        .filter(iat => {
          const value = this.store[iat];
          return (
            (typeof value === 'number' ? Number(this.store[iat]) : iat) +
              this.storageItemTTL <=
            now
          );
        })
        .forEach(iat => {
          delete this.store[iat];
        });
    }, 1000);
  }

  destroy() {
    clearInterval(this.lifecycleTimer);
  }

  set(iat: number, status: 'issued' | 'registered' | 'activated') {
    if (status === 'activated') {
      delete this.store[iat];
    } else if (status === 'registered') {
      // mark `iat` as registered, pass register time as value
      this.store[iat] = (Date.now() / 1000) ^ 0;
    }
  }

  get(iat: number) {
    return this.store[iat];
  }
}
