import {createSlice} from '@reduxjs/toolkit';
import {PlaceAppearanceConfigExtended} from '@components/common/AppearanceCustomizer/index';

export enum AppearanceStages {
  picker = 'picker',
  awardNameEdit = 'awardNameEdit',
}

type Error = {title: string; description: string};
const INITIAL_EXTENDED_CONFIG: PlaceAppearanceConfigExtended = {
  color: null,
  stamp_resource_id: null,
  stamp_resource_content: null,
  award_name: null,
  award_resource_id: null,
  award_resource_content: null,
};

const initialState = {
  stage: AppearanceStages.picker,
  initialConfig: INITIAL_EXTENDED_CONFIG,
  placeAppearanceConfig: INITIAL_EXTENDED_CONFIG,
  error: null as Error | null,
  loading: false,
};

const slice = createSlice({
  name: 'AppearanceCustomizer',
  initialState,
  reducers: {
    init: () => ({...initialState}),
    loading: state => {
      state.loading = true;
      state.error = null;
    },
    error: (state, action: {payload: Error}) => {
      state.loading = false;
      state.error = action.payload;
    },
    success: (
      state,
      action: {
        payload: {
          placeAppearanceConfig: PlaceAppearanceConfigExtended;
        };
      },
    ) => {
      state.initialConfig = action.payload.placeAppearanceConfig;
      state.placeAppearanceConfig = {
        ...(state.placeAppearanceConfig ?? {}),
        ...(action.payload.placeAppearanceConfig ?? {}),
      };
      state.loading = false;
      state.error = null;
    },
    picker: state => {
      state.stage = AppearanceStages.picker;
    },
    awardNameEdit: state => {
      state.stage = AppearanceStages.awardNameEdit;
    },
    change: (
      state,
      action: {payload: Partial<PlaceAppearanceConfigExtended>},
    ) => {
      if (action.payload) {
        state.placeAppearanceConfig = {
          ...(state.placeAppearanceConfig ?? {}),
          ...action.payload,
        } as PlaceAppearanceConfigExtended;
      }
    },
    reset: state => {
      state.placeAppearanceConfig = state.initialConfig;
    },
  },
});

export default slice;
