import {useEffect} from 'react';
import {Notification} from '@typings/Notification';
import PushNotifications from '@utils/PushNotifications';
import {subscribeToRestoredMessages} from '../../BackgroundNotificationsEnvironment';

export function useNotifications(
  handler: (notification: Notification) => void,
  options?: {disableHandlingBackgroundRestoredMessages: boolean},
) {
  useEffect(() => {
    const unsubscribe = PushNotifications.subscribeToNotifications(
      ({notification}) => handler(notification),
    );

    if (!options?.disableHandlingBackgroundRestoredMessages) {
      const unsubscribeFromRestoration = subscribeToRestoredMessages(
        ({notification}) => handler(notification),
      );
      return () => {
        unsubscribe();
        unsubscribeFromRestoration();
      };
    }

    return () => {
      unsubscribe();
    };
  }, [handler, options]);
}
