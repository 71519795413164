import React, {useMemo} from 'react';
import {
  ActivityIndicator,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import {Card, Spacer, Typography} from '@b2cmessenger/doppio-components';
import {colors} from '@b2cmessenger/doppio-shared';
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
} from 'rn-placeholder';
import QRCode from '@components/common/QRCode';
import {IconCamera} from '@components/common/icons/SvgIcon';
import {isTablet} from 'react-native-device-info';
import useWindowInfo from '@hooks/useWindowInfo';

const _isTablet = isTablet();

const PADDING = _isTablet ? 40 : 24;

export function QRCodeWithCaption({
  caption: _caption,
  value,
  hideContent,
  size = 227,
  style: _style,
  header: _header,
}: {
  header?: React.ReactNode;
  caption: string | React.ReactNode;
  hideContent?: boolean;
  loading?: boolean;
  value: string | null;
  size?: number;
  style?: StyleProp<ViewStyle>;
}) {
  const {isLandscape} = useWindowInfo();

  const style = useMemo(
    () => [styles.wrapper, {minHeight: size, minWidth: size}, _style],
    [size, _style],
  );
  const placeholderStyle = useMemo(() => ({width: size, height: size}), [size]);
  const header = useMemo(
    () =>
      _header ? (
        <>
          {_header}
          <Spacer />
        </>
      ) : undefined,
    [_header],
  );

  const qrCode = useMemo(
    () =>
      value === null || hideContent ? (
        <Placeholder
          Animation={hideContent ? undefined : Fade}
          style={placeholderStyle}>
          <PlaceholderMedia size={size} />
          {hideContent ? null : (
            <ActivityIndicator
              size="large"
              color={colors.darkgray}
              style={StyleSheet.absoluteFill}
            />
          )}
        </Placeholder>
      ) : (
        <QRCode size={size} value={value} color={colors.black} />
      ),
    [hideContent, placeholderStyle, size, value],
  );

  const caption = useMemo(() => {
    if (typeof _caption !== 'string') {
      return _caption;
    }

    return (
      <>
        <IconCamera size={_isTablet ? 36 : 28} style={styles.captionIcon} />
        <View style={styles.captionTextContainer}>
          <Text style={styles.captionText} numberOfLines={isLandscape ? 2 : 1}>
            {_caption}
          </Text>
        </View>
      </>
    );
  }, [_caption, isLandscape]);

  const hint = useMemo(
    () =>
      hideContent ? (
        <Placeholder
          Left={() => <PlaceholderMedia size={28} style={{marginRight: 12}} />}
          style={styles.caption}>
          <PlaceholderLine
            noMargin
            height={
              StyleSheet.flatten(
                _isTablet ? Typography.mediumBodyTablet : Typography.mediumBody,
              ).lineHeight
            }
          />
        </Placeholder>
      ) : (
        <View style={isLandscape ? styles.captionLandscape : styles.caption}>
          {caption}
        </View>
      ),
    [caption, hideContent, isLandscape],
  );

  return (
    <Card style={style} paddingHorizontal={PADDING} paddingVertical={PADDING}>
      {isLandscape ? (
        <>
          <View style={styles.landscapeRow}>
            <View style={styles.landscapeInfoColumn}>
              <View style={styles.landscapeHeaderWrapper}>{header}</View>
              {hint}
            </View>
            {qrCode}
          </View>
        </>
      ) : (
        <>
          {header}
          {qrCode}
          <Spacer height={16} />
          {hint}
        </>
      )}
    </Card>
  );
}

export const styles = StyleSheet.create({
  wrapper: {
    padding: PADDING,
    borderRadius: PADDING,
    backgroundColor: colors.white,
    alignItems: 'center',
    width: '100%',
  },
  caption: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    maxWidth: '100%',
  },
  captionLandscape: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    height: 42,
  },
  captionTextContainer: {
    flexShrink: 1,
  },
  captionText: _isTablet
    ? {
        ...StyleSheet.flatten(Typography.mediumBodyTablet),
        color: colors.darkgray,
      }
    : {
        ...StyleSheet.flatten(Typography.mediumBody),
        color: colors.darkgray,
      },
  captionIcon: {
    width: _isTablet ? 36 : 28,
    height: _isTablet ? 36 : 28,
    marginRight: 6,
  },
  landscapeRow: {
    flexDirection: 'row',
    width: '100%',
  },
  landscapeInfoColumn: {
    marginRight: 32,
    flex: 1,
  },
  landscapeHeaderWrapper: {flex: 1},
});
