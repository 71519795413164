import React, {useContext, useMemo} from 'react';
import Story from '@features/Story';
import {
  CheckBalance,
  POS,
  Reward,
  Stamps,
  Stats,
  CustomersStory,
  ScanStampsViaWeb,
} from './ui';
import {StyleSheet} from 'react-native';
import FirebaseRemoteConfigContext from '@utils/FirebaseRemoteConfigContext';
import useWindowInfo from '@hooks/useWindowInfo';

type OnboardingProps = {
  onDone?: () => void;
};
const STORIES = [
  <Stamps />,
  <CheckBalance />,
  <Reward />,
  <Stats />,
  <CustomersStory />,
  <POS />,
];
function Onboarding({onDone = () => {}}: OnboardingProps) {
  const {height} = useWindowInfo();
  const displayProgressBar = useMemo(() => height > 700, [height]);

  const {scanViaWebOnboardingStory} = useContext(FirebaseRemoteConfigContext);
  const stories = useMemo(() => {
    if (scanViaWebOnboardingStory) {
      return [...STORIES, <ScanStampsViaWeb />];
    }

    return STORIES;
  }, [scanViaWebOnboardingStory]);

  return (
    <Story
      onDone={onDone}
      stories={stories}
      contentContainerStyle={styles.container}
      displayProgressBar={displayProgressBar}
      displayPrevButton={true}
    />
  );
}

const styles = StyleSheet.create({
  container: {flex: 1, flexGrow: 1},
});

export default Onboarding;
