import {
  Button,
  Navbar,
  Pusher,
  Screen,
  Spacer,
  ThemeProvider,
  useTheme,
  useTypography,
} from '@b2cmessenger/doppio-components';
import React, {useCallback, useEffect, useMemo, useReducer} from 'react';
import {navigationRef} from '@navigation/navigationRef';
import {useScreenPrimaryButtonStyle} from '@components/hooks/useScreenPrimaryButtonStyle';
import {Platform, Text} from 'react-native';
import {createSlice} from '@reduxjs/toolkit';
import {useDispatch, useSelector} from 'react-redux';
import {loginedUserSelectors} from '@store/selectors';
import {Alert} from '@components/common/Alert';
import {logout} from '@store/actions';
import CheckboxItem from '@components/common/CheckboxItem';
import {Logger} from '@b2cmessenger/doppio-shared';
import {useAccountRemove} from '@components/hooks/useAccountRemove';
import {useTranslation} from '@shared';

const initialState = {consent: 1};
const slice = createSlice({
  name: 'removalConsents',
  initialState: initialState,
  reducers: {
    reset: () => ({...initialState}),
    toggleCustomersConsent(state) {
      state.consent =
        state.consent === initialState.consent
          ? state.consent - 1
          : initialState.consent;
    },
  },
});

export function EmployeeAccountRemoval() {
  const {t} = useTranslation();

  const pbsStyle = useScreenPrimaryButtonStyle();
  const {body, mediumBody} = useTypography();
  const {colors} = useTheme();

  const loginedUserProfile = useSelector(
    loginedUserSelectors.profile,
  ) as Exclude<ReturnType<typeof loginedUserSelectors.profile>, null>;
  const [state, dispatch] = useReducer(slice.reducer, {...initialState});

  useEffect(() => {
    Logger.verboseTag('state: ', state);
  }, [state]);

  const goBack = useCallback(() => {
    dispatch(slice.actions.reset());
    if (navigationRef.current?.canGoBack()) {
      navigationRef.current?.goBack();
    } else {
      navigationRef.current?.navigate('Dashboard', {
        screen: 'QRCode',
      });
    }
  }, []);
  const {loading, remove} = useAccountRemove();
  const dispatchAction = useDispatch();

  const toggleCustomerConsent = useCallback(() => {
    dispatch(slice.actions.toggleCustomersConsent());
  }, []);
  const removeAccountButtonHandler = useCallback(async () => {
    Alert.alert(
      t('Screens.EmployeeAccountRemoval.areYouSure'),
      t(
        'Screens.EmployeeAccountRemoval.youAreGoingToRemoveYourAccountDataCanNotBeRecovere',
      ) || '',
      [
        {
          style: 'destructive',
          text: t('Screens.EmployeeAccountRemoval.removeAccount') || '',
          onPress: async () => {
            const success = await remove(loginedUserProfile.id);
            if (success) {
              dispatchAction(logout());
              setTimeout(
                Alert.alert,
                333,
                t('Screens.EmployeeAccountRemoval.yourAccountHasBeenDeleted'),
              );
            } else {
              Alert.alert(
                t('Screens.EmployeeAccountRemoval.anUnknownErrorHasOccurred'),
                t(
                  'Screens.EmployeeAccountRemoval.pleaseTryAgainLaterOrContactUsAtHellogetdoppiocom',
                ) || '',
              );
            }
          },
        },
        {
          style: 'cancel',
          text: t('Screens.EmployeeAccountRemoval.cancel') || '',
        },
      ],
    );
  }, [dispatchAction, loginedUserProfile.id, remove, t]);

  return (
    <Screen
      navbar={useMemo(
        () => (
          <Navbar />
        ),
        [],
      )}
    >
      <Screen.Heading>
        {t('Screens.EmployeeAccountRemoval.accountRemoval')}
      </Screen.Heading>
      <Spacer />

      <>
        <Text style={body}>
          {t('Screens.EmployeeAccountRemoval.generalWarning.youCanDelete')}{' '}
          <Text style={mediumBody}>
            {t('Screens.EmployeeAccountRemoval.generalWarning.doppio')}
          </Text>{' '}
          {t('Screens.EmployeeAccountRemoval.generalWarning.accountAnyTime')}
        </Text>
        <Spacer />
        <>
          <CheckboxItem
            disabled={loading}
            status={state.consent === 0 ? 'checked' : 'unchecked'}
            onPress={toggleCustomerConsent}
          >
            <Text style={body}>
              {t(
                'Screens.EmployeeAccountRemoval.iUnderstandThatTheDataCannotBeRecovered',
              )}
            </Text>
          </CheckboxItem>
        </>
        <Spacer />
        {state.consent === 0 ? (
          <>
            <Text style={body}>
              {t('Screens.EmployeeAccountRemoval.confirmHint', {
                context: Platform.OS,
              })}
            </Text>
            <Spacer />
            <Spacer />
            <Pusher />
            <ThemeProvider colors={{brand: colors.red}}>
              <Button
                title={t('Screens.EmployeeAccountRemoval.removeAccount')}
                onPress={loading ? undefined : removeAccountButtonHandler}
                loading={loading}
              />
            </ThemeProvider>
            <Spacer />
          </>
        ) : (
          <Pusher />
        )}
      </>

      <Button.Secondary
        disabled={loading}
        onPress={goBack}
        title={
          state.consent === 0
            ? t('Screens.EmployeeAccountRemoval.cancel')
            : t('Screens.EmployeeAccountRemoval.close')
        }
        style={pbsStyle}
      />
    </Screen>
  );
}
