import {
  ActivityIndicator,
  Platform,
  StyleSheet,
  Text,
  TextProps,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
} from 'react-native';
import React, {ComponentProps, PropsWithChildren, useMemo} from 'react';
import {Button, Typography, useTheme} from '@b2cmessenger/doppio-components';
import {useTranslation} from '@shared';

export function IconButton({
  label,
  caption,
  children,
  IconComponent,
  style: _style,
  captionStyle: _captionStyle,
  textStyle: _textStyle,
  ...rest
}: PropsWithChildren<
  TouchableOpacityProps & {
    label: string;
    caption?: string;
    fill?: string;
    captionStyle?: TextProps['style'];
    textStyle?: TextProps['style'];
    IconComponent: React.ComponentType<{size: number; fill?: string}>;
  }
>) {
  const {colors} = useTheme();
  const ibStyles = useMemo(
    () =>
      StyleSheet.create({
        wrapper: {
          flexDirection: 'row',
          alignItems: 'center',
          height: 60,
          paddingVertical: 10,
        },
        content: {
          alignSelf: 'stretch',
          borderBottomColor: colors.lightgray,
          borderBottomWidth: 1,
          marginBottom: -10,
          paddingBottom: 10,
          marginLeft: 8,
          flexGrow: 1,
        },
        contentRow: {
          marginTop: 'auto',
          marginBottom: 'auto',
          flexDirection: 'row',
          alignItems: 'center',
        },
        textRow: {
          flexGrow: 1,
        },
      }),
    [colors.lightgray],
  );
  const style = useMemo(() => [ibStyles.wrapper, _style], [
    _style,
    ibStyles.wrapper,
  ]);
  const captionStyle = useMemo(() => [Typography.smallBody, _captionStyle], [
    _captionStyle,
  ]);
  const textStyle = useMemo(() => [Typography.mediumBody, _textStyle], [
    _textStyle,
  ]);

  return (
    <TouchableOpacity {...rest} style={style}>
      <IconComponent size={28} fill={rest.fill} />
      <View style={ibStyles.content}>
        <View style={ibStyles.contentRow}>
          <View style={ibStyles.textRow}>
            <Text style={textStyle}>{label}</Text>
            {caption !== undefined ? (
              <Text style={captionStyle}>{caption}</Text>
            ) : null}
          </View>
          {children}
        </View>
      </View>
    </TouchableOpacity>
  );
}

type DeleteButtonProps = {disabled?: boolean; loading?: boolean} & Pick<
  ComponentProps<typeof Button.ExtraSmall>,
  'onPress'
>;
export function DeleteButton({disabled, loading, onPress}: DeleteButtonProps) {
  const {t} = useTranslation();

  const {colors} = useTheme();
  const _dbStyles = useMemo(() => {
    return StyleSheet.create({
      button: {
        ...dbStyles.button,
        backgroundColor: colors.red,
      },
    });
  }, [colors.red]);

  return loading ? (
    <ActivityIndicator
      color={colors.red}
      size="small"
      style={dbStyles.loader}
    />
  ) : (
    <Button.ExtraSmall
      onPress={onPress}
      title={t('Components.DeleteButton.delete')}
      disabled={disabled}
      style={disabled ? undefined : _dbStyles.button}
    />
  );
}

const dbStyles = StyleSheet.create({
  button: {
    width: 77,
    paddingHorizontal: 0,
    ...(Platform.OS === 'web' ? {'pointer-events': 'auto'} : {}),
  },
  loader: {width: 77},
});
export type IconButtonProps = React.ComponentProps<typeof IconButton>;
