import React, {useMemo} from 'react';
import {Text, TextStyle, View, ViewStyle} from 'react-native';
import {Typography, useTheme} from '@b2cmessenger/doppio-components';
import {IconPosTabled} from '@components/common/icons/SvgIcon';
import {useTranslation} from '@shared';

export function NoDevicesAndEmployeesPlaceholder() {
  const {t} = useTranslation();
  const {colors} = useTheme();
  const style = useMemo<ViewStyle>(() => ({alignItems: 'center'}), []);
  const textStyle = useMemo<TextStyle>(
    () => ({
      ...Typography.mediumBody,
      textAlign: 'center',
      color: colors.darkgray,
    }),
    [colors.darkgray],
  );
  return (
    <View style={style}>
      <IconPosTabled size={108} stroke={colors.brand} fill={colors.brand} />
      <Text style={textStyle}>
        {t(
          'Screens.Profile.NoDevicesAndEmployeesPlaceholder.addYourPosTabletOrAnEmployeeSmartphoneToGenerateQr',
        )}
      </Text>
    </View>
  );
}
