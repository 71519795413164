import {api} from '@b2cmessenger/doppio-core';
import {initSentry} from '@b2cmessenger/doppio-shared';

import React, {useCallback, useEffect} from 'react';
import {Provider as StoreProvider} from 'react-redux';
import {persistor, store} from '@store/index';
import {Platform} from 'react-native';
import {PersistGate} from 'redux-persist/integration/react';
import {loadAsync as FontLoadAsync} from 'expo-font';
import {ActionSheetProvider} from '@expo/react-native-action-sheet';

// @ts-ignore
import iconFont from '../assets/icons/fonts/icomoon/icomoon.ttf';
import Config from '@utils/Config';
import {AppContent} from './AppContent';
import AppVersion from '@utils/AppVersion';
import {localization, bundles} from '@shared';
import 'intl-pluralrules';

if (!__DEV__) {
  initSentry(Config.SENTRY_DSN);
}
api.configure({
  apiUrl: Config.API_URL,
  appId: Config.APP_ID,
  appVersion: AppVersion,
});

localization.init({bundles}).then(() => {
  api.updateHeaders({['Accept-Language']: localization.getLanguage()});
});
localization.events.addListener('languageChanged', () => {
  api.updateHeaders({['Accept-Language']: localization.getLanguage()});
});

if (Platform.OS !== 'web') {
  const {enableScreens} = require('react-native-screens');
  enableScreens(true);
}

function App() {
  const children = useCallback((isStoreRehydrated: boolean) => {
    if (!isStoreRehydrated) {
      return null;
    }

    return <AppContent />;
  }, []);

  useEffect(() => {
    FontLoadAsync({
      icomoon: iconFont,
    });
  }, []);

  return (
    <ActionSheetProvider>
      <StoreProvider store={store}>
        <PersistGate persistor={persistor} children={children} />
      </StoreProvider>
    </ActionSheetProvider>
  );
}

export default App;
