import {StampScanNotification} from '@typings/Notification';

export type OperationResult =
  | {state: 'award'; awardId: number; awardQuantity: number}
  | {
      state: 'stamp';
      quantity: number;
      awardBalanceDiff: number;
      firstAwardRemains?: number;
    }
  | {state: 'error'; awardRemains: number};

export type OperationResultWithResetState =
  | OperationResult
  | {state: 'reset'; current: number};

type ScanOperationResultFactoryOptions = {
  placeId: number;
  /**
   * cost of default award in stamps
   */
  awardCost: number;
};

export const constructFromNotification = (
  notification: StampScanNotification,
  options: ScanOperationResultFactoryOptions,
): OperationResultWithResetState | undefined => {
  if (notification.data.place_id !== options.placeId) {
    return undefined;
  }

  switch (notification.data.type) {
    case 'give':
      const awardsCountBefore = Math.floor(
        (notification.data.balance - notification.data.current) /
          options.awardCost,
      );
      const awardsCountCurrent = Math.floor(
        notification.data.balance / options.awardCost,
      );

      return {
        state: 'stamp',
        quantity: notification.data.current,
        awardBalanceDiff: awardsCountCurrent - awardsCountBefore,
        ...(notification.data.balance <= options.awardCost
          ? {
              firstAwardRemains: options.awardCost - notification.data.balance,
            }
          : {}),
      };

    case 'give_register':
      return {
        state: 'reset',
        current: notification.data.current,
      };

    case 'award':
      return {
        state: 'award',
        awardQuantity: notification.data.award_quantity,
        awardId: notification.data.award_id,
      };

    default:
      return {
        state: 'error',
        awardRemains: notification.data.award_remains,
      };
  }
};
