import {createBackgroundNotificationsEnvironment} from '@b2cmessenger/doppio-core';
import AsyncStorage from '@react-native-async-storage/async-storage';

const {
  backgroundMessageHandler,
  restoreBackgroundMessages,
  triggerMessageRestore,
  subscribeToRestoredMessages,
  NotificationStorage,
} = createBackgroundNotificationsEnvironment({
  storage: AsyncStorage,
});

async function purgeMessageStorage() {
  await NotificationStorage.purge();
}

export {
  backgroundMessageHandler,
  restoreBackgroundMessages,
  triggerMessageRestore,
  subscribeToRestoredMessages,
  NotificationStorage,
  purgeMessageStorage,
};
