import {useCallback, useEffect, useMemo} from 'react';
import useSWR from 'swr';
import {InactiveCustomers, InactiveCustomersMessages} from '@typings/common';
import {api} from '@b2cmessenger/doppio-core';
import {Logger} from '@b2cmessenger/doppio-shared';
import {useIsFocused} from '@react-navigation/native';

export enum CustomerMessagesCardStates {
  loading = 'loading',
  readyToSend = 'readyToSend',
  waiting = 'waiting',
  haveNoGuests = 'haveNoGuests',
  allGuestsActive = 'allGuestsActive',
}

export function getCustomerMessagesState({
  totalCount,
  inactiveCustomers,
  isAbleToSend,
}: {
  inactiveCustomers: number | null | undefined;
  isAbleToSend: boolean;
  totalCount: number | null | undefined;
}) {
  if (typeof totalCount !== 'number' || typeof inactiveCustomers !== 'number') {
    return CustomerMessagesCardStates.loading;
  } else if (totalCount > 0 && isAbleToSend && inactiveCustomers > 0) {
    return CustomerMessagesCardStates.readyToSend;
  } else if (totalCount > 0 && !isAbleToSend && inactiveCustomers > 0) {
    return CustomerMessagesCardStates.waiting;
  } else if (totalCount === 0) {
    return CustomerMessagesCardStates.haveNoGuests;
  } else if (totalCount > 0 && inactiveCustomers === 0) {
    return CustomerMessagesCardStates.allGuestsActive;
  } else {
    Logger.errorTag('getCustomerMessagesState', 'something goes wrong');
  }
}

export function useMessageToCustomers(placeId: number) {
  const swrKey = useMemo(() => {
    // period
    if (!placeId) {
      return null;
    }
    return `/api/v2/place/${placeId}/stamps/stat/inactive-customers`;
  }, [placeId]);

  const {data, error, mutate, isLoading} = useSWR(swrKey, fetcher, {
    revalidateOnMount: false,
    revalidateOnFocus: false,
  });
  // revalidate onFocus
  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      mutate();
    }
  }, [isFocused, mutate]);

  const update = useCallback(
    async (newData: Partial<InactiveCustomers>) => {
      await mutate({...data, ...newData}, {revalidate: false});
    },
    [mutate, data],
  );
  return {
    inactiveCustomers: data?.customers_count,
    isAbleToSend: Boolean(
      data?.send_message_allowed && data?.send_message_next_in_hours === 0,
    ),
    sendMessageNextInHours: data?.send_message_next_in_hours,
    error,
    loading: isLoading,
    update,
  };
}

async function fetcher(url: string) {
  try {
    const response = await api.default.get(url);
    return api.getResponseData(response) as InactiveCustomers;
  } catch (e) {
    throw api.parseErrorToHumanReadableMessage(e);
  }
}

const _mockSendMessageToCustomersResponse: InactiveCustomersMessages = {
  /**
   * Дата
   * example:
   * 2018-11-12 13:40:50
   */
  send_message_last_date: '2018-11-12 13:40:50', // yyyy-MM-dd HH:mm:ss
  /**
   * Статус последней отправки сообщений, 0 – только что создано, 1 – в процессе отправки, 2 – отправлено
   */
  send_message_last_status: null,
  /**
   * Через сколько _часов_ доступна следующая отправка сообщений относительно текущего момента
   */
  send_message_next_in_hours: 1,
  /**
   * Период _дней_, не чаще которого можно отправлять сообщения
   */
  send_message_interval_days: 7,
};
export async function sendMessageToCustomers(placeId: number) {
  try {
    const response = await api.default.post(
      `/api/v2/place/${placeId}/stamps/stat/inactive-customers/messages`,
    );
    return api.getResponseData(response) as InactiveCustomersMessages;
  } catch (e) {
    throw api.parseErrorToHumanReadableMessage(e);
  }
}
