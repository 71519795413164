import {
  PlaceSubscriptionState,
  PlaceSubscriptionStateExtended,
} from '@typings/Place';
import {SubscriptionStatuses} from '@utils/subscriptionState';

enum OverviewDisplayState {
  fullAccess = 'fullAccess',
  fullAccessWithExpirationDate = 'fullAccessWithExpirationDate',
  trialNotStartedYet = 'trialNotStartedYet',
  subscriptionPaused = 'subscriptionPaused',
  limitedAccess = 'limitedAccess',
}

export type GetDisplayStateProps = Pick<
  PlaceSubscriptionStateExtended,
  'isForeverFree' | 'status' | 'expireInDays' | 'isTrialNotStartedYet'
>;
function getDisplayState(state: GetDisplayStateProps): OverviewDisplayState {
  const {isForeverFree, status, isTrialNotStartedYet} = state;

  if (isForeverFree || isSubscriptionAutoRenewal(state)) {
    return OverviewDisplayState.fullAccess;
  } else if (isTrialNotStartedYet) {
    return OverviewDisplayState.trialNotStartedYet;
  } else if (isFullAccessWithExpirationDate(state)) {
    return OverviewDisplayState.fullAccessWithExpirationDate;
  } else if (isPaused(status)) {
    return OverviewDisplayState.subscriptionPaused;
  } else {
    return OverviewDisplayState.limitedAccess;
  }
}

/** The user has access to advanced functionality.
 * Subscription status: ok or access with payment problems
 **/
export function isStatusOkOrAccessWithPaymentProblems(
  status: PlaceSubscriptionState['status'],
) {
  return (
    [
      SubscriptionStatuses.ok,
      SubscriptionStatuses.accessWithPaymentProblems,
    ].indexOf(status) !== -1
  );
}
function isSubscriptionAutoRenewal(state: GetDisplayStateProps) {
  return (
    state.expireInDays === null &&
    isStatusOkOrAccessWithPaymentProblems(state.status) &&
    !state.isTrialNotStartedYet
  );
}
function isFullAccessWithExpirationDate(state: GetDisplayStateProps) {
  return (
    isStatusOkOrAccessWithPaymentProblems(state.status) &&
    state.expireInDays !== null
  );
}
function isPaused(status: PlaceSubscriptionState['status']) {
  return status === SubscriptionStatuses.paused;
}
export function hasPaymentProblems(status: PlaceSubscriptionState['status']) {
  return status === SubscriptionStatuses.accessWithPaymentProblems;
}

export {OverviewDisplayState};
export {getDisplayState as getOverviewDisplayState};
