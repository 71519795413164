import {FirebaseDynamicLinksTypes} from '@react-native-firebase/dynamic-links';
import {buildLinkSync, DynamicLinksConfig} from '@utils/DynamicLinks/shared';

/**
 * Build a link and provide only
 *
 * `android.packageName`, `ios.bundleId` and `android|ios.fallbackUrl` options
 *
 * to restrict client to either scan link and get directly in mobile app
 *
 * either open page in web browser.
 *
 * Link look like that:
 * https://punchcards.com/link/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0Ij...kIi_w
 */

export enum DynamicLinkActions {
  giveStamps = 'giveStamps',
  awardFreeGift = 'awardFreeGift',
  stampBalance = 'stampBalance',
}
export const DynamicLinkActionShortTags = {
  [DynamicLinkActions.awardFreeGift]: 'sa',
  [DynamicLinkActions.giveStamps]: 'sg',
  [DynamicLinkActions.stampBalance]: 'sb',
};

export function buildDynamicLink(options: {
  action: DynamicLinkActions;
  jwt: string;
}) {
  /**
   * domain.com/link/${actualJwtString}
   */
  const link = [
    DynamicLinksConfig.Client.DynamicLinkDomain,
    'link',
    options.jwt,
  ].join('/');

  return buildLinkSync({
    domainUriPrefix: DynamicLinksConfig.Client.DomainURIPrefix,
    link: link,
    android: {
      packageName: DynamicLinksConfig.Client.AndroidPackageName,
      fallbackUrl: link,
    },
    ios: {
      fallbackUrl: link,
      bundleId: DynamicLinksConfig.Client.IosBundleId,
    },
    navigation: {
      forcedRedirectEnabled: true,
    },
  });
}
