import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {stampAwardsSelectors} from '@store/selectors';
import pluralize from 'pluralize';

const DefaultAwardId = 0;

export type GetAwardName = (
  placeId: number,
  awardId?: number,
  quantity?: number,
) => string;
export type GetAwardCost = (
  placeId: number,
  awardId?: number,
) => number | undefined;
type UseStampAwardResult = {
  getAwardName: GetAwardName;
  getAwardCost: GetAwardCost;
};
function useStampAward(): UseStampAwardResult {
  const placeAwardNameMemo = useSelector(stampAwardsSelectors.placeAwardName);
  const placeAwardCostMemo = useSelector(stampAwardsSelectors.placeAwardCost);

  const getAwardName = useCallback<GetAwardName>(
    (placeId, awardId = DefaultAwardId, quantity = 1) => {
      const name = placeAwardNameMemo(placeId, awardId);
      return name ? pluralize(name, quantity) : '';
    },
    [placeAwardNameMemo],
  );

  const getAwardCost = useCallback(
    (placeId, awardId = DefaultAwardId) => {
      return placeAwardCostMemo(placeId, awardId);
    },
    [placeAwardCostMemo],
  );
  return {getAwardName, getAwardCost};
}

export default useStampAward;
