import DynamicLinks from './index';
import {InviteLink} from './shared';

export async function parseDynamicLink(value: string) {
  const dl = await DynamicLinks.resolveLink(value);
  const inviteLink = InviteLink.parse(dl.url);
  if (inviteLink !== null) {
    const {type, placeId, inviteCode} = inviteLink;

    if (type === 'employee') {
      return {
        type: 'employeeInviteLink',
        invite: {
          placeId: placeId,
          code: inviteCode,
        },
      } as UserInvite<'employee'>;
    }
    return {
      type: 'posInviteLink',
      invite: {
        placeId: placeId,
        code: inviteCode,
      },
    } as UserInvite<'pos'>;
  }

  return null;
}

interface UserInvite<T extends 'employee' | 'pos'> {
  type: T extends 'employee' ? 'employeeInviteLink' : 'posInviteLink';
  invite: {
    placeId: number;
    code: string;
  };
}
