import React, {useCallback, useMemo} from 'react';
import {Navbar, Screen} from '@b2cmessenger/doppio-components';
import {navigationRef} from '@navigation/navigationRef';
import Manage from '@screens/Dashboard/screens/ManageSubscription/components/Manage';
import {useSelector} from 'react-redux';
import {loginedUserSelectors} from '@store/selectors';
import Overview from '@screens/Dashboard/screens/ManageSubscription/components/Overview';
import {getSubscriptionStateExtended} from '@utils/subscriptionState';
import {useTranslation} from '@shared';

function ManageSubscription() {
  const {t} = useTranslation();
  const workplaceSubscription = useSelector(
    loginedUserSelectors.workplaceSubscription,
  );

  const workplaceSubscriptionExtended = useMemo(
    () => getSubscriptionStateExtended(workplaceSubscription),
    [workplaceSubscription],
  );

  const goBack = useCallback(() => {
    if (navigationRef.current?.canGoBack()) {
      navigationRef.current?.goBack();
    } else {
      navigationRef.current?.navigate('Dashboard', {
        screen: 'Profile',
        params: {
          screen: 'Settings',
        },
      });
    }
  }, []);

  const navbar = useMemo(() => {
    const _title = t('Screens.ManageSubscription.payments');
    return (
      <Navbar
        title={_title}
        onBack={goBack}
        backButtonTestID={'manage-subscription-back'}
      />
    );
  }, [goBack, t]);

  return (
    <Screen navbar={navbar} scrollable>
      <Overview subscriptionStateExtended={workplaceSubscriptionExtended} />

      {workplaceSubscriptionExtended.isForeverFree ? null : (
        <Manage subscriptionStateExtended={workplaceSubscriptionExtended} />
      )}
    </Screen>
  );
}

export default ManageSubscription;
