import {api} from '@b2cmessenger/doppio-core';
import {Logger} from '@b2cmessenger/doppio-shared';
import {SubscriptionInfo} from '@typings/common';

export async function getSubscriptionInfo() {
  return api.default
    .get<SubscriptionInfo>('/api/init/subscription-info')
    .catch(e => {
      Logger.errorTag('getSubscriptionInfo', e);
      throw api.parseErrorToHumanReadableMessage(e);
    })
    .then(api.getResponseData)
    .then(info => {
      if (
        info &&
        typeof info.number_of_trial_days === 'number' &&
        typeof info.price_per_month_usd_cents === 'number'
      ) {
        return {
          numberOfTrialDays: info.number_of_trial_days as number,
          pricePerMonthUsdCents: info.price_per_month_usd_cents as number,
        };
      }
    });
}
