import {api} from '@b2cmessenger/doppio-core';
import {PlaceAppearance} from '@typings/Place';
import {Logger} from '@b2cmessenger/doppio-shared';
import {AxiosRequestConfig} from 'axios';
import {PlaceAppearanceConfigExtended} from '@screens/BusinessWizard/slice';

export async function updatePlaceAppearance(
  {
    placeId,
    config,
  }: {
    placeId: number;
    config: PlaceAppearanceConfigExtended;
  },
  requestConfig?: AxiosRequestConfig,
) {
  const data: PlaceAppearanceConfigExtended = {
    color: config.color,
    stamp_resource_id: config.stamp_resource_id,
    award_resource_id: config.award_resource_id,
    award_name: config.award_name || null,
  };

  return api.default
    .post<PlaceAppearance>(
      `/api/v2/place/${placeId}/appearance`,
      data,
      requestConfig,
    )
    .then(api.getResponseData)
    .catch(e => {
      Logger.errorTag('updatePlaceAppearance', e);
      throw api.parseErrorToHumanReadableMessage(e);
    });
}
