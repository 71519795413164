import {colors} from '@b2cmessenger/doppio-shared';

type Confetti = {
  vectorEndPosition: {
    x: number;
    y: number;
  };
  height: number;
  width: number;
  rotate: number;
  offsetX?: number;
  fill?: string;
  type: 'star' | 'rectangle' | 'ellipse' | 'snake';
};

const Stars: Array<Omit<Confetti, 'type'>> = [
  {
    vectorEndPosition: {x: 106, y: 45},
    height: 20,
    width: 20,
    fill: colors.green,
    rotate: -15,
    offsetX: -2,
  },
  {
    vectorEndPosition: {x: 212, y: 244},
    height: 20,
    width: 20,
    fill: colors.brand,
    rotate: -15,
    offsetX: -4,
  },
  {
    vectorEndPosition: {x: 339, y: 164},
    height: 32,
    width: 32,
    fill: colors.yellow,
    rotate: 30,
    offsetX: 4,
  },
];

const Rectangles: Array<Omit<Confetti, 'type'>> = [
  {
    vectorEndPosition: {x: 133, y: 202},
    height: 24,
    width: 8,
    fill: colors.green,
    rotate: 30,
    offsetX: -5,
  },
  {
    vectorEndPosition: {x: 159, y: 88},
    height: 24,
    width: 8,
    fill: colors.red,
    rotate: -15,
    offsetX: -2,
  },
  {
    vectorEndPosition: {x: 232, y: 40},
    height: 32,
    width: 12,
    fill: colors.yellow,
    rotate: 23,
    offsetX: 4,
  },
  {
    vectorEndPosition: {x: 283, y: 199},
    height: 32,
    width: 12,
    fill: colors.brand,
    offsetX: 15,
    rotate: 135,
  },
];

const Ellipses: Array<Omit<Confetti, 'type'>> = [
  {
    vectorEndPosition: {x: 103, y: 106},
    height: 6,
    width: 6,
    fill: colors.yellow,
    rotate: 0,
  },
  {
    vectorEndPosition: {x: 207, y: 106},
    height: 12,
    width: 12,
    fill: colors.brand,
    rotate: 0,
  },
  {
    vectorEndPosition: {x: 217, y: 206},
    height: 12,
    width: 12,
    fill: colors.yellow,
    rotate: 0,
  },
  {
    vectorEndPosition: {x: 163, y: 212},
    height: 6,
    width: 6,
    fill: colors.red,
    rotate: 0,
  },
];

const Snakes: Array<Omit<Confetti & {secondFill?: string}, 'type'>> = [
  {
    vectorEndPosition: {x: 24, y: 200},
    height: 56,
    width: 24,
    rotate: 30,
    fill: '#BD380E',
    secondFill: colors.red,
    offsetX: -15,
  },
  {
    vectorEndPosition: {x: 0, y: 38},
    height: 72,
    width: 36,
    rotate: -60,
    fill: '#0046CF',
    secondFill: colors.brand,
    offsetX: -12,
  },
  {
    vectorEndPosition: {x: 291, y: 48},
    height: 56,
    width: 24,
    rotate: 30,
    fill: '#13885E',
    secondFill: colors.green,
    offsetX: -5,
  },
];

export const ConfettiItems = [
  ...Stars.map((p) => ({...p, type: 'star'})),
  ...Rectangles.map((p) => ({...p, type: 'rectangle'})),
  ...Snakes.map((p) => ({...p, type: 'snake'})),
  ...Ellipses.map((p) => ({...p, type: 'ellipse'})),
];
