import React, {ComponentProps, useCallback, useEffect, useMemo} from 'react';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {
  DashboardNavigatorScreenParams,
  RootNavigatorScreenProps,
} from '@navigation/config';
import {
  IconPlaceProfile,
  IconQRCode,
  IconStats,
} from '@components/common/icons/SvgIcon';
import {QRCodeTabScreen} from '@screens/Dashboard/screens/QRCode';
import {ProfileTabScreen} from '@screens/Dashboard/screens/Profile';
import {StatsTabScreen} from './screens/Stats/Stats';
import {useDispatch, useSelector} from 'react-redux';
import {loginedUserSelectors, tooltipsSelector} from '@store/selectors';
import WorkplaceContext, {
  getWorkplaceRole,
} from '@components/common/WorkplaceContext';
import ChangeWorkplaceName from '@screens/Dashboard/screens/ChangeWorkplaceName';
import Tooltip from '@components/common/Tooltip/Tooltip';
import {TooltipKeys} from '@store/tooltips.slice';
import {logout, setTooltipOptions} from '@store/actions';
import {getFocusedRouteNameFromRoute} from '@react-navigation/native';
import EditLoyaltyProgram from '@screens/Dashboard/screens/EditLoyaltyProgram';
import ManageSubscription from '@screens/Dashboard/screens/ManageSubscription';
import CustomizeAppearance from '@screens/Dashboard/screens/CustomizeAppearance';
import {TEST_IDS} from './DashboardNavigatorTestIDS';
import {Analytics} from '@b2cmessenger/doppio-core';
import {ThemeProvider, useTheme} from '@b2cmessenger/doppio-components';
import {Portal} from 'react-native-paper';
import {AccountRemoval} from '@screens/Dashboard/screens/AccountRemoval';
import {EmployeeAccountRemoval} from '@screens/Dashboard/screens/EmployeeAccountRemoval';
import {useTranslation, SharedHooks} from '@shared';
import {Customers} from '@screens/Dashboard/screens/Stats/Customers';
import {CustomerMessages} from '@screens/Dashboard/screens/Stats/Messages/CustomerMessages';
const {usePlaceAppearance} = SharedHooks;

const Tab = createBottomTabNavigator<DashboardNavigatorScreenParams>();
type TabNavigationProps = ComponentProps<typeof Tab.Navigator>;

export function DashboardNavigator({
  route,
  navigation,
}: RootNavigatorScreenProps<'Dashboard'>) {
  const workplace = useSelector(loginedUserSelectors.workplace) as Exclude<
    ReturnType<typeof loginedUserSelectors.workplace>,
    null
  >;
  const {color} = usePlaceAppearance(workplace.id);
  return (
    <ThemeProvider colors={useMemo(() => ({brand: color}), [color])}>
      <Portal.Host>
        <DashboardNavigatorContent route={route} navigation={navigation} />
      </Portal.Host>
    </ThemeProvider>
  );
}
function DashboardNavigatorContent({
  route,
}: RootNavigatorScreenProps<'Dashboard'>) {
  const {t} = useTranslation();
  const initialDashboardTooltips = useMemo(() => {
    return [
      {
        title: t('Screens.DashboardNavigator.trackVisitsOfYourCustomers'),
        isShown: false,
      },
      {
        title: t(
          'Screens.DashboardNavigator.manageYourAccountDevicesAndEmployees',
        ),
        isShown: false,
      },
    ];
  }, [t]);

  const {colors} = useTheme();
  const routeName = useMemo(() => {
    return getFocusedRouteNameFromRoute(route) ?? '';
  }, [route]);

  const tooltips = useSelector(tooltipsSelector);
  const dispatch = useDispatch();
  const workplace = useSelector(loginedUserSelectors.workplace) as Exclude<
    ReturnType<typeof loginedUserSelectors.workplace>,
    null
  >;
  const role = getWorkplaceRole(workplace);
  const loginedUser = useSelector(loginedUserSelectors.profile);
  const isDevice = useMemo(() => loginedUser?.is_device === 1, [loginedUser]);

  const [qrIcon, statIcon, profileIcon] = useTabBarIcons();

  useEffect(() => {
    if (role === 'owner') {
      Analytics.logEvent('dashboard_ready');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const screenOptions = useCallback<
    Extract<TabNavigationProps['screenOptions'], Function>
  >(
    ({route}) => {
      const isRouteTabBarLess = getIsScreenTabBarLess(route.name);
      const _qrLabel = t('Screens.DashboardNavigator.qrCode');
      const _statsLabel = t('Screens.DashboardNavigator.statistics');
      const _profileLabel = t('Screens.DashboardNavigator.profile');

      return {
        tabBarLabel: isRouteTabBarLess
          ? ''
          : route.name === 'QRCode'
          ? _qrLabel
          : route.name === 'Stats'
          ? _statsLabel
          : _profileLabel,
        tabBarIcon: isRouteTabBarLess
          ? () => null
          : route.name === 'QRCode'
          ? qrIcon
          : route.name === 'Stats'
          ? statIcon
          : profileIcon,
        tabBarTestID: isRouteTabBarLess
          ? route.name
          : route.name === 'QRCode'
          ? TEST_IDS.buttons.QRCode
          : route.name === 'Stats'
          ? TEST_IDS.buttons.Stats
          : TEST_IDS.buttons.Profile,
        tabBarVisible: !isRouteTabBarLess && role === 'owner',
        tabBarButton: isRouteTabBarLess ? () => null : undefined,
      };
    },
    [profileIcon, qrIcon, role, statIcon, t],
  );

  const tabBarOptions = useMemo<TabNavigationProps['tabBarOptions']>(
    () => ({
      inactiveBackgroundColor: colors.white,
      activeBackgroundColor: colors.white,
      activeTintColor: colors.brand,
      inactiveTintColor: colors.darkgray,
      allowFontScaling: false,
    }),
    [colors.brand, colors.darkgray, colors.white],
  );

  const onAllTooltipsShown = useCallback(() => {
    dispatch(
      setTooltipOptions({
        key: TooltipKeys.dashboard,
        tooltipOptions: {shown: true},
      }),
    );
  }, [dispatch]);

  const isTooltipsVisible = useMemo(
    () =>
      !tooltips[TooltipKeys.dashboard]?.shown &&
      role === 'owner' &&
      !getIsScreenTabBarLess(routeName),
    [role, routeName, tooltips],
  );

  useEffect(() => {
    if (
      role === 'employee' &&
      loginedUser?.is_device === 1 &&
      workplace.roles.length === 0
    ) {
      dispatch(logout());
    }
  }, [dispatch, loginedUser, role, workplace.roles]);

  return (
    <Tooltip.Group
      onDone={onAllTooltipsShown}
      allShown={tooltips[TooltipKeys.dashboard]?.shown}
      initialTips={initialDashboardTooltips}
      visible={isTooltipsVisible}>
      <WorkplaceContext.Provider value={workplace}>
        <Tab.Navigator
          sceneContainerStyle={{overflow: 'visible'}}
          screenOptions={screenOptions}
          tabBarOptions={tabBarOptions}>
          <Tab.Screen name="QRCode" component={QRCodeTabScreen} />
          {role !== 'owner' && !isDevice ? (
            <Tab.Screen
              name="AccountRemoval"
              component={EmployeeAccountRemoval}
              options={{
                title: t('Screens.DashboardNavigator.accountRemoval') || '',
              }}
            />
          ) : null}
          {role === 'owner' ? (
            <>
              <Tab.Screen
                name="Stats"
                component={StatsTabScreen}
                options={{
                  title: t('Screens.DashboardNavigator.statistics') || '',
                }}
              />
              <Tab.Screen
                name="Customers"
                component={Customers}
                options={{
                  title: t('Screens.DashboardNavigator.statistics') || '',
                }}
              />
              <Tab.Screen
                name="CustomerMessages"
                component={CustomerMessages}
                options={{
                  title: t('Screens.DashboardNavigator.statistics') || '',
                }}
              />
              <Tab.Screen
                name="ChangeWorkplaceName"
                options={{
                  title:
                    t('Screens.DashboardNavigator.changeWorkplaceName') || '',
                }}
                component={ChangeWorkplaceName}
              />
              <Tab.Screen
                name="EditLoyaltyProgram"
                options={{
                  title:
                    t('Screens.DashboardNavigator.editLoyaltyProgram') || '',
                }}
                component={EditLoyaltyProgram}
              />
              <Tab.Screen
                name="AccountRemoval"
                component={AccountRemoval}
                options={{
                  title: t('Screens.DashboardNavigator.accountRemoval') || '',
                }}
              />
              <Tab.Screen
                name="ManageSubscription"
                options={{
                  title:
                    t('Screens.DashboardNavigator.manageSubscription') || '',
                }}
                component={ManageSubscription}
              />
              <Tab.Screen
                name="CustomizeAppearance"
                options={{
                  title:
                    t('Screens.DashboardNavigator.customizeAppearance') || '',
                }}
                component={CustomizeAppearance}
              />
              <Tab.Screen name="Profile" component={ProfileTabScreen} />
            </>
          ) : null}
        </Tab.Navigator>
      </WorkplaceContext.Provider>
    </Tooltip.Group>
  );
}

type RenderIconProps = {color: string; size: number};
type IconWithTooltip = 'stat' | 'profile';
function getIconByType(props: {type: IconWithTooltip} & RenderIconProps) {
  const {type, size, color} = props;
  switch (type) {
    case 'stat':
      return <IconStats size={size} fill={color} />;
    case 'profile':
      return <IconPlaceProfile size={size} fill={color} />;
  }
}

const TOOLTIP_SIZE = {
  width: 220,
  height: 80,
};
function useTabBarIcons() {
  const qrIcon = useMemo(
    () =>
      ({size, color}: RenderIconProps) => (
        <IconQRCode size={size} fill={color} />
      ),
    [],
  );
  const [statIcon, profileIcon] = useMemo(
    () => [
      ...(['stat', 'profile'] as IconWithTooltip[]).map(
        (type, iconIdx) =>
          ({size, color}: RenderIconProps) => {
            const icon = getIconByType({type, size, color});
            return (
              <Tooltip.Item
                tooltip={TOOLTIP_SIZE}
                idx={iconIdx} // iconIdx strictly follow tips index
              >
                {icon}
              </Tooltip.Item>
            );
          },
      ),
    ],
    [],
  );

  return [qrIcon, statIcon, profileIcon];
}

function getIsScreenTabBarLess(routeName: string): boolean {
  return (
    [
      'ChangeWorkplaceName',
      'EditLoyaltyProgram',
      'ManageSubscription',
      'Customers',
      'CustomerMessages',
      'CustomizeAppearance',
      'AccountRemoval',
    ].indexOf(routeName) !== -1
  );
}
