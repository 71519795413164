import React, {
  ComponentProps,
  forwardRef,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LayoutAnimation,
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  TextProps,
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps,
  UIManager,
  View,
  ViewStyle,
} from 'react-native';
import {Card, Spacer, Typography} from '@b2cmessenger/doppio-components';
import {colors} from '@b2cmessenger/doppio-shared';
import {
  IconAlertCircle,
  IconBack,
  IconMinus,
  IconPlus,
} from '@components/common/icons/SvgIcon';
import {OperationResult} from '@screens/Dashboard/screens/QRCode/components/QRCodeGenerator/operationResultFactory';
import {isTablet} from 'react-native-device-info';
import {useTranslation, SharedHooks, useGetCurrentLanguage} from '@shared';
const {usePlaceAppearance} = SharedHooks;

if (
  Platform.OS === 'android' &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

export type QRCodeGeneratorControlsRef = {
  setOperationResult: (value: OperationResult) => void;
};

export type OnPressNoActiveSubscription = () => void;

type QRCodeGeneratorControlsProps = {
  state?: 'stamp' | 'award';
  onStamp: (q: number) => void;
  onAward: (q: number) => void;
  onInitialState: () => void;
  onOperationDone: (type: 'award' | 'stamp' | 'error') => void;
  onPressNoActiveSubscription: OnPressNoActiveSubscription;
  placeId: number;
  isAvailableFullAccessToApp: boolean;
};

const DefaultAwardQuantity = 1;

export const QRCodeGeneratorControls = forwardRef<
  QRCodeGeneratorControlsRef,
  QRCodeGeneratorControlsProps
>((props, ref) => {
  const {
    state: externalState,
    onStamp,
    onAward,
    onInitialState,
    onOperationDone,
    onPressNoActiveSubscription,
    placeId,
    isAvailableFullAccessToApp,
  } = props;

  const [state, setState] = useState<'stamp' | 'award' | undefined>(
    externalState,
  );

  useEffect(() => {
    setState(externalState);
  }, [externalState]);

  const [result, setResult] = useState<OperationResult>();

  const animateLayout = useCallback(() => {
    LayoutAnimation.configureNext(
      LayoutAnimation.create(
        150,
        LayoutAnimation.Types.easeInEaseOut,
        LayoutAnimation.Properties.opacity,
      ),
    );
  }, []);
  const animateLayoutScale = useCallback(() => {
    LayoutAnimation.configureNext(
      LayoutAnimation.create(
        150,
        LayoutAnimation.Types.easeInEaseOut,
        LayoutAnimation.Properties.scaleXY,
      ),
    );
  }, []);
  const resetResult = useCallback(() => {
    animateLayoutScale();
    setResult(undefined);
    onInitialState();
  }, [animateLayoutScale, onInitialState]);

  const resetTimer = useRef<ReturnType<typeof setTimeout>>();
  const runResetTimer = useCallback(() => {
    if (resetTimer.current) {
      clearTimeout(resetTimer.current);
    }
    resetTimer.current = setTimeout(() => {
      resetResult();
    }, 7000);
  }, [resetResult]);

  useEffect(() => {
    return () => {
      if (resetTimer.current) {
        clearTimeout(resetTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    if (result) {
      onOperationDone(result.state);
      runResetTimer();
    }
  }, [onOperationDone, result, runResetTimer]);

  const [pressAward, pressStamp] = useMemo(
    () => [
      () => {
        animateLayout();
        setState(s => (!s ? 'award' : undefined));
      },
      () => {
        animateLayout();
        setState(s => (!s ? 'stamp' : undefined));
      },
    ],
    [animateLayout],
  );
  const onBack = useCallback(() => {
    animateLayout();
    setState(undefined);
    onInitialState();
  }, [animateLayout, onInitialState]);
  useEffect(() => {
    if (ref) {
      if (typeof ref === 'function') {
        ref({
          setOperationResult: v => {
            animateLayoutScale();
            setResult(v);
          },
        });
      } else {
        ref.current = {
          setOperationResult: v => {
            animateLayoutScale();
            setResult(v);
          },
        };
      }
    }
  }, [animateLayoutScale, ref]);

  const onPressResultBlock = useCallback(() => {
    if (resetTimer.current) {
      clearTimeout(resetTimer.current);
    }
    resetResult();
  }, [resetResult]);

  return (
    <View style={s.selector}>
      {result ? (
        <ResultBlock
          value={result}
          onPress={onPressResultBlock}
          placeId={placeId}
        />
      ) : !isAvailableFullAccessToApp ? (
        <>
          <NoActiveSubscriptionAward
            onCounterChange={() => {}}
            active={false}
            onPress={onPressNoActiveSubscription}
            onBack={() => {}}
            placeId={placeId}
          />
          <View style={s.spacerHorizontal} />
          <NoActiveSubscriptionStamp
            onCounterChange={() => {}}
            active={false}
            onPress={onPressNoActiveSubscription}
            onBack={() => {}}
            placeId={placeId}
          />
        </>
      ) : (
        <>
          {state === 'stamp' ? null : (
            <AwardCard
              onPress={pressAward}
              active={state === 'award'}
              onCounterChange={onAward}
              onBack={onBack}
              placeId={placeId}
            />
          )}
          {state ? null : <View style={s.spacerHorizontal} />}
          {state === 'award' ? null : (
            <StampCard
              onPress={pressStamp}
              active={state === 'stamp'}
              onCounterChange={onStamp}
              onBack={onBack}
              placeId={placeId}
            />
          )}
        </>
      )}
    </View>
  );
});

function ResultBlock({
  value,
  onPress,
  placeId,
}: {
  value: OperationResult;
  placeId: number;
} & Pick<TouchableOpacityProps, 'onPress'>) {
  const {t} = useTranslation();

  const {
    IconStamp,
    IconAward,
    stamp,
    award,
    color: appearanceColor,
  } = usePlaceAppearance(placeId);
  const IconStampActive = useMemo(
    () =>
      function ActiveStampIcon({
        active = true,
        ...rest
      }: ComponentProps<typeof IconStamp>) {
        return <IconStamp active={active} {...rest} />;
      },
    [IconStamp],
  );
  const {backgroundColor, title, caption, IconComponent, iconQty} =
    useMemo(() => {
      switch (value.state) {
        case 'stamp':
          let stampCaption;

          if (value.firstAwardRemains) {
            stampCaption = t('Screens.QRCode.ResultBlock.justMoreFor', {
              firstAwardRemains: value.firstAwardRemains,
              award: award(DefaultAwardQuantity),
            });
          } else if (value.awardBalanceDiff > 0) {
            stampCaption = t(
              'Screens.QRCode.ResultBlock.youCanGetYourRightNow',
              {
                award: award(DefaultAwardQuantity),
              },
            );
          }

          return {
            backgroundColor: appearanceColor,
            title: t('Screens.QRCode.ResultBlock.youHaveReceived', {
              quantity: value.quantity,
              stamp: stamp(value.quantity),
            }),
            caption: stampCaption,
            IconComponent: IconStampActive,
            iconQty: value.quantity,
          };
        case 'award':
          return {
            backgroundColor: colors.black,
            title: t('Screens.QRCode.ResultBlock.grabYour', {
              awardQuantity: value.awardQuantity,
              award: award(value.awardQuantity),
            }),
            caption: null,
            IconComponent: IconAward,
            iconQty: value.awardQuantity,
          };
        case 'error':
        default:
          return {
            backgroundColor: colors.red,
            title: t('Screens.QRCode.ResultBlock.youNeedMore', {
              awardRemains: value.awardRemains,
              stamp: stamp(value.awardRemains),
            }),
            caption: null,
            IconComponent: IconAlertCircle,
            iconQty: 1,
          };
      }
    }, [value, appearanceColor, t, stamp, IconStampActive, award, IconAward]);

  const [shadowEnabled, setShadowEnabled] = useState(true);
  const [onPressIn, onPressOut] = useMemo(
    () => [() => setShadowEnabled(false), () => setShadowEnabled(true)],
    [],
  );
  const contentStyle = useMemo(
    () =>
      ({
        ...StyleSheet.flatten(s.cardContent),
        justifyContent: 'center',
        backgroundColor,
      }) as ViewStyle,
    [backgroundColor],
  );

  const style = useMemo(() => [s.block], []);
  return (
    <Card
      backgroundColor={backgroundColor}
      shadowColor={backgroundColor}
      style={style}
      shadowEnabled={shadowEnabled}
      paddingVertical={0}>
      <View style={contentStyle}>
        <TouchableOpacity
          onPress={onPress}
          onPressIn={onPressIn}
          onPressOut={onPressOut}>
          <View style={s.iconWrapper}>
            {value.state === 'stamp' || value.state === 'award' ? (
              <View style={s.resultRow}>
                {Array.from({
                  length: iconQty > 3 ? 1 : iconQty,
                }).map((_, idx) => (
                  <IconComponent
                    key={String(idx)}
                    size={44}
                    style={idx > 0 ? {marginLeft: 16} : undefined}
                  />
                ))}
              </View>
            ) : (
              <IconComponent size={44} />
            )}
          </View>
          <Spacer height={10} />
          <ButtonText>{title}</ButtonText>
          {caption ? (
            <>
              <Spacer height={4} />
              <SmallBodyText>{caption}</SmallBodyText>
            </>
          ) : null}
        </TouchableOpacity>
      </View>
    </Card>
  );
}

const _isTablet = isTablet();
const Separator = _isTablet ? ' ' : '\n';

type NoActiveSubscriptionProps = Pick<
  ComponentProps<typeof Block>,
  'onPress' | 'active' | 'onCounterChange' | 'onBack'
> & {placeId: number};
function NoActiveSubscriptionAward(props: NoActiveSubscriptionProps) {
  const {onPress, active, onCounterChange, onBack, placeId} = props;

  const {t} = useTranslation();
  const {IconAward, award} = usePlaceAppearance(placeId);
  const caption = t('Screens.QRCode.AwardCard.tapToRewardClient', {
    Separator: Separator,
    award: award(DefaultAwardQuantity),
  });

  return (
    <Block
      onCounterChange={onCounterChange}
      onBack={onBack}
      captionText={useCallback(q => award(q), [award])}
      active={active}
      onPress={onPress}
      title={t('Screens.QRCode.AwardCard.free') || ''}
      caption={caption}
      backgroundColor={colors.middlegray}>
      <IconAward size={28} mode="flat" />
    </Block>
  );
}
function NoActiveSubscriptionStamp(props: NoActiveSubscriptionProps) {
  const {onPress, active, onCounterChange, onBack, placeId} = props;

  const {t} = useTranslation();
  const {IconStamp, stamp} = usePlaceAppearance(placeId);
  const caption = t('Screens.QRCode.StampCard.tapToGiveClientloyaltyStamp', {
    Separator: Separator,
  });

  return (
    <Block
      onCounterChange={onCounterChange}
      onBack={onBack}
      captionText={useCallback(q => stamp(q), [stamp])}
      active={active}
      onPress={onPress}
      title={stamp(1)}
      caption={caption}
      backgroundColor={colors.middlegray}>
      <IconStamp size={28} mode="flat" />
    </Block>
  );
}

function AwardCard({
  onPress,
  active,
  onCounterChange,
  onBack,
  placeId,
}: Pick<
  ComponentProps<typeof Block>,
  'onPress' | 'active' | 'onCounterChange' | 'onBack'
> & {placeId: number}) {
  const {t} = useTranslation();
  const {IconAward, award} = usePlaceAppearance(placeId);
  const caption = t('Screens.QRCode.AwardCard.tapToRewardClient', {
    Separator: Separator,
    award: award(DefaultAwardQuantity),
  });

  return (
    <Block
      onCounterChange={onCounterChange}
      onBack={onBack}
      captionText={useCallback(q => award(q), [award])}
      active={active}
      onPress={onPress}
      title={t('Screens.QRCode.AwardCard.free') || ''}
      caption={caption}
      backgroundColor={colors.black}>
      <IconAward size={28} mode="flat" />
    </Block>
  );
}

function StampCard({
  onPress,
  active,
  onCounterChange,
  onBack,
  placeId,
}: Pick<
  ComponentProps<typeof Block>,
  'onPress' | 'active' | 'onCounterChange' | 'onBack'
> & {placeId: number}) {
  const {t} = useTranslation();

  const {IconStamp, stamp, color} = usePlaceAppearance(placeId);
  const caption = t('Screens.QRCode.StampCard.tapToGiveClientloyaltyStamp', {
    Separator: Separator,
  });
  return (
    <Block
      onCounterChange={onCounterChange}
      onBack={onBack}
      captionText={stamp}
      active={active}
      onPress={onPress}
      title={stamp(1)}
      caption={caption}
      backgroundColor={color}>
      <IconStamp size={30} active style={s.stampCardIcon} />
    </Block>
  );
}

function Block({
  active,
  title,
  caption,
  backgroundColor,
  children: icon,
  onPress,
  onCounterChange,
  onBack,
  captionText,
  defaultValue,
  style,
}: PropsWithChildren<
  {
    active?: boolean;
    title: string;
    caption: string;
    backgroundColor: string;
    style?: StyleProp<ViewStyle>;
    onBack?: () => void;
  } & Pick<TouchableOpacityProps, 'onPress'> &
    Pick<
      ComponentProps<typeof BlockCounter>,
      'onCounterChange' | 'captionText' | 'defaultValue'
    >
>) {
  const [shadowEnabled, setShadowEnabled] = useState(true);
  const [onPressIn, onPressOut] = useMemo(
    () => [() => setShadowEnabled(false), () => setShadowEnabled(true)],
    [],
  );
  const contentStyle = useMemo(
    () => ({
      ...StyleSheet.flatten(s.cardContent),
      backgroundColor,
    }),
    [backgroundColor],
  );

  const _style = useMemo(() => [s.block, style], [style]);
  return (
    <Card
      style={_style}
      shadowEnabled={shadowEnabled}
      backgroundColor={backgroundColor}
      shadowColor={backgroundColor}>
      <View style={contentStyle}>
        {active ? (
          <BlockCounter
            onCounterChange={onCounterChange}
            onBack={onBack}
            captionText={captionText}
            defaultValue={defaultValue}
          />
        ) : (
          <TouchableOpacity
            onPress={onPress}
            onPressIn={onPressIn}
            onPressOut={onPressOut}>
            <View style={s.iconWrapper}>{icon}</View>
            <Spacer height={8} />
            <ButtonText>{title}</ButtonText>
            <Spacer height={6} />
            <CaptionText>{caption}</CaptionText>
          </TouchableOpacity>
        )}
      </View>
    </Card>
  );
}

function BlockCounter({
  min = 1,
  max = 99,
  step = 1,
  captionText,
  onCounterChange,
  onBack,
  defaultValue,
}: {
  defaultValue?: number;
  min?: number;
  max?: number;
  step?: number;
  captionText: (v: number) => string;
  onCounterChange: (value: number) => void;
  onBack: TouchableOpacityProps['onPress'];
}) {
  const [value, setValue] = useState(
    defaultValue && defaultValue >= min ? defaultValue : min,
  );
  const animateLayout = useCallback(() => {
    LayoutAnimation.configureNext(
      LayoutAnimation.create(
        150,
        Platform.OS === 'ios'
          ? LayoutAnimation.Types.keyboard
          : LayoutAnimation.Types.easeIn,
        LayoutAnimation.Properties.scaleXY,
      ),
    );
  }, []);
  const [decrease, increase] = useMemo(
    () => [
      () => {
        animateLayout();
        setValue(v => (v - step >= min ? v - step : min));
      },
      () => {
        animateLayout();
        setValue(v => (v + step <= max ? v + step : max));
      },
    ],
    [animateLayout, max, min, step],
  );

  const currentLanguage = useGetCurrentLanguage();

  useEffect(() => {
    onCounterChange(value);
  }, [onCounterChange, value]);

  return (
    <View style={bc.wrapper}>
      <TouchableOpacity onPress={onBack} disabled={!onBack} style={bc.back}>
        <IconBack size={44} />
      </TouchableOpacity>
      <View style={bc.divider} />
      <TouchableOpacity
        style={bc.minus}
        onPress={decrease}
        disabled={value <= min}>
        <IconMinus size={44} />
      </TouchableOpacity>
      <View style={bc.counter}>
        <Text key={String(value)} style={bc.counterValue}>
          {value}
          {shouldShowXBetweenCounter(currentLanguage, value) ? (
            <Text style={bc.separator}>x</Text>
          ) : null}
        </Text>

        <Text style={bc.counterCaption}>{captionText(value)}</Text>
      </View>
      <TouchableOpacity
        style={bc.plus}
        onPress={increase}
        disabled={value >= max}>
        <IconPlus size={44} />
      </TouchableOpacity>
    </View>
  );
}

function shouldShowXBetweenCounter(lng: string, count: number) {
  return lng && count && count > 1 && lng.split('-')[0].toLowerCase() !== 'en';
}

const bc = StyleSheet.create({
  wrapper: {
    flexGrow: 1,
    flexDirection: 'row',
    marginHorizontal: -Card.PADDING_HORIZONTAL,
  },
  spacer24: {
    width: 24,
  },
  minus: {
    justifyContent: 'center',
    paddingLeft: 24,
    paddingRight: 17,
  },
  separator: {
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.white,
    textAlign: 'center',
    fontSize: 12,
    height: 10,
    marginLeft: 4,
    ...Platform.select({
      android: {},
      default: {
        lineHeight: 8,
      },
    }),
  },
  plus: {
    justifyContent: 'center',
    paddingLeft: 17,
    paddingRight: 24,
  },
  back: {
    justifyContent: 'center',
    paddingHorizontal: 24,
  },
  counter: {
    flexGrow: 1,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  counterValue: {
    textAlign: 'center',
    color: colors.white,
    fontWeight: '900',
    fontSize: 52,
    lineHeight: 52 * 1.2,
    marginTop: -StyleSheet.flatten(Typography.button).lineHeight - 2,
  },
  counterCaption: {
    position: 'absolute',
    left: -(44 + 24 + 17 - 6 * 2), // x coord of minus el start + 6 padding
    right: -(44 + 24 + 17 - 6 * 2), // x coord of plus el end + 6 padding
    bottom: -StyleSheet.flatten(Typography.button).lineHeight - 2,
    ...StyleSheet.flatten(Typography.button),
    textTransform: 'uppercase',
    textAlign: 'center',
    color: colors.white,
  },
  divider: {
    width: 1,
    opacity: 0.5,
    backgroundColor: colors.middlegray,
  },
});

const s = StyleSheet.create({
  spacerHorizontal: {
    width: 16,
  },
  selector: {
    flexDirection: 'row',
    alignContent: 'stretch',
    height: 120,
  },
  resultRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  block: {
    flexGrow: 1,
    flexDirection: 'row',
  },
  blockCounter: {
    flexDirection: 'row',
    backgroundColor: 'red',
    flexGrow: 1,
  },
  blockCounterButton: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  row: {
    flexDirection: 'row',
    alignContent: 'stretch',
  },
  card: {
    flexGrow: 1,
  },
  cardContent: {
    flexGrow: 1,
  },
  iconWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  awardCard: {
    backgroundColor: colors.black,
    color: colors.white,
  },
  stampCard: {
    backgroundColor: colors.brand,
    color: colors.white,
  },
  stampCardIcon: {
    marginBottom: -2,
  },
});

function ButtonText({style: _style, ...rest}: PropsWithChildren<TextProps>) {
  const style = useMemo<TextStyle>(
    () => ({
      ...StyleSheet.flatten(Typography.button),
      ...StyleSheet.flatten(_style),
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: _isTablet ? 20 : 14,
      lineHeight: _isTablet ? 24 : 17,
      color: colors.white,
    }),
    [_style],
  );
  return <Text style={style} {...rest} />;
}

function CaptionText({style: _style, ...rest}: PropsWithChildren<TextProps>) {
  const style = useMemo<TextStyle>(
    () => ({
      ...StyleSheet.flatten(_style),
      fontSize: _isTablet ? 16 : 10,
      lineHeight: _isTablet ? 20 : 12,
      fontWeight: '500',
      textAlign: 'center',
      color: colors.white,
    }),
    [_style],
  );
  return <Text style={style} {...rest} />;
}

function SmallBodyText({style: _style, ...rest}: PropsWithChildren<TextProps>) {
  const style = useMemo<TextStyle>(
    () => ({
      ...StyleSheet.flatten(Typography.smallBody),
      ...StyleSheet.flatten(_style),
      textAlign: 'center',
      color: colors.white,
    }),
    [_style],
  );
  return <Text style={style} {...rest} />;
}

export {Block as QRCodeBlock};
