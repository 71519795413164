import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {PlaceAppearanceConfigExtended} from '@screens/BusinessWizard/slice';
import {
  DEFAULT_AWARD_NAME,
  getAwardNameSuggestions,
  PossiblePlaceAppearances,
} from '@components/common/AppearanceCustomizer/shared';
import {Button, Spacer, TextInput} from '@b2cmessenger/doppio-components';
import {StyleSheet, View} from 'react-native';

type AwardNameEditProps = {
  initialName: string | null;
  configName: string | null;
  displayedName: string;
  changeAwardName: (name: string | null | undefined) => void;
  nameFromAward: string | null;
  awardId: number | null;
};
function AwardNameEdit(
  props: AwardNameEditProps &
    Omit<ComponentProps<typeof TextInput>, 'value' | 'onChangeText'>,
) {
  const {
    initialName,
    configName,
    changeAwardName,
    nameFromAward,
    displayedName,
    awardId,
    ...rest
  } = props;

  const [currentName, setCurrentName] = useState(displayedName);
  const isCurrentNameEmpty = useMemo(() => currentName === '', [currentName]);

  useEffect(() => {
    const _name = currentName.trim().toLowerCase();
    const isDefaultName =
      awardId === null && _name === DEFAULT_AWARD_NAME.toLowerCase();
    const isEqualToNameFromAward = _name === nameFromAward?.toLowerCase();
    if (isDefaultName || isEqualToNameFromAward) {
      changeAwardName(null);
    } else if (currentName.trim() !== configName) {
      changeAwardName(currentName.trim());
    }
  }, [awardId, changeAwardName, configName, currentName, nameFromAward]);

  const suggestions = useMemo(() => {
    const _suggestions = getAwardNameSuggestions({
      initialName,
      nameFromAward,
      configName,
      isEmpty: isCurrentNameEmpty,
      awardId,
    });

    return _suggestions.length > 0
      ? _suggestions.map(v => {
          return (
            <Button.ExtraSmall
              key={v}
              title={v}
              onPress={() => setCurrentName(v)}
              style={styles.suggestion}
            />
          );
        })
      : null;
  }, [awardId, configName, initialName, isCurrentNameEmpty, nameFromAward]);

  return (
    <View style={styles.container}>
      <TextInput
        placeholder={displayedName}
        value={currentName}
        onChangeText={setCurrentName}
        maxLength={20}
        {...rest}
        style={[styles.input, rest.style ? rest.style : {}]}
      />
      <Spacer height={8} />
      <View style={styles.suggestionsContainer}>{suggestions}</View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  suggestionsContainer: {flexDirection: 'row', flexWrap: 'wrap'},
  suggestion: {marginBottom: 8, marginRight: 8},
  input: {height: 40},
});

export function useAwardName({
  appearances,
  changeValue,
}: {
  appearances: PossiblePlaceAppearances;
  changeValue: (config: Partial<PlaceAppearanceConfigExtended>) => void;
}) {
  const possibleAwards = useMemo(
    () => appearances.resources.filter(v => v.type === 'award'),
    [appearances],
  );
  const getAwardNameById = useCallback(
    (id: PlaceAppearanceConfigExtended['award_resource_id']) =>
      possibleAwards.find(r => r.id === id)?.name || null,
    [possibleAwards],
  );
  const changeAwardName = useCallback(
    (name: PlaceAppearanceConfigExtended['award_name']) =>
      changeValue({award_name: name ? name.trim() : null}),
    [changeValue],
  );

  return {getAwardNameById, changeAwardName};
}

export default AwardNameEdit;
