import {useCallback, useState} from 'react';
import {api} from '@b2cmessenger/doppio-core';
import {LoginedUser} from '@typings/shared';
import {Logger} from '@b2cmessenger/doppio-shared';

export function useAccountRemove() {
  const [loading, setLoading] = useState(false);
  const remove = useCallback(async (id: number) => {
    try {
      setLoading(true);
      await removeAccount(id);
      setLoading(false);
      return true;
    } catch (_e) {
      setLoading(false);
      Logger.errorTag('useAccountRemove', _e);
      return false;
    }
  }, []);
  return {loading, remove};
}

async function removeAccount(id: LoginedUser['id']) {
  return api.default
    .post('/api/v2/user/destroy', {
      id,
    })
    .then(api.getResponseData);
}
