import {useCallback} from 'react';
import {Alert} from '@components/common/Alert';
import {logout} from '@store/actions';
import {useDispatch} from 'react-redux';
import {useTranslation} from '@shared';

export function useLogoutPrompt() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  return useCallback(() => {
    const _title = t('Components.useLogoutPrompt.areYouSure');
    const _msg = t('Components.useLogoutPrompt.youWillBeLoggedOut');
    Alert.alert(_title, _msg, [
      {text: t('Components.useLogoutPrompt.cancel') || '', style: 'cancel'},
      {
        text: t('Components.useLogoutPrompt.logMeOut') || '',
        style: 'destructive',
        onPress: dispatch.bind(null, logout()),
      },
    ]);
  }, [dispatch, t]);
}
