import Config from '@utils/Config';
import {buildLinkSync} from './shared';
import {localization} from '@shared';

function onLink() {
  return () => {};
}
async function getInitialLink() {
  return null;
}
async function buildLink(params: Parameters<typeof buildLinkSync>[0]) {
  return buildLinkSync(params);
}
async function buildShortLink(params: Parameters<typeof buildLinkSync>[0]) {
  const link = buildLinkSync(params);
  try {
    const response = await fetch(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${Config.FIREBASE_WEB_API_KEY}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          longDynamicLink: link,
        }),
      },
    );

    const data = await response.json();

    if ('error' in data) {
      throw data.error.message;
    }

    return data.shortLink;
  } catch (e) {
    throw e;
  }
}

async function resolveLink() {
  throw (
    localization.t(
      'Utils.DynamicLinks.ResolveLink.dynamicLinksResolutionNotImplementedInWebBrowser',
    ) || 'Dynamic Links resolution not implemented in Web Browser'
  );
}

export default {onLink, getInitialLink, buildLink, buildShortLink, resolveLink};
