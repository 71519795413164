import {slices, createAbsoluteSelectors} from '@b2cmessenger/doppio-core';
import {parentSelectors} from '@store/reducer';
import {createSelector} from '@reduxjs/toolkit';
import {selectors as appTracking} from './appTracking.slice';
import {selectors as _subscriptionSelectors} from './subscription/slice';
import {isStatusOkOrAccessWithPaymentProblems} from '@screens/Dashboard/screens/ManageSubscription/components/Overview/utils';
import {TooltipKeys} from '@store/tooltips.slice';

export const appSelectors = createAbsoluteSelectors(
  parentSelectors.app,
  slices.app.selectors,
);
export const initSelectors = createAbsoluteSelectors(
  parentSelectors.init,
  slices.init.selectors,
);
export const loginedUserSelectors = createAbsoluteSelectors(
  parentSelectors.loginedUser,
  slices.loginedUser.selectors,
);
export const workplaceSigningKeySelector = createSelector(
  parentSelectors.workplaceSigningKey,
  state => state,
);
export const stampAwardsSelectors = createAbsoluteSelectors(
  parentSelectors.stampAwards,
  slices.stampAwards.selectors,
);
export const tooltipsSelector = createSelector(
  parentSelectors.tooltips,
  state => state,
);
export const shouldPreloadAppearance = createSelector(
  parentSelectors.tooltips,
  state => !state[TooltipKeys.dashboard]?.shown,
);
export const isAvailableFullAccessToAppSelector = createSelector(
  parentSelectors.loginedUser,
  profile =>
    profile.workplaceSubscription &&
    isStatusOkOrAccessWithPaymentProblems(profile.workplaceSubscription.status),
);

export const appTrackingSelectors = createAbsoluteSelectors(
  parentSelectors.appTracking,
  appTracking,
);
export const subscriptionSelectors = createAbsoluteSelectors(
  parentSelectors.subscription,
  _subscriptionSelectors,
);
