import {useCallback, useContext} from 'react';
import FirebaseRemoteConfigContext from '@utils/FirebaseRemoteConfigContext';
import {Analytics} from '@b2cmessenger/doppio-core';
import {Linking} from 'react-native';

export function useExtendedDemo() {
  const {extendedDemoUrl} = useContext(FirebaseRemoteConfigContext);
  const openExtendedDemo = useCallback(
    async (source?: string) => {
      Analytics.logEvent('demo_video_click', source ? {source} : undefined);
      if (await Linking.canOpenURL(extendedDemoUrl)) {
        Linking.openURL(extendedDemoUrl);
      }
    },
    [extendedDemoUrl],
  );

  return openExtendedDemo;
}
