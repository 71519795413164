import React, {useCallback, useEffect, useMemo} from 'react';
import {StyleSheet, View} from 'react-native';
import {Pusher, Spacer} from '@b2cmessenger/doppio-components';
import {InvertedSocialSignInButton} from './InvertedSocialSignInButton';
import useAppleSignIn from '@components/hooks/auth/useAppleSignIn';
import useFacebookSignIn from '@components/hooks/auth/useFacebookSignIn';
import Config from '@utils/Config';
import useGoogleSignIn from '@components/hooks/auth/useGoogleSignIn';
import {useDispatch, useSelector} from 'react-redux';
import {appSelectors} from '@store/selectors';
import {Alert} from '@components/common/Alert';
import {dismissLoginError} from '@store/actions';
import {useIsFocused} from '@react-navigation/native';
import {TEST_IDS} from '../JoinAsEmployeeTestIDS';
import {Logger} from '@b2cmessenger/doppio-shared';
import {useGetCurrentLanguage, useTranslation} from '@shared';
import {isTablet} from 'react-native-device-info';

useGoogleSignIn.configure(
  Config.GOOGLE_WEB_CLIENT_ID,
  Config.GOOGLE_IOS_CLIENT_ID,
);

export function Auth({
  onAppleSignInSucceed,
  onFacebookSignInSucceed,
  onGoogleSignInSucceed,
}: {
  onAppleSignInSucceed: Parameters<typeof useGoogleSignIn>[0]['onSuccess'];
  onFacebookSignInSucceed: Parameters<typeof useFacebookSignIn>[0]['onSuccess'];
  onGoogleSignInSucceed: Parameters<typeof useGoogleSignIn>[0]['onSuccess'];
}) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const loginError = useSelector(appSelectors.loginError);
  const isFocused = useIsFocused();
  useEffect(() => {
    if (loginError && isFocused) {
      Logger.verboseTag('Auth [loginError]', loginError);
      Alert.alert('Error', loginError, [
        {
          text: t('Screens.SignIn.ok') || 'ok',
          style: 'cancel',
          onPress: dispatch.bind(null, dismissLoginError()),
        },
      ]);
    }
  }, [t, dispatch, isFocused, loginError]);

  const onAppleSignInFailed = useCallback((error, raw) => {
    Logger.errorTag('SignIn with Apple', error, raw);
  }, []);
  const {
    isAvailable: isAppleSignInAvailable,
    loading: signingInApple,
    authorize: signInApple,
  } = useAppleSignIn({
    onSuccess: onAppleSignInSucceed,
    onError: onAppleSignInFailed,
  });

  const onFacebookSignInFailed = useCallback(error => {
    Logger.errorTag('SignIn with Facebook', error);
  }, []);
  const {
    loading: signingInFacebook,
    authorize: signInFacebook,
  } = useFacebookSignIn({
    webClientId: Config.FACEBOOK_APP_ID,
    onSuccess: onFacebookSignInSucceed,
    onError: onFacebookSignInFailed,
  });

  const onGoogleSignInFailed = useCallback((error, more) => {
    Logger.errorTag('SignIn with Google', error, more);
  }, []);
  const {loading: signingInGoogle, authorize: signInGoogle} = useGoogleSignIn({
    clientId: Config.GOOGLE_WEB_CLIENT_ID,
    onSuccess: onGoogleSignInSucceed,
    onError: onGoogleSignInFailed,
  });
  const signInWithApple = t('Screens.SignIn.signInWithApple');
  const signInWithFacebook = t('Screens.SignIn.signInWithFacebook');
  const signInWithGoogle = t('Screens.SignIn.signInWithGoogle');
  const currentLanguage = useGetCurrentLanguage();
  const _isTablet = isTablet();
  const socialButtonStyles = useMemo(() => {
    if (currentLanguage.match('es')) {
      return _isTablet ? {width: 315} : {width: 275};
    }

    return undefined;
  }, [currentLanguage, _isTablet]);

  return (
    <>
      <Spacer height={60} />
      <View style={styles.container}>
        {isAppleSignInAvailable ? (
          <>
            <InvertedSocialSignInButton
              type="apple"
              disabled={signingInApple}
              onPress={signInApple}
              style={socialButtonStyles}
              title={signInWithApple}
            />
            <Spacer />
          </>
        ) : null}
        <InvertedSocialSignInButton
          testID={TEST_IDS.buttons.Facebook}
          type="facebook"
          disabled={signingInFacebook}
          onPress={signInFacebook}
          style={socialButtonStyles}
          title={signInWithFacebook}
        />
        <Spacer />
        <InvertedSocialSignInButton
          testID={TEST_IDS.buttons.Google}
          type="google"
          disabled={signingInGoogle}
          onPress={signInGoogle}
          style={socialButtonStyles}
          title={signInWithGoogle}
        />
      </View>
      <Pusher />
    </>
  );
}
const styles = StyleSheet.create({
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});
