import React, {ReactNode, useCallback, useMemo, useRef} from 'react';
import {Animated, Easing, View} from 'react-native';
import {
  IconEllipse,
  IconRectangle,
  IconSnake,
  IconStar,
} from '@components/common/icons/SvgIcon';
import {ConfettiItems} from '@components/common/Confetti/shared';
import useWindowInfo from '@hooks/useWindowInfo';

const CorrectionX = 375; // the expected width of screen by which the endpoints of the vector were built
const CorrectionY = 667;
export const ExplosionSpeed = 1000;

type ConfettiProps = {
  children: ReactNode;
  onBeforeStartAnimation?: () => void;
  onFinishAnimation?: ({finished}: {finished: boolean}) => void;
};
function Confetti(props: ConfettiProps) {
  const ref = useRef<View>(null);
  const {width, height} = useWindowInfo();
  const [bb, setBb] = React.useState({x: 0, y: 0, w: width, h: 90});

  const animVal = useRef(new Animated.Value(0)).current;
  const animation = useRef<Animated.CompositeAnimation>();

  const startAnimation = useCallback(() => {
    animation.current = Animated.timing(animVal, {
      toValue: 2,
      delay: 0,
      duration: ExplosionSpeed,
      easing: Easing.out(Easing.quad),
      useNativeDriver: true,
    });
    props.onBeforeStartAnimation?.call(null);
    animation.current.start(props.onFinishAnimation?.bind(null));
  }, [animVal, props.onFinishAnimation, props.onBeforeStartAnimation]);

  const confetti = useMemo(() => {
    return ConfettiItems.map((item, idx) => {
      const {
        rotate,
        vectorEndPosition: position,
        type,
        offsetX,
        ...rest
      } = item;

      const left = animVal.interpolate({
        inputRange: [0, 1],
        outputRange: [
          bb.w / 2 + (offsetX ? (offsetX * bb.w) / CorrectionX : 0),
          position.x * (bb.w / CorrectionX),
        ],
      });
      const top = animVal.interpolate({
        inputRange: [0, 1],
        outputRange: [bb.h / 2, (position.y - 90) * (height / CorrectionY)],
      });

      const opacity = animVal.interpolate({
        inputRange: [0, 0.5, 1, 2],
        outputRange: [0, 1, 1, 0],
      });

      return (
        <Animated.View
          key={idx}
          pointerEvents="none"
          renderToHardwareTextureAndroid={true}
          style={{
            opacity: opacity,
            position: 'absolute',
            transform: [
              {translateX: left},
              {translateY: top},
              {rotate: `${rotate}deg`},
              {scale: animVal},
              {perspective: 1000}, // without this line this Animation will not render on Android while working fine on iOS
            ],
          }}>
          {type === 'star' ? (
            <IconStar {...rest} />
          ) : type === 'rectangle' ? (
            <IconRectangle {...rest} />
          ) : type === 'snake' ? (
            <IconSnake {...rest} />
          ) : type === 'ellipse' ? (
            <IconEllipse {...rest} />
          ) : null}
        </Animated.View>
      );
    });
  }, [animVal, bb.w, bb.h, height]);

  return (
    <View
      ref={ref}
      onLayout={() => {
        setTimeout(() => {
          ref.current?.measureInWindow((x, y, width, height) => {
            setBb({x, y, w: width, h: height});
            setTimeout(() => {
              startAnimation();
            }, 1);
          });
        }, 1);
      }}>
      {confetti}
      {props.children}
    </View>
  );
}

export default Confetti;
