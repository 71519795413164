import React, {ComponentProps, useEffect, useRef} from 'react';
import {LinearGradient} from 'expo-linear-gradient';
import {Animated} from 'react-native';
const _colors = ['rgba(0,0,0,0.10)', 'rgba(0,0,0,0.0)'];

type ShadowProps = {
  visible: boolean;
  size: number;
  style?: ComponentProps<typeof LinearGradient>['style'];
  linearGradientProps?: Partial<
    Omit<ComponentProps<typeof LinearGradient>, 'children'>
  >;
};
function Shadow(props: ShadowProps) {
  const {visible, size, style, linearGradientProps} = props;
  const opacity = useRef(new Animated.Value(0)).current;
  useEffect(() => {
    Animated.timing(opacity, {
      toValue: visible ? 1 : 0,
      duration: 250,
      useNativeDriver: true,
    }).start();
  }, [visible, opacity]);

  return (
    <Animated.View style={[{opacity: opacity, top: 0}, style ?? {}]}>
      <LinearGradient
        start={{x: 0, y: 0}}
        end={{x: 0, y: 0}}
        colors={_colors}
        {...linearGradientProps}
        style={[
          {
            position: 'absolute',
            width: '100%',
            height: size,
          },
          linearGradientProps?.style ?? {},
        ]}
      />
    </Animated.View>
  );
}

export default Shadow;
