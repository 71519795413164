import React, {PropsWithChildren, useEffect} from 'react';
import {ImageSourcePropType, StyleSheet, View, Image} from 'react-native';
import DemoVideo from '@features/DemoVideo';

import {SharedHooks} from '@shared';
import {AVPlaybackSource} from 'expo-av';

type OnboardingViewWithVideoProps = PropsWithChildren<{
  videoSource?: AVPlaybackSource | undefined;
  imageSource?: ImageSourcePropType;
  header: JSX.Element;
  setSize?: (size: number) => void;
}>;
function OnboardingView({
  header,
  videoSource,
  imageSource,
  children,
  setSize,
}: OnboardingViewWithVideoProps) {
  const {setContainerDimension, setOccupiedHeight, size} =
    SharedHooks.useMaxAvailableSize();

  useEffect(() => {
    if (setSize) {
      setSize(size);
    }
  }, [setSize, size]);

  return (
    <View
      style={styles.container}
      onLayout={event =>
        setContainerDimension({
          w: event.nativeEvent.layout.width,
          h: event.nativeEvent.layout.height,
        })
      }>
      <View
        style={styles.header}
        onLayout={event => setOccupiedHeight(event.nativeEvent.layout.height)}>
        {header}
      </View>
      {children}
      {videoSource ? (
        <DemoVideo
          size={size}
          source={videoSource}
          containerStyle={[styles.demoVideo, {maxHeight: size}]}
        />
      ) : null}
      {imageSource ? (
        <View style={[styles.imageWrapper, {maxHeight: size}]}>
          <Image
            source={imageSource}
            style={{
              width: size,
              height: size,
            }}
          />
        </View>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {flex: 1, flexGrow: 1, justifyContent: 'space-evenly'},
  header: {alignSelf: 'center'},
  demoVideo: {flex: 1},
  imageWrapper: {flex: 1, justifyContent: 'center', alignItems: 'center'},
});

export {OnboardingView};
