import React from 'react';

export type ControlsContextType = {
  controlsSuppressed: boolean;
  suppressControls: (value: boolean) => void;
  customControls: React.ReactNode;
  setCustomControls: (value: React.ReactNode) => void;
};

const ControlsContext = React.createContext({
  controlsSuppressed: false,
  suppressControls: () => {},
  customControls: null,
  setCustomControls: () => {},
} as ControlsContextType);

export {ControlsContext};
