import {
  Button,
  Navbar,
  Pusher,
  Screen,
  Spacer,
  ThemeProvider,
  useTheme,
  useTypography,
} from '@b2cmessenger/doppio-components';
import React, {useCallback, useMemo, useReducer} from 'react';
import {navigationRef} from '@navigation/navigationRef';
import {useScreenPrimaryButtonStyle} from '@components/hooks/useScreenPrimaryButtonStyle';
import {Linking, Platform, Text} from 'react-native';
import {createSlice} from '@reduxjs/toolkit';
import {useDispatch, useSelector} from 'react-redux';
import {loginedUserSelectors} from '@store/selectors';
import {Alert} from '@components/common/Alert';
import {logout} from '@store/actions';
import {getBundleId} from 'react-native-device-info';
import {PRODUCT_ID} from '@screens/Dashboard/screens/ManageSubscription/ProductId';
import {useAccountRemove} from '@components/hooks/useAccountRemove';
import CheckboxItem from '@components/common/CheckboxItem';
import {useTranslation} from '@shared';

// доступ есть, но есть проблемы с платежами, необходимо вмешательство изначального автора подписки.
const SUBSCRIPTION_BASIC_STATUS_ISSUES = 0;
const SUBSCRIPTION_BASIC_STATUS_EXPIRED = -1;

const initialState = {consent: 2};
const slice = createSlice({
  name: 'removalConsents',
  initialState: initialState,
  reducers: {
    reset: () => ({...initialState}),
    togglePlaceConsent(state) {
      state.consent =
        state.consent === initialState.consent
          ? state.consent - 1
          : initialState.consent;
    },
    toggleCustomersConsent(state) {
      state.consent = state.consent !== 0 ? 0 : initialState.consent - 1;
    },
  },
});

export function AccountRemoval() {
  const {t} = useTranslation();
  const pbsStyle = useScreenPrimaryButtonStyle();
  const {body, mediumBody} = useTypography();
  const {colors} = useTheme();

  const loginedUserProfile = useSelector(
    loginedUserSelectors.profile,
  ) as Exclude<ReturnType<typeof loginedUserSelectors.profile>, null>;
  const workplace = useSelector(loginedUserSelectors.workplace) as Exclude<
    ReturnType<typeof loginedUserSelectors.workplace>,
    null
  >;
  const workplaceSubscription = useSelector(
    loginedUserSelectors.workplaceSubscription,
  ) as Exclude<
    ReturnType<typeof loginedUserSelectors.workplaceSubscription>,
    null
  >;

  const [state, dispatch] = useReducer(slice.reducer, {...initialState});
  const goBack = useCallback(() => {
    dispatch(slice.actions.reset());
    if (navigationRef.current?.canGoBack()) {
      navigationRef.current?.goBack();
    } else {
      navigationRef.current?.navigate('Dashboard', {
        screen: 'Profile',
        params: {
          screen: 'Settings',
        },
      });
    }
  }, []);
  const {loading, remove} = useAccountRemove();
  const dispatchAction = useDispatch();

  const isPossibleToRemoveAccount = useMemo(
    () =>
      loginedUserProfile.id !== workplaceSubscription.payer_user_id ||
      (workplaceSubscription.status !== SUBSCRIPTION_BASIC_STATUS_ISSUES &&
        workplaceSubscription.expire_at) ||
      workplaceSubscription.status === SUBSCRIPTION_BASIC_STATUS_EXPIRED,
    [
      loginedUserProfile.id,
      workplaceSubscription.payer_user_id,
      workplaceSubscription.status,
      workplaceSubscription.expire_at,
    ],
  );

  const manageSubscription = useMemo(
    () =>
      Platform.OS === 'ios'
        ? () => {
            // noinspection JSIgnoredPromiseFromCall
            Linking.openURL('https://apps.apple.com/account/subscriptions');
          }
        : Platform.OS === 'android'
        ? () => {
            // noinspection JSIgnoredPromiseFromCall
            Linking.openURL(
              `https://play.google.com/store/account/subscriptions?package=${getBundleId()}&sku=${PRODUCT_ID}`,
            );
          }
        : undefined,
    [],
  );

  const togglePlaceConsent = useCallback(() => {
    dispatch(slice.actions.togglePlaceConsent());
  }, []);
  const toggleCustomerConsent = useCallback(() => {
    dispatch(slice.actions.toggleCustomersConsent());
  }, []);

  const removeAccount = useCallback(async () => {
    const _title = t('Screens.AccountRemoval.areYouSure');
    const _msg = t(
      'Screens.AccountRemoval.youAreGoingToRemoveYourAccountDataCanNotBeRecovere',
    );
    Alert.alert(_title, _msg, [
      {
        style: 'destructive',
        text: t('Screens.AccountRemoval.removeAccount') || '',
        onPress: async () => {
          const success = await remove(loginedUserProfile.id);
          if (success) {
            dispatchAction(logout());
            setTimeout(
              Alert.alert,
              333,
              t('Screens.AccountRemoval.yourAccountHasBeenDeleted'),
            );
          } else {
            Alert.alert(
              t('Screens.AccountRemoval.anUnknownErrorHasOccurred'),
              t(
                'Screens.AccountRemoval.pleaseTryAgainLaterOrContactUsAtHellogetdoppiocom',
              ) || '',
            );
          }
        },
      },
      {style: 'cancel', text: t('Screens.AccountRemoval.cancel') || ''},
    ]);
  }, [dispatchAction, loginedUserProfile.id, remove, t]);

  return (
    <Screen
      navbar={useMemo(
        () => (
          <Navbar />
        ),
        [],
      )}
    >
      <Screen.Heading>
        {t('Screens.AccountRemoval.accountRemovalHeading')}
      </Screen.Heading>
      <Spacer />
      {isPossibleToRemoveAccount ? (
        <>
          <Text style={body}>
            {t('Screens.AccountRemoval.generalWarning.youCanDelete')}{' '}
            <Text style={mediumBody}>
              {t('Screens.AccountRemoval.generalWarning.doppio')}
            </Text>{' '}
            {t('Screens.AccountRemoval.generalWarning.accountAnyTime')}
          </Text>
          <Spacer />
          <CheckboxItem
            disabled={loading}
            status={
              state.consent < initialState.consent ? 'checked' : 'unchecked'
            }
            onPress={togglePlaceConsent}
          >
            <Text style={body}>
              {t('Screens.AccountRemoval.iUnderstandThat.profileOfMyCompany')}{' '}
              <Text style={mediumBody}>{workplace.name}</Text>{' '}
              {t('Screens.AccountRemoval.iUnderstandThat.willBeDeleted')}
            </Text>
          </CheckboxItem>
          {state.consent < initialState.consent ? (
            <>
              <CheckboxItem
                disabled={loading}
                status={state.consent === 0 ? 'checked' : 'unchecked'}
                onPress={toggleCustomerConsent}
              >
                <Text style={body}>
                  {t(
                    'Screens.AccountRemoval.iUnderstandThatAllOfMyCompanyCustomersWillLoseAny',
                  )}
                </Text>
              </CheckboxItem>
            </>
          ) : null}
          <Spacer />
          {state.consent === 0 ? (
            <>
              <Text style={body}>
                {t('Screens.AccountRemoval.confirmHint', {
                  context: Platform.OS,
                })}
              </Text>

              <Spacer />
              <Text style={mediumBody}>
                {t(
                  'Screens.AccountRemoval.noteAnyUnusedPortionOfAnActiveSubscriptionPeriodWi',
                )}
              </Text>
              <Spacer />
              <Pusher />
              <ThemeProvider colors={{brand: colors.red}}>
                <Button
                  title={t('Screens.AccountRemoval.removeAccount')}
                  onPress={loading ? undefined : removeAccount}
                  loading={loading}
                />
              </ThemeProvider>
              <Spacer />
            </>
          ) : (
            <Pusher />
          )}
        </>
      ) : (
        <>
          <Text style={mediumBody}>
            {t('Screens.AccountRemoval.yourSubscriptionIsActive')}
          </Text>
          <Spacer />
          <Text style={body}>
            {t(
              'Screens.AccountRemoval.toDeleteAnAccountPleaseGoToYourSubscriptionManagem',
            )}
          </Text>
          <Pusher />

          {Platform.OS !== 'web' ? (
            <Button.Primary
              title={t('Screens.AccountRemoval.manageSubscription')}
              onPress={manageSubscription}
            />
          ) : null}
          <Spacer />
        </>
      )}

      <Button.Secondary
        disabled={loading}
        onPress={goBack}
        title={
          state.consent === 0
            ? t('Screens.AccountRemoval.cancel')
            : t('Screens.AccountRemoval.close')
        }
        style={pbsStyle}
      />
    </Screen>
  );
}
