import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {createMigrate, persistReducer} from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  TooltipOptions,
  TooltipsSliceState,
  TooltipKeys,
  tooltipsMigrations,
  tooltipPersistActualVersion,
} from '@store/tooltipsMigrations';

const InitialState: TooltipsSliceState = {
  [TooltipKeys.dashboard]: {shown: false},
  [TooltipKeys.dashboardAdvanced]: {shown: false},
};

const {reducer: tooltipsReducer, actions} = createSlice({
  name: 'tooltips',
  initialState: InitialState,
  reducers: {
    set: (
      state,
      action: PayloadAction<{
        key: keyof typeof TooltipKeys;
        tooltipOptions: TooltipOptions;
      }>,
    ) => {
      state[action.payload.key] = action.payload.tooltipOptions;
    },
  },
});

const reducer = persistReducer(
  {
    key: 'tooltips',
    version: tooltipPersistActualVersion,
    storage: AsyncStorage,
    migrate: createMigrate(tooltipsMigrations, {debug: true}),
  },
  tooltipsReducer,
);

export {reducer, actions, TooltipKeys};
