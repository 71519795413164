import {Platform, Share, ShareOptions} from 'react-native';
import {Logger} from '@b2cmessenger/doppio-shared';

export async function shareURL(url: string, options?: ShareOptions) {
  try {
    const res = await Share.share(
      Platform.OS === 'ios' ? {url} : {message: url},
      options,
    );
    if (res.action === Share.sharedAction) {
      return res.activityType ? res.activityType : 'shared';
    }
  } catch (e) {
    Logger.errorTag('shareURL', e.message);
  }

  return null;
}
