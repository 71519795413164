import {Card, Spacer, Typography} from '@b2cmessenger/doppio-components';
import {colors} from '@b2cmessenger/doppio-shared';
import {IconCry} from '@components/common/icons/SvgIcon';
import {StyleSheet, Text} from 'react-native';
import React from 'react';
import {useTranslation} from '../lib/Localization/useTranslation';

function ErrorCard({error}: {error: Error}) {
  const {t} = useTranslation();

  const description =
    typeof error === 'string'
      ? error
      : error?.message ?? t('Shared.ErrorCard.anErrorHasOccurred');

  return (
    <Card backgroundColor={colors.black} style={styles.container}>
      <IconCry size={44} />
      <Spacer />
      <Text style={[Typography.mediumBody, {color: colors.white}]}>
        {description}
      </Text>
    </Card>
  );
}
const styles = StyleSheet.create({
  container: {alignItems: 'center'},
});

export default ErrorCard;
